import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Tooltip,
} from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BigNumber, ethers } from 'ethers';
import { get } from 'lodash';

import { getOptionBalance } from 'graphql/queries';
import { useApproveMarket, useDenominatorAddress } from 'hooks';
import { useWeb3 } from 'state/application/hooks';
import { useTokenBalance } from 'state/wallet/hooks';
import { useOptionSettings } from 'state/options/hooks';
import { formatNumber } from 'utils/formatNumber';
import { OrderType } from 'state/market/reducer';
import {
  useTradeSettings,
  useBuy,
  useSell,
  useBuyNewOption,
  useTradeQuote,
} from 'state/market/hooks';

import { Loader } from 'components';
import { DEFAULT_DECIMALS } from '../../constants';
import { formatUnits } from 'ethers/lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  optionsHeld: {
    textAlign: 'center',
    marginTop: '48px',
    marginBottom: '48px',
  },

  fieldLabel: {
    width: '160px',
    textAlign: 'left',
    paddingBottom: '8px',
  },

  spacer: {
    width: '25%',
  },

  row: {
    width: '100%',
    marginTop: 8,
  },

  fieldRow: {
    width: '100%',
    marginTop: 24,
    marginBottom: 48,
  },

  secondFieldRow: {
    width: '100%',
    marginTop: -32,
    marginBottom: 64,
  },

  footerRow: {
    width: '100%',
    marginBottom: 48,
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  fieldHint: {
    position: 'absolute',
    bottom: -20,
    right: 0,
  },

  buyButton: {
    minWidth: 100,
    backgroundColor: theme.palette.success.main,
  },

  sellButton: {
    minWidth: 100,
    backgroundColor: theme.palette.error.main,
  },

  thin: {
    fontWeight: 200,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '104%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  greenText: {
    color: theme.palette.success.main,
  },

  tradeButtonStyle: {
    minWidth: 100,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  tradePaperStyle: {
    minWidth: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }

}));

const OptionTradePanel: React.FC = () => {
  const { watch, register, setValue } = useForm();
  const { account } = useWeb3();
  const [ignoreDifference, setIgnoreDifference] = useState<boolean>(false);
  const denominatorAddress = useDenominatorAddress();
  const classes = useStyles();

  const {
    denominator,
    selectedToken,
    selectedOption,
    strikePrice,
    setOptionSettings,
  } = useOptionSettings();
  const { orderType, quantity, price, setTradeSettings } = useTradeSettings();
  const expectedFee = useTradeQuote();

  const onBuy = useBuy(selectedOption);
  const onBuyNew = useBuyNewOption();
  const onSell = useSell(selectedOption);

  const quantityValue = watch('quantity') || 0;
  const priceValue = watch('price') || 0;

  const { loading: balanceLoading, data: balanceData } = useQuery(
    getOptionBalance,
    {
      skip: !account || !selectedOption,
      variables: {
        id: `${account.toLowerCase()}.${selectedOption?.id.toString()}`,
      },
      pollInterval: 2000,
    },
  );

  const optionBalance: BigNumber = get(
    balanceData,
    'optionBalance.balance',
    BigNumber.from(0),
  );

  const denominatorBalance = useTokenBalance(
    account,
    get(balanceData, 'optionBalance.option.denominator'),
  );

  const {
    loading: approvalLoading,
    approvedDenominator,
    tokenAllowance,
    onApproveToken,
    onApproveDenominator,
  } = useApproveMarket(denominatorAddress ?? '', denominator);

  const handleApprove = useCallback(async () => {
    try {
      if (!approvedDenominator) {
        await onApproveDenominator();
      }

      if (!tokenAllowance.gt(0)) {
        await onApproveToken();
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    tokenAllowance,
    approvedDenominator,
    onApproveDenominator,
    onApproveToken,
  ]);

  useEffect(() => {
    if (!ignoreDifference && price !== priceValue) {
      setValue('price', price);
    }

    if (!ignoreDifference && quantity !== quantityValue) {
      setValue('quantity', quantity);
    }
  }, [ignoreDifference, price, priceValue, quantity, quantityValue, setValue]);

  return (
    <Paper>
      <Grid item>
        <Typography className={classes.optionsHeld}>
          You hold{' '}
          {balanceLoading ? (
            <Loader />
          ) : (
            ethers.utils.formatUnits(
              optionBalance.toString(),
              selectedToken?.decimals ?? DEFAULT_DECIMALS,
            )
          )}{' '}
          of these options
        </Typography>

        <Grid container justify='center' className={classes.row}>
          <Grid item container justify='center' direction='row'>
            <Typography
              variant='body2'
              color='textSecondary'
              className={classes.fieldLabel}
            >
              Order Type
            </Typography>{' '}
            <div className={classes.spacer} />
          </Grid>

          <Grid item xs={4}>
            <Button
              variant='contained'
              color={orderType === 'Limit' ? 'primary' : 'secondary'}
              fullWidth
              className={classes.tradeButtonStyle}
              onClick={() => setTradeSettings({ orderType: OrderType.Limit })}
            >
              Limit
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Box clone height='100%'>
              <Tooltip title='Feature coming soon'>
                <Paper className={classes.tradePaperStyle}>
                  <Typography
                    variant='body1'
                    style={{ fontSize: '0.875rem' }}
                  >
                    Market
                  </Typography>
                </Paper>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        {!selectedOption?.id && (
          <Grid container justify='center' className={classes.fieldRow}>
            <Box width={2 / 3}>
              <InputLabel>Strike Price</InputLabel>
              <FormControl style={{ width: '100%' }}>
                <OutlinedInput
                  name='strikePrice'
                  type='number'
                  value={strikePrice ?? ''}
                  inputRef={register}
                  onChange={(event) => {
                    setOptionSettings({ strikePrice: event.target.value });
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        )}

        <Grid
          container
          justify='center'
          className={
            selectedOption?.id ? classes.fieldRow : classes.secondFieldRow
          }
        >
          <Box width={2 / 3} position='relative'>
            <InputLabel>Quantity</InputLabel>
            <FormControl style={{ width: '100%' }}>
              <OutlinedInput
                name='quantity'
                type='number'
                value={quantityValue === 0 ? '' : quantityValue}
                inputRef={register}
                onKeyDown={() => setIgnoreDifference(true)}
                onChange={(event) => {
                  setTradeSettings({ quantity: event.target.value });
                  setIgnoreDifference(false);
                }}
              />
            </FormControl>

            <Typography
              color='textSecondary'
              variant='body2'
              className={classes.fieldHint}
            >
              (Total: {formatNumber(quantityValue)} {selectedToken?.symbol})
            </Typography>
          </Box>
        </Grid>

        {orderType === 'Limit' && (
          <Grid container justify='center' className={classes.secondFieldRow}>
            <Box width={2 / 3} position='relative'>
              <InputLabel>Price</InputLabel>
              <FormControl style={{ width: '100%' }}>
                <OutlinedInput
                  name='price'
                  type='number'
                  value={priceValue === 0 ? '' : priceValue}
                  inputRef={register}
                  onKeyDown={() => setIgnoreDifference(true)}
                  onChange={(event) => {
                    setTradeSettings({ price: event.target.value });
                    setIgnoreDifference(false);  
                  }}
                />
              </FormControl>

              <Typography
                color='textSecondary'
                variant='body2'
                className={classes.fieldHint}
              >
                (Total: {formatNumber(quantityValue * priceValue)} {denominator}
                )
              </Typography>
            </Box>
          </Grid>
        )}

        {quantity && price && (
          <Box clone marginBottom={2}>
            <Grid container justify='center'>
              <Box width={2 / 3}>
                <Grid container justify='space-between'>
                  <Typography variant='body2' className={classes.thin}>
                    Protocol Fee:
                  </Typography>
                  {expectedFee !== null ? (
                    <Typography variant='body2'>
                      <span className={classes.putRed}>{expectedFee}</span>{' '}
                      <span className={classes.thin}>{denominator}</span>
                    </Typography>
                  ) : (
                    <Loader />
                  )}
                </Grid>
              </Box>
            </Grid>
          </Box>
        )}

        <Grid container justify='center' className={classes.footerRow}>
          {approvalLoading ? (
            <Loader />
          ) : (
            <>
              {approvedDenominator && tokenAllowance.gt(0) ? (
                <>
                  {Number(quantity) > Number(denominatorBalance) ? (
                    <Tooltip title='Insufficient balance.'>
                      <Box width={1 / 3} marginRight={1}>
                        <Button
                          disabled
                          fullWidth
                          variant='contained'
                          className={classes.buyButton}
                        >
                          Buy
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box clone width={1 / 3} marginRight={1}>
                      <Button
                        disabled={!selectedOption?.id && !strikePrice}
                        variant='contained'
                        className={classes.buyButton}
                        onClick={() =>
                          selectedOption?.id
                            ? onBuy(selectedOption)
                            : onBuyNew()
                        }
                      >
                        Buy
                      </Button>
                    </Box>
                  )}

                  {Number(quantity) >
                  Number(
                    formatUnits(optionBalance, selectedOption?.token.decimals),
                  ) ? (
                    <Tooltip title='Insufficient balance.'>
                      <Box width={1 / 4} marginLeft={1}>
                        <Button
                          disabled
                          fullWidth
                          variant='contained'
                          className={classes.sellButton}
                        >
                          Sell
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box clone width={1 / 4} marginLeft={1}>
                      <Button
                        variant='contained'
                        className={classes.sellButton}
                        onClick={() => onSell(selectedOption)}
                      >
                        Sell
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Box clone width={1 / 2}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleApprove}
                  >
                    Approve Market
                    <br />(
                    {approvedDenominator || tokenAllowance.gt(0)
                      ? '2 / 2'
                      : '1 / 2'}
                    )
                  </Button>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OptionTradePanel;
