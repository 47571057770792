import React, { useCallback } from 'react';
import { Typography, Modal, Grid, Button, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { formatUnits } from 'ethers/lib/utils';

import {
  convertGraphMarketOrderToOrder,
  MarketOrder,
  SaleSide,
} from 'web3/market';
import { formatNumber } from 'utils/formatNumber';
import { useWeb3 } from 'state/application/hooks';
import { useTransact } from 'hooks';
import { DEFAULT_DECIMALS } from '../../../../constants';

import { ModalContainer, OptionTitle } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  buyGreen: {
    color: theme.palette.success.main,
  },

  sellRed: {
    color: theme.palette.error.main,
  },

  thinText: {
    fontWeight: 300,
  },
}));

export interface CancelOrderModalProps {
  open: boolean;
  order: MarketOrder;
  onClose: () => void;
}

const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  open,
  order,
  onClose,
}) => {
  const { contracts } = useWeb3();
  const transact = useTransact();
  const classes = useStyles();

  const onCancelOrder = useCallback(async () => {
    const _order = convertGraphMarketOrderToOrder(order);

    await transact(contracts.premiaMarket?.cancelOrder(_order), {
      option: order.option,
      description: `Cancel existing ${
        order.side === SaleSide.Buy ? 'buy' : 'sell'
      } order`,
    });

    onClose();
  }, [contracts, order, transact, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Cancel Open Order</Typography>
        </Box>

        <OptionTitle hideSubtitle option={order.option} />

        <Box width={1 / 2} marginX='auto' marginTop={4}>
          <Grid container direction='row' justify='space-around'>
            <Grid item>
              <Typography variant='body2'>Price</Typography>
              <Typography variant='body2'>Quantity</Typography>
              <Typography variant='body2'>Total</Typography>
              <Typography variant='body2'>Side</Typography>
            </Grid>

            <Grid item>
              <Typography variant='body2' align='right'>
                <b>
                  {formatNumber(
                    formatUnits(order.pricePerUnit, DEFAULT_DECIMALS),
                  )}
                </b>{' '}
                <span className={classes.thinText}>
                  {order.paymentToken.symbol} / {order.pair.token.symbol}
                </span>
              </Typography>

              <Typography variant='body2' align='right'>
                <b>
                  {formatNumber(
                    formatUnits(
                      order.remainingUnfilled,
                      order.option.token.decimals,
                    ),
                  )}
                </b>{' '}
                <span className={classes.thinText}>
                  {order.pair.token.symbol}
                </span>
              </Typography>

              <Typography variant='body2' align='right'>
                <b>
                  {formatNumber(
                    Number(formatUnits(order.pricePerUnit, DEFAULT_DECIMALS)) *
                      Number(
                        formatUnits(
                          order.remainingUnfilled,
                          order.option.token.decimals,
                        ),
                      ),
                  )}
                </b>{' '}
                <span className={classes.thinText}>
                  {order.paymentToken.symbol}
                </span>
              </Typography>

              <Typography
                variant='body2'
                align='right'
                className={
                  order.side === SaleSide.Buy
                    ? classes.buyGreen
                    : classes.sellRed
                }
              >
                {order.side}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box width={1 / 2} marginX='auto' marginY={2}>
          <Button
            fullWidth
            variant='contained'
            color='secondary'
            onClick={onCancelOrder}
          >
            Cancel Order
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default CancelOrderModal;
