import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Box, Button, TableCell, TableRow } from '@material-ui/core';
import { BigNumber, ethers } from 'ethers';
import { get } from 'lodash';
import { Contract, ContractCall } from 'ethers-multicall';
import { formatUnits } from 'ethers/lib/utils';
import moment from 'moment';
import cx from 'classnames';

import { getFilteredOptionsWithOrders } from 'graphql/queries';

import { OptionWithOrders, OptionType } from 'web3/options';
import {
  SaleSide,
  MarketOrder,
  IOrder,
  convertGraphMarketOrderToOrder,
} from 'web3/market';
import { useFilterSettings } from 'state/market/hooks';
import { usePrices, useWeb3 } from 'state/application/hooks';
import { useDebounce } from 'hooks';
import { formatCompact } from 'utils/formatNumber';
import { DEFAULT_DECIMALS } from '../../constants';
import PremiaMarketAbi from 'constants/abi/PremiaMarket.json';
import getDenominatorLogo from 'utils/getDenominatorLogo';

import {
  DataTable,
  TradeOptionModal,
  TradeNewOptionModal,
  CurrencyLogo,
} from 'components';
import { HeadCell } from 'components/DataTable';
import WalletTableToolbar from './OptionsTableToolbar';

const getHeadCells = (prices: {
  [symbol: string]: number;
}): HeadCell<OptionWithOrders>[] => [
  {
    id: 'expiration',
    numeric: false,
    label: 'Expiration',
    sortKey: (option: OptionWithOrders) => option?.expiration.toString(),
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (option: OptionWithOrders) =>
      `${option?.token}/${option?.denominator}`,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortKey: (option: OptionWithOrders) => option.type,
  },
  {
    id: 'strike',
    numeric: true,
    label: 'Strike',
    sortKey: (option: OptionWithOrders) => Number(option.strikePrice),
  },
  {
    id: 'open_interest',
    numeric: true,
    label: 'OI',
    sortKey: (option: OptionWithOrders) =>
      Number(option.openInterest) * prices[option.token.symbol],
  },
  {
    id: 'total_volume',
    numeric: true,
    label: 'Volume',
    sortKey: (option: OptionWithOrders) =>
      Number(option.totalVolume) * prices[option.token.symbol],
  },
  {
    id: 'price_spread',
    numeric: true,
    label: 'Spread',
    sortKey: (option: OptionWithOrders) => 1,
  },
  {
    id: 'actions',
    numeric: true,
    label: 'Actions',
    sortKey: (option: OptionWithOrders) => 1,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bidText: {
      color: theme.palette.success.main,
    },
    askText: {
      color: theme.palette.error.main,
    },
    greyText: {
      fontWeight: 200,
    },
    thinFont: {
      fontWeight: 300,
    },
    spacedText: {
      marginLeft: '0.25rem',
    },
    separator: {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
    },
  }),
);

export interface MarketOptionsTableProps {
  filter: { [filterOption: string]: any };
}

export const MarketOptionsTable: React.FC<MarketOptionsTableProps> = ({
  filter,
}) => {
  const classes = useStyles();
  const { account, contracts, multicallProvider } = useWeb3();
  const [tradeNewOptionModalOpen, setTradeNewOptionModalOpen] = useState(false);
  const [validOptions, setValidOptions] = useState<OptionWithOrders[]>([]);
  const [optionToTrade, setOptionToTrade] = useState<null | OptionWithOrders>(
    null,
  );

  const { priceRange } = useFilterSettings();
  const prices = usePrices();
  const headCells = getHeadCells(prices);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: ordersLoading, data, fetchMore }] = useLazyQuery(
    getFilteredOptionsWithOrders,
    {
      variables: { first: 100, skip, where: filter }
    },
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsListed = useDebounce(
    useMemo(
      () => { 
        if (!!data?.options?.length) {
          setResult(data?.options || []);
        }
        return get(data, 'options', [])
          .filter((option: OptionWithOrders) => option?.orders?.length > 0)
          .filter(({ orders }: OptionWithOrders) =>
            orders.find(
              ({ remainingUnfilled }) => remainingUnfilled > BigNumber.from(0),
            ),
          )
          .filter(
            ({ orders }: OptionWithOrders) =>
              !priceRange ||
              orders.find(({ pricePerUnit }) => {
                const price = ethers.utils.formatEther(pricePerUnit);

                return (
                  Number(price) >= Number(priceRange.low || 0) &&
                  Number(price) <=
                    Number(priceRange.high || ethers.constants.MaxUint256)
                );
              }),
          );
      },
      [data, priceRange],
    ),
    250,
  );

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          options: isFirstPage
            ? currentResult?.fetchMoreResult?.options
            : [...result, ...currentResult?.fetchMoreResult?.options
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  useEffect(() => {
    if (!account || !contracts.premiaMarket || !multicallProvider) {
      setValidOptions(optionsListed);
      return;
    }

    const marketContract = new Contract(
      contracts.premiaMarket.address,
      PremiaMarketAbi.abi,
    );

    (async () => {
      const optionsOrNull: (OptionWithOrders | null)[] = await Promise.all(
        optionsListed.map(async (option: OptionWithOrders) => {
          const sortedOrders = option.orders.map((order: MarketOrder) =>
            convertGraphMarketOrderToOrder(order),
          );

          const isValidCalls: ContractCall[] = sortedOrders.map(
            (order: IOrder) => marketContract.isOrderValid(order),
          );

          const isValidResults = await multicallProvider.all(isValidCalls);

          return sortedOrders.some(
            (order: IOrder, i: number) => isValidResults[i],
          )
            ? option
            : null;
        }),
      );

      const _validOptions = optionsOrNull.filter(
        (option: OptionWithOrders | null) => option !== null,
      ) as OptionWithOrders[];

      setValidOptions(_validOptions);
    })();
  }, [
    account,
    optionsListed,
    contracts.premiaMarket,
    multicallProvider,
    setValidOptions,
  ]);

  return (
    <Grid container>
      <TradeNewOptionModal
        open={tradeNewOptionModalOpen}
        onClose={() => setTradeNewOptionModalOpen(false)}
      />

      {optionToTrade && (
        <TradeOptionModal
          open
          option={optionToTrade}
          onClose={() => setOptionToTrade(null)}
        />
      )}

      <Grid container item justify='flex-end'>
        <Box marginBottom={2}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setTradeNewOptionModalOpen(true)}
          >
            Trade New Option
          </Button>
        </Box>
      </Grid>

      <DataTable
        headCells={headCells}
        data={validOptions}
        loading={ordersLoading}
        caption='* Highlighted options may be In the Money (ITM).'
        toolbar={<WalletTableToolbar />}
        onChange={onChange}
        size={validOptions.length}
        renderRow={(option: OptionWithOrders) => {
          const {
            id,
            type,
            denominator,
            token,
            expiration,
            strikePrice,
            openInterest,
            totalVolume,
            orders,
          } = option;

          const denominatorLogo = getDenominatorLogo(denominator.symbol);
          const price = prices[token.symbol];

          const bidAsk = orders.reduce(
            ({ bid, ask }: any, order) => {
              if (!BigNumber.from(order.remainingUnfilled).gt(0)) {
                return { bid, ask };
              }

              let highBid = bid.value;
              let highBidToken = bid.token;
              let lowAsk = ask.value;
              let lowAskToken = ask.token;

              if (order.side === SaleSide.Buy) {
                highBid =
                  order.pricePerUnit > highBid ? order.pricePerUnit : highBid;
                highBidToken =
                  order.pricePerUnit > highBid
                    ? order.paymentToken.symbol
                    : highBidToken;
              } else {
                lowAsk =
                  order.pricePerUnit < lowAsk ? order.pricePerUnit : lowAsk;
                lowAskToken =
                  order.pricePerUnit < lowAsk
                    ? order.paymentToken.symbol
                    : lowAskToken;
              }

              return {
                bid: { value: highBid, token: highBidToken },
                ask: { value: lowAsk, token: lowAskToken },
              };
            },
            {
              bid: { value: 0, token: denominator.symbol },
              ask: { value: Infinity, token: denominator.symbol },
            },
          );

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={id.toString()}
              style={{ cursor: 'pointer' }}
              onClick={() => setOptionToTrade(option)}
            >
              <TableCell
                component='th'
                scope='row'
                align='center'
                className={classes.greyText}
              >
                {moment(Number(expiration?.toString()) * 1000)
                  .format('MMM. Do, YYYY')
                  .replace(`, ${moment().year()}`, '')}
              </TableCell>

              <TableCell align='center'>
                <CurrencyLogo currency={token} />
                <img
                  src={denominatorLogo}
                  height={16}
                  width={16}
                  alt={denominator.symbol}
                  style={{ marginLeft: '-8px', marginBottom: '-4px' }}
                />
              </TableCell>

              <TableCell align='center'>
                <span
                  className={cx({
                    [classes.askText]: type === OptionType.Put,
                    [classes.bidText]: type === OptionType.Call,
                  })}
                >
                  {type.toUpperCase()}
                </span>
              </TableCell>

              <TableCell align='center'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <span style={{ marginRight: '0.25rem' }}>
                    {formatCompact(formatUnits(strikePrice, DEFAULT_DECIMALS))}
                  </span>

                  <img
                    src={denominatorLogo}
                    height={16}
                    width={16}
                    alt={denominator.symbol}
                  />
                </Grid>
              </TableCell>

              <TableCell align='center' className={classes.thinFont}>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <b style={{ marginRight: '0.25rem' }}>
                    {formatCompact(
                      price *
                        Number(formatUnits(openInterest, DEFAULT_DECIMALS)),
                      1,
                    )}
                  </b>
                  <img
                    src={denominatorLogo}
                    height={16}
                    width={16}
                    alt={denominator.symbol}
                  />
                </Grid>
              </TableCell>

              <TableCell align='center' className={classes.thinFont}>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <b style={{ marginRight: '0.25rem' }}>
                    {formatCompact(
                      price *
                        Number(formatUnits(totalVolume, DEFAULT_DECIMALS)),
                      1,
                    )}
                  </b>
                  <img
                    src={denominatorLogo}
                    height={16}
                    width={16}
                    alt={denominator.symbol}
                  />
                </Grid>
              </TableCell>

              <TableCell align='center'>
                <Grid container wrap='nowrap'>
                  <span className={classes.bidText}>
                    {formatCompact(
                      Number(bidAsk.bid.value) / Math.pow(10, DEFAULT_DECIMALS),
                    )}
                  </span>
                  <span className={cx(classes.spacedText)}>
                    <img
                      src={denominatorLogo}
                      height={16}
                      width={16}
                      alt={denominator.symbol}
                    />
                  </span>
                  <span className={classes.separator}>-</span>
                  <span className={classes.askText}>
                    {formatCompact(
                      Number(bidAsk.ask.value) / Math.pow(10, DEFAULT_DECIMALS),
                    )}
                  </span>{' '}
                  <span className={cx(classes.spacedText)}>
                    <img
                      src={denominatorLogo}
                      height={16}
                      width={16}
                      alt={denominator.symbol}
                    />
                  </span>
                </Grid>
              </TableCell>

              <TableCell align='center'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOptionToTrade(option)}
                >
                  Trade
                </Button>
              </TableCell>
            </TableRow>
          );
        }}
      />
    </Grid>
  );
};

export default MarketOptionsTable;
