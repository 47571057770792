import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SpaceOptionsHero from 'assets/svg/SpaceOptionsHero.svg';

const useStyles = makeStyles({
  scaleDownY: {
    transform: 'scaleY(0.85) translateY(-20px)',
    marginBottom: '-20px',
  },

  title: {
    fontFamily: '"Poppins"',
    fontWeight: 600,
    fontSize: '2rem',
  },

  subtitle: {
    fontFamily: '"Inter"',
    fontSize: '1rem',
    marginTop: 8,
  },

  button: {
    height: 58,
  },
});

const MintHero: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  return (
    <Box position='relative' mb={4}>
      <img
        src={SpaceOptionsHero}
        alt='Hero'
        width='100%'
        className={classes.scaleDownY}
        onLoad={() => setLoading(false)}
      />

      {loading ? null : (
        <Box
          position='absolute'
          p={{ sm: 2, md: 4 }}
          top={0}
          left={0}
          width={1}
          height='100%'
        >
          <Typography
            component='h1'
            variant='h2'
            color='textPrimary'
            className={classes.title}
          >
            Mint Options
          </Typography>

          <Typography
            component='h3'
            color='textPrimary'
            className={classes.subtitle}
          >
            Sell minted options on the marketplace to earn premiums
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MintHero;
