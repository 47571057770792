import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PremiaLogo from 'assets/images/premia-logo.png';
import PremiaLogoGold from 'assets/images/premia-logo-gold.png';
import { StakeMode } from './types';

interface StakeSelectBtnProps {
  mode: StakeMode;
  selected: boolean;
  onClick?: (e: any) => void;
}

const useStyles = makeStyles({
  button: {
    flexGrow: 1,
    margin: 5,
    flexBasis: 0
  },

  containerInside: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  logoBackground: {
    backgroundColor: '#1f2128',
    borderRadius: 12,
    width: 40,
    height: 40,
    border: (props: any) => `1px solid ${props.color}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const StakeSelectBtn: React.FC<StakeSelectBtnProps> = (props) => {
  const classes = useStyles({
    color: props.mode === StakeMode.Premia ? '#66A6FF' : '#CAA64E'
  });

  return (
    <Button
      className={classes.button}
      color={props.selected ? 'primary' : undefined}
      style={{ backgroundColor: props.selected ? '#66A6FF' : undefined }}
      onClick={props.onClick}
    >
      <div className={classes.containerInside}>
        <div className={classes.logoBackground}>
          <img
            width={32}
            alt={`logo-${props.mode}`}
            src={props.mode === StakeMode.Premia ? PremiaLogo : PremiaLogoGold}
          />
        </div>
        <div style={{ fontSize: 12, fontFamily: 'Inter' }}>
          {props.children}
        </div>
      </div>
    </Button>
  );
};

export default StakeSelectBtn;
