import React from 'react';
import { useQuery } from 'react-apollo';
import { MonetizationOn, BarChart, DonutLarge } from '@material-ui/icons';
import { Box, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { formatUnits } from 'ethers/lib/utils';

import { TokenPair } from 'web3/tokens';
import { useDenominatorAddress } from 'hooks';
import { getTokenPairs } from 'graphql/queries';
import { formatCompact } from 'utils/formatNumber';
import { usePrices } from 'state/application/hooks';
import { contracts } from 'web3/contracts';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const statisticsData: any = {
  VALUE_CAPTURED: {
    logo: MonetizationOn,
    label: 'Value Captured',
    progressValue: 0.25,
    progressColor: '#66A6FF',
  },
  TOTAL_VOLUME: {
    logo: BarChart,
    label: 'Total Volume',
    progressValue: 0.25,
    progressColor: '#7FBA7A',
  },
  TOKENS_OFFERED: {
    logo: DonutLarge,
    label: 'Tokens Offered',
    progressValue: 0.25,
    progressColor: '#FF9A7B',
  },
};

const useComponentStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,

    '& svg': {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  },
  wrapperHeaderMobile: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,

    '& svg': {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  },
  label: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '16px',
  },
  value: {
    fontWeight: 500,
    fontSize: 32,
    textAlign: 'center',
    lineHeight: '48px',
    marginBottom: 12,
  },
  valueSmall: {
    fontWeight: 500,
    fontSize: 26,
    textAlign: 'center',
    lineHeight: '44px',
    marginBottom: 2,
  },
  valueMobile: {
    fontWeight: 500,
    fontSize: 22,
    textAlign: 'center',
    lineHeight: '36px',
    marginBottom: 2,
  },
  progressBarBackground: {
    display: 'flex',
    margin: '0 auto',
    height: 2,
    width: 90,
    borderRadius: 1,
    background: '#E4E4E4',
    position: 'relative',
  },
  progressBarTick: {
    display: 'flex',
    margin: '0 auto',
    height: 2,
    borderRadius: 1,
    position: 'absolute',
    left: 0,
    top: 0,
  },
});

export interface StatisticComponentProps {
  unit: string;
  value: string;
  currency?: boolean;
}

const StatisticComponent: React.FC<StatisticComponentProps> = ({
  unit,
  value,
  currency = false,
}) => {
  const classes = useComponentStyles();
  const theme = useTheme();
  const thinDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const StatisticLogo = statisticsData[unit].logo;

  return (
    <Box className={classes.wrapper} component='div'>
      <Box
        className={
          !mobile ? classes.wrapperHeader : classes.wrapperHeaderMobile
        }
        component='div'
      >
        <StatisticLogo />
        <Typography className={classes.label} component='h2'>
          {statisticsData[unit].label}
        </Typography>
      </Box>

      <Typography
        className={
          !thinDesktop && !mobile
            ? classes.value
            : thinDesktop && !mobile
            ? classes.valueSmall
            : classes.valueMobile
        }
        component='span'
      >
        {currency ? '$' : ''}
        {value}
      </Typography>

      <Box
        className={classes.progressBarBackground}
        component='div'
        style={mobile ? { marginBottom: '8px' } : {}}
      >
        <Box
          className={classes.progressBarTick}
          component='div'
          style={{
            width: `${100 * statisticsData[unit].progressValue}%`,
            background: statisticsData[unit].progressColor,
          }}
        ></Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    borderRadius: 16,
    background: '#141414',
    marginBottom: 32,
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '24px 16px',
    marginBottom: 32,
  },
  paperMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100vw - 32px)',
    padding: 8,
    margin: '16px 0',
  },
});

const StatisticsHeader: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const thinDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const prices = usePrices();

  const denominatorAddress = useDenominatorAddress();

  const { data: { tokenPairs = [] } = {} } = useQuery(getTokenPairs, {
    variables: { denominatorAddress: denominatorAddress || contracts.DAI[1] },
  });

  const formatVolume = (
    pairs: TokenPair[],
    type: 'totalVolume' | 'openInterest',
  ) => {
    return formatCompact(
      pairs.reduce(
        (total: number, pair: TokenPair) =>
          total +
          Number(formatUnits(pair[type], pair.token.decimals)) *
            (prices[pair.token.symbol] || 0),
        0,
      ),
      1,
    );
  };

  const totalVolume = formatVolume(tokenPairs, 'totalVolume');
  const valueCaptured = formatVolume(tokenPairs, 'openInterest');

  return (
    <Paper
      className={!mobile ? classes.paper : classes.paperMobile}
      style={thinDesktop ? { marginTop: '18px' } : {}}
    >
      <Grid item xs={7} style={!mobile ? {} : { margin: '12px 8px 6px' }}>
        <StatisticComponent
          currency
          unit='VALUE_CAPTURED'
          value={valueCaptured}
        />
      </Grid>
      <Grid item xs={7} style={!mobile ? {} : { margin: '12px 8px' }}>
        <StatisticComponent currency unit='TOTAL_VOLUME' value={totalVolume} />
      </Grid>
      <Grid item xs={7} style={!mobile ? {} : { margin: '6px 8px 12px' }}>
        <StatisticComponent
          unit='TOKENS_OFFERED'
          value={tokenPairs.length.toString()}
        />
      </Grid>
    </Paper>
  );
};

export default StatisticsHeader;
