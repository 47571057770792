import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBlockNumber, useWeb3 } from 'state/application/hooks';
import { setStakingBalances } from './actions';
import { useStakingBalances } from './hooks';

export default function Updater(): null {
  const { account, contracts } = useWeb3();
  const {
    xPremiaLocked,
    xPremiaBalance,
    totalPremiaStaked,
    xPremiaSupply,
  } = useStakingBalances();
  const dispatch = useDispatch();

  const latestBlockNumber = useBlockNumber();

  useEffect(() => {
    contracts?.premiaMining
      ?.pendingPremia(0, account)
      ?.then((r) => dispatch(setStakingBalances({ unclaimedPremia: r })));

    contracts?.premia
      ?.balanceOf(account)
      ?.then((r) => dispatch(setStakingBalances({ premiaBalance: r })));

    contracts?.xPremia
      ?.balanceOf(account)
      ?.then((r) => dispatch(setStakingBalances({ xPremiaBalance: r })));

    contracts?.premiaFeeDiscount
      ?.userInfo(account)
      ?.then((info) =>
        dispatch(setStakingBalances({ xPremiaLocked: info.balance })),
      );

    contracts?.premiaFeeDiscount
      ?.getStakeAmountWithBonus(account)
      ?.then((r) => dispatch(setStakingBalances({ xPremiaStakeWithBonus: r })));

    contracts?.premiaFeeDiscount
      ?.getDiscount(account)
      ?.then((r) => dispatch(setStakingBalances({ xPremiaFeeDiscount: r })));

    if (contracts.xPremia) {
      contracts?.premia
        ?.balanceOf(contracts?.xPremia.address)
        .then((r) => dispatch(setStakingBalances({ totalPremiaStaked: r })));
    }

    contracts?.xPremia
      ?.balanceOf(account)
      .then((r) => dispatch(setStakingBalances({ premiaStaked: r })));

    contracts.premiaFeeDiscount
      ?.userInfo(account)
      .then((r) => dispatch(setStakingBalances({ xPremiaLocked: r.balance })));

    contracts.xPremia
      ?.totalSupply()
      .then((r) => dispatch(setStakingBalances({ xPremiaSupply: r })));
  }, [contracts, dispatch, latestBlockNumber, account]);

  useEffect(() => {
    if (xPremiaSupply.eq(0)) return;

    const underlyingPremia = xPremiaBalance
      .add(xPremiaLocked)
      .mul(totalPremiaStaked)
      .div(xPremiaSupply);

    dispatch(setStakingBalances({ underlyingPremia }));
  }, [
    dispatch,
    xPremiaBalance,
    xPremiaLocked,
    totalPremiaStaked,
    xPremiaSupply,
  ]);

  return null;
}
