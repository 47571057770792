import React, { useCallback, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { Scatter } from 'react-chartjs-2';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';

import { ERC2612PermitMessage, signERC2612Permit } from 'eth-permit/eth-permit';
import { RSV } from 'eth-permit/rpc';
import { useBondingCurve } from 'state/bondingCurve/hooks';
import { useWeb3 } from 'state/application/hooks';
import { useTransact } from 'hooks';
import formatNumber from 'utils/formatNumber';

import { PageWithSidebar } from 'layouts';
import { Hero } from 'components';
import { HeroVariant } from 'components/Hero';
import Arrow from 'assets/svg/Arrow.svg';
import { Container, SubTitle, Title } from '../../components/StyledPage';
interface BondingCurveState {
  mode: 'buy' | 'sell';
  premiaAmount: number;
  permit?: ERC2612PermitMessage & RSV;
  permitDeadline?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    inputContainer: {
      marginTop: 5,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },

    inputToken: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: 10,
      color: '#808191'
    },

    input: {
      backgroundColor: 'transparent',
      borderRadius: 12,
      outline: 'none',
      color: 'white',
      border: '2px solid #b151e8',
      boxShadow: '2px 4px 4px 2px rgba(0, 0, 0, 0.25)',
      padding: 10,
      width: 250,
    
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },

      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },

      '&.premia': {
        border: '2px solid #3edaffaa'
      }
    },

    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    subContainer: {
      boxShadow: '2px 4px 4px 2px rgba(0, 0, 0, 0.25)',
      border: '1px solid #66a6ff',
      borderRadius: 24,
      paddingBottom: 30
    },

    infoContainer: {
      borderRadius: 12,
      border: '2px solid #3edaff',
      margin: '10px 0',
      padding: '5px 20px',
      fontSize: '11px',
      boxShadow: '2px 4px 4px 2px rgba(0, 0, 0, 0.25)',
      background: '#1f2128',
      textAlign: 'center'
    },

    explainer: {
      margin: '20px 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '5px 20px',
    
      border: '2px solid rgba(228, 228, 228, 0.1)',
      boxShadow: '2px 4px 4px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: 24
    },

    explainerText: {
      fontFamily: 'Roboto Mono',
      fontStyle: 'normal',
      fontSize: 10,
      lineHeight: 20
    },

    btnSelect: {
      color: 'white',
      background: '#66a6ff',
      borderRadius: 16,
      width: 140,
      margin: '0 5px',
      
      '&.disabled': {
        border: '2px solid rgba(228, 228, 228, 0.1)',
        background: undefined
      }
    }

  }),
);

const BondingCurve: React.FC = () => {
  const { chainId, ethereum, account, signer, contracts } = useWeb3();
  const transact = useTransact();
  const classes = useStyles();

  const [state, setState] = useState<BondingCurveState>({
    mode: 'buy',
    premiaAmount: 0,
  });
  const bondingCurve = useBondingCurve();

  const currentAmount = Number(formatEther(bondingCurve.premiaSold));
  const premiaAvailable = Number(formatEther(bondingCurve.premiaAvailable));

  const startPrice = BigNumber.from('1919488000000000');
  const k = BigNumber.from('1800000000');
  const maxSlippage = 0.05;

  const startPriceFloat = 0.0019;
  const endPriceFloat = 0.031;

  const getEthCost = useCallback(
    (premiaSold: BigNumber, offset: number) => {
      const withOffset = premiaSold.add(parseEther(offset.toString()));

      let x0: BigNumber;
      let x1: BigNumber;

      if (premiaSold.gt(withOffset)) {
        x0 = withOffset;
        x1 = premiaSold;
      } else {
        x0 = premiaSold;
        x1 = withOffset;
      }

      return x1
        .add(x0)
        .mul(x1.sub(x0))
        .div(2)
        .div(k)
        .add(startPrice.mul(x1.sub(x0)))
        .div(parseEther('1'));
    },
    [k, startPrice],
  );

  function handlePremiaAmountChange(e: any) {
    const value = e.target.value;

    let valueClamped = clampPremiaAmount(value, state.mode);

    setState((s) => ({
      ...s,
      premiaAmount: valueClamped,
      permit: undefined,
      permitDeadline: undefined,
    }));
  }

  function clampPremiaAmount(value: number, mode: 'buy' | 'sell') {
    let valueClamped: number;
    if (mode === 'buy') {
      valueClamped = Math.min(Number(value), premiaAvailable);
    } else {
      valueClamped = Math.min(Number(value), 25000000 - premiaAvailable);
    }

    return valueClamped;
  }

  const handleSignPermit = useCallback(async () => {
    if (!signer || !contracts.premia || !contracts.premiaBondingCurve) return;

    const deadline = Math.floor(new Date().getTime() / 1000 + 3600);

    const result = await signERC2612Permit(
      ethereum,
      contracts.premia.address,
      account,
      contracts.premiaBondingCurve.address,
      parseEther(state.premiaAmount.toString()).toString(),
      deadline,
    );

    setState((s) => ({ ...s, permit: result, permitDeadline: deadline }));
  }, [signer, account, state.premiaAmount, contracts, setState, ethereum]);

  const handleConfirm = useCallback(async () => {
    let ethAmount = Number(
      formatEther(
        getEthCost(
          bondingCurve.premiaSold,
          state.mode === 'buy' ? state.premiaAmount : -state.premiaAmount,
        ),
      ),
    );

    if (state.mode === 'buy') {
      ethAmount *= 1 + maxSlippage;

      await transact(
        contracts?.premiaBondingCurve?.buyExactTokenAmount(
          parseEther(state.premiaAmount.toString()),
          { value: parseEther(ethAmount.toString()) },
        ),
        {
          description: `Buying ${state.premiaAmount} PREMIA for ${ethAmount} ETH`,
        },
      );
    } else {
      if (!state.permitDeadline || !state.permit) return;

      ethAmount *= 1 - maxSlippage;

      await transact(
        contracts?.premiaBondingCurve?.sellWithPermit(
          parseEther(state.premiaAmount.toString()),
          chainId === 4 ? 0 : parseEther(ethAmount.toString()),
          state.permitDeadline,
          state.permit.v,
          state.permit.r,
          state.permit.s,
        ),
        {
          description: `Selling ${state.premiaAmount} PREMIA for ${ethAmount} ETH`,
        },
      );
    }
  }, [
    chainId,
    transact,
    contracts,
    state.mode,
    state.premiaAmount,
    bondingCurve.premiaSold,
    state.permit,
    state.permitDeadline,
    getEthCost,
  ]);

  let ethAmount = Number(
    formatEther(
      getEthCost(
        bondingCurve.premiaSold,
        state.mode === 'buy' ? state.premiaAmount : -state.premiaAmount,
      ),
    ),
  );

  if (state.mode === 'buy') {
    // Add max slippage
    ethAmount *= 1 + maxSlippage;
  } else {
    // Subtract 10% fee
    ethAmount *= 0.9;
  }

  return (
    <PageWithSidebar>
      <Grid container>
        <Typography
          component='h2'
          variant='h3'
          color='textPrimary'
          style={{
            fontFamily: '"Teko"',
            fontWeight: 600,
            fontSize: 48,
            marginBottom: 32,
          }}
        >
          Premia Bonding Curve
        </Typography>

        <Hero
          variant={HeroVariant.BondingCurve}
          title={'Ethereum Bonding Curve'}
          subTitle={'Acquire or sell Premia tokens via linear bonding curve'}
        />

        <Container>
          <Title>Linear Bonding Curve</Title>
          <SubTitle>Buy or Sell PREMIA Tokens</SubTitle>

          <div style={{ margin: '0 30px' }}>
            <div className={classes.explainer}>
              <div className={classes.explainerText}>
                The Premia token price increases as the supply of the token
                increases. When a new buyer acquires Premia tokens, each buyer
                there after will have to pay a slightly higher price for each
                token. As more people will discover Premia and buying continues,
                the value of each token gradually increases with the bonding
                curve. ETH-PREMIA fees are 0% while PREMIA-ETH fees are 10%.
              </div>
            </div>

            <div className={classes.subContainer}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 0',
                }}
              >
                <Button
                  className={cn(state.mode === 'buy' ? '': 'disabled', classes.btnSelect)}
                  variant={state.mode === 'buy' ? 'contained' : undefined}
                  color={state.mode === 'buy' ? 'primary' : undefined}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      mode: 'buy',
                      premiaAmount: clampPremiaAmount(s.premiaAmount, 'buy'),
                    }))
                  }
                >
                  Buy
                </Button>
                <Button
                  className={cn(state.mode === 'sell' ? '': 'disabled', classes.btnSelect)}
                  variant={state.mode === 'sell' ? 'contained' : undefined}
                  color={state.mode === 'sell' ? 'primary' : undefined}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      mode: 'sell',
                      premiaAmount: clampPremiaAmount(s.premiaAmount, 'sell'),
                    }))
                  }
                >
                  Sell
                </Button>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 20px',
                }}
              >
                <div className={classes.inputGroup}>
                  <SubTitle>
                    {state.mode === 'buy'
                      ? 'You are buying this amount of PREMIA'
                      : 'You are selling this amount of PREMIA'}
                  </SubTitle>
                  <div className={classes.inputContainer}>
                    <input
                      className={cn(classes.input, 'premia')}
                      type='number'
                      value={state.premiaAmount}
                      onChange={handlePremiaAmountChange}
                    />
                    <div className={classes.inputToken}>PREMIA</div>
                  </div>
                </div>

                <img src={Arrow} alt='Arrow' style={{ marginTop: 20 }} />

                <div className={classes.inputGroup}>
                  <SubTitle>
                    {state.mode === 'buy'
                      ? 'You are paying this amount of ETH'
                      : 'You are receiving this amount of ETH'}
                  </SubTitle>
                  <div className={classes.inputContainer}>
                    <input className={classes.input} type='number' value={ethAmount} />
                    <div className={classes.inputToken}>ETH</div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                }}
              >
                <div
                  style={{
                    width: 400,
                    // border: '1px solid #3edaff',
                    marginTop: 5,
                    borderRadius: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Scatter
                    // height={150}
                    // width={400}
                    plugins={[
                      {
                        beforeRender: (x: any, options: any) => {
                          const c = x.chart;
                          const dataset = x.data.datasets[0];
                          const xScale = x.scales['x-axis-1'];

                          const xPos0 = xScale.getPixelForValue(
                            x.data.datasets[0].data[1].x,
                          );

                          const xPos1 = xScale.getPixelForValue(
                            x.data.datasets[0].data[2].x,
                          );

                          const unfilledColor = 'rgb(177,81,232, 0.2)';
                          const partialFillColor = 'rgb(177,81,232, 0.5)';
                          const filledColor = 'rgb(177,81,232, 0.8)';

                          const gradientFill = c.ctx.createLinearGradient(
                            0,
                            0,
                            c.width,
                            0,
                          );

                          gradientFill.addColorStop(0, filledColor);

                          gradientFill.addColorStop(
                            xPos0 / c.width,
                            filledColor,
                          );

                          gradientFill.addColorStop(
                            xPos0 / c.width,
                            partialFillColor,
                          );

                          gradientFill.addColorStop(
                            xPos1 / c.width,
                            partialFillColor,
                          );

                          gradientFill.addColorStop(
                            xPos1 / c.width,
                            unfilledColor,
                          );

                          gradientFill.addColorStop(1, unfilledColor);

                          const model =
                            x.data.datasets[0]._meta[
                              Object.keys(dataset._meta)[0]
                            ].dataset._model;
                          model.backgroundColor = gradientFill;
                        },
                      },
                    ]}
                    options={{
                      legend: {
                        display: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            type: 'linear',
                            position: 'bottom',
                            gridLines: { color: 'rgba(255, 255, 255, 0.1)' },
                          },
                        ],
                        yAxes: [
                          {
                            gridLines: { color: 'rgba(255, 255, 255, 0.1)' },
                            ticks: {
                              beginAtZero: false,
                            },
                          },
                        ],
                      },
                    }}
                    data={{
                      labels: ['Test'],
                      datasets: [
                        {
                          label: 'Placeholder',
                          fill: true,
                          borderColor: 'rgb(177,81,232)',
                          backgroundColor: 'rgb(177,81,232, 0.2)',
                          pointRadius: 0,
                          type: 'line',
                          data: [
                            { x: 0, y: startPriceFloat },
                            {
                              x:
                                (currentAmount -
                                  (state.mode === 'sell'
                                    ? state.premiaAmount
                                    : 0)) /
                                1e6,
                              y:
                                startPriceFloat +
                                ((currentAmount -
                                  (state.mode === 'sell'
                                    ? state.premiaAmount
                                    : 0)) /
                                  25e6) *
                                  (endPriceFloat - startPriceFloat),
                            },
                            {
                              x:
                                (currentAmount +
                                  (state.mode === 'buy'
                                    ? state.premiaAmount
                                    : 0)) /
                                1e6,
                              y:
                                startPriceFloat +
                                ((currentAmount +
                                  (state.mode === 'buy'
                                    ? state.premiaAmount
                                    : 0)) /
                                  25e6) *
                                  (endPriceFloat - startPriceFloat),
                            },
                            { x: 25, y: endPriceFloat },
                          ],
                        },
                      ],
                    }}
                  />
                </div>

                <div>
                  <div className={classes.infoContainer}>
                    <div>Your Buy Price</div>
                    <div>
                      {formatNumber(ethAmount / state.premiaAmount, true, {
                        maximumFractionDigits: 10,
                      })}{' '}
                      ETH
                    </div>
                  </div>

                  <div className={classes.infoContainer}>
                    <div>Acquired on the Bonding Curve</div>
                    <div>{formatNumber(currentAmount)} PREMIA</div>
                  </div>

                  <div className={classes.infoContainer}>
                    <div>Available on the Bonding Curve</div>
                    <div>{formatNumber(25000000 - currentAmount)} PREMIA</div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {state.mode === 'sell' && !state.permit ? (
                  <Button
                    onClick={handleSignPermit}
                    variant='contained'
                    color='primary'
                    style={{
                      marginTop: 10,
                      width: 300,
                    }}
                  >
                    Sign Permit
                  </Button>
                ) : (
                  <Button
                    onClick={handleConfirm}
                    variant='contained'
                    color='primary'
                    style={{
                      marginTop: 10,
                      width: 300,
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Grid>
    </PageWithSidebar>
  );
};

export default BondingCurve;
