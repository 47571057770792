import { useState, useEffect, useCallback, useMemo } from 'react';
import { ethers, BigNumber } from 'ethers';

import { Token, TokenDenominator, isToken } from 'web3/tokens';
import { getOptionContract } from 'web3/contracts';
import { useTransact } from 'hooks';
import { useWeb3 } from 'state/application/hooks';
import { useTokenContract } from './useContract';
import { Erc20__factory } from 'contracts';

export function useApproveMint(
  token: Token | undefined,
  tokenDenominator: TokenDenominator | Token,
) {
  const { signer, account, contracts } = useWeb3();
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const tokenContract = useTokenContract(token?.address);
  const transact = useTransact();

  const denominator = useMemo(
    () =>
      isToken(tokenDenominator)
        ? TokenDenominator[
            (tokenDenominator as Token).symbol as keyof typeof TokenDenominator
          ]
        : tokenDenominator,
    [tokenDenominator],
  );
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const fetchAllowance = useCallback(async () => {
    if (!optionContract || !tokenContract) return;

    const allowance = await tokenContract?.allowance(
      account,
      optionContract.address,
    );

    setAllowance(allowance);
    setLoading(false);
  }, [tokenContract, account, optionContract]);

  useEffect(() => {
    if (account && token && optionContract) {
      fetchAllowance().catch((e) => console.error(e));
    }

    const refreshInterval = setInterval(fetchAllowance, 1000);

    return () => clearInterval(refreshInterval);
  }, [token, account, optionContract, fetchAllowance]);

  const handleApprove = useCallback(async () => {
    if (!token || !denominator) return;

    const operator = getOptionContract(contracts, denominator);
    const tokenContract = Erc20__factory.connect(token.address, signer!);

    if (!operator || !tokenContract || !signer) return;

    try {
      const symbol = await tokenContract.symbol();

      return transact(
        tokenContract
          .connect(signer!)
          .approve(operator.address, ethers.constants.MaxUint256),
        {
          closeOnSuccess: true,
          option: null,
          description: `Approve ${symbol} transfer to option contract`,
        },
      );
    } catch (e) {
      return console.error(e);
    }
  }, [token, denominator, signer, transact, contracts]);

  return { loading, allowance, onApprove: handleApprove };
}

export default useApproveMint;
