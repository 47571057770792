import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ethers } from 'ethers';
import moment from 'moment';

import { Option, OptionDetails } from 'web3/options';
import { isToken } from 'web3/tokens';
import { formatBigNumber } from 'utils/formatNumber';

import { TokenPriceChip } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  thinFont: {
    fontWeight: 300,
  },

  callText: {
    color: theme.palette.success.main,
  },

  putText: {
    color: theme.palette.error.main,
  },

  subtitle: {
    marginBottom: '16px',
    marginTop: '8px',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },

  titleSubtitleHidden: {
    marginBottom: '16px',
  },
}));

export interface OptionTitleProps {
  option: OptionDetails | Option;
  small?: boolean;
  hideSubtitle?: boolean;
}

const OptionTitle: React.FC<OptionTitleProps> = ({
  option,
  small = false,
  hideSubtitle = false,
}) => {
  const classes = useStyles({ small });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tokenSymbol = isToken(option.token)
    ? option.token.symbol
    : option.token;
  const denominatorSymbol = isToken(option.denominator)
    ? option.denominator.symbol
    : option.denominator;

  return (
    <Grid item>
      <Grid container>
        {!mobile ? (
          <Typography
            variant={small ? 'h6' : 'h5'}
            component='h2'
            className={hideSubtitle ? classes.titleSubtitleHidden : ''}
          >
            <b>{tokenSymbol}</b>-
            <span className={classes.thinFont}>{denominatorSymbol}</span> •{' '}
            <span className={classes.thinFont}>
              {moment(Number(option.expiration.toString()) * 1000)
                .format('YYYYMMMDD')
                .toUpperCase()}
            </span>{' '}
            •{' '}
            <span
              className={
                option.type === 'CALL' ? classes.callText : classes.putText
              }
            >
              {option.type}
            </span>{' '}
            • {formatBigNumber(option.strikePrice)}{' '}
            <span className={classes.thinFont}>{denominatorSymbol}</span>
          </Typography>
        ) : (
          <>
            <Box display="flex" width={1} justifyContent="center">
              <Typography
                variant={small ? 'h6' : 'h5'}
                component='h2'
                className={hideSubtitle ? classes.titleSubtitleHidden : ''}
              >
                <b>{tokenSymbol}</b>-
                <span className={classes.thinFont}>{denominatorSymbol}</span> •{' '}
                <span className={classes.thinFont}>
                  {moment(Number(option.expiration.toString()) * 1000)
                    .format('YYYYMMMDD')
                    .toUpperCase()}
                </span>{' '}
              </Typography>
            </Box>
            <Box display="flex" width={1} justifyContent="center"  style={{margin: "8px 0 12px"}}>
              <Typography
                variant={small ? 'h6' : 'h5'}
                component='h2'
                className={hideSubtitle ? classes.titleSubtitleHidden : ''}
              >
                <span
                  className={
                    option.type === 'CALL' ? classes.callText : classes.putText
                  }
                >
                  {option.type}
                </span>{' '}
                • {formatBigNumber(option.strikePrice)}{' '}
                <span className={classes.thinFont}>{denominatorSymbol}</span>
              </Typography>
              </Box>
          </>
        )}

        {!small && isToken(option.token) && (
          <Box marginX={2}>
            <TokenPriceChip token={option.token} />
          </Box>
        )}

        {!small && isToken(option.denominator) && (
          <Box>
            <TokenPriceChip token={option.denominator} />
          </Box>
        )}
      </Grid>

      {!small && !hideSubtitle && option.pair && (
        <Typography variant='body1' component='h4' className={classes.subtitle}>
          1 {tokenSymbol} Per Contract • Price Increment{' '}
          {ethers.utils.formatEther(option.pair?.strikePriceIncrement)}{' '}
          {denominatorSymbol}
        </Typography>
      )}
    </Grid>
  );
};

export default OptionTitle;
