import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SpaceWhaleHero from 'assets/svg/SpaceWhaleHero.svg';

const useStyles = makeStyles({
  title: {
    fontFamily: '"Poppins"',
    fontWeight: 600,
    marginTop: 24,
  },

  subtitle: {
    fontFamily: '"Inter"',
    fontSize: '1rem',
    marginTop: 8,
  },

  button: {
    height: 58,
  },
});

const MarketplaceHero: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  return (
    <Box position='relative' mb={4}>
      <img
        src={SpaceWhaleHero}
        alt='Hero'
        width='100%'
        onLoad={() => setLoading(false)}
      />

      {loading ? null : (
        <Box
          position='absolute'
          px={{ xs: 3, lg: 6 }}
          py={{ xs: 1 }}
          top={0}
          left={0}
          width={1}
          height='100%'
        >
          <Typography
            component='h1'
            variant='h2'
            color='textPrimary'
            className={classes.title}
          >
            Marketplace
          </Typography>

          <Typography
            component='h3'
            color='textPrimary'
            className={classes.subtitle}
          >
            Trade options on your favorite DeFi tokens
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MarketplaceHero;
