import { useCallback, useEffect, useState, useMemo } from 'react';
import { ethers, BigNumber } from 'ethers';

import { useTransact } from 'hooks';
import { useWeb3 } from 'state/application/hooks';
import { TokenDenominator, Token, isToken } from 'web3/tokens';
import { getOptionContract } from 'web3/contracts';
import { Erc20__factory } from 'contracts';

export function useApproveMarket(
  tokenAddress: string | undefined,
  _denominator: TokenDenominator | Token | undefined,
) {
  const [approvedDenominator, setApprovedDenominator] = useState(false);
  const [tokenAllowance, setTokenAllowance] = useState(BigNumber.from(0));
  const [loading, setLoading] = useState(true);
  const transact = useTransact();
  const { account, signer, contracts } = useWeb3();

  const denominator = isToken(_denominator)
    ? TokenDenominator[
        (_denominator as Token).symbol as keyof typeof TokenDenominator
      ]
    : _denominator;

  const optionContract = useMemo(
    () => (denominator ? getOptionContract(contracts, denominator) : null),
    [denominator, contracts],
  );
  const tokenContract = useMemo(
    () => (tokenAddress ? Erc20__factory.connect(tokenAddress, signer!) : null),
    [tokenAddress, signer],
  );
  const premiaMarket = useMemo(() => contracts.premiaMarket, [contracts]);

  const fetchApproval = useCallback(async () => {
    if (!optionContract || !premiaMarket || !tokenContract) return;

    const isDenominatorApproved = await optionContract.isApprovedForAll(
      account,
      premiaMarket.address,
    );

    const _tokenAllowance = await tokenContract.allowance(
      account,
      premiaMarket.address,
    );

    setApprovedDenominator(isDenominatorApproved);
    setTokenAllowance(_tokenAllowance);
    setLoading(false);
  }, [account, optionContract, tokenContract, premiaMarket]);

  useEffect(() => {
    if (account && optionContract) {
      fetchApproval().catch((e) => console.error(e));
    }

    const refreshInterval = setInterval(fetchApproval, 1000);

    return () => clearInterval(refreshInterval);
  }, [account, optionContract, fetchApproval]);

  const handleApproveDenominator = useCallback(async () => {
    if (!optionContract || !premiaMarket) return console.log('uh oh');

    return transact(
      optionContract?.setApprovalForAll(premiaMarket.address, true),
      {
        closeOnSuccess: true,
        option: null,
        description: 'Approve option transfer on marketplace',
      },
    );
  }, [optionContract, transact, premiaMarket]);

  const handleApproveToken = useCallback(async () => {
    if (!tokenContract || !premiaMarket) return console.log('uh oh');

    const symbol = await tokenContract.symbol();

    return transact(
      tokenContract.approve(premiaMarket.address, ethers.constants.MaxUint256),
      {
        closeOnSuccess: true,
        option: null,
        description: `Approve ${symbol} transfer on marketplace`,
      },
    );
  }, [tokenContract, transact, premiaMarket]);

  return {
    approvedDenominator,
    tokenAllowance,
    loading,
    onApproveDenominator: handleApproveDenominator,
    onApproveToken: handleApproveToken,
  };
}

export default useApproveMarket;
