import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from 'react-apollo';
import { ethers } from 'ethers';
import { Contract, ContractCall } from 'ethers-multicall';
import { formatUnits, parseEther } from 'ethers/lib/utils';
import { get } from 'lodash';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import PremiaMarketAbi from 'constants/abi/PremiaMarket.json';
import { getMatchingBuyOrders, getMatchingSellOrders } from 'graphql/queries';
import { DEFAULT_DECIMALS, ZERO_ADDRESS } from '../../constants';
import { useWeb3, useToggleTxLoadingModal } from 'state/application/hooks';
import { useTokenBalance } from 'state/wallet/hooks';
import {
  convertGraphMarketOrderToOrder,
  IOrder,
  MarketOrder,
  MarketOrderWithValid,
  SaleSide,
} from 'web3/market';
import { getOptionContract } from 'web3/contracts';
import { TokenDenominator } from 'web3/tokens';
import { Erc20__factory } from 'contracts';
import { OptionDetailsWithId, OptionType } from 'web3/options';
import { useOptionSettings } from 'state/options/hooks';
import { formatBigNumber } from 'utils/formatNumber';
import {
  useDenominatorAddress,
  useDenominatorDecimals,
  useTransact,
  useReferrer,
} from 'hooks';
import { AppDispatch, AppState } from 'state';
import { initialState } from './reducer';
import {
  setFilterSettings as _setFilterSettings,
  SetFilterSettings,
  setTradeSettings as _setTradeSettings,
  SetTradeSettings,
} from './actions';

export const useTradeSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['market']>(
    (state) => state.market,
  );

  const setTradeSettings = (tradeSettings: SetTradeSettings) =>
    dispatch(_setTradeSettings(tradeSettings));

  return { ...state.tradeSettings, setTradeSettings };
};

export const useFilterSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['market']>(
    (state) => state.market,
  );

  const setFilterSettings = useCallback(
    (filterSettings: SetFilterSettings) =>
      dispatch(_setFilterSettings(filterSettings)),
    [dispatch],
  );

  const resetFilterSettings = useCallback(
    () => setFilterSettings(initialState.filterSettings),
    [setFilterSettings],
  );

  return { ...state.filterSettings, setFilterSettings, resetFilterSettings };
};

export const useBuy = (_option?: OptionDetailsWithId | null | undefined) => {
  const { contracts, multicallProvider } = useWeb3();
  const { denominator, selectedToken } = useOptionSettings();
  const transact = useTransact();
  const client = useApolloClient();
  const referrer = useReferrer();
  const toggleTxLoadingModal = useToggleTxLoadingModal();
  const denominatorDecimals = useDenominatorDecimals();
  const denominatorAddress = useDenominatorAddress();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const { quantity, price } = useTradeSettings();

  const onBuy = useCallback(
    async (
      option: OptionDetailsWithId | null | undefined = _option,
      {
        isDelayedWriting = false,
      }: { isDelayedWriting?: boolean | undefined } = {
        isDelayedWriting: false,
      },
    ) => {
      if (!optionContract) {
        return console.error(
          'Could not load option contract. Are you on the correct network?',
        );
      }

      if (
        !option ||
        !multicallProvider ||
        !contracts.premiaMarket ||
        !denominatorAddress ||
        !denominatorDecimals
      ) {
        return console.error('Could not load option.');
      }

      toggleTxLoadingModal(true);

      const pricePerUnit = ethers.utils.parseEther(price);

      const { data: matchingData } = await client.query({
        query: getMatchingBuyOrders,
        variables: {
          optionId: option.id.toString(),
          pricePerUnit: pricePerUnit.toString(),
        },
      });

      const matchingOrders = get(matchingData, 'marketOrders', []);

      matchingOrders.sort((orderA: MarketOrder, orderB: MarketOrder) => {
        if (orderA.pricePerUnit === orderB.pricePerUnit) {
          return (
            Number(orderB.remainingUnfilled) - Number(orderA.remainingUnfilled)
          );
        }

        return Number(orderA.pricePerUnit) - Number(orderB.pricePerUnit);
      });

      const sortedOrders = matchingOrders.map((order: MarketOrder) =>
        convertGraphMarketOrderToOrder(order),
      );

      const marketContract = new Contract(
        contracts.premiaMarket.address,
        PremiaMarketAbi.abi,
      );

      const isValidCalls: ContractCall[] = sortedOrders.map((order: IOrder) =>
        marketContract.isOrderValid(order),
      );

      const isValidResults = await multicallProvider.all(isValidCalls);

      const validOrders = sortedOrders.filter(
        (order: IOrder, i: number) => isValidResults[i],
      );

      if (validOrders.length > 0) {
        return transact(
          contracts.premiaMarket?.createOrderAndTryToFill(
            {
              maker: ZERO_ADDRESS,
              side: 0,
              isDelayedWriting,
              optionId: option.id,
              optionContract: optionContract.address,
              paymentToken: denominatorAddress,
              pricePerUnit,
              expirationTime: 0,
              salt: 0,
              decimals: denominatorDecimals,
            },
            ethers.utils.parseUnits(quantity, selectedToken?.decimals),
            validOrders,
            false,
            referrer,
          ),
          {
            option,
            description: `Buying ${quantity} option${
              Number(quantity) === 1 ? '' : 's'
            } for ${formatBigNumber(pricePerUnit)} ${denominator}${
              Number(quantity) === 1 ? '' : ' each'
            }`,
          },
        );
      }

      return transact(
        contracts.premiaMarket?.createOrder(
          {
            maker: ZERO_ADDRESS,
            side: 0,
            isDelayedWriting,
            optionId: option.id,
            optionContract: optionContract.address,
            paymentToken: denominatorAddress,
            pricePerUnit,
            expirationTime: 0,
            salt: 0,
            decimals: denominatorDecimals,
          },
          ethers.utils.parseUnits(quantity, selectedToken?.decimals),
        ),
        {
          option,
          description: `Buying ${quantity} option${
            Number(quantity) === 1 ? '' : 's'
          } for ${formatBigNumber(pricePerUnit)} ${denominator}${
            Number(quantity) === 1 ? '' : ' each'
          }`,
        },
      );
    },
    [
      client,
      multicallProvider,
      contracts,
      transact,
      denominator,
      denominatorDecimals,
      denominatorAddress,
      optionContract,
      price,
      quantity,
      _option,
      selectedToken,
      toggleTxLoadingModal,
      referrer,
    ],
  );

  return onBuy;
};

export const useBuyNewOption = () => {
  const { contracts } = useWeb3();
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const transact = useTransact();
  const referrer = useReferrer();
  const denominatorDecimals = useDenominatorDecimals();
  const denominatorAddress = useDenominatorAddress();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const { quantity, price } = useTradeSettings();

  const onBuyNewOption = useCallback(async () => {
    if (!optionContract) {
      return console.error(
        'Could not load option contract. Are you on the correct network?',
      );
    }

    if (
      !selectedToken ||
      !selectedExpiration ||
      !optionType ||
      !strikePrice ||
      !denominatorAddress ||
      !denominatorDecimals
    )
      return;

    return transact(
      contracts?.premiaMarket?.createOrderForNewOption(
        {
          maker: ZERO_ADDRESS,
          side: 0,
          isDelayedWriting: false,
          optionId: 0,
          optionContract: optionContract.address,
          paymentToken: denominatorAddress,
          pricePerUnit: parseEther(price),
          expirationTime: 0,
          salt: 0,
          decimals: denominatorDecimals,
        },
        ethers.utils.parseUnits(quantity, selectedToken.decimals),
        {
          expiration: Math.floor(Number(selectedExpiration) / 1000),
          isCall: optionType === OptionType.Call,
          strikePrice: parseEther(strikePrice),
          token: selectedToken.address,
        },
        referrer,
      ),
      {
        description: `Buying ${quantity} option${
          Number(quantity) === 1 ? '' : 's'
        } for ${formatBigNumber(price)} ${denominator}${
          Number(quantity) === 1 ? '' : ' each'
        }`,
      },
    );
  }, [
    transact,
    contracts,
    denominator,
    denominatorDecimals,
    denominatorAddress,
    optionContract,
    price,
    quantity,
    referrer,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  ]);

  return onBuyNewOption;
};

export const useSell = (_option?: OptionDetailsWithId | null) => {
  const { contracts, multicallProvider } = useWeb3();
  const { denominator, selectedToken } = useOptionSettings();
  const transact = useTransact();
  const client = useApolloClient();
  const referrer = useReferrer();
  const toggleTxLoadingModal = useToggleTxLoadingModal();
  const denominatorDecimals = useDenominatorDecimals();
  const denominatorAddress = useDenominatorAddress();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const { quantity, price } = useTradeSettings();

  const onSell = useCallback(
    async (
      option: OptionDetailsWithId | null | undefined = _option,
      {
        isDelayedWriting = false,
        writeOnBuyFill = false,
      }: {
        isDelayedWriting?: boolean | undefined;
        writeOnBuyFill?: boolean | undefined;
      } = {
        isDelayedWriting: false,
        writeOnBuyFill: false,
      },
    ) => {
      if (!optionContract) {
        return console.error(
          'Could not load option contract. Are you on the correct network?',
        );
      }

      if (
        !option ||
        !multicallProvider ||
        !contracts.premiaMarket ||
        !denominatorDecimals ||
        !denominatorAddress
      ) {
        return console.error('Could not load option.');
      }

      toggleTxLoadingModal(true);

      const pricePerUnit = ethers.utils.parseEther(price);

      const { data: matchingData } = await client.query({
        query: getMatchingSellOrders,
        variables: {
          optionId: option.id.toString(),
          pricePerUnit: pricePerUnit.toString(),
        },
      });

      const matchingOrders = get(matchingData, 'marketOrders', []);

      matchingOrders.sort((orderA: MarketOrder, orderB: MarketOrder) => {
        if (orderA.pricePerUnit === orderB.pricePerUnit) {
          return (
            Number(orderB.remainingUnfilled) - Number(orderA.remainingUnfilled)
          );
        }

        return Number(orderB.pricePerUnit) - Number(orderA.pricePerUnit);
      });

      const sortedOrders = matchingOrders.map((order: MarketOrder) =>
        convertGraphMarketOrderToOrder(order),
      );

      const marketContract = new Contract(
        contracts.premiaMarket.address,
        PremiaMarketAbi.abi,
      );

      const isValidCalls: ContractCall[] = sortedOrders.map((order: IOrder) =>
        marketContract.isOrderValid(order),
      );

      const isValidResults = await multicallProvider.all(isValidCalls);

      const validOrders = sortedOrders.filter(
        (order: IOrder, i: number) => isValidResults[i],
      );

      if (validOrders.length > 0) {
        return transact(
          contracts.premiaMarket.createOrderAndTryToFill(
            {
              maker: ZERO_ADDRESS,
              side: 1,
              isDelayedWriting,
              optionId: option.id.toString(),
              optionContract: optionContract.address,
              paymentToken: denominatorAddress,
              pricePerUnit,
              expirationTime: 0,
              salt: 0,
              decimals: denominatorDecimals,
            },
            ethers.utils.parseUnits(quantity, selectedToken?.decimals),
            validOrders,
            writeOnBuyFill,
            referrer,
          ),

          {
            option,
            description: `${
              isDelayedWriting ? 'Minting on sale' : 'Selling'
            } ${quantity} option${
              Number(quantity) === 1 ? '' : 's'
            } for ${formatBigNumber(pricePerUnit)} ${denominator}${
              Number(quantity) === 1 ? '' : ' each'
            }`,
          },
        );
      }

      return transact(
        contracts.premiaMarket.createOrder(
          {
            maker: ZERO_ADDRESS,
            side: 1,
            isDelayedWriting,
            optionId: option.id.toString(),
            optionContract: optionContract.address,
            paymentToken: denominatorAddress,
            pricePerUnit,
            expirationTime: 0,
            salt: 0,
            decimals: denominatorDecimals,
          },
          ethers.utils.parseUnits(quantity, selectedToken?.decimals),
        ),
        {
          option,
          description: `${
            isDelayedWriting ? 'Minting on sale' : 'Selling'
          } ${quantity} option${
            Number(quantity) === 1 ? '' : 's'
          } for ${formatBigNumber(pricePerUnit)} ${denominator}${
            Number(quantity) === 1 ? '' : ' each'
          }`,
        },
      );
    },
    [
      client,
      contracts,
      transact,
      multicallProvider,
      denominatorAddress,
      denominator,
      denominatorDecimals,
      optionContract,
      price,
      quantity,
      _option,
      selectedToken,
      toggleTxLoadingModal,
      referrer,
    ],
  );

  return onSell;
};

export const useSellNewOption = () => {
  const { contracts } = useWeb3();
  const transact = useTransact();
  const referrer = useReferrer();
  const { quantity, price } = useTradeSettings();
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const denominatorDecimals = useDenominatorDecimals();
  const denominatorAddress = useDenominatorAddress();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const onSellNewOption = useCallback(async (_quantity = null, _price = null) => {
    if (!optionContract) {
      return console.error(
        'Could not load option contract. Are you on the correct network?',
      );
    }

    if (
      !selectedExpiration ||
      !selectedToken ||
      !optionType ||
      !strikePrice ||
      !denominatorAddress ||
      !denominatorDecimals
    )
      return console.log(
        'No option selected',
        !selectedExpiration,
        !selectedToken,
        !optionType,
        !strikePrice,
        !denominatorAddress,
        !denominatorDecimals,
      );

    return transact(
      contracts?.premiaMarket?.createOrderForNewOption(
        {
          maker: ZERO_ADDRESS,
          side: 1,
          isDelayedWriting: true,
          optionId: 0,
          optionContract: optionContract.address,
          paymentToken: denominatorAddress,
          pricePerUnit: _price || parseEther(price),
          expirationTime: 0,
          salt: 0,
          decimals: denominatorDecimals,
        },
        ethers.utils.parseUnits(_quantity || quantity, selectedToken.decimals),
        {
          expiration: Math.floor(Number(selectedExpiration) / 1000),
          isCall: optionType === OptionType.Call,
          strikePrice: parseEther(strikePrice),
          token: selectedToken.address,
        },
        referrer,
      ),
      {
        description: `Minting on sale ${_quantity || quantity} option${
          Number(_quantity || quantity) === 1 ? '' : 's'
        } for ${_price || formatBigNumber(price)} ${denominator}${
          Number(_quantity || quantity) === 1 ? '' : ' each'
        }`,
      },
    );
  }, [
    transact,
    contracts,
    denominator,
    denominatorAddress,
    denominatorDecimals,
    optionContract,
    price,
    quantity,
    referrer,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  ]);

  return onSellNewOption;
};

export const useCreateAndSell = () => {
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const { quantity, price } = useTradeSettings();
  const transact = useTransact();

  const { contracts } = useWeb3();
  const referrer = useReferrer();
  const denominatorDecimals = useDenominatorDecimals();
  const denominatorAddress = useDenominatorAddress();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const onCreateAndSell = useCallback(async () => {
    if (!optionContract) {
      return console.error(
        'Could not load option contract. Are you on the correct network?',
      );
    }

    if (
      !denominatorAddress ||
      !denominatorDecimals ||
      !selectedToken ||
      !selectedExpiration ||
      !strikePrice ||
      !quantity ||
      !price
    ) {
      return console.error('Not all parameters have been set');
    }

    return transact(
      contracts.premiaMarket?.createOrderForNewOption(
        {
          maker: ZERO_ADDRESS,
          side: 1,
          optionContract: optionContract.address,
          paymentToken: denominatorAddress,
          pricePerUnit: ethers.utils.parseEther(price),
          isDelayedWriting: true,
          expirationTime: 0,
          salt: 0,
          optionId: 0,
          decimals: denominatorDecimals,
        },
        quantity,
        {
          token: selectedToken?.address,
          strikePrice: ethers.utils.parseEther(strikePrice),
          expiration: Math.floor(selectedExpiration.getTime() / 1000),
          isCall: optionType === OptionType.Call,
        },
        referrer,
      ),
      {
        description: `Minting on sale ${quantity} option${
          Number(quantity) === 1 ? '' : 's'
        } for ${formatBigNumber(price)} ${denominator}${
          Number(quantity) === 1 ? '' : ' each'
        }`,
      },
    );
  }, [
    transact,
    contracts,
    denominator,
    denominatorAddress,
    denominatorDecimals,
    optionContract,
    price,
    optionType,
    selectedToken,
    selectedExpiration,
    quantity,
    strikePrice,
    referrer,
  ]);

  return onCreateAndSell;
};

export const useTradeQuote = () => {
  const [expectedFee, setExpectedFee] = useState<number | null>(null);
  const { account, contracts } = useWeb3();
  const {
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const { quantity, price } = useTradeSettings();
  const referrer = useReferrer();

  useEffect(() => {
    if (!contracts.feeCalculator || !quantity || !price) return;

    (async () => {
      setExpectedFee(null);

      const quote = await contracts.feeCalculator?.getFeeAmounts(
        account,
        referrer !== ZERO_ADDRESS,
        ethers.utils.parseEther((Number(quantity) * Number(price)).toString()),
        3,
      );

      setExpectedFee(
        Number(formatUnits(quote?._fee ?? '0', DEFAULT_DECIMALS)) +
          Number(formatUnits(quote?._feeReferrer ?? '0', DEFAULT_DECIMALS)),
      );
    })();
  }, [
    contracts,
    quantity,
    price,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
    account,
    referrer,
    setExpectedFee,
  ]);

  return expectedFee;
};

export const useOrderInvalidReason = (order: MarketOrderWithValid) => {
  const [makerFee, setMakerFee] = useState<number | null>(null);
  const [invalidReason, setInvalidReason] = useState<string | ''>('');
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState<any | null>(null);
  const { signer, contracts } = useWeb3();
  const tokenBalance = useTokenBalance(order.maker, order.paymentToken);
  const [tokenAllowance, setTokenAllowance] = useState(
    ethers.BigNumber.from(0),
  );
  const [ mounted, setMounted ] = useState(true);

  const referrer = useReferrer();

  const premiaMarket = useMemo(() => contracts.premiaMarket, [contracts]);

  const denominator =
    TokenDenominator[
      order.option.denominator.symbol as keyof typeof TokenDenominator
    ];

  const optionContract = useMemo(
    () =>
      order.option.denominator
        ? getOptionContract(contracts, denominator)
        : null,
    [order.option.denominator, denominator, contracts],
  );

  const tokenContract = useMemo(
    () =>
      order.paymentToken.address
        ? Erc20__factory.connect(order.paymentToken.address, signer!)
        : null,
    [order.paymentToken.address, signer],
  );

  useEffect(() => {
    if (order.side === SaleSide.Sell && order.isDelayedWriting) {
      (async () => {
        const _quote = await optionContract?.getWriteQuote(
          order.maker,
          {
            token: order.option.token.address,
            strikePrice: order.option.strikePrice,
            expiration: order.option.expiration,
            isCall: order.option.type === OptionType.Call,
            amount: order.remainingUnfilled,
          },
          referrer,
          order.option.token.decimals,
        );
        
        if (mounted)
          setQuote(_quote);
      })();
    }
    return () => {
      setMounted(false);
    }
  }, [order, mounted, optionContract, referrer]);

  const collateralContract = useMemo(
    () =>
      quote?.collateralToken
        ? Erc20__factory.connect(quote?.collateralToken, signer!)
        : null,
    [quote, signer],
  );

  const fetchApproval = useCallback(async () => {
    if (!optionContract || !premiaMarket || !tokenContract) return;

    const isApproved = await optionContract.isApprovedForAll(
      order.maker,
      premiaMarket.address,
    );

    const _tokenAllowance = await tokenContract.allowance(
      order.maker,
      premiaMarket.address,
    );

    setApproved(isApproved);
    setTokenAllowance(_tokenAllowance);
    setLoading(false);
  }, [optionContract, tokenContract, premiaMarket, order.maker]);

  useEffect(() => {
    if (order.maker) {
      fetchApproval().catch((e) => console.error(e));
    }

    const refreshInterval = setInterval(fetchApproval, 1000);

    return () => clearInterval(refreshInterval);
  }, [order.maker, fetchApproval]);

  useEffect(() => {
    if (!contracts.feeCalculator) return;

    (async () => {
      setMakerFee(null);

      const makerFee = await contracts.feeCalculator?.getFee(
        order.maker,
        false,
        2,
      );

      if (mounted)
        setMakerFee(Number(formatUnits(makerFee ?? '0', DEFAULT_DECIMALS)));
    })();

    return () => {
      setMounted(false);
    }
  }, [contracts, mounted, setMakerFee, order.maker]);

  useEffect(() => {
    try {
      if (moment() > moment(Number(order.option.expiration) * 1000)) {
        setInvalidReason('The option is expired');
        return;
      }

      if (Number(order.remainingUnfilled) === 0) {
        setInvalidReason('Order has already been filled.');
        return;
      }

      if (loading) return;

      if (order.side === SaleSide.Buy) {
        const basePrice = ethers.BigNumber.from(order.pricePerUnit)
          .mul(ethers.BigNumber.from(order.remainingUnfilled))
          .div(ethers.BigNumber.from(10).pow(order.decimals));
        const orderMakerFee = basePrice
          .mul(ethers.BigNumber.from(makerFee ?? 0))
          .div(ethers.BigNumber.from(10000));
        const totalPrice = basePrice.add(orderMakerFee);
        const _tokenBalance = new BigNumber(tokenBalance ?? 0).multipliedBy(
          new BigNumber(10).pow(order.decimals),
        );

        if (!_tokenBalance.gte(totalPrice.toString())) {
          setInvalidReason('Insufficient balance for payment and fee.');
          return;
        }

        if (!tokenAllowance.gte(totalPrice.toString())) {
          setInvalidReason('Token allowances too low.');
          return;
        }
      } else if (order.side === SaleSide.Sell) {
        if (order.isDelayedWriting) {
          if (!quote || !optionContract) return;

          (async () => {
            const collateralBalance = await collateralContract?.balanceOf(
              order.maker,
            );

            const collateralAllowance = await collateralContract?.allowance(
              order.maker,
              order.optionContract,
            );

            const totalCollateralPrice = quote.collateral
              .add(quote.fee)
              .add(quote.feeReferrer);
            
            if (mounted) {
              if (!approved) {
                setInvalidReason('Option has not been approved to be traded.');
                return;
              }
  
              if (!collateralBalance?.gte(totalCollateralPrice.toString())) {
                setInvalidReason('Insufficient balance for payment and fee.');
                return;
              }
  
              if (!collateralAllowance?.gte(totalCollateralPrice.toString())) {
                setInvalidReason('Token allowances too low.');
                return;
              }  
            }
          })();
        } else {
          (async () => {
            const optionBalance = await optionContract?.balanceOf(
              order.maker,
              order.optionId,
            );

            if (mounted) {
              if (!approved) {
                setInvalidReason('Option has not been approved to be traded.');
                return;
              }
  
              if (optionBalance?.gte(order.remainingUnfilled)) {
                setInvalidReason(
                  'Insufficient balance of the option being sold.',
                );
                return;
              }  
            }
          })();
        }
      }
    } catch (err) {
      console.error(err);
    }
    return () => {
      setMounted(false);
    }
  }, [
    mounted,
    loading,
    approved,
    order,
    makerFee,
    collateralContract,
    optionContract,
    referrer,
    tokenAllowance,
    tokenBalance,
    quote,
  ]);

  return invalidReason;
};
