import React, { useMemo } from 'react';
import { Typography, Modal, Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopy } from '@material-ui/icons';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';

import { getAccount } from 'graphql/queries';
import { useWeb3 } from 'state/application/hooks';
import { formatNumber } from 'utils/formatNumber';

import { ModalContainer, Loader } from 'components';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 200,
  },

  callGreen: {
    color: theme.palette.success.main,
  },
}));

export interface ReferralModalProps {
  open: boolean;
  onClose: () => void;
}

const ReferralModal: React.FC<ReferralModalProps> = ({ open, onClose }) => {
  const { account } = useWeb3();
  const classes = useStyles();

  const { loading, data } = useQuery(getAccount, {
    variables: { account },
  });

  const accountData = useMemo(() => get(data, 'account'), [data]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='sm'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Your Referrals</Typography>
        </Box>

        <Typography variant='body2' color='textSecondary'>
          Refer users to Premia using the link below to earn 10% of their fees
          on writing and exercising options. Any referred users also get a 10%
          discount on fees, while using the platform.
        </Typography>

        <Box width={1} marginTop={4}>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <Typography variant='body2'>
              https://premia.finance/?ref={account}
            </Typography>

            <Box marginY={2}>
              <CopyToClipboard text={`https://premia.finance/?ref=${account}`}>
                <IconButton>
                  <FileCopy />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Grid>
        </Box>

        {loading ? (
          <Loader />
        ) : (
          <Box width={1 / 2} marginX='auto' marginTop={4}>
            {accountData && (
              <Grid container direction='column'>
                <Grid item container direction='row' justify='space-between'>
                  <Typography variant='body2' className={classes.thin}>
                    Referrals:
                  </Typography>

                  <Typography variant='body2' className={classes.callGreen}>
                    {accountData?.usersReferred}
                  </Typography>
                </Grid>

                <Grid item container direction='row' justify='space-between'>
                  <Typography variant='body2' className={classes.thin}>
                    Fees Earned:
                  </Typography>

                  <Typography variant='body2' className={classes.callGreen}>
                    {formatNumber(
                      formatUnits(accountData?.feesEarned, DEFAULT_DECIMALS),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default ReferralModal;
