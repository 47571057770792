import { createAction } from '@reduxjs/toolkit';

import { Option, OptionDetails } from 'web3/options';
import { SetCurrentTransaction } from './reducer';

export const setCurrentTx = createAction<
  SetCurrentTransaction | null | undefined
>('transactions/setCurrentTx');

export const setTxStateMsg = createAction<string | null | undefined>(
  'transactions/setTxStateMsg',
);

export const setTxOption = createAction<
  OptionDetails | Option | null | undefined
>('transactions/setTxOption');
