import { createReducer } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import { Token, TokenDenominator } from 'web3/tokens';
import { OptionType } from 'web3/options';
import { setTradeSettings, setFilterSettings } from './actions';

export enum OrderType {
  Limit = 'Limit',
  Market = 'Market',
}

export interface DateRange {
  start: Moment | null;
  end: Moment | null;
}

export interface PriceRange {
  low: number | null;
  high: number | null;
}

export interface MarketState {
  tradeSettings: {
    orderType: OrderType;
    quantity: string;
    price: string;
  };

  filterSettings: {
    denominator: TokenDenominator;
    token?: Token | null;
    expirationRange?: DateRange | null;
    strikeRange?: PriceRange | null;
    priceRange?: PriceRange | null;
    optionType?: OptionType | null;
    createdByUserOnly: boolean;
  };
}

export const initialState: MarketState = {
  tradeSettings: {
    orderType: OrderType.Limit,
    quantity: '',
    price: '',
  },

  filterSettings: {
    denominator: TokenDenominator.DAI,
    token: null,
    expirationRange: {
      start: moment(),
      end: null,
    },
    strikeRange: null,
    priceRange: null,
    optionType: null,
    createdByUserOnly: false,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setTradeSettings, (state, { payload: tradeSettings }) => {
      state.tradeSettings = {
        ...state.tradeSettings,
        ...tradeSettings,
      };
    })
    .addCase(setFilterSettings, (state, { payload: filterSettings }) => {
      state.filterSettings = {
        ...state.filterSettings,
        ...filterSettings,
      };
    }),
);
