import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { ChainId } from '@uniswap/sdk';

import DaiLogo from 'assets/svg/DaiLogo.svg';
import BusdLogo from 'assets/images/busd-logo.png';
import WethLogo from 'assets/images/weth-logo.png';
import WbnbLogo from 'assets/images/wbnb-logo.png';

import { useOptionSettings } from 'state/options/hooks';
import { TokenDenominator } from 'web3/tokens';
import { DAI, WETH } from '../../constants';
import { useCurrencyBalances } from 'state/wallet/hooks';
import { useWeb3 } from 'state/application/hooks';
import { formatCompact } from 'utils/formatNumber';

import { Loader } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  demoninations: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 16,
  },

  balance: {
    marginTop: '8px',
    lineHeight: 1,
  },

  button: {
    width: '100%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  fullHeight: {
    height: '100%'
  }
}));

const WalletDenominators: React.FC = () => {
  const classes = useStyles();
  const { chainId, account, wallet } = useWeb3();
  const { denominator, setOptionSettings } = useOptionSettings();
  const balances = useCurrencyBalances(account, [
    DAI[chainId ?? ChainId.MAINNET],
    WETH[chainId ?? ChainId.MAINNET],
  ]);

  return (
    <Grid container className={classes.demoninations}>
      <Grid item xs={6}>
        <Button
          variant='contained'
          className={classes.button}
          color={denominator === TokenDenominator.DAI ? 'primary' : 'secondary'}
          onClick={() =>
            setOptionSettings({
              denominator: TokenDenominator.DAI,
            })
          }
        >
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <img
              src={chainId === 56 ? BusdLogo : DaiLogo}
              alt={chainId === 56 ? 'BUSD' : 'DAI'}
              height={20}
              width={20}
            />

            {wallet && wallet.provider && account && (
              <Typography variant='body2' className={classes.balance}>
                {balances[0] ? (
                  formatCompact(Number(balances[0] || 0))
                ) : (
                  <Loader />
                )}
              </Typography>
            )}
          </Grid>
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          className={classes.fullHeight}
        >
          <img
            src={chainId === 56 ? WbnbLogo : WethLogo}
            alt={chainId === 56 ? 'WBNB' : 'WETH'}
            height={20}
            width={20}
          />

          {wallet && wallet.provider && account && (
            <Typography variant='body2' className={classes.balance}>
              {balances[1] ? (
                formatCompact(Number(balances[1]) || 0)
              ) : (
                <Loader />
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WalletDenominators;
