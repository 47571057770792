import gql from 'graphql-tag';

export const Token = gql`
  fragment Token on Token {
    id
    name
    symbol
    decimals
    address
  }
`;

export const TokenPair = gql`
  ${Token}

  fragment TokenPair on TokenPair {
    id
    name
    token {
      ...Token
    }
    denominator {
      ...Token
    }
    strikePriceIncrement
    openInterest
    totalVolume
  }
`;

export const OptionPool = gql`
  fragment OptionPool on OptionPool {
    id
    tokenAmount
    denominatorAmount
  }
`;

export const Option = gql`
  ${OptionPool}
  ${TokenPair}

  fragment Option on Option {
    id
    contractAddress
    decimals
    strikePrice
    expiration
    type
    claimsPreExp
    claimsPostExp
    exercised
    supply
    openInterest
    totalVolume

    token {
      ...Token
    }

    denominator {
      ...Token
    }

    pair {
      ...TokenPair
    }

    pool {
      ...OptionPool
    }
  }
`;

export const TokenPairWithOptions = gql`
  ${TokenPair}
  ${Option}

  fragment TokenPairWithOptions on TokenPair {
    ...TokenPair

    options {
      ...Option
    }
  }
`;

export const OptionWithOrders = gql`
  ${Option}

  fragment OptionWithOrders on Option {
    ...Option

    orders {
      id
      hash
      maker
      side
      optionId
      optionContract
      decimals
      paymentToken {
        ...Token
      }
      pricePerUnit
      expirationTime
      isDelayedWriting
      salt
      amount
      amountFilled
      remainingUnfilled
    }
  }
`;

export const OptionBalance = gql`
  ${Option}

  fragment OptionBalance on OptionBalance {
    id
    option {
      ...Option
    }
    owner
    nbWritten
    balance
  }
`;

export const MarketOrder = gql`
  ${Option}
  ${TokenPair}

  fragment MarketOrder on MarketOrder {
    id
    hash
    maker
    side
    pair {
      ...TokenPair
    }
    option {
      ...Option
    }
    optionId
    optionContract
    decimals
    paymentToken {
      ...Token
    }
    pricePerUnit
    expirationTime
    isDelayedWriting
    salt
    amount
    amountFilled
    remainingUnfilled
  }
`;

export const AccountHistoryItem = gql`
  ${Option}

  fragment AccountHistoryItem on AccountHistoryItem {
    id
    user
    option {
      ...Option
    }
    amount
    timestamp
    action
    txHash
  }
`;

export const TokenPairHistoryItem = gql`
  fragment TokenPairHistoryItem on TokenPairHistoryItem {
    id
    tokenPairId
    openInterest
    totalVolume
    tokenSymbol
    tokenDecimals
    timestamp
  }
`;

export const Account = gql`
  fragment Account on Account {
    id
    usersReferred
    feesEarned
  }
`;
