import React, { useCallback, useEffect, useState } from 'react';
import { Button, lighten, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseEther } from 'ethers/lib/utils';

import { useWeb3 } from 'state/application/hooks';
import { useTransact } from 'hooks';

import { ConfirmTermsModal } from 'components';

const useStyles = makeStyles({
  container: {
    marginTop: 16,
    padding: 20,
    borderRadius: 12,
    border: '2px solid rgba(228, 228, 228, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  
    cursor: ({ clickable }: { clickable?: boolean }) =>
      clickable ? 'pointer' : 'default',
  
    ...({ clickable }: { clickable?: boolean }) =>
      clickable
        ? { '&:hover': { backgroundColor: lighten('#66a6ff', 0.05) } }
        : {}
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10
  },

  input: {
    backgroundColor: 'transparent',
    borderRadius: 12,
    outline: 'none',
    color: '#3edaff',
    border: '2px solid #3edaffaa',
    padding: 10,
  
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },

  inputToken: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 10,
    color: '#808191'
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
});

const BLOCKED_COUNTRIES = [
  'CS',
  'BY',
  'BU',
  'CI',
  'CU',
  'CG',
  'IR',
  'IQ',
  'LR',
  'KP',
  'SD',
  'SY',
  'ZW',
  'US',
];

interface PBCContributeProps {
  isPBCEnded: boolean;
}

interface PBCContributeState {
  ethAmount: number;
  fetching: boolean;
  blocked: boolean;
  countryCode?: string;
  country?: string;
}

const PBCContribute: React.FC<PBCContributeProps> = (props) => {
  const [confirmTermsModalOpen, setConfirmTermsModalOpen] = useState(false);
  const { contracts, account, wallet } = useWeb3();
  const classes = useStyles({
    clickable: !wallet || !wallet.provider || !account
  });
  const transact = useTransact();

  const [state, setState] = useState<PBCContributeState>({
    ethAmount: 1,
    blocked: true,
    fetching: true,
  });

  useEffect(() => {
    if (!state.fetching) return;

    fetch('https://ipwhois.app/json/?key=PYKMKQCGDyqCsWpJ')
      .then(async (res: any) => {
        const data = await res.json();

        if (BLOCKED_COUNTRIES.includes(data.country_code)) {
          setState((s) => ({
            ...s,
            blocked: true,
            fetching: false,
            countryCode: data.country_code,
            country: data.country,
          }));
        } else {
          setState((s) => ({
            ...s,
            blocked: false,
            fetching: false,
            countryCode: data.country_code,
            country: data.country,
          }));
        }
      })
      .catch(() => {
        setState((s) => ({ ...s, blocked: false, fetching: false }));
      });
  }, [state.fetching]);

  const handleContribute = useCallback(async () => {
    return transact(
      contracts.premiaPBC?.contribute({
        value: parseEther(state.ethAmount.toString()),
      }),
    );
  }, [contracts, transact, state.ethAmount]);

  function handleEthAmountChange(e: any) {
    const value = e.target.value;
    setState((s) => ({ ...s, ethAmount: Number(value) }));
  }

  return (
    <div
      className={classes.container}
      {...(wallet && wallet.provider && account
        ? {}
        : { onClick: () => setConfirmTermsModalOpen(true) })}
    >
      {confirmTermsModalOpen && (
        <ConfirmTermsModal
          open={confirmTermsModalOpen}
          onClose={() => setConfirmTermsModalOpen(false)}
        />
      )}

      {state.blocked && !state.fetching ? (
        <div
          style={{
            fontWeight: 'bold',
            color: 'orange',
            fontSize: 14,
            marginBottom: 10,
          }}
        >
          {`Residents of ${state.country} are not allowed to participate in the PBC`}
        </div>
      ) : (
        <div
          style={{
            fontWeight: 'bold',
            color: 'orange',
            fontSize: 14,
            marginBottom: 10,
          }}
        >
          All contributions are final.
        </div>
      )}

      {!state.blocked && !state.fetching && (
        <>
          {wallet && wallet.provider && account ? (
            <>
              <div
                className={classes.inputGroup}
                style={{ opacity: props.isPBCEnded ? 0.5 : undefined }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    className={classes.inputGroup}
                    style={{
                      opacity:
                        state.blocked || props.isPBCEnded ? 0.5 : undefined,
                    }}
                  >
                    <div className={classes.inputContainer}>
                      <input
                        disabled={state.blocked || props.isPBCEnded}
                        value={state.ethAmount}
                        onChange={handleEthAmountChange}
                        type='number'
                      />
                      <div className={classes.inputToken}>ETH</div>
                    </div>
                  </div>

                  <Button
                    variant='contained'
                    color='primary'
                    style={{ opacity: props.isPBCEnded ? 0.5 : undefined }}
                    disabled={state.blocked || props.isPBCEnded}
                    onClick={handleContribute}
                  >
                    Contribute
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Typography variant='body1' align='center'>
              Connect Wallet to Participate
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default PBCContribute;
