import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';

import { PageWithSidebar } from 'layouts';
import { TokenWallet, Breadcrumb } from 'components';
import { PairSummariesTable, PairSummariesMobileCards } from './components';
import { Chart } from '../Pair/components';

const Pairs: React.FC = () => {
  return (
    <>
      <Hidden smDown>
        <PageWithSidebar>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Breadcrumb
                steps={[{ title: 'Dashboard', link: '/' }, { title: 'Pairs' }]}
              />

              <Grid container spacing={4} style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} md={6}>
                  <Chart
                    id='all'
                    type='line'
                    label='Open Interest'
                    height={240}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Chart id='all' type='bar' label='Volume' height={240} />
                </Grid>
              </Grid>

              <PairSummariesTable />
            </Grid>

            <Grid item xs={4}>
              <Box position='fixed' width={1 / 4}>
                <TokenWallet />
              </Box>
            </Grid>
          </Grid>
        </PageWithSidebar>
      </Hidden>

      <Hidden mdUp>
        <PageWithSidebar mobile>
          <PairSummariesMobileCards />
        </PageWithSidebar>
      </Hidden>
    </>
  );
};

export default Pairs;
