import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import { setPBCSettings } from './actions';

export interface PBCSettings {
  startBlock: number;
  endBlock: number;
  ethTotal: BigNumber;
  userContribution: BigNumber;
  hasCollected: boolean;
}

export interface SetPBCSettings {
  startBlock?: number;
  endBlock?: number;
  ethTotal?: BigNumber;
  userContribution?: BigNumber;
  hasCollected?: boolean;
}

export interface PBCState {
  pbcSettings: PBCSettings;
}

export const initialState: PBCState = {
  pbcSettings: {
    startBlock: 0,
    endBlock: 1,
    ethTotal: BigNumber.from(0),
    userContribution: BigNumber.from(0),
    hasCollected: false,
  },
};

export default createReducer(initialState, (builder) =>
  builder.addCase(
    setPBCSettings,
    (state, { payload }: { payload: SetPBCSettings }) => {
      state.pbcSettings = { ...state.pbcSettings, ...payload };
    },
  ),
);
