import React, { useEffect, useState, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useBlockNumber } from 'state/application/hooks';
import { usePBCSettings } from 'state/pbc/hooks';
import useInterval from 'hooks/useInterval';

const useStyles = makeStyles({
  counterSubtitle: {
    minWidth: 60,
    textAlign: 'center',
    fontSize: 11,
    color: '#b151e8',
    fontFamily: 'Roboto Mono'
  },

  counterSeparator: {
    padding: '0 5px'
  },

  squareCounter: {
    background: '#b151e8',
    boxShadow: '1px 1px 1px 1px rgba(177, 81, 232, 0.31)',
    width: 45,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  textVariantTwo: {
    fontSize: 11,
    color: '#3edaff',
    fontFamily: 'Roboto Mono'
  },

  textVariantOne: {
    fontSize: 11,
    color: '#b151e8',
    fontFamily: 'Roboto Mono'
  }
});

const PBCTimer: React.FC = () => {
  const classes = useStyles();
  const [lastBlockTime, setLastBlockTime] = useState(new Date());
  const [sinceLastUpdate, setSinceLastUpdate] = useState(0);
  const lastBlock = useBlockNumber();
  const pbcSettings = usePBCSettings();

  const secondsLeft = useMemo(
    () => Math.max(pbcSettings.secondsLeft - sinceLastUpdate, 0),
    [pbcSettings.secondsLeft, sinceLastUpdate],
  );

  useEffect(() => {
    setLastBlockTime(new Date());
  }, [lastBlock]);

  useInterval(() => {
    setSinceLastUpdate((new Date().getTime() - lastBlockTime.getTime()) / 1000);
  }, 1000);

  return (
    <Grid container direction='column' justify='center' alignItems='center'>
      <div className={classes.textVariantTwo}>Premia PBC ends in :</div>

      <Box clone paddingY={1}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <div className={classes.squareCounter}>{Math.floor(secondsLeft / (24 * 3600))}</div>

          <div className={classes.counterSeparator}>:</div>

          <div className={classes.squareCounter}>
            {Math.floor((secondsLeft % (24 * 3600)) / 3600)}
          </div>

          <div className={classes.counterSeparator}>:</div>

          <div className={classes.squareCounter}>
            {Math.floor(((secondsLeft % (24 * 3600)) % 3600) / 60)}
          </div>

          <div className={classes.counterSeparator}>:</div>

          <div className={classes.squareCounter}>
            {Math.floor(((secondsLeft % (24 * 3600)) % 3600) % 60)}
          </div>
        </Grid>
      </Box>

      <Grid container direction='row' justify='center' alignItems='center'>
        <div className={classes.counterSubtitle}>Days</div>
        <div className={classes.counterSubtitle}>Hours</div>
        <div className={classes.counterSubtitle}>Minutes</div>
        <div className={classes.counterSubtitle}>Secs</div>
      </Grid>
    </Grid>
  );
};

export default PBCTimer;
