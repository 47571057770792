import { useCallback, useEffect, useState, useMemo } from 'react';
import { ethers } from 'ethers';

import { useTransact } from 'hooks';
import { useWeb3 } from 'state/application/hooks';
import { TokenDenominator, Token, isToken } from 'web3/tokens';
import { getOptionContract } from 'web3/contracts';
import { Erc20, Erc20__factory } from 'contracts';

export function useApproveOptionBatch(pairs: any[][]) {
  const [approvedAllDenominator, setApprovedAllDenominator] = useState(false);
  const [approvedAllTokens, setApprovedAllTokens] = useState(false);
  const [loading, setLoading] = useState(false);
  const transact = useTransact();
  const { account, signer, contracts } = useWeb3();

  const getDenominator = (denominator: Token) => {
    return isToken(denominator)
      ? TokenDenominator[
          (denominator as Token).symbol as keyof typeof TokenDenominator
        ]
      : denominator;
  };

  const denominators = useMemo(
    () => [...new Set(pairs.map((pair) => pair[1]))],
    [pairs],
  );

  const optionContracts = useMemo(
    () =>
      denominators.map((denominator: Token) =>
        denominator
          ? getOptionContract(contracts, getDenominator(denominator))
          : null,
      ),
    [denominators, contracts],
  );

  const tokenContracts = useMemo(
    () =>
      pairs.reduce(
        (
          result: {
            [key: string]: { contract: Erc20 | null; denominator: Token };
          },
          pair: any[],
        ) => ({
          ...result,
          [pair[0]]: {
            contract: pair[0] ? Erc20__factory.connect(pair[0], signer!) : null,
            denominator: pair[1],
          },
        }),
        {},
      ),
    [pairs, signer],
  );

  const premiaOptionBatch = useMemo(() => contracts.premiaOptionBatch, [
    contracts,
  ]);

  const fetchApproval = useCallback(async () => {
    if (!optionContracts || !premiaOptionBatch || !tokenContracts) return;

    const isTokensAllApproved = (
      await Promise.all(
        Object.keys(tokenContracts).map((key) =>
          (tokenContracts as any)[key].contract?.allowance(
            account,
            getOptionContract(
              contracts,
              getDenominator((tokenContracts as any)[key].denominator),
            )?.address as string,
          ),
        ),
      )
    ).every((tokenAllowance) => tokenAllowance?.gt(0));

    const isDenominatorAllApproved = (
      await Promise.all(
        optionContracts.map((optionContract) =>
          optionContract?.isApprovedForAll(account, premiaOptionBatch.address),
        ),
      )
    ).every(Boolean);

    setApprovedAllDenominator(isDenominatorAllApproved);
    setApprovedAllTokens(isTokensAllApproved);
    setLoading(false);
  }, [account, contracts, optionContracts, tokenContracts, premiaOptionBatch]);

  useEffect(() => {
    if (account && tokenContracts) {
      setLoading(true);
      fetchApproval().catch((e) => console.error(e));
    }

    const refreshInterval = setInterval(fetchApproval, 1000);

    return () => clearInterval(refreshInterval);
  }, [account, tokenContracts, fetchApproval]);

  const handleApproveAllTokens = useCallback(async () => {
    if (!tokenContracts || !premiaOptionBatch) return console.log('uh oh');

    return Promise.all(
      Object.keys(tokenContracts).map(async (key) => {
        const tokenContract = (tokenContracts as any)[key];
        const denominatorContract = getOptionContract(
          contracts,
          getDenominator(tokenContract.denominator),
        );
        const symbol = await tokenContract.contract?.symbol();

        return transact(
          tokenContract.contract?.approve(
            denominatorContract?.address as string,
            ethers.constants.MaxUint256,
          ),
          {
            closeOnSuccess: true,
            option: null,
            description: `Approve ${symbol} batch transfer to option contract`,
          },
        );
      }),
    );
  }, [tokenContracts, contracts, transact, premiaOptionBatch]);

  const handleApproveAllDenominator = useCallback(async () => {
    if (!optionContracts || !premiaOptionBatch) return console.log('uh oh');

    return optionContracts.map((optionContract) =>
      transact(
        optionContract?.setApprovalForAll(premiaOptionBatch.address, true),
        { closeOnSuccess: true, description: 'Approve options for batch contract' },
      ),
    );
  }, [optionContracts, transact, premiaOptionBatch]);

  return {
    approvedAllDenominator,
    approvedAllTokens,
    loading,
    onApproveToken: handleApproveAllTokens,
    onApproveDenominator: handleApproveAllDenominator,
  };
}

export default useApproveOptionBatch;
