import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, TableCell, TableRow, Box } from '@material-ui/core';
import { formatUnits } from 'ethers/lib/utils';
import { get } from 'lodash';
import cx from 'classnames';

import { getTokenPairs } from 'graphql/queries';
import { getAvailabilities, TokenPair, PairAvailability } from 'web3/tokens';
import { useDenominatorAddress } from 'hooks';
import { getDenominatorLogo } from 'utils/getDenominatorLogo';
import { formatCompact } from 'utils/formatNumber';
import { usePrices } from 'state/application/hooks';
import { contracts } from 'web3/contracts';

import { DataTable, TokenPairLogo, Loader } from 'components';
import { HeadCell } from 'components/DataTable';
import PageSummariesToolbar from './PairSummariesToolbar';

const getHeadCells = (prices: {
  [key: string]: number;
}): HeadCell<TokenPair>[] => [
  {
    id: 'logo',
    numeric: false,
    label: '',
    sortKey: (pair: TokenPair) => pair.name,
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (pair: TokenPair) => pair.name,
  },
  {
    id: 'open_interest',
    numeric: true,
    label: 'Open Interest',
    sortKey: (pair: TokenPair) =>
      Number(formatUnits(pair.openInterest, pair.token.decimals)) *
      prices[pair.token.symbol],
  },
  {
    id: 'total_volume',
    numeric: true,
    label: 'Volume',
    sortKey: (pair: TokenPair) =>
      Number(formatUnits(pair.totalVolume, pair.token.decimals)) *
      prices[pair.token.symbol],
  },
  {
    id: 'availability',
    numeric: true,
    label: 'Availability',
    sortKey: (pair: TokenPair) =>
      Number(formatUnits(pair.openInterest, pair.token.decimals)) *
      prices[pair.token.symbol],
  },
  {
    id: 'actions',
    numeric: true,
    label: 'Actions',
    sortKey: (pair: TokenPair) => 1,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greenText: {
      color: theme.palette.success.main,
    },
    redText: {
      color: theme.palette.error.main,
    },
    thinText: {
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
    loaderWithMessage: {
      position: 'absolute',
      top: '170px',
      height: '70px',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center', 
      margin: '0 auto',
    },
  }),
);

export default function PairSummariesTable() {
  const classes = useStyles();
  const history = useHistory();
  const denominatorAddress = useDenominatorAddress();
  const prices = usePrices();
  const headCells = getHeadCells(prices);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: loadingTokenPairs, data: tokenPairsData, fetchMore }] = useLazyQuery(
    getTokenPairs,
    {
      variables: { first: 100, skip, denominatorAddress: denominatorAddress || contracts.DAI[1] },
    },
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenPairs: TokenPair[] = useMemo(
    () => {
      if (!!tokenPairsData?.tokenPairs?.length) {
        setResult(tokenPairsData?.tokenPairs || []);
      }

      return get(tokenPairsData, 'tokenPairs') || []; 
    },
    [tokenPairsData],
  );

  const availabilities = useMemo(() => getAvailabilities(tokenPairs, prices), [
    tokenPairs,
    prices,
  ]);

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          tokenPairs: isFirstPage
            ? currentResult?.fetchMoreResult?.tokenPairs
            : [...result, ...currentResult?.fetchMoreResult?.tokenPairs
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  return Object.values(prices).length > 0 ? (
    <DataTable
      headCells={headCells}
      data={tokenPairs}
      size={tokenPairs.length}
      onChange={onChange}
      loading={loadingTokenPairs}
      defaultOrderBy={headCells[2]}
      defaultOrder='desc'
      caption='* Highlighted options may be In the Money (ITM).'
      toolbar={<PageSummariesToolbar />}
      renderRow={(pair: TokenPair, index) => {
        const availability = availabilities[pair.name];
        const price = prices[pair.token.symbol];
        const denominatorLogo = getDenominatorLogo(pair.denominator.symbol);

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={pair.name}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/pairs/${pair.name}`)}
          >
            <TableCell component='th' scope='row' align='center'>
              <TokenPairLogo pair={pair} backgroundIndex={index} />
            </TableCell>

            <TableCell align='center' className={classes.thinText}>
              <b>{pair.token.symbol}</b>-{pair.denominator.symbol}
            </TableCell>

            <TableCell align='center' className={classes.thinText}>
              <Grid container justify='center' alignItems='center'>
                <b style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    price *
                      Number(
                        formatUnits(pair.openInterest, pair.token.decimals),
                      ),
                    1,
                  )}
                </b>
                <img
                  src={denominatorLogo}
                  height={16}
                  width={16}
                  alt={pair.denominator.symbol}
                />
              </Grid>
            </TableCell>

            <TableCell align='center' className={classes.thinText}>
              <Grid container justify='center' alignItems='center'>
                <b style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    price *
                      Number(
                        formatUnits(pair.totalVolume, pair.token.decimals),
                      ),
                    1,
                  )}
                </b>
                <img
                  src={denominatorLogo}
                  height={16}
                  width={16}
                  alt={pair.denominator.symbol}
                />
              </Grid>
            </TableCell>

            <TableCell
              align='center'
              className={cx({
                [classes.greenText]: availability === PairAvailability.HIGH,
                [classes.redText]: availability === PairAvailability.LOW,
              })}
            >
              {availability}
            </TableCell>

            <TableCell align='center'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => history.push(`/pairs/${pair.name}`)}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        );
      }}
    />
  ) : (
    <Box display="flex" className={classes.loaderWithMessage}>
      <Loader />
    </Box>
  );
}
