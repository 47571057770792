import React, { useState, useEffect } from 'react';
import { Modal, Grid, Box, Hidden } from '@material-ui/core';

import { useOptionSettings } from 'state/options/hooks';
import { useExpirations } from 'hooks';
import { Option } from 'web3/options';

import {
  ModalContainer,
  HorizontalOptionFilter,
  OptionTitle,
} from 'components';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../constants';

export interface OptionModalProps {
  open: boolean;
  option: Option;
  toolbar?: React.ReactNode;
  onClose: () => void;
}

const OptionModal: React.FC<OptionModalProps> = ({
  open,
  option,
  toolbar,
  children,
  onClose,
}) => {
  const [mountedOption, setMountedOption] = useState<string | null>(null);

  const expirations = useExpirations();

  const { selectedOption, setOptionSettings } = useOptionSettings();

  useEffect(() => {
    if (mountedOption && mountedOption !== option.id.toString()) {
      setMountedOption(null);
    }

    if (!mountedOption) {
      setMountedOption(option.id.toString());
      setOptionSettings({
        selectedOption: option,
        selectedToken: option.token,
        optionType: option.type,
        selectedExpiration: new Date(Number(option.expiration) * 1000),
        strikePrice: formatUnits(option.strikePrice ?? '0', DEFAULT_DECIMALS),
      });
    }
  }, [setOptionSettings, option, mountedOption, expirations]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='lg'>
        <Hidden smDown>
          <HorizontalOptionFilter />
        </Hidden>

        <Box clone width={1}>
          <Grid container justify='space-between'>
            <OptionTitle option={selectedOption || option} />

            <Grid item>{toolbar}</Grid>
          </Grid>
        </Box>

        <Grid container direction='row' justify='center'>
          {children}
        </Grid>
      </ModalContainer>
    </Modal>
  );
};

export default OptionModal;
