import React, { useState } from 'react';
import { Grid, Box, Hidden, Tooltip, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SwapHoriz, AccountBalance } from '@material-ui/icons';

import { Option } from 'web3/options';

import {
  OptionModal,
  OptionOrderBook,
  OptionTradePanel,
  MintAndSellOptionPanel,
} from 'components';

export interface TradeOptionModalProps {
  open: boolean;
  option: Option;
  defaultPanel?: 'trade' | 'mint';
  onClose: () => void;
}

const TradeOptionModal: React.FC<TradeOptionModalProps> = ({
  open,
  option,
  defaultPanel = 'trade',
  onClose,
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('xl'));
  const [activePanel, setActivePanel] = useState<'trade' | 'mint'>(
    defaultPanel,
  );

  return (
    <OptionModal
      open={open}
      option={option}
      toolbar={
        <Grid container>
          <Box clone marginRight={2}>
            <IconButton onClick={() => setActivePanel('mint')}>
              <Tooltip title='Mint Option'>
                <AccountBalance />
              </Tooltip>
            </IconButton>
          </Box>

          <IconButton onClick={() => setActivePanel('trade')}>
            <Tooltip title='Trade Option'>
              <SwapHoriz />
            </Tooltip>
          </IconButton>
        </Grid>
      }
      onClose={onClose}
    >
      <Hidden lgDown>
        <Box marginRight={3} marginTop={4} width='28%'>
          {activePanel === 'trade' ? (
            <OptionTradePanel />
          ) : (
            <MintAndSellOptionPanel />
          )}
        </Box>

        <Box marginLeft={desktop ? 2 : 0} width='65%'>
          <OptionOrderBook
            activePanel={activePanel}
            option={option}
            onGoToTrade={() => setActivePanel('trade')}
            onGoToMint={() => setActivePanel('mint')}
          />
        </Box>
      </Hidden>

      <Hidden xlUp>
        <Box marginRight={0} marginTop={2} width='100%'>
          {activePanel === 'trade' ? (
            <OptionTradePanel />
          ) : (
            <MintAndSellOptionPanel />
          )}
        </Box>

        <Box marginLeft={desktop ? 2 : 0} marginTop={4} width='100%'>
          <OptionOrderBook
            activePanel={activePanel}
            option={option}
            onGoToTrade={() => setActivePanel('trade')}
            onGoToMint={() => setActivePanel('mint')}
          />
        </Box>
      </Hidden>
    </OptionModal>
  );
};

export default TradeOptionModal;
