import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HeroPBC from 'assets/svg/HeroPBC.svg';
import HeroBondingCurve from 'assets/svg/HeroBondingCurve.svg';

const useStyles = makeStyles({
  title: {
    fontFamily: '"Poppins"',
    fontSize: '1.9rem',
    fontWeight: 600,
  },

  subtitle: {
    fontFamily: '"Inter"',
    fontSize: '1rem',
    marginTop: 8,
  },

  button: {
    height: 58,
  },

  learnMoreBtn: {
    width: 164,
    height: 56,
  
    background: '#141414',
    borderRadius: 16,  
    cursor: 'pointer',
  
    '&:focus': {
      outline: 'none'
    }
  }
});

export enum HeroVariant {
  PBC = 'pbc',
  BondingCurve = 'bondingCurve',
}

export interface HeroProps {
  variant: HeroVariant;
  title: string;
  subTitle: string;
  onLearnMore?: () => void;
}

const Hero: React.FC<HeroProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  let src: any;
  switch (props.variant) {
    case HeroVariant.PBC:
      src = HeroPBC;
      break;
    case HeroVariant.BondingCurve:
      src = HeroBondingCurve;
      break;
    default:
      src = HeroPBC;
      break;
  }

  return (
    <div style={{ position: 'relative' }}>
      <img src={src} alt='Hero' width='100%' onLoad={() => setLoading(false)} />

      {loading ? null : (
        <div
          style={{
            top: 0,
            left: 0,
            position: 'absolute',
            height: '100%',
            width: '100%',
            padding: '0 50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              component='h1'
              variant='h3'
              color='textPrimary'
              className={classes.title}
            >
              {props.title}
            </Typography>

            <Typography
              component='h3'
              color='textPrimary'
              className={classes.subtitle}
            >
              {props.subTitle}
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          {props?.onLearnMore ? (
            <Button
              className={classes.learnMoreBtn}
              onClick={props.onLearnMore}
              color='primary'
              variant='contained'
            >
              Learn More
            </Button>
          ) : undefined}
        </div>
      )}
    </div>
  );
};

export default Hero;
