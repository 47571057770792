/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { PremiaOptionBatch } from "../PremiaOptionBatch";

export class PremiaOptionBatch__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PremiaOptionBatch {
    return new Contract(address, _abi, signerOrProvider) as PremiaOptionBatch;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IPremiaOption",
        name: "_premiaOption",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_optionId",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_amounts",
        type: "uint256[]",
      },
    ],
    name: "batchCancelOption",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPremiaOption",
        name: "_premiaOption",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_optionId",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_amounts",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "_referrer",
        type: "address",
      },
    ],
    name: "batchExerciseOption",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPremiaOption",
        name: "_premiaOption",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_optionId",
        type: "uint256[]",
      },
    ],
    name: "batchWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPremiaOption",
        name: "_premiaOption",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_optionId",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_amounts",
        type: "uint256[]",
      },
    ],
    name: "batchWithdrawPreExpiration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPremiaOption",
        name: "_premiaOption",
        type: "address",
      },
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "strikePrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isCall",
            type: "bool",
          },
        ],
        internalType: "struct IPremiaOption.OptionWriteArgs[]",
        name: "_options",
        type: "tuple[]",
      },
      {
        internalType: "address",
        name: "_referrer",
        type: "address",
      },
    ],
    name: "batchWriteOption",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
