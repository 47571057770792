import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Card, Chip, Typography, Box, Button } from '@material-ui/core';
import { BigNumber, ethers } from 'ethers';
import { Contract, ContractCall } from 'ethers-multicall';
import { formatUnits } from 'ethers/lib/utils';
import moment from 'moment';
import cx from 'classnames';

import { getFilteredOptionsWithOrders } from 'graphql/queries';

import { OptionWithOrders, OptionType } from 'web3/options';
import {
  SaleSide,
  MarketOrder,
  IOrder,
  convertGraphMarketOrderToOrder,
} from 'web3/market';
import { useFilterSettings } from 'state/market/hooks';
import { usePrices, useWeb3 } from 'state/application/hooks';
import { useDebounce } from 'hooks';
import { formatCompact } from 'utils/formatNumber';
import { DEFAULT_DECIMALS } from '../../constants';
import PremiaMarketAbi from 'constants/abi/PremiaMarket.json';
import getDenominatorLogo from 'utils/getDenominatorLogo';
import {
  TokenPairLogo,
  TradeOptionModal,
  TradeNewOptionModal,
  Loader,
} from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      flexDirection: 'column',
      height: 'calc(100vh - 84px)',
      width: '100%',
      marginTop: '64px',
    },
    pageTitleMobile: {
      fontFamily: '"Teko"',
      fontWeight: 600,
      fontSize: '2.2rem',
      margin: '4px 0px 4px',
    },
    cardsWrapper: {
      flexDirection: 'column',
      overflowY: 'scroll',
      width: '100%',
      height: '100%',
    },
    cardRoot: {
      width: 'calc(100% - 4px)',
      minHeight: '180px',
      height: 'auto',
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '8px 0px 8px 4px',
      padding: '8px 18px',
      cursor: 'pointer',
    },
    col: {
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
    elementWrapper: {
      justifyContent: 'center',
      margin: '4px 6px',
      alignItems: 'center',
    },
    pairIconsBox: {
      width: '50px',
      height: '50px',
      justifyContent: 'center',
      margin: '4px 6px',
    },
    header: {
      alignSelf: 'center',
      minHeight: '248px',
      flexDirection: 'column',
      width: 'calc(100% - 28px)',
      margin: '16px 0px 12px',
    },
    chip: {
      width: '92px',
    },
    greenText: {
      color: theme.palette.success.main,
    },
    redText: {
      color: theme.palette.error.main,
    },
    thinText: {
      fontWeight: 300,
      marginRight: '16px',
      whiteSpace: 'nowrap',
    },
    loaderWithMessage: {
      position: 'absolute',
      top: '120px',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 auto',
    },
    bidText: {
      color: theme.palette.success.main,
    },
    askText: {
      color: theme.palette.error.main,
    },
    greyText: {
      fontWeight: 200,
    },
    thinFont: {
      fontWeight: 300,
    },
    spacedText: {
      marginLeft: '0.25rem',
    },
    separator: {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
    },
    tradeNewOptionButton: {
      padding: '4px 8px',
      fontSize: '14px',
      margin: '8px 0',
    },
    tradeButton: {
      padding: '2px',
      fontSize: '12px',
    },
  }),
);

export interface MarketOptionsCardsProps {
  filter: { [filterOption: string]: any };
}

const MarketOptionsCards: React.FC<MarketOptionsCardsProps> = ({ filter }) => {
  const classes = useStyles();
  const [high, setHigh] = React.useState(true);
  const { account, contracts, multicallProvider } = useWeb3();
  const [tradeNewOptionModalOpen, setTradeNewOptionModalOpen] = useState(false);
  const [validOptions, setValidOptions] = useState<OptionWithOrders[]>([]);
  const [optionToTrade, setOptionToTrade] = useState<null | OptionWithOrders>(
    null,
  );

  const { priceRange } = useFilterSettings();
  const prices = usePrices();

  const { loading: ordersLoading, data } = useQuery(
    getFilteredOptionsWithOrders,
    {
      variables: { first: 200, skip: 0, where: filter },
      pollInterval: 2500,
    },
  );

  const optionsListed = useDebounce(
    useMemo(
      () =>
        (data ? data.options : [])
          .filter((option: OptionWithOrders) => option?.orders?.length > 0)
          .filter(({ orders }: OptionWithOrders) =>
            orders.find(
              ({ remainingUnfilled }) => remainingUnfilled > BigNumber.from(0),
            ),
          )
          .filter(
            ({ orders }: OptionWithOrders) =>
              !priceRange ||
              orders.find(({ pricePerUnit }) => {
                const price = ethers.utils.formatEther(pricePerUnit);

                return (
                  Number(price) >= Number(priceRange.low || 0) &&
                  Number(price) <=
                    Number(priceRange.high || ethers.constants.MaxUint256)
                );
              }),
          ),
      [data, priceRange],
    ),
    250,
  );

  useEffect(() => {
    if (!account || !contracts.premiaMarket || !multicallProvider) {
      setValidOptions(optionsListed);
      return;
    }

    const marketContract = new Contract(
      contracts.premiaMarket.address,
      PremiaMarketAbi.abi,
    );

    (async () => {
      const optionsOrNull: (OptionWithOrders | null)[] = await Promise.all(
        optionsListed.map(async (option: OptionWithOrders) => {
          const sortedOrders = option.orders.map((order: MarketOrder) =>
            convertGraphMarketOrderToOrder(order),
          );

          const isValidCalls: ContractCall[] = sortedOrders.map(
            (order: IOrder) => marketContract.isOrderValid(order),
          );

          const isValidResults = await multicallProvider.all(isValidCalls);

          return sortedOrders.some(
            (order: IOrder, i: number) => isValidResults[i],
          )
            ? option
            : null;
        }),
      );

      const _validOptions = optionsOrNull.filter(
        (option: OptionWithOrders | null) => option !== null,
      ) as OptionWithOrders[];
      setValidOptions(_validOptions);
    })();
  }, [
    account,
    optionsListed,
    contracts.premiaMarket,
    multicallProvider,
    setValidOptions,
  ]);

  const getExpiration = (option: OptionWithOrders) => {
    const { expiration } = option;
    return Number(expiration?.toString()) * 1000;
  };

  const getStrike = (option: OptionWithOrders) => {
    const { strikePrice } = option;
    return Number(formatUnits(strikePrice, DEFAULT_DECIMALS));
  };

  const getOI = (option: OptionWithOrders) => {
    const { openInterest, token } = option;
    const price = prices[token.symbol];
    return formatCompact(
      price * Number(formatUnits(openInterest, DEFAULT_DECIMALS)),
      1,
    );
  };

  const getVolume = (option: OptionWithOrders) => {
    const { totalVolume, token } = option;
    const price = prices[token.symbol];
    return formatCompact(
      price * Number(formatUnits(totalVolume, DEFAULT_DECIMALS)),
      1,
    );
  };

  const getTypeValue = (option: OptionWithOrders) => {
    const { type } = option;
    return type === 'CALL' ? 1 : 0;
  };

  const getBidAsk = (option: OptionWithOrders) => {
    const { orders, denominator } = option;
    const bidAsk = orders.reduce(
      ({ bid, ask }: any, order) => {
        if (!BigNumber.from(order.remainingUnfilled).gt(0)) {
          return { bid, ask };
        }

        let highBid = bid.value;
        let highBidToken = bid.token;
        let lowAsk = ask.value;
        let lowAskToken = ask.token;

        if (order.side === SaleSide.Buy) {
          highBid = order.pricePerUnit > highBid ? order.pricePerUnit : highBid;
          highBidToken =
            order.pricePerUnit > highBid
              ? order.paymentToken.symbol
              : highBidToken;
        } else {
          lowAsk = order.pricePerUnit < lowAsk ? order.pricePerUnit : lowAsk;
          lowAskToken =
            order.pricePerUnit < lowAsk
              ? order.paymentToken.symbol
              : lowAskToken;
        }

        return {
          bid: { value: highBid, token: highBidToken },
          ask: { value: lowAsk, token: lowAskToken },
        };
      },
      {
        bid: { value: 0, token: denominator.symbol },
        ask: { value: Infinity, token: denominator.symbol },
      },
    );
    return bidAsk;
  };

  const getSpread = (option: OptionWithOrders) => {
    const { orders, denominator } = option;
    const bidAsk = orders.reduce(
      ({ bid, ask }: any, order) => {
        if (!BigNumber.from(order.remainingUnfilled).gt(0)) {
          return { bid, ask };
        }

        let highBid = bid.value;
        let highBidToken = bid.token;
        let lowAsk = ask.value;
        let lowAskToken = ask.token;

        if (order.side === SaleSide.Buy) {
          highBid = order.pricePerUnit > highBid ? order.pricePerUnit : highBid;
          highBidToken =
            order.pricePerUnit > highBid
              ? order.paymentToken.symbol
              : highBidToken;
        } else {
          lowAsk = order.pricePerUnit < lowAsk ? order.pricePerUnit : lowAsk;
          lowAskToken =
            order.pricePerUnit < lowAsk
              ? order.paymentToken.symbol
              : lowAskToken;
        }

        return {
          bid: { value: highBid, token: highBidToken },
          ask: { value: lowAsk, token: lowAskToken },
        };
      },
      {
        bid: { value: 0, token: denominator.symbol },
        ask: { value: Infinity, token: denominator.symbol },
      },
    );
    return bidAsk.bid.value - bidAsk.ask.value;
  };

  const sortByExpiration = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        getExpiration(high ? b : a) - getExpiration(high ? a : b),
    );
    setHigh(!high);
  };

  const sortByVolme = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        parseFloat(getVolume(high ? b : a)) -
        parseFloat(getVolume(high ? a : b)),
    );
    setHigh(!high);
  };

  const sortByOI = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        parseFloat(getOI(high ? b : a)) - parseFloat(getOI(high ? a : b)),
    );
    setHigh(!high);
  };

  const sortByType = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        getTypeValue(high ? b : a) - getTypeValue(high ? a : b),
    );
    setHigh(!high);
  };

  const sortByStrike = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        getStrike(high ? b : a) - getStrike(high ? a : b),
    );
    setHigh(!high);
  };

  const sortBySpread = () => {
    validOptions.sort(
      (a: OptionWithOrders, b: OptionWithOrders) =>
        getSpread(high ? b : a) - getSpread(high ? a : b),
    );
    setHigh(!high);
  };

  const cards =
    validOptions && validOptions.length > 0
      ? validOptions.map((option: OptionWithOrders, index: number) => {
          const { type, denominator, token, pair } = option;

          const denominatorLogo = getDenominatorLogo(denominator.symbol);

          return (
            <Card
              className={classes.cardRoot}
              key={index}
              onClick={() => setOptionToTrade(option)}
            >
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                  justifyContent='space-evenly'
                  alignItems='center'
                  width='100%'
                >
                  {/* Pair     */}
                  <Box display='flex' className={classes.elementWrapper}>
                    <b
                      className={cx({
                        [classes.askText]: type === OptionType.Put,
                        [classes.bidText]: type === OptionType.Call,
                      })}
                    >
                      {token.symbol}
                    </b>
                    -<span>{denominator.symbol}</span>
                  </Box>

                  {'•'}

                  {/* Expiration */}
                  <Box display='flex' className={classes.elementWrapper}>
                    {moment(getExpiration(option)).format('MMM. Do, YYYY')}
                  </Box>

                  {'•'}

                  {/* Type */}

                  <Box display='flex' className={classes.elementWrapper}>
                    <span
                      className={cx({
                        [classes.askText]: type === OptionType.Put,
                        [classes.bidText]: type === OptionType.Call,
                      })}
                    >
                      {type.toUpperCase()}
                    </span>
                  </Box>
                </Box>

                <Box display='flex' justifyContent='space-evenly' width='100%'>
                  <Box height='72px'>
                    <TokenPairLogo pair={pair} backgroundIndex={index} mobile />
                  </Box>

                  <Box display='flex' flexDirection='column'>
                    {/* Strike */}
                    <Box display='flex'>
                      <Typography>Strike:</Typography>
                      <Box display='flex' className={classes.elementWrapper}>
                        <span style={{ marginRight: '0.25rem' }}>
                          {formatCompact(getStrike(option))}
                        </span>

                        <img
                          src={denominatorLogo}
                          height={16}
                          width={16}
                          alt={denominator.symbol}
                        />
                      </Box>
                    </Box>

                    {/* -OI- */}
                    <Box display='flex'>
                      <Typography>OI:</Typography>
                      <Box display='flex' className={classes.elementWrapper}>
                        <b style={{ marginRight: '0.25rem' }}>
                          {getOI(option)}
                        </b>
                        <img
                          src={denominatorLogo}
                          height={16}
                          width={16}
                          alt={denominator.symbol}
                        />
                      </Box>
                    </Box>

                    {/* Volume */}
                    <Box display='flex'>
                      <Typography>Volume:</Typography>
                      <Box display='flex' className={classes.elementWrapper}>
                        <b style={{ marginRight: '0.25rem' }}>
                          {getVolume(option)}
                        </b>
                        <img
                          src={denominatorLogo}
                          height={16}
                          width={16}
                          alt={denominator.symbol}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display='flex' justifyContent='space-between' width='90%'>
                  {/* Spread */}
                  <Box display='flex' justifySelf='center'>
                    <Typography>Spread:</Typography>
                    <Box display='flex' className={classes.elementWrapper}>
                      <span className={classes.bidText}>
                        {formatCompact(
                          Number(getBidAsk(option).bid.value) /
                            Math.pow(10, DEFAULT_DECIMALS),
                        )}
                      </span>
                      <img
                        src={denominatorLogo}
                        height={16}
                        width={16}
                        style={{ margin: '0px 0 0 3px' }}
                        alt={denominator.symbol}
                      />
                      <span className={classes.separator}>-</span>
                      <span className={classes.askText}>
                        {formatCompact(
                          Number(getBidAsk(option).ask.value) /
                            Math.pow(10, DEFAULT_DECIMALS),
                        )}
                      </span>{' '}
                      <img
                        src={denominatorLogo}
                        height={16}
                        width={16}
                        style={{ margin: '0px 3px 0' }}
                        alt={denominator.symbol}
                      />
                    </Box>
                  </Box>

                  {/* Action Button */}
                  <Box>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setOptionToTrade(option)}
                      className={classes.tradeButton}
                    >
                      Trade
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          );
        })
      : false;

  return !ordersLoading ? (
    <Box display='flex' className={classes.body}>
      <Box display='flex' className={classes.header}>
        <Typography className={classes.pageTitleMobile}>
          Listed Options
        </Typography>
        <Box alignSelf='flex-end'>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setTradeNewOptionModalOpen(true)}
            className={classes.tradeNewOptionButton}
          >
            Trade New Option
          </Button>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          minHeight='80px'
          justifyContent='space-evenly'
          style={{ margin: '8px 0' }}
        >
          <Typography variant='h5'>Sort by: </Typography>
          <Box
            display='flex'
            width='98%'
            flexDirection='column'
            style={{ margin: '4px 0 0' }}
          >
            <Box display='flex' justifyContent='space-between' marginTop='8px'>
              <Chip
                onClick={() => sortByExpiration()}
                label='Expiration'
                size='small'
                variant='outlined'
                style={{ margin: '2px 6px 2px 0', width: '100px' }}
              />
              <Chip
                onClick={() => sortByType()}
                label='Type'
                size='small'
                variant='outlined'
                style={{ margin: '2px 6px', width: '100px' }}
              />
              <Chip
                onClick={() => sortByStrike()}
                label='Strike'
                size='small'
                variant='outlined'
                style={{ margin: '2px 0 2px 6px', width: '100px' }}
              />
            </Box>
            <Box display='flex' justifyContent='space-between' marginTop='8px'>
              <Chip
                onClick={() => sortByVolme()}
                label='Volume'
                size='small'
                variant='outlined'
                style={{ margin: '2px 6px 2px 0', width: '100px' }}
              />
              <Chip
                onClick={() => sortByOI()}
                label='OI'
                size='small'
                variant='outlined'
                style={{ margin: '2px 6px', width: '100px' }}
              />
              <Chip
                onClick={() => sortBySpread()}
                label='Spread'
                size='small'
                variant='outlined'
                style={{ margin: '2px 0 2px 6px', width: '100px' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display='flex' className={classes.cardsWrapper}>
        {cards}
      </Box>

      <TradeNewOptionModal
        open={tradeNewOptionModalOpen}
        onClose={() => setTradeNewOptionModalOpen(false)}
      />

      {optionToTrade && (
        <TradeOptionModal
          open
          option={optionToTrade}
          onClose={() => setOptionToTrade(null)}
        />
      )}
    </Box>
  ) : (
    <Loader />
  );
};

export default MarketOptionsCards;
