import { createReducer } from '@reduxjs/toolkit';

import { OptionType, Option } from 'web3/options';
import { TokenDenominator, Token } from 'web3/tokens';

import {
  SetOptionSettings,
  setOptionSettings,
  SetExerciseSettings,
  setExerciseSettings,
  SetWithdrawSettings,
  setWithdrawSettings,
  SetTransferSettings,
  setTransferSettings,
} from './actions';

const DEFAULT_SLIPPAGE = '2.0';

export interface OptionSettings {
  denominator: TokenDenominator;
  optionType: OptionType;
  selectedToken?: Token | null;
  selectedExpiration?: Date;
  strikePrice?: string | null;
  selectedOption?: Option | null;
}

export enum ExerciseType {
  Exercise = 'Exercise',
  FlashExercise = 'Flash Exercise',
}

export interface ExerciseSettings {
  type: ExerciseType;
  quantity?: string;
  maxSlippage?: string;
}

export enum WithdrawType {
  WithdrawExpired = 'Withdraw Expired',
  TakeEarlyAssignment = 'Take Early Assignment',
  BurnEarly = 'Burn Early',
}

export interface WithdrawSettings {
  type: WithdrawType;
  quantity?: string;
}

export interface TransferSettings {
  quantity?: string;
  to?: string;
}

export interface OptionsState {
  optionSettings: OptionSettings;
  exerciseSettings: ExerciseSettings;
  withdrawSettings: WithdrawSettings;
  transferSettings: TransferSettings;
}

export const initialState: OptionsState = {
  optionSettings: {
    denominator: TokenDenominator.DAI,
    optionType: OptionType.Call,
    selectedToken: undefined,
    selectedExpiration: undefined,
    strikePrice: undefined,
    selectedOption: undefined,
  },

  exerciseSettings: {
    type: ExerciseType.Exercise,
    quantity: undefined,
    maxSlippage: DEFAULT_SLIPPAGE,
  },

  withdrawSettings: {
    type: WithdrawType.WithdrawExpired,
    quantity: undefined,
  },

  transferSettings: {
    quantity: undefined,
    to: undefined,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      setOptionSettings,
      (state, { payload: optionSettings }: { payload: SetOptionSettings }) => {
        state.optionSettings = { ...state.optionSettings, ...optionSettings };
      },
    )
    .addCase(
      setExerciseSettings,
      (
        state,
        { payload: exerciseSettings }: { payload: SetExerciseSettings },
      ) => {
        state.exerciseSettings = {
          ...state.exerciseSettings,
          ...exerciseSettings,
        };
      },
    )
    .addCase(
      setTransferSettings,
      (
        state,
        { payload: transferSettings }: { payload: SetTransferSettings },
      ) => {
        state.transferSettings = {
          ...state.transferSettings,
          ...transferSettings,
        };
      },
    )
    .addCase(
      setWithdrawSettings,
      (
        state,
        { payload: withdrawSettings }: { payload: SetWithdrawSettings },
      ) => {
        state.withdrawSettings = {
          ...state.withdrawSettings,
          ...withdrawSettings,
        };
      },
    ),
);
