import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BigNumber } from 'ethers';

import { DEFAULT_DECIMALS } from '../../../../constants';
import { useFilterSettings } from 'state/market/hooks';
import { useDenominatorAddress, useDebounce } from 'hooks';

import { MarketOptionsTable, MarketOptionsCards } from 'components';

export default function ListedOptionsTable() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const denominatorAddress = useDenominatorAddress();

  const {
    token,
    expirationRange,
    strikeRange,
    optionType,
  } = useFilterSettings();

  const filter = useDebounce(
    useMemo(
      () => ({
        denominator: denominatorAddress,
        ...(token ? { token: token.address } : {}),
        ...(expirationRange
          ? {
              ...(expirationRange.start
                ? { expiration_gte: expirationRange.start?.unix() }
                : {}),
              ...(expirationRange.end
                ? { expiration_lte: expirationRange.end?.unix() }
                : {}),
            }
          : {}),
        ...(strikeRange
          ? {
              ...(strikeRange.low
                ? {
                    strikePrice_gte: BigNumber.from(Math.floor(strikeRange.low))
                      .mul(BigNumber.from(10).pow(DEFAULT_DECIMALS))
                      .toString(),
                  }
                : {}),
              ...(strikeRange.high
                ? {
                    strikePrice_lte: BigNumber.from(
                      Math.floor(strikeRange.high),
                    )
                      .mul(BigNumber.from(10).pow(DEFAULT_DECIMALS))
                      .toString(),
                  }
                : {}),
            }
          : {}),
        ...(optionType ? { type: optionType } : {}),
      }),
      [token, denominatorAddress, expirationRange, strikeRange, optionType],
    ),
    250,
  );

  if (!mobile) {
    return <MarketOptionsTable filter={filter} />;
  } else {
    return <MarketOptionsCards filter={filter} />;
  }
}
