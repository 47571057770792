import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';

import { getAccountHistory } from 'graphql/queries';
import { OptionType, Option } from 'web3/options';
import { AccountHistoryItem, AccountHistoryAction } from 'web3/account';
import { useWeb3 } from 'state/application/hooks';
import { formatCompact } from 'utils/formatNumber';

import { DataTable, TradeOptionModal, CurrencyLogo } from 'components';
import { HeadCell } from 'components/DataTable';
import WalletTableToolbar from './WalletTableToolbar';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../../../constants';

const headCells: HeadCell<AccountHistoryItem>[] = [
  {
    id: 'timestamp',
    numeric: true,
    label: 'Time',
    sortKey: (item: AccountHistoryItem) => item.timestamp.toString(),
  },
  {
    id: 'expiration',
    numeric: true,
    label: 'Expiration',
    sortKey: (item: AccountHistoryItem) => item.option.expiration.toString(),
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (item: AccountHistoryItem) =>
      `${item?.option.token}/${item.option.denominator}`,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortKey: (item: AccountHistoryItem) => item?.option.type.toString(),
  },
  {
    id: 'strike',
    numeric: true,
    label: 'Strike',
    sortKey: (item: AccountHistoryItem) => item?.option.strikePrice.toString(),
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
    sortKey: (item: AccountHistoryItem) => item?.amount.toString(),
  },
  {
    id: 'history',
    numeric: false,
    label: 'History',
    sortKey: (item: AccountHistoryItem) => item?.action,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sortKey: (item: AccountHistoryItem) => item?.txHash,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  thinFont: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
  },

  thickFont: {
    fontWeight: 700,
  },

  callGreen: {
    color: theme.palette.success.main,
    whiteSpace: 'nowrap',
  },

  putRed: {
    color: theme.palette.error.main,
    whiteSpace: 'nowrap',
  },

  orange: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
}));

export default function AccountHistoryTable() {
  const classes = useStyles();

  const { account } = useWeb3();
  const [anchorEl, setAnchorEl] = useState<null | {
    element: HTMLElement;
    itemId: string;
  }>(null);
  const [optionToTrade, setOptionToTrade] = useState<null | Option>(null);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: historyLoading, data, fetchMore }] = useLazyQuery(getAccountHistory, {
    variables: { first: 100, skip, account: account.toLowerCase() },
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useMemo(() => {
    if (!!data?.accountHistoryItems?.length) {
      setResult(data?.accountHistoryItems || []);
    }

    return data ? data.accountHistoryItems : [];
  }, [data]);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: AccountHistoryItem,
  ) => {
    setAnchorEl({ element: event.currentTarget, itemId: item.id });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          accountHistoryItems: isFirstPage
            ? currentResult?.fetchMoreResult?.accountHistoryItems
            : [...result, ...currentResult?.fetchMoreResult?.accountHistoryItems
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  return (
    <DataTable
      headCells={headCells}
      data={history}
      size={history.length}
      onChange={onChange}
      loading={historyLoading}
      defaultOrder='desc'
      toolbar={
        <>
          {optionToTrade && (
            <TradeOptionModal
              open
              option={optionToTrade}
              onClose={() => setOptionToTrade(null)}
            />
          )}

          <WalletTableToolbar />
        </>
      }
      caption='* Highlighted options may be In the Money (ITM).'
      renderRow={(item: AccountHistoryItem) => {
        const { id, option, amount, action, timestamp } = item;
        const { token, denominator, expiration, strikePrice, type } = option;

        return (
          <TableRow hover tabIndex={-1} key={id}>
            <TableCell
              component='th'
              scope='row'
              align='center'
              className={classes.thinFont}
            >
              {moment(Number(timestamp.toString()) * 1000).fromNow()}
            </TableCell>

            <TableCell
              component='th'
              scope='row'
              align='center'
              className={classes.thinFont}
            >
              {moment(Number(expiration.toString()) * 1000).format(
                'MMM. Do, YYYY',
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid container wrap='nowrap'>
                <span
                  className={cx(
                    type === OptionType.Call
                      ? classes.callGreen
                      : classes.putRed,
                  )}
                >
                  <b>{token.symbol}</b>
                </span>
                -<span>{denominator.symbol}</span>
              </Grid>
            </TableCell>

            <TableCell align='center'>
              {type === OptionType.Call ? (
                <span className={classes.callGreen}>CALL</span>
              ) : (
                <span className={classes.putRed}>PUT</span>
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(strikePrice.toString(), DEFAULT_DECIMALS),
                  )}
                </span>

                <CurrencyLogo currency={denominator} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(amount.toString(), token.decimals),
                  )}
                </span>

                <CurrencyLogo currency={token} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
                className={cx({
                  [classes.callGreen]: action.includes('Buy'),
                  [classes.putRed]: action.includes('Sell'),
                  [classes.orange]:
                    !action.includes('Buy') && !action.includes('Sell'),
                })}
              >
                {
                  AccountHistoryAction[
                    (action as unknown) as keyof typeof AccountHistoryAction
                  ]
                }
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <IconButton onClick={(event) => handleOpenMenu(event, item)}>
                <Add />
              </IconButton>

              <Menu
                keepMounted
                anchorEl={anchorEl?.element}
                open={anchorEl?.itemId === item.id}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setOptionToTrade(option);
                  }}
                >
                  Trade on Marketplace
                </MenuItem>

                <MenuItem
                  onClick={() =>
                    window.open(
                      `https://etherscan.io/tx/${item.txHash}`,
                      '_blank',
                    )
                  }
                >
                  View Transaction
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
