import { Currency } from '@uniswap/sdk';
import { BigNumber } from 'ethers';
import { isObject } from 'lodash';

import { Option } from './options';

export const BNB: Currency = {
  decimals: 18,
  symbol: 'BNB',
  name: 'Binance Coin',
};

export enum TokenDenominator {
  DAI = 'DAI',
  BUSD = 'BUSD',
  WBNB = 'WBNB',
  WETH = 'WETH',
  WBTC = 'WBTC',
}

export enum PairAvailability {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export type PairAvailabilityMap = { [pairName: string]: PairAvailability };

export interface Token extends Currency {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
}

export interface TokenPair {
  name: string;
  token: Token;
  denominator: Token;
  strikePriceIncrement: BigNumber;
  openInterest: BigNumber;
  totalVolume: BigNumber;

  options?: Option[];
}

export function isToken(object: any): object is Token {
  return isObject(object) && 'address' in object;
}

export function getAvailabilities(
  pairs: TokenPair[],
  prices: { [symbol: string]: number },
): PairAvailabilityMap {
  const totalOpenInterest = pairs.reduce(
    (total: number, pair: TokenPair) =>
      total + Number(pair.openInterest) * prices[pair.token.symbol],
    0,
  );

  return pairs.reduce(
    (availabilities: PairAvailabilityMap, pair: TokenPair) => {
      const oiFraction =
        (Number(pair.openInterest) * prices[pair.token.symbol]) /
        totalOpenInterest;

      if (oiFraction >= 0.3) {
        return { ...availabilities, [pair.name]: PairAvailability.HIGH };
      } else if (oiFraction >= 0.1) {
        return { ...availabilities, [pair.name]: PairAvailability.MEDIUM };
      }

      return { ...availabilities, [pair.name]: PairAvailability.LOW };
    },
    {},
  );
}
