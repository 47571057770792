import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';

import { getTokenPairs } from 'graphql/queries';
import { useDenominatorAddress } from 'hooks/useDenominatorAddress';
import { TokenPair } from 'web3/tokens';
import { contracts } from 'web3/contracts';

import { Loader, TokenPairLogo } from 'components';

const useStyles = makeStyles(() => ({
  scroller: {
    overflowX: 'scroll',
    paddingBottom: 4,
    marginTop: 16,
  },
}));

export interface TokenPairListProps {
  hideTitle?: boolean;
}

const TokenPairList: React.FC<TokenPairListProps> = (
  { hideTitle } = { hideTitle: false },
) => {
  const classes = useStyles();
  const denominatorAddress = useDenominatorAddress();
  const history = useHistory();

  const { loading: loadingTokenPairs, data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      variables: { denominatorAddress: denominatorAddress || contracts.DAI[1] },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  return (
    <Box clone paddingX={2} paddingY={1} marginY={4} boxShadow={3}>
      <Paper>
        {loadingTokenPairs ? (
          <Loader />
        ) : (
          <>
            <Grid container>
              <Grid
                item
                container
                direction='row'
                justify={hideTitle ? 'flex-end' : 'space-between'}
              >
                {!hideTitle && (
                  <Typography>Select a pair below for more details:</Typography>
                )}

                <Link to='/pairs'>
                  <Typography variant='body2'>View All Pairs</Typography>
                </Link>
              </Grid>

              <Grid container wrap='nowrap' className={classes.scroller}>
                {tokenPairs.map((pair: TokenPair, i: number) => (
                  <Box
                    key={pair.name}
                    onClick={() => history.push(`/pairs/${pair.name}`)}
                  >
                    <TokenPairLogo pair={pair} backgroundIndex={i} />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default TokenPairList;
