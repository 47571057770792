import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';

import { getTokenPairs } from 'graphql/queries';
import { useDenominatorAddress } from 'hooks/useDenominatorAddress';
import { TokenPair, Token } from 'web3/tokens';
import { contracts } from 'web3/contracts';

import TokenWalletItem from 'components/TokenWallet/TokenWalletItem';

export interface TokenSelectProps {
  selectedToken?: Token | null;
  onSelectToken: (token: Token) => void;
}

const TokenSelect: React.FC<TokenSelectProps> = ({
  selectedToken,
}) => {
  const denominatorAddress = useDenominatorAddress();

  const { data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      variables: { denominatorAddress: denominatorAddress || contracts.DAI[1] },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const allTokens: Token[] = useMemo(() => {
    return tokenPairs.map(({ token }) => token);
  }, [tokenPairs]);

  return (
    <Autocomplete
      autoHighlight
      fullWidth
      value={selectedToken}
      options={[null, ...allTokens] as any[]}
      getOptionSelected={(token) => token?.address === selectedToken?.address}
      getOptionLabel={(option: Token | undefined | null) =>
        option?.symbol ?? ''
      }
      renderOption={(token: Token | undefined | null) =>
        !token ? null : (
          <TokenWalletItem
            currency={token}
            isSelected={selectedToken?.symbol === token.symbol}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              value={selectedToken?.symbol ?? ''}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />  
  );
};

export default TokenSelect;
