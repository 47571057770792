import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  Fab,
  OutlinedInput,
  InputLabel,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { formatUnits, parseEther } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import cx from 'classnames';

import {
  useExerciseSettings,
  useExerciseOption,
  useFlashExerciseOption,
  useExerciseQuote,
} from 'state/options/hooks';
import { ExerciseType } from 'state/options/reducer';
import { OptionBalance } from 'web3/options';
import { formatNumber } from 'utils/formatNumber';

import { ModalContainer, OptionTitle } from 'components';
import { useApproval } from '../../../../hooks';
import { useWeb3 } from '../../../../state/application/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 300,
    whiteSpace: 'nowrap',
  },

  thick: {
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  fieldLabel: {
    width: '300px',
    textAlign: 'left',
    paddingBottom: '8px',
  },

  spacer: {
    width: '25%',
  },

  fieldRow: {
    width: '100%',
    marginTop: 24,
    marginBottom: 64,
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '103%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  mobileFieldStatus: {
    bottom: -20,
    left: 5,
  },

  greenText: {
    color: theme.palette.success.main,
  },
}));

export interface ExerciseOptionModalProps {
  open: boolean;
  optionBalance: OptionBalance;
  onClose: () => void;
}

const ExerciseOptionModal: React.FC<ExerciseOptionModalProps> = ({
  open,
  optionBalance,
  onClose,
}) => {
  const { contracts } = useWeb3();
  const classes = useStyles();
  const { register } = useForm();

  const {
    type,
    quantity,
    maxSlippage,
    setExerciseSettings,
  } = useExerciseSettings();

  const quote = useExerciseQuote(optionBalance.option);
  const flashReceive = (quote?.output ?? 0) - (quote?.inSwapAmount ?? 0);
  const { allowance, onApprove } = useApproval(
    quote?.inputToken as string,
    contracts.premiaOptionDai?.address as string,
  );

  const handleExercise = useExerciseOption(optionBalance.option);
  const handleFlashExercise = useFlashExerciseOption(optionBalance.option);

  const maxQuantity = Number(formatUnits(optionBalance.balance, optionBalance.option.token.decimals));
  const hasSufficientBalance = Number(quantity) <= maxQuantity;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='lg'>
        <Box marginBottom={4}>
          <OptionTitle option={optionBalance.option} />
        </Box>

        <Grid container justify='center'>
          <Box clone width='55%'>
            <Paper>
              <Grid
                item
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Box marginY={4}>
                  <Typography align='center'>
                    You hold{' '}
                    {ethers.utils.formatUnits(
                      optionBalance.balance.toString(),
                      optionBalance.option.token.decimals,
                    )}{' '}
                    of these options
                  </Typography>
                </Box>

                <Box clone width={1}>
                  <Grid container justify='center'>
                    <Grid item container justify='center' direction='row'>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.fieldLabel}
                      >
                        Exercise Type
                      </Typography>{' '}
                      <div className={classes.spacer} />
                    </Grid>

                    <ButtonGroup
                      variant='contained'
                      className={classes.orderTypes}
                    >
                      <Box clone width={3 / 8}>
                        <Button
                          variant='contained'
                          color={type === 'Exercise' ? 'primary' : 'secondary'}
                          style={{ minWidth: 100 }}
                          onClick={() =>
                            setExerciseSettings({ type: ExerciseType.Exercise })
                          }
                        >
                          Exercise
                        </Button>
                      </Box>

                      <Box clone width={3 / 8}>
                        <Button
                          variant='contained'
                          color={
                            type === 'Flash Exercise' ? 'primary' : 'secondary'
                          }
                          style={{ minWidth: 100 }}
                          onClick={() =>
                            setExerciseSettings({
                              type: ExerciseType.FlashExercise,
                            })
                          }
                        >
                          Flash Exercise
                        </Button>
                      </Box>
                    </ButtonGroup>
                  </Grid>
                </Box>

                <Grid container justify='center'>
                  <Box
                    width={3 / 4}
                    marginTop={2}
                    marginBottom={6}
                    position='relative'
                  >
                    <InputLabel>Quantity</InputLabel>
                    <FormControl style={{ width: '100%' }}>
                      <OutlinedInput
                        name='quantity'
                        type='number'
                        value={quantity || ''}
                        inputRef={register}
                        inputProps={
                          optionBalance.option.token.decimals
                            ? {
                                step:
                                  1 *
                                  10 ** -optionBalance.option.token.decimals,
                              }
                            : {}
                        }
                        onChange={(event) => {
                          setExerciseSettings({ quantity: event.target.value })
                        }}
                      />
                      {
                        quantity &&
                          <Typography
                            className={cx(Number(quantity) > 0 && hasSufficientBalance ? classes.greenText : classes.putRed, classes.fieldStatus, classes.desktopFieldStatus)}
                            variant='body2'
                          >
                            { Number(quantity) > 0 && hasSufficientBalance ? 'Valid' : 'Invalid' }
                          </Typography>
                      }
                      <Fab
                        variant='extended'
                        color='primary'
                        size='small'
                        onClick={() => setExerciseSettings({ quantity: maxQuantity.toString() }) }
                      >
                        MAX
                      </Fab>
                    </FormControl>
                  </Box>

                  {type === 'Flash Exercise' && (
                    <Box
                      width={3 / 4}
                      marginTop={-4}
                      marginBottom={6}
                      position='relative'
                    >
                      <InputLabel>Max Slippage (%)</InputLabel>
                      <FormControl style={{ width: '100%' }} variant='outlined'>
                        <OutlinedInput
                          name='maxSlippage'
                          type='number'
                          value={maxSlippage}
                          inputRef={register}
                          onChange={(event) =>
                            setExerciseSettings({
                              maxSlippage: event.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Box>
                  )}

                  {!hasSufficientBalance ? (
                    <Box width={1 / 2} marginBottom={3}>
                      <Button
                        fullWidth
                        disabled
                        variant='contained'
                        color='primary'
                      >
                        Insufficient Balance
                      </Button>
                    </Box>
                  ) : type === 'Exercise' &&
                    allowance.lt(
                      parseEther(
                        ((quote?.input ?? 0) + (quote?.fee ?? 0)).toString(),
                      ),
                    ) ? (
                    <Box width={1 / 2} marginBottom={3}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => onApprove()}
                      >
                        Approve
                      </Button>
                    </Box>
                  ) : (
                    <Box width={1 / 2} marginBottom={3}>
                      <Button
                        disabled={
                          type === 'Flash Exercise' && flashReceive <= 0
                        }
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={
                          type === 'Flash Exercise'
                            ? () => handleFlashExercise().then(onClose)
                            : () => handleExercise().then(onClose)
                        }
                      >
                        Confirm
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Box width={1} paddingBottom={2} textAlign='center'>
                <a
                  href='https://medium.com/premia/flash-exercise-b871a3ee3da4'
                  target='_blank'
                  rel='noreferrer'
                >
                  Learn how flash exercise works
                </a>
              </Box>
            </Paper>
          </Box>

          {quantity && (
            <Box clone marginLeft={4} width='40%'>
              <Paper>
                <Box clone width={1} height='100%' padding={4}>
                  <Grid
                    container
                    direction='column'
                    justify='space-around'
                    alignItems='center'
                  >
                    {type === 'Flash Exercise' ? (
                      <Box marginBottom={4}>
                        <Typography align='center'>
                          Underlying asset:
                        </Typography>

                        <Typography align='center'>
                          <b>
                            {optionBalance.option.type === 'CALL'
                              ? formatNumber(quantity)
                              : formatNumber(
                                  Number(
                                    formatUnits(
                                      optionBalance.option.strikePrice,
                                      optionBalance.option.denominator.decimals,
                                    ),
                                  ) * Number(quantity),
                                )}
                          </b>{' '}
                          <span className={classes.callGreen}>
                            {optionBalance.option.type === 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                        </Typography>
                      </Box>
                    ) : undefined}

                    <Box marginBottom={4}>
                      <Typography align='center'>
                        {type === 'Flash Exercise'
                          ? 'Exercise cost:'
                          : 'You will spend:'}
                      </Typography>

                      <Typography align='center'>
                        {type === 'Flash Exercise' ? (
                          <>
                            <b>
                              {quote?.inSwapAmount
                                ? formatNumber(Number(quote?.inSwapAmount))
                                : 0}
                            </b>
                          </>
                        ) : (
                          <>
                            <b>
                              {optionBalance.option.type === 'CALL'
                                ? formatNumber(
                                    Number(
                                      formatUnits(
                                        optionBalance.option.strikePrice,
                                        optionBalance.option.denominator
                                          .decimals,
                                      ),
                                    ) * Number(quantity),
                                  )
                                : formatNumber(quantity)}
                            </b>
                          </>
                        )}{' '}
                        {type === 'Flash Exercise' ? (
                          <span className={classes.putRed}>
                            {optionBalance.option.type === 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                        ) : (
                          <>
                            <span className={classes.putRed}>
                              {optionBalance.option.type === 'CALL'
                                ? optionBalance.option.denominator.symbol
                                : optionBalance.option.token.symbol}
                            </span>
                            {' + '}
                            <b>
                              {quote?.input
                                ? formatNumber(Number(quote.fee))
                                : 0}
                            </b>{' '}
                            <span className={classes.putRed}>
                              {optionBalance.option.type !== 'CALL'
                                ? optionBalance.option.token.symbol
                                : optionBalance.option.denominator.symbol}
                            </span>
                            {' Fee'}
                          </>
                        )}
                      </Typography>

                      {type === 'Flash Exercise' ? (
                        <Typography align='center'>
                          {'= '}
                          <b>
                            {quote?.input
                              ? formatNumber(Number(quote.input))
                              : 0}
                          </b>{' '}
                          <span className={classes.putRed}>
                            {optionBalance.option.type !== 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                          {' + '}
                          <b>
                            {quote?.input ? formatNumber(Number(quote.fee)) : 0}
                          </b>{' '}
                          <span className={classes.putRed}>
                            {optionBalance.option.type !== 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                          {' Fee'}
                        </Typography>
                      ) : undefined}
                    </Box>

                    <Box marginBottom={4}>
                      <Typography align='center'>You will receive:</Typography>

                      <Typography align='center'>
                        {type === 'Flash Exercise' && quote?.output ? (
                          <b>{formatNumber(flashReceive)}</b>
                        ) : (
                          <b>
                            {optionBalance.option.type === 'CALL'
                              ? formatNumber(quantity)
                              : formatNumber(
                                  Number(
                                    formatUnits(
                                      optionBalance.option.strikePrice,
                                      optionBalance.option.denominator.decimals,
                                    ),
                                  ) * Number(quantity),
                                )}
                          </b>
                        )}{' '}
                        <span className={classes.callGreen}>
                          {optionBalance.option.type === 'CALL'
                            ? optionBalance.option.token.symbol
                            : optionBalance.option.denominator.symbol}
                        </span>
                      </Typography>
                    </Box>

                    {flashReceive <= 0 ? (
                      <Typography className={cx(classes.putRed, classes.thick)}>
                        Option not in the money
                      </Typography>
                    ) : undefined}

                    {type === 'Flash Exercise' && (
                      <Box clone width={1}>
                        <Grid
                          container
                          direction='row'
                          justify='space-between'
                          wrap='nowrap'
                        >
                          <Grid container item direction='column'>
                            <div className={classes.thin}>Routing</div>
                            <div className={classes.thin}>Price</div>
                            {/*<div className={classes.thin}>Price Impact</div>*/}
                            {/*<div className={classes.thin}>DEX Fee</div>*/}
                          </Grid>

                          <Grid
                            container
                            item
                            direction='column'
                            alignItems='flex-end'
                          >
                            <div className={classes.thick}>SushiSwap</div>
                            <div className={classes.thick}>
                              1 {optionBalance.option.token.symbol} ={' '}
                              {formatNumber(quote?.tokenPrice)}{' '}
                              {optionBalance.option.denominator.symbol}
                            </div>
                            {/*<div className={classes.thick}>*/}
                            {/*  {quote?.priceImpact*/}
                            {/*    ? formatNumber(quote?.priceImpact)*/}
                            {/*    : '0'}*/}
                            {/*  %*/}
                            {/*</div>*/}
                            {/*<div className={classes.thick}>*/}
                            {/*  {formatNumber(Number(quantity) * 0.003)}{' '}*/}
                            {/*  {optionBalance.option.token.symbol}*/}
                            {/*</div>*/}
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}
        </Grid>
      </ModalContainer>
    </Modal>
  );
};

export default ExerciseOptionModal;
