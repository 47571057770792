import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Warning } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';

import { getOpenOrdersOfAccount } from 'graphql/queries';

import { OptionType, Option } from 'web3/options';
import {
  MarketOrder,
  MarketOrderWithValid,
  SaleSide,
  convertGraphMarketOrderToOrder,
  IOrder,
} from 'web3/market';
import { useWeb3 } from 'state/application/hooks';
import { useOrderInvalidReason } from 'state/market/hooks';
import { formatCompact } from 'utils/formatNumber';
import PremiaMarketAbi from 'constants/abi/PremiaMarket.json';
import { Contract, ContractCall } from 'ethers-multicall';

import { DataTable, TradeOptionModal, CurrencyLogo } from 'components';
import { HeadCell } from 'components/DataTable';
import CancelOrderModal from '../CancelOrderModal';
import WalletTableToolbar from './WalletTableToolbar';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../../../constants';

const headCells: HeadCell<MarketOrderWithValid>[] = [
  {
    id: 'expiration',
    numeric: false,
    label: 'Expiration',
    sortKey: (order: MarketOrderWithValid) =>
      order?.option.expiration.toString(),
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (order: MarketOrderWithValid) =>
      `${order?.option.token}/${order.option.denominator}`,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortKey: (order: MarketOrderWithValid) => order?.option.type.toString(),
  },
  {
    id: 'strike',
    numeric: true,
    label: 'Strike',
    sortKey: (order: MarketOrderWithValid) =>
      order?.option.strikePrice.toString(),
  },
  {
    id: 'side',
    numeric: false,
    label: 'Side',
    sortKey: (order: MarketOrderWithValid) => order?.side.toString(),
  },
  {
    id: 'price',
    numeric: true,
    label: 'Price',
    sortKey: (order: MarketOrderWithValid) => order?.pricePerUnit.toString(),
  },
  {
    id: 'filled',
    numeric: true,
    label: 'Filled',
    sortKey: (order: MarketOrderWithValid) => order?.amountFilled.toString(),
  },
  {
    id: 'total',
    numeric: true,
    label: 'Total',
    sortKey: (order: MarketOrderWithValid) => order?.amount.toString(),
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    sortKey: (order: MarketOrderWithValid) => 1,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  thinFont: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
  },

  thickFont: {
    fontWeight: 700,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  invalidOrder: {
    color: theme.palette.warning.dark,
    marginRight: 5,
    verticalAlign: 'middle',
  },
}));

interface InvalidReasonProps {
  order: MarketOrderWithValid;
}

const InvalidReason: React.FC<InvalidReasonProps> = ({ order }) => {
  const invalidReason = useOrderInvalidReason(order);
  return <span>{`Invalid Order: ${invalidReason}`}</span>;
};

export default function OpenOrdersTable() {
  const classes = useStyles();

  const { contracts, multicallProvider, account } = useWeb3();
  const [defaultPanel, setDefaultPanel] = useState<'trade' | 'mint'>('trade');
  const [anchorEl, setAnchorEl] = useState<null | {
    element: HTMLElement;
    orderId: string;
  }>(null);
  const [optionToTrade, setOptionToTrade] = useState<null | Option>(null);
  const [orderToCancel, setOrderToCancel] = useState<null | MarketOrder>(null);
  const [orders, setOrders] = useState<MarketOrderWithValid[]>([]);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: ordersLoading, data, fetchMore }] = useLazyQuery(getOpenOrdersOfAccount, {
    variables: { first: 100, skip, account: account.toLowerCase() },
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _orders: MarketOrder[] = useMemo(() => {
    const _marketOrders = data ? data.marketOrders : [];

    if (!!_marketOrders.length) {
      setResult(_marketOrders);
    }

    return _marketOrders.filter(
      ({ option }: MarketOrder) =>
        moment() < moment(Number(option.expiration) * 1000),
    );
  }, [data]);

  useEffect(() => {
    if (!contracts.premiaMarket || !multicallProvider) return;

    const marketContract = new Contract(
      contracts.premiaMarket.address,
      PremiaMarketAbi.abi,
    );

    const sortedOrders = _orders.map((order: MarketOrder) =>
      convertGraphMarketOrderToOrder(order),
    );

    (async () => {
      const isValidCalls: ContractCall[] = sortedOrders.map((order: IOrder) =>
        marketContract.isOrderValid(order),
      );

      const isValidResults = await multicallProvider.all(isValidCalls);

      const ordersWithValid: MarketOrderWithValid[] = _orders.map(
        (order: MarketOrder, i: number) => {
          return { ...order, valid: isValidResults[i] };
        },
      );

      setOrders(ordersWithValid);
    })();
  }, [_orders, contracts.premiaMarket, multicallProvider]);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    order: MarketOrder,
  ) => {
    setAnchorEl({ element: event.currentTarget, orderId: order.id });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          marketOrders: isFirstPage
            ? currentResult?.fetchMoreResult?.marketOrders
            : [...result, ...currentResult?.fetchMoreResult?.marketOrders
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  return (
    <DataTable
      headCells={headCells}
      data={orders}
      size={orders.length}
      onChange={onChange}
      loading={ordersLoading}
      toolbar={
        <>
          {optionToTrade && (
            <TradeOptionModal
              open
              option={optionToTrade}
              defaultPanel={defaultPanel}
              onClose={() => setOptionToTrade(null)}
            />
          )}

          {orderToCancel && (
            <CancelOrderModal
              open
              order={orderToCancel}
              onClose={() => setOrderToCancel(null)}
            />
          )}

          <WalletTableToolbar />
        </>
      }
      caption='* Highlighted options may be In the Money (ITM).'
      renderRow={(order: MarketOrderWithValid) => {
        const {
          id,
          option,
          paymentToken,
          pricePerUnit,
          amountFilled,
          amount,
          valid,
        } = order;
        const { token, denominator, expiration, strikePrice, type } = option;

        return (
          <Tooltip key={id} title={!valid ? <InvalidReason order={order} /> : ''}>
            <TableRow hover tabIndex={-1}>
              <TableCell
                component='th'
                scope='row'
                align='center'
                className={classes.thinFont}
              >
                {!valid && <Warning className={classes.invalidOrder} />}
                {moment(Number(expiration.toString()) * 1000).format(
                  'MMM. Do, YYYY',
                )}
              </TableCell>

              <TableCell align='center'>
                <Grid container wrap='nowrap'>
                  <span
                    className={cx(
                      type === OptionType.Call
                        ? classes.callGreen
                        : classes.putRed,
                    )}
                  >
                    <b>{token.symbol}</b>
                  </span>
                  -<span>{denominator.symbol}</span>
                </Grid>
              </TableCell>

              <TableCell align='center'>
                {type === OptionType.Call ? (
                  <span className={classes.callGreen}>CALL</span>
                ) : (
                  <span className={classes.putRed}>PUT</span>
                )}
              </TableCell>

              <TableCell align='center'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <span style={{ marginRight: '0.25rem' }}>
                    {formatCompact(
                      formatUnits(strikePrice.toString(), DEFAULT_DECIMALS),
                    )}
                  </span>

                  <CurrencyLogo currency={denominator} size='16px' />
                </Grid>
              </TableCell>

              <TableCell align='center'>
                {order.side === SaleSide.Buy ? (
                  <span className={classes.callGreen}>BUY</span>
                ) : (
                  <span className={classes.putRed}>SELL</span>
                )}
              </TableCell>

              <TableCell align='center'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <span style={{ marginRight: '0.25rem' }}>
                    {formatCompact(
                      formatUnits(pricePerUnit.toString(), DEFAULT_DECIMALS),
                    )}
                  </span>

                  <CurrencyLogo currency={paymentToken} size='16px' />
                </Grid>
              </TableCell>

              <TableCell align='center'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <span style={{ marginRight: '0.25rem' }}>
                    {formatCompact(
                      formatUnits(amountFilled.toString(), token.decimals),
                    )}
                  </span>

                  <CurrencyLogo currency={token} size='16px' />
                </Grid>
              </TableCell>

              <TableCell align='center'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  wrap='nowrap'
                >
                  <span style={{ marginRight: '0.25rem' }}>
                    {formatCompact(formatUnits(amount, token.decimals))}
                  </span>

                  <CurrencyLogo currency={token} size='16px' />
                </Grid>
              </TableCell>

              <TableCell align='center'>
                <IconButton onClick={(event) => handleOpenMenu(event, order)}>
                  <Add />
                </IconButton>

                <Menu
                  keepMounted
                  anchorEl={anchorEl?.element}
                  open={anchorEl?.orderId === order.id}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    key='trade'
                    onClick={() => {
                      handleCloseMenu();
                      setDefaultPanel('trade');
                      setOptionToTrade(option);
                    }}
                  >
                    Trade Additional
                  </MenuItem>

                  <MenuItem
                    key='mint'
                    onClick={() => {
                      handleCloseMenu();
                      setDefaultPanel('mint');
                      setOptionToTrade(option);
                    }}
                  >
                    Mint Additional
                  </MenuItem>

                  <MenuItem key='cancelorder' onClick={() => setOrderToCancel(order)}>
                    Cancel Order
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          </Tooltip>
        );
      }}
    />
  );
}
