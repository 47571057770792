import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChainId } from '@uniswap/sdk';
import { formatUnits } from 'ethers/lib/utils';
import { ethers, BigNumber } from 'ethers';

import {
  DEFAULT_DECIMALS,
  WETH,
  swapPaths,
  defaultSwapPath,
} from '../../constants';
import { AppDispatch, AppState } from 'state';
import { useWeb3 } from 'state/application/hooks';
import { TokenDenominator } from 'web3/tokens';
import {
  Option,
  OptionType,
  OptionBalance,
  convertOptionToOptionData,
  OptionDetails,
} from 'web3/options';
import { getOptionContract, getOptionBatchContract } from 'web3/contracts';
import {
  SetExerciseSettings,
  setExerciseSettings as _setExerciseSettings,
  SetOptionSettings,
  setOptionSettings as _setOptionSettings,
  SetTransferSettings,
  setTransferSettings as _setTransferSettings,
  SetWithdrawSettings,
  setWithdrawSettings as _setWithdrawSettings,
} from './actions';
import { useTradeSettings } from 'state/market/hooks';
import { useAllTokens, useReferrer, useTransact } from 'hooks';

export const useOptionSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['options']>(
    (state) => state.options,
  );

  const setOptionSettings = (optionSettings: SetOptionSettings) =>
    dispatch(_setOptionSettings(optionSettings));

  return { ...state.optionSettings, setOptionSettings };
};

export const useExerciseSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['options']>(
    (state) => state.options,
  );

  const setExerciseSettings = (optionSettings: SetExerciseSettings) =>
    dispatch(_setExerciseSettings(optionSettings));

  return { ...state.exerciseSettings, setExerciseSettings };
};

export const useWithdrawSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['options']>(
    (state) => state.options,
  );

  const setWithdrawSettings = (optionSettings: SetWithdrawSettings) =>
    dispatch(_setWithdrawSettings(optionSettings));

  return { ...state.withdrawSettings, setWithdrawSettings };
};

export const useTransferSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['options']>(
    (state) => state.options,
  );

  const setTransferSettings = (optionSettings: SetTransferSettings) =>
    dispatch(_setTransferSettings(optionSettings));

  return { ...state.transferSettings, setTransferSettings };
};

export const useWriteOption = () => {
  const { contracts } = useWeb3();
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const { quantity } = useTradeSettings();
  const transact = useTransact();
  const referrer = useReferrer();

  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const handleWrite = useCallback(async () => {
    if (!selectedToken || !selectedExpiration || !strikePrice || !quantity) {
      return;
    }

    const option: OptionDetails = {
      token: selectedToken,
      denominator,
      expiration: Math.floor(selectedExpiration.getTime() / 1000),
      strikePrice: ethers.utils.parseEther(strikePrice),
      type: optionType,
    };

    return transact(
      optionContract?.writeOption(
        {
          token: selectedToken?.address,
          strikePrice: ethers.utils.parseEther(strikePrice),
          expiration: Math.floor(selectedExpiration.getTime() / 1000),
          isCall: optionType === OptionType.Call,
          amount: ethers.utils.parseUnits(quantity, selectedToken.decimals),
        },
        referrer,
      ),
      {
        option,
        description: `Writing ${quantity} option${
          Number(quantity) === 1 ? '' : 's'
        } with ${
          optionType === OptionType.Call
            ? quantity
            : Number(strikePrice) * Number(quantity)
        } ${
          optionType === OptionType.Call ? selectedToken.symbol : denominator
        } as collateral`,
      },
    );
  }, [
    transact,
    optionType,
    optionContract,
    selectedExpiration,
    selectedToken,
    denominator,
    quantity,
    strikePrice,
    referrer,
  ]);

  return handleWrite;
};

export const useWriteQuote = () => {
  const [expectedFee, setExpectedFee] = useState<number | null>(null);
  const [prevValue, setPrevValue] = useState<string | null>(null);
  const { account, contracts } = useWeb3();
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const { quantity } = useTradeSettings();
  const referrer = useReferrer();

  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  useEffect(() => {
    if (
      !optionContract ||
      !selectedToken ||
      !strikePrice ||
      !optionType ||
      !selectedExpiration ||
      !quantity
    )
      return setExpectedFee(0);

    if (prevValue === quantity) return;

    setExpectedFee(null);

    (async () => {
      const quote = await optionContract?.getWriteQuote(
        account,
        {
          token: selectedToken?.address,
          strikePrice: ethers.utils.parseEther(strikePrice),
          expiration: Math.floor(selectedExpiration.getTime() / 1000),
          isCall: optionType === OptionType.Call,
          amount: ethers.utils.parseUnits(
            quantity,
            selectedToken.decimals || DEFAULT_DECIMALS,
          ),
        },
        referrer,
        selectedToken?.decimals,
      );

      setPrevValue(quantity);
      setExpectedFee(
        Number(
          formatUnits(
            quote.fee.add(quote.feeReferrer),
            optionType === OptionType.Call
              ? selectedToken.decimals
              : DEFAULT_DECIMALS,
          ),
        ),
      );
    })();
  }, [
    optionContract,
    quantity,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
    account,
    referrer,
    prevValue,
  ]);

  return expectedFee;
};

export const useExerciseOption = (option: Option) => {
  const { contracts } = useWeb3();
  const transact = useTransact();

  const { quantity } = useExerciseSettings();
  const referrer = useReferrer();
  const optionContract = getOptionContract(
    contracts,
    TokenDenominator[
      option.denominator.symbol as keyof typeof TokenDenominator
    ],
  );

  const handleExercise = useCallback(async () => {
    return transact(
      optionContract?.exerciseOption(
        option.id,
        ethers.utils.parseUnits(quantity ?? '0', option.token.decimals),
        referrer,
      ),
      {
        option,
        description: `Exercising ${quantity} option${
          Number(quantity) === 1 ? '' : 's'
        }`,
      },
    );
  }, [quantity, option, optionContract, transact, referrer]);

  return handleExercise;
};

export const useFlashExerciseOption = (option: Option) => {
  const { account, contracts, chainId } = useWeb3();
  const transact = useTransact();

  const { quantity } = useExerciseSettings();
  const referrer = useReferrer();
  const optionData = convertOptionToOptionData(option);
  const optionContract = getOptionContract(
    contracts,
    TokenDenominator[
      option.denominator.symbol as keyof typeof TokenDenominator
    ],
  );

  const handleFlashExercise = useCallback(async () => {
    if (!quantity || !chainId) return;

    const quote = await optionContract?.getExerciseQuote(
      account,
      optionData,
      ethers.utils.parseUnits(quantity ?? '0', option.token.decimals),
      referrer,
      option.token.decimals,
    );

    if (!quote) return;

    const swapPath =
      swapPaths[quote.outputToken] ||
      defaultSwapPath(quote.outputToken, chainId);

    const path = [...swapPath];

    if (quote.inputToken !== path[swapPath.length - 1]) {
      path.push(quote.inputToken);
    }

    return transact(
      optionContract?.flashExerciseOption(
        option.id,
        ethers.utils.parseUnits(quantity ?? '0', option.token.decimals),
        referrer,
        contracts.sushiswapRouter?.address ?? '',
        ethers.utils.parseUnits(
          String(
            (Number(quantity) * Number(option.strikePrice)) /
              10 ** option.token.decimals,
          ),
          option.token.decimals,
        ),
        path,
      ),
      {
        option,
        description: `Flash exercising ${quantity} option${
          Number(quantity) === 1 ? '' : 's'
        }`,
      },
    );
  }, [
    chainId,
    transact,
    contracts,
    quantity,
    option,
    optionContract,
    account,
    optionData,
    referrer,
  ]);

  return handleFlashExercise;
};

export const useWithdrawExpired = (optionBalances: OptionBalance[]) => {
  const { contracts } = useWeb3();
  const transact = useTransact();

  const optionBatchContract = getOptionBatchContract(contracts);

  const optionIds = optionBalances.map(({ option }) => option.id);

  const handleWithdrawExpired = useCallback(async () => {
    if (contracts.premiaOptionDai?.address) {
      return await transact(
        optionBatchContract?.batchWithdraw(
          contracts.premiaOptionDai.address,
          optionIds,
        ),
        {
          description: `Batch withdrawing available collateral from option${
            optionIds.length === 1 ? '' : 's'
          }`,
        },
      );
    }
  }, [contracts, optionIds, optionBatchContract, transact]);

  return handleWithdrawExpired;
};

export const useExerciseAll = (optionBalances: OptionBalance[]) => {
  const { contracts } = useWeb3();
  const transact = useTransact();

  const referrer = useReferrer();
  const optionBatchContract = getOptionBatchContract(contracts);

  const optionIds = optionBalances.map(({ option }) => option.id);
  const amounts = optionBalances.map(({ balance }) => balance);

  const handleExerciseAll = useCallback(async () => {
    if (contracts.premiaOptionDai?.address) {
      return transact(
        optionBatchContract?.batchExerciseOption(
          contracts.premiaOptionDai.address,
          optionIds,
          amounts,
          referrer,
        ),
        {
          description: `Batch exercising available option${
            Number(amounts[0]) === 1 ? '' : 's'
          }`,
        },
      );
    }
  }, [contracts, optionIds, amounts, optionBatchContract, transact, referrer]);

  return handleExerciseAll;
};

export const useExerciseQuote = (option: Option) => {
  const [quote, setQuote] = useState<
    | {
        inputToken: string | undefined;
        tokenPrice: number | undefined;
        priceImpact: number | undefined;
        inSwapAmount: number | undefined;
        input: number | undefined;
        output: number | undefined;
        fee: number | undefined;
      }
    | undefined
  >(undefined);
  const { account, contracts, chainId } = useWeb3();
  const { denominator } = useOptionSettings();
  const { quantity } = useExerciseSettings();
  const referrer = useReferrer();
  const wethAddress = WETH[chainId ?? ChainId.MAINNET].address;
  const tokens = useAllTokens();

  const optionContract = getOptionContract(contracts, denominator);
  const optionData = convertOptionToOptionData(option);

  useEffect(() => {
    let isSubscribed = true;

    if (!quantity || !chainId) return;

    (async () => {
      const quote = await optionContract?.getExerciseQuote(
        account,
        optionData,
        ethers.utils.parseUnits(quantity, option.token.decimals),
        referrer,
        option.token.decimals,
      );

      if (!quote) return;

      try {
        const totalRequired = quote.input.add(quote.fee).add(quote.feeReferrer);

        const swapPath =
          swapPaths[quote.outputToken] ||
          defaultSwapPath(quote.outputToken, chainId);
        const path = [...new Set([...swapPath, quote.inputToken])];

        const amountsIn:
          | BigNumber[]
          | undefined = await contracts.sushiswapRouter?.getAmountsIn(
          totalRequired,
          path,
        );

        if (!amountsIn || !isSubscribed) return;

        const priceImpact = Number(quote.input) / Number(amountsIn[0]);

        setQuote({
          priceImpact,
          inputToken: quote.inputToken,
          tokenPrice:
            Number(
              ethers.utils.formatUnits(totalRequired, quote.inputDecimals),
            ) /
            Number(
              ethers.utils.formatUnits(amountsIn[0], quote.outputDecimals),
            ),
          inSwapAmount: Number(
            ethers.utils.formatUnits(amountsIn[0], quote.outputDecimals),
          ),
          input: Number(
            ethers.utils.formatUnits(quote.input, quote.inputDecimals),
          ),
          output: Number(
            ethers.utils.formatUnits(quote.output, quote.outputDecimals),
          ),
          fee:
            Number(
              ethers.utils.formatUnits(
                quote.fee,
                option.type === OptionType.Call
                  ? quote.inputDecimals
                  : quote.outputDecimals,
              ),
            ) +
            Number(
              ethers.utils.formatUnits(
                quote.feeReferrer,
                option.type === OptionType.Call
                  ? quote.inputDecimals
                  : quote.outputDecimals,
              ),
            ),
        });
      } catch (err) {
        console.error("Couldn't fetch quote.", err);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [
    chainId,
    account,
    optionData,
    optionContract,
    referrer,
    quantity,
    option,
    contracts,
    wethAddress,
    tokens,
  ]);

  return quote;
};

export const useMaxQuantity = (tokenBalance: any, denominatorBalance: any) => {
  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const [maxQuantity, setMaxQuantity] = useState(0);
  const { account, contracts } = useWeb3();
  const referrer = useReferrer();
  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  useEffect(() => {
    if (
      !optionContract ||
      !selectedToken ||
      !strikePrice ||
      !optionType ||
      !selectedExpiration ||
      (optionType === OptionType.Call
        ? !Number(tokenBalance)
        : !Number(denominatorBalance))
    )
      return setMaxQuantity(0);

    (async () => {
      const quote = await optionContract?.getWriteQuote(
        account,
        {
          token: selectedToken?.address,
          strikePrice: ethers.utils.parseEther('1'),
          expiration: Math.floor(selectedExpiration.getTime() / 1000),
          isCall: optionType === OptionType.Call,
          amount: ethers.utils.parseUnits(
            optionType === OptionType.Call ? tokenBalance : denominatorBalance,
            selectedToken.decimals || DEFAULT_DECIMALS,
          ),
        },
        referrer,
        selectedToken.decimals,
      );

      const maxFee = Number(
        formatUnits(
          quote.fee.add(quote.feeReferrer),
          optionType === OptionType.Call
            ? selectedToken.decimals
            : DEFAULT_DECIMALS,
        ),
      );

      if (optionType === OptionType.Call) {
        setMaxQuantity(
          Math.max(
            (Number(tokenBalance) * Number(tokenBalance)) /
              (Number(tokenBalance) + maxFee),
            0,
          ),
        );
      } else {
        setMaxQuantity(
          Math.max(
            (Number(denominatorBalance) * Number(denominatorBalance)) /
              (Number(denominatorBalance) + maxFee),
            0,
          ) / Number(strikePrice),
        );
      }
    })();
  }, [
    optionContract,
    selectedToken,
    strikePrice,
    optionType,
    selectedExpiration,
    account,
    tokenBalance,
    denominatorBalance,
    referrer,
  ]);
  return maxQuantity;
};
