import React from 'react';
import { Box, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

import { useWeb3 } from 'state/application/hooks';
import { shortenAddress } from 'utils';

import { PageWithSidebar } from 'layouts';
import { ExploreHero } from './components';
import { TokenPairList } from 'components';
import FloatingAstronauts from 'assets/svg/FloatingAstronauts.svg';
import PinkWonderland from 'assets/svg/PinkWonderland.svg';
import HelpOctopus from 'assets/svg/HelpOctopus.svg';

const useStyles = makeStyles(() => ({
  hoverable: {
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },

  floatCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  floatTopLeft: {
    position: 'absolute',
    top: '65%',
    left: '30%',
    transform: 'translate(-30%, -65%)',
  },

  topContainer: {},

  title: {
    whiteSpace: 'nowrap',
  },

  titleMobile: {
    fontSize: '3.1vw',
  },

  smallerTitle: {
    whiteSpace: 'nowrap',
    fontSize: '1.5vw',
  },

  smallerTitleMobile: {
    whiteSpace: 'nowrap',
    fontSize: '2vw',
  },

  subtitle: {
    fontWeight: 300,
  },

  topContainerMobile: {
    margin: '80px 0 0 12px',
    width: 'calc(100% - 50px)'
  },
}));

const Dashboard: React.FC = () => {
  const { account, wallet } = useWeb3();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageWithSidebar mobile={mobile}>
      <Grid container direction='column'>
        <Box width={1} className={mobile ? classes.topContainerMobile : classes.topContainer}>
          {wallet && wallet.provider && account ? (
            <>
              <Typography
                component='h2'
                variant='h4'
                color='textPrimary'
                style={{
                  fontFamily: '"Inter"',
                  fontWeight: 500,
                  fontSize: 24,
                  lineHeight: 1.5,
                }}
              >
                Hi {shortenAddress(account ?? '')},
              </Typography>

              <Typography
                component='h2'
                variant='h3'
                color='textPrimary'
                style={!mobile ? {
                  fontFamily: '"Teko"',
                  fontWeight: 600,
                  fontSize: 48,
                  marginBottom: 32,
                } : {
                  fontFamily: '"Teko"',
                  fontWeight: 600,
                  fontSize: 48,
                }}
              >
                Welcome Back
              </Typography>
            </>
          ) : (
            <Typography
              component='h2'
              variant='h3'
              color='textPrimary'
              style={!mobile ? {
                fontFamily: '"Teko"',
                fontWeight: 600,
                fontSize: 48,
                marginBottom: 32,
              } : {
                fontFamily: '"Teko"',
                fontWeight: 600,
                fontSize: 40,
                marginBottom: 8,
              }}
            >
              Dashboard
            </Typography>
          )}
        </Box>

        <Hidden smDown>
          <ExploreHero />
        </Hidden>

          <Box width={1} paddingBottom={4}>
            <TokenPairList />
          </Box>

        <Hidden smDown>
          <Grid container>
            <Grid item container direction='row' xs={4}>
              <Box
                position='relative'
                marginBottom={2}
                width={1}
                className={classes.hoverable}
                onClick={() =>
                  window.open('https://premia.medium.com/', '_blank')
                }
              >
                <Box clone width={1}>
                  <img src={PinkWonderland} alt='How it Works' width='100%' />
                </Box>

                <Box className={classes.floatCenter}>
                  <Typography variant='h3' className={classes.title}>
                    How it Works
                  </Typography>
                </Box>
              </Box>

              <Box
                position='relative'
                width={1}
                className={classes.hoverable}
                onClick={() =>
                  window.open('https://discord.gg/6MhRmzmdHN', '_blank')
                }
              >
                <Box clone width={1}>
                  <img src={HelpOctopus} alt='Ask for Help' width='100%' />
                </Box>

                <Box className={classes.floatCenter}>
                  <Typography variant='h3' className={classes.title}>
                    Ask for Help
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Box clone paddingLeft={2}>
              <Grid
                item
                container
                xs={8}
                onClick={() => history.push('/stake')}
              >
                <Box
                  position='relative'
                  width={1}
                  className={classes.hoverable}
                >
                  <Box clone height='100%'>
                    <img
                      src={FloatingAstronauts}
                      alt='Stake Premia'
                      height='100%'
                      width='100%'
                    />
                  </Box>

                  <Box className={classes.floatTopLeft}>
                    <Typography variant='h3' className={classes.smallerTitle}>
                      Stake your Premia to Earn Rewards
                    </Typography>

                    <Typography variant='h6' className={classes.subtitle}>
                      Your Premia can be staked to earn rewards from fees on the
                      platform.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container direction='column'>
            <Grid item container direction='row'>
              <Box
                position='relative'
                marginBottom={4}
                width={1}
                className={classes.hoverable}
                onClick={() =>
                  window.open('https://premia.medium.com/', '_blank')
                }
              >
                <Box clone width={1}>
                  <img src={PinkWonderland} alt='How it Works' width='100%' />
                </Box>

                <Box className={classes.floatCenter}>
                  <Typography variant='h3' className={classes.titleMobile}>
                    How it Works
                  </Typography>
                </Box>
              </Box>

              <Box
                position='relative'
                width={1}
                className={classes.hoverable}
                onClick={() =>
                  window.open('https://discord.gg/6MhRmzmdHN', '_blank')
                }
              >
                <Box clone width={1}>
                  <img src={HelpOctopus} alt='Ask for Help' width='100%' />
                </Box>

                <Box className={classes.floatCenter}>
                  <Typography variant='h3' className={classes.titleMobile}>
                    Ask for Help
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Box clone paddingTop={4}>
              <Grid item container onClick={() => history.push('/stake')}>
                <Box
                  position='relative'
                  width={1}
                  className={classes.hoverable}
                >
                  <Box clone height='100%'>
                    <img
                      src={FloatingAstronauts}
                      alt='Primary Bootstrap Contribution'
                      height='100%'
                      width='100%'
                    />
                  </Box>

                  <Box className={classes.floatTopLeft}>
                    <Typography variant='h5' className={classes.titleMobile}>
                      Stake your Premia to Earn Rewards
                    </Typography>

                    <Typography
                      variant='body2'
                      className={classes.smallerTitleMobile}
                    >
                      Your Premia can be staked to earn rewards from fees on the
                      platform.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </PageWithSidebar>
  );
};

export default Dashboard;
