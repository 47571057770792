import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Box,
  Grid,
  ButtonGroup,
  Button,
  Paper,
  Typography,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Fab,
  OutlinedInput,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import { getTokenPairs } from 'graphql/queries';
import { useApproveMint, useExpirations, useDenominatorAddress } from 'hooks';
import { useWeb3 } from 'state/application/hooks';
import {
  useOptionSettings,
  useWriteQuote,
  useMaxQuantity,
} from 'state/options/hooks';
import { useTradeSettings } from 'state/market/hooks';
import { useTokenBalance } from 'state/wallet/hooks';
import { OptionType } from 'web3/options';
import { Token, TokenPair } from 'web3/tokens';
import { formatNumber, formatBigNumber } from 'utils/formatNumber';

import { Loader } from 'components';
import CreateOptionModal from './CreateOptionModal';
import TokenWalletItem from 'components/TokenWallet/TokenWalletItem';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
  },

  skinnyRow: {
    width: '100%',
    marginBottom: 12,
  },

  firstRow: {
    marginTop: 32,
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },

  alignRight: {
    textAlign: 'right',
  },

  fieldHint: {
    position: 'absolute',
    top: -18,
    fontSize: 12,
    lineHeight: 1.3,
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '104%',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  mobileFieldStatus: {
    bottom: -20,
    left: 5,
  },

  greenText: {
    color: theme.palette.success.main,
  },
  redText: {
    color: theme.palette.error.main,
  },

  fieldFee: {
    top: 'unset',
    bottom: -18,
  },

  fullwidth: {
    width: '100%',
  },

  inputWidth: {
    width: '70%',
  },

  itemContainer: {
    position: 'relative',
  },
}));

const CreateOption: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const { register, control } = useForm();
  const { account } = useWeb3();
  const [createOptionOpen, setCreateOptionOpen] = useState(false);

  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
    setOptionSettings,
  } = useOptionSettings();
  const { quantity, setTradeSettings } = useTradeSettings();
  const expirations = useExpirations();
  const denominatorAddress = useDenominatorAddress();

  const { data: tokenPairsData } = useQuery(getTokenPairs, {
    skip: !denominatorAddress,
    variables: { denominatorAddress },
  });

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const allTokens: Token[] = useMemo(() => {
    return tokenPairs.map(({ token }) => token);
  }, [tokenPairs]);

  const activePair: TokenPair | undefined = useMemo(
    () =>
      tokenPairs.find(({ token }) => token.address === selectedToken?.address),
    [tokenPairs, selectedToken],
  );

  const expectedFee = useWriteQuote();
  const tokenBalance = useTokenBalance(account, activePair?.token);
  const denominatorBalance = useTokenBalance(account, activePair?.denominator);
  const hasSufficientBalance =
    Number(expectedFee) > 0
      ? optionType === OptionType.Call
        ? Number(tokenBalance) >= Number(quantity) + Number(expectedFee)
        : Number(denominatorBalance) >=
          Number(strikePrice) * Number(quantity) + Number(expectedFee)
      : false;

  const maxQuantity = useMaxQuantity(tokenBalance, denominatorBalance);

  const approvalToken =
    optionType === OptionType.Call
      ? activePair?.token
      : activePair?.denominator;

  const { allowance, onApprove } = useApproveMint(approvalToken, denominator);

  const isValidPrice =
    Number(strikePrice) > 0 &&
    Number.isInteger(
      Number(strikePrice) /
        Number(formatBigNumber(activePair?.strikePriceIncrement)),
    );

  const handleApprove = useCallback(async () => {
    try {
      await onApprove();
    } catch (e) {
      console.error(e);
    }
  }, [onApprove]);

  return (
    <Box clone boxShadow={3} px={!mobile ? 4 : 2} py={4}>
      <Paper component='form'>
        {selectedToken && selectedExpiration && strikePrice && quantity && (
          <CreateOptionModal
            open={createOptionOpen}
            onClose={() => setCreateOptionOpen(false)}
          />
        )}

        <Typography component='h4' variant='h4'>
          Select your option specification below
        </Typography>

        <Box mt={2}>
          <Typography component='h6' variant='h6' color='textSecondary'>
            Create the perfect option for your portfolio
          </Typography>
        </Box>

        <Box px={!mobile ? 6 : 1} py={8}>
          <Typography variant='body2' color='textSecondary'>
            Order Type
          </Typography>

          <Grid container justify='center' className={classes.row}>
            <ButtonGroup variant='contained' className={classes.orderTypes}>
              <Box clone width={1 / 3}>
                <Button
                  variant='contained'
                  color={
                    optionType === OptionType.Call ? 'primary' : 'secondary'
                  }
                  style={{ minWidth: 100 }}
                  onClick={() =>
                    setOptionSettings({ optionType: OptionType.Call })
                  }
                >
                  Call
                </Button>
              </Box>

              <Box clone width={1 / 3}>
                <Button
                  variant='contained'
                  color={
                    optionType === OptionType.Put ? 'primary' : 'secondary'
                  }
                  style={{ minWidth: 100 }}
                  onClick={() =>
                    setOptionSettings({ optionType: OptionType.Put })
                  }
                >
                  Put
                </Button>
              </Box>
            </ButtonGroup>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Select Token:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl
                className={cx(tablet ? classes.fullwidth : classes.inputWidth)}
              >
                <Autocomplete
                  autoHighlight
                  value={selectedToken || null}
                  options={allTokens}
                  getOptionSelected={(token) =>
                    token?.address === selectedToken?.address
                  }
                  getOptionLabel={(option: any) => option.symbol}
                  renderOption={(token: Token) => (
                    <TokenWalletItem
                      currency={token}
                      isSelected={selectedToken?.symbol === token.symbol}
                      onSelect={() =>
                        setOptionSettings({ selectedToken: token })
                      }
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant='filled'
                      {...params}
                      value={selectedToken?.symbol ?? ''}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Expiration:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl className={classes.fullwidth}>
                <Controller
                  control={control}
                  name='expiration'
                  defaultValue=''
                  value={selectedExpiration}
                  as={
                    <Select
                      disableUnderline
                      className={cx(
                        tablet ? classes.fullwidth : classes.inputWidth,
                      )}
                    >
                      {expirations.map((expiration) => (
                        <MenuItem
                          key={expiration.getTime()}
                          value={expiration.getTime()}
                          onClick={() =>
                            setOptionSettings({
                              selectedExpiration: expiration,
                            })
                          }
                        >
                          {moment(expiration).format('MMMM Do YYYY')}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center'>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Strike Price:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              <Typography
                color='textSecondary'
                variant='body2'
                className={cx(
                  tablet ? classes.fullwidth : classes.inputWidth,
                  classes.alignRight,
                  classes.fieldHint,
                )}
              >
                {activePair?.strikePriceIncrement && (
                  <>
                    ({formatBigNumber(activePair.strikePriceIncrement)}{' '}
                    {denominator} increment)
                  </>
                )}
              </Typography>

              <FormControl
                className={tablet ? classes.fullwidth : classes.inputWidth}
              >
                <OutlinedInput
                  name='strikePrice'
                  type='number'
                  value={strikePrice || ''}
                  inputRef={register}
                  className={classes.fullwidth}
                  inputProps={
                    activePair?.strikePriceIncrement
                      ? {
                          step: formatBigNumber(
                            activePair.strikePriceIncrement,
                          ),
                        }
                      : {}
                  }
                  onChange={(event) =>
                    setOptionSettings({ strikePrice: event.target.value })
                  }
                />
                {selectedToken && strikePrice && (
                  <Typography
                    className={cx(
                      isValidPrice ? classes.greenText : classes.redText,
                      classes.fieldStatus,
                      tablet
                        ? classes.mobileFieldStatus
                        : classes.desktopFieldStatus,
                    )}
                    variant='body2'
                  >
                    {isValidPrice ? 'Valid' : 'Invalid'}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Quantity:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              <Typography
                color='textSecondary'
                variant='body2'
                className={cx(
                  tablet ? classes.fullwidth : classes.inputWidth,
                  classes.alignRight,
                  classes.fieldHint,
                )}
              >
                {strikePrice && quantity && selectedToken && (
                  <>
                    (Collateral:{' '}
                    {formatNumber(
                      optionType === OptionType.Call
                        ? Number(quantity)
                        : Number(strikePrice) * Number(quantity),
                    )}{' '}
                    {optionType === OptionType.Call
                      ? selectedToken?.symbol
                      : denominator}
                    )
                  </>
                )}
              </Typography>

              <FormControl
                className={tablet ? classes.fullwidth : classes.inputWidth}
              >
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity}
                  inputRef={register}
                  className={classes.fullwidth}
                  inputProps={
                    selectedToken?.decimals
                      ? {
                          step: 1 * 10 ** -selectedToken.decimals,
                        }
                      : {}
                  }
                  onChange={(event) => {
                    setTradeSettings({ quantity: event.target.value });
                  }}
                />
                {Number(expectedFee) > 0 && (
                  <Typography
                    className={cx(
                      hasSufficientBalance
                        ? classes.greenText
                        : classes.redText,
                      classes.fieldStatus,
                      tablet
                        ? classes.mobileFieldStatus
                        : classes.desktopFieldStatus,
                    )}
                    variant='body2'
                  >
                    {hasSufficientBalance ? 'Valid' : 'Invalid'}
                  </Typography>
                )}
                {selectedToken && selectedExpiration && strikePrice && (
                  <Fab
                    variant='extended'
                    color='primary'
                    size='small'
                    onClick={() =>
                      setTradeSettings({ quantity: maxQuantity.toString() })
                    }
                  >
                    MAX
                  </Fab>
                )}
              </FormControl>
              {Number(expectedFee) > 0 && (
                <Typography
                  color='textSecondary'
                  className={cx(
                    tablet ? classes.fullwidth : classes.inputWidth,
                    classes.alignRight,
                    classes.fieldHint,
                    classes.fieldFee,
                  )}
                  variant='body2'
                >
                  (Fee:{' '}
                  {expectedFee !== null ? (
                    formatNumber(Number(expectedFee))
                  ) : (
                    <Loader />
                  )}{' '}
                  {optionType === OptionType.Call
                    ? selectedToken?.symbol
                    : denominator}
                  )
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.row}>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              {allowance.eq(0) ? (
                <Box
                  className={tablet ? classes.fullwidth : classes.inputWidth}
                >
                  <Button
                    className={classes.fullwidth}
                    disabled={!selectedToken}
                    size='large'
                    variant='contained'
                    color='primary'
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                </Box>
              ) : (
                <Box
                  className={tablet ? classes.fullwidth : classes.inputWidth}
                >
                  {hasSufficientBalance ? (
                    <Button
                      className={classes.fullwidth}
                      disabled={
                        !quantity ||
                        !strikePrice ||
                        !selectedExpiration ||
                        !selectedToken
                      }
                      size='large'
                      variant='contained'
                      color='primary'
                      onClick={() => setCreateOptionOpen(true)}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      className={classes.fullwidth}
                      disabled
                      size='large'
                      variant='contained'
                      color='primary'
                    >
                      Insufficient Balance
                    </Button>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateOption;
