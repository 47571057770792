import { useState, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { BigNumber } from 'ethers';
import { map, reverse, uniq } from 'lodash';
import { formatUnits } from 'ethers/lib/utils';

import {
  getHistoryItemsForTokenPair,
  getAllTokenPairHistoryItems,
} from 'graphql/queries';
import { usePrices } from 'state/application/hooks';

export interface TokenPairHistoryProps {
  id?: string;
  tokenPairId?: string;
  openInterest: BigNumber | number;
  totalVolume: BigNumber | number;
  tokenDecimals?: number;
  tokenSymbol?: string;
  timestamp: number;
}

const SECS_PER_DAY = 86400;
const DAYS_PER_WEEK = 7;

export function usePeriodFetchTokenPairs(period?: string, id?: string): any[] {
  const [startTimestamp, setStartTimestamp] = useState((Date.now() / 1000) | 0);
  const prices = usePrices();
  const currentTimestamp = (Date.now() / 1000) | 0;

  const [
    getHistoryItems,
    { data: { tokenPairHistoryItems = [] } = {} },
  ] = useLazyQuery(
    id === 'all' ? getAllTokenPairHistoryItems : getHistoryItemsForTokenPair,
    {
      variables: {
        ...(id === 'all'
          ? { start: startTimestamp, end: currentTimestamp }
          : { tokenPair: id, start: startTimestamp, end: currentTimestamp }),
      },
    },
  );

  useEffect(() => {
    if (period === '1W') {
      setStartTimestamp(
        currentTimestamp - SECS_PER_DAY * DAYS_PER_WEEK,
      );
    } else if (period === '1M') {
      setStartTimestamp(
        currentTimestamp - SECS_PER_DAY * DAYS_PER_WEEK * 30,
      );
    } else {
      setStartTimestamp(0);
    }

    getHistoryItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, getHistoryItems, id]);

  const newResult: TokenPairHistoryProps[] = [];

  uniq(map(tokenPairHistoryItems, 'timestamp')).map((timestamp) => {
    const openInterestSum = tokenPairHistoryItems
      .filter((a: TokenPairHistoryProps) => a.timestamp === timestamp)
      .reduce(
        (total: number, pair: TokenPairHistoryProps) =>
          total +
          Number(formatUnits(pair.openInterest, pair.tokenDecimals)) *
            (prices[pair.tokenSymbol || ''] || 0),
        0,
      );
    const volumeSum = tokenPairHistoryItems
      .filter((a: TokenPairHistoryProps) => a.timestamp === timestamp)
      .reduce(
        (total: number, pair: TokenPairHistoryProps) =>
          total +
          Number(formatUnits(pair.totalVolume, pair.tokenDecimals)) *
            (prices[pair.tokenSymbol || ''] || 0),
        0,
      );

    newResult.push({
      openInterest: openInterestSum,
      totalVolume: volumeSum,
      tokenSymbol: '',
      tokenDecimals: 18,
      timestamp,
    });

    return null;
  });

  return reverse(newResult);
}

export default usePeriodFetchTokenPairs;
