import React, { useState } from 'react';
import {
  Typography,
  Modal,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Option } from 'web3/options';

import { useSellNewOption } from 'state/market/hooks';
import { ModalContainer, OptionTitle } from 'components';
import { OrderBookItem } from 'components/OptionOrderBook';
import { formatBigNumber } from 'utils/formatNumber';

export interface CopySellOrderProps {
  open: boolean;
  option: Option;
  orderBookItem: OrderBookItem;
  onClose: () => void;
}

const CopySellOrder: React.FC<CopySellOrderProps> = ({
  open,
  option,
  orderBookItem,
  onClose,
}) => {
  const { register } = useForm();
  const [quantity, setQuantity] = useState(
    formatBigNumber(
      orderBookItem.quantity,
      false,
      {},
      orderBookItem.token.decimals,
    ),
  );

  const [price, setPrice] = useState(
    formatBigNumber(
      orderBookItem.price,
      false,
      {},
      orderBookItem.token.decimals,
    ),
  );
  const onSellNewOption = useSellNewOption();


  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Copy Sell Order</Typography>
        </Box>
        <OptionTitle hideSubtitle option={option} />

        <Box width={1 / 2} marginX='auto' marginTop={4}>
          <Grid container direction='column'>
            <InputLabel>Quantity</InputLabel>
            <Box width={1}>
              <FormControl style={{ width: '100%' }} variant='outlined'>
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity}
                  inputRef={register}
                  onChange={(event) => {
                    setQuantity(event.target.value);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Box>

        <Box width={1 / 2} marginX='auto' marginTop={2}>
          <Grid container direction='column'>
            <InputLabel>Price</InputLabel>
            <Box width={1}>
              <FormControl style={{ width: '100%' }} variant='outlined'>
                <OutlinedInput
                  name='price'
                  type='number'
                  value={price}
                  inputRef={register}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Box>

        <Box width={1 / 2} marginX='auto' marginY={2} marginTop={4}>
          <Button
            fullWidth
            disabled={!quantity}
            variant='contained'
            color='secondary'
            onClick={async () => {
              await onSellNewOption(
                quantity,
                price,
              );
            }}
          >
            Create Sell Order
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default CopySellOrder;
