import { ethers } from 'ethers';

import {
  Erc20,
  Erc20__factory,
  Weth__factory,
  Weth,
  PremiaMarket,
  PremiaMarket__factory,
  PremiaOption,
  PremiaOption__factory,
  PremiaOptionBatch,
  PremiaOptionBatch__factory,
  PremiaPBC,
  PremiaPBC__factory,
  PremiaErc20,
  PremiaErc20__factory,
  PremiaBondingCurve,
  PremiaBondingCurve__factory,
  UniswapV2Router02,
  UniswapV2Router02__factory,
  FeeCalculator,
  FeeCalculator__factory,
  PremiaStaking,
  PremiaStaking__factory,
  PremiaFeeDiscount,
  PremiaMining,
  PremiaFeeDiscount__factory,
  PremiaMining__factory,
  RopeMerkleClaimer,
  RopeMerkleClaimer__factory,
} from '../contracts';
import { TokenDenominator } from './tokens';

///////////////
///////////////

export enum ContractType {
  Weth = 'WETH',
  Dai = 'DAI',
  Wbnb = 'WBNB',
  Busd = 'BUSD',
  PremiaOptionDai = 'premiaOptionDai',
  PremiaOptionBusd = 'premiaOptionBusd',
  PremiaOptionBatch = 'PremiaOptionBatch',
  PremiaMarket = 'premiaMarket',
  Premia = 'premia',
  PremiaPBC = 'premiaPBC',
  PremiaBondingCurve = 'premiaBondingCurve',
  FeeCalculator = 'feeCalculator',
  SushiswapRouter = 'sushiswapRouter',
  xPremia = 'xPremia',
  PremiaFeeDiscount = 'premiaFeeDiscount',
  PremiaMining = 'premiaMining',
  RopeMerkleClaimer = 'ropeMerkleClaimer',
}

export type ContractAddresses = {
  [key in ContractType]: { [chainId: number]: string };
};

export interface PremiaContracts {
  weth?: Weth;
  dai?: Erc20;
  wbnb?: Weth;
  busd?: Erc20;
  premiaOptionDai?: PremiaOption;
  premiaOptionBusd?: PremiaOption;
  premiaOptionBatch?: PremiaOptionBatch;
  premiaMarket?: PremiaMarket;
  premiaPBC?: PremiaPBC;
  premia?: PremiaErc20;
  premiaBondingCurve?: PremiaBondingCurve;
  feeCalculator?: FeeCalculator;
  sushiswapRouter?: UniswapV2Router02;
  xPremia?: PremiaStaking;
  premiaFeeDiscount?: PremiaFeeDiscount;
  premiaMining?: PremiaMining;
  ropeMerkleClaimer?: RopeMerkleClaimer;
}

///////////////
///////////////

export const contracts: ContractAddresses = {
  WETH: {
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    42: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
  },
  DAI: {
    1: '0x6b175474e89094c44da98b954eedeac495271d0f',
    4: '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa',
    42: '0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa',
  },
  WBNB: {
    56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
  BUSD: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  premiaOptionBusd: {
    56: '0x8172aAC30046F74907a6b77ff7fC867A6aD214e4',
  },
  premiaOptionDai: {
    1: '0x5920cb60B1c62dC69467bf7c6EDFcFb3f98548c0',
    4: '0xd3a3E9dBdEDcb834d7409D201FB9c1d6eA00F50E',
    42: '0x039bb1f4d7aa29fcd5bA91D833A5275578C7dFDa',
    56: '0x8172aAC30046F74907a6b77ff7fC867A6aD214e4',
  },
  PremiaOptionBatch: {
    1: '0xf386D276d648E84Cbb7013Db97d952fDD0092DBC',
    4: '0x101D4C5Dc57198AAa742562d9691577cd976a039',
    42: '0x1e200FD6E6Cef9d6E054b13De6F0119Be4AAea61',
  },
  premiaMarket: {
    1: '0x45eBD0FC72E2056adb5c864Ea6F151ad943d94af',
    4: '0x12900503321923C920d041a45942a4c62BFdb485',
    42: '0x7435ebdd65997bD533Ea69f9915AF0aFdd7fE935',
    56: '0xeaD808Ce263dD229C8F0dCeB04f1c9894d67b8b1',
  },
  premia: {
    1: '0x6399C842dD2bE3dE30BF99Bc7D1bBF6Fa3650E70',
    4: '0x7a8864eA3A4B855D0d359F16D38d966ce018aDb9',
    42: '0xa880c61D2774b34b9C710e9c41C0Cc6D8C791C3c',
  },
  premiaPBC: {
    1: '0x67aEe3454d5F82344d58021179830A3bb2245C11',
    4: '0x13F6376A31dDb10b4d727610e747870591fcb699',
    42: '0x99d0eA9Cc19c2D76499D8D8fdAA63661F021885E',
  },
  premiaBondingCurve: {
    1: '0xf49e0fdbf4839afaeb825eb448aa24b1c43e946b',
    4: '0x80Dde471Be94B52Ef705201Ca44C64284212E26C',
    42: '0x5384471160E119f71c359EFa842282D200ddEDA6',
  },
  feeCalculator: {
    1: '0x602B50091B0B351CA179E87aD6e006AeCEB2a6Ad',
    4: '0xf3a726eDd344513f81000379819669AC78205BCB',
    42: '0x4E831efC11511c6d259b5fd9f4cA6FE732728FAB',
    56: '0x581d114C4058230504F862119D5Bf01393E9e17e',
  },
  sushiswapRouter: {
    1: '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f',
    4: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    42: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  },
  xPremia: {
    1: '0x16f9D564Df80376C61AC914205D3fDfF7057d610',
    4: '0x65191E877AE65ff9c4959b8389Dd7E7881cDAe38',
    42: '0x1f87Beb89e43824C075d82B3a7061b1e50D1615d',
  },
  premiaFeeDiscount: {
    1: '0xF5aae75D1AD6fDD62Cce66137F2674c96FEda854',
    4: '0xbaBd6824CC148b509E0C5B9657D3A4C733aFdFFE',
    42: '0xc0cddb80011321e2405b7C517aceda32C6867343',
  },
  premiaMining: {
    1: '0xf0f16B3460512554d4D821DD482dbfb78817EC43',
    4: '0x290F98506Ee278a1613bF45598d9568d82da9b9c',
  },
  ropeMerkleClaimer: {
    1: '0x52E15B7EcA8d7C104587c0d76dc8C4AEFCA6E0A1',
    4: '0xC931C5939B5f28589ce1a8f1C7a0128cD343F62e',
  },
};

export function getContractAddress(
  chainId: number,
  contractType: ContractType,
) {
  return contracts[contractType][chainId] ?? '';
}

export function getOptionContract(
  contracts: PremiaContracts,
  denominator: TokenDenominator,
) {
  switch (denominator) {
    case TokenDenominator.DAI:
      return contracts.premiaOptionDai;
    case TokenDenominator.BUSD:
      return contracts.premiaOptionBusd;
    default:
      throw new Error('Case not handled : ' + denominator);
  }
}

export function getOptionBatchContract(contracts: PremiaContracts) {
  return contracts.premiaOptionBatch;
}

export function getDenominatorContract(
  contracts: PremiaContracts,
  denominator: TokenDenominator,
) {
  switch (denominator) {
    case TokenDenominator.DAI:
      return contracts.dai;
    case TokenDenominator.BUSD:
      return contracts.busd;
    default:
      throw new Error('Case not handled : ' + denominator);
  }
}

export function getDenominatorFromContractAddress(
  contracts: PremiaContracts,
  address: string,
) {
  if (address === contracts.premiaOptionDai?.address) {
    return TokenDenominator.DAI;
  }
  if (address === contracts.premiaOptionBusd?.address) {
    return TokenDenominator.BUSD;
  }
  return null;
}

export async function getSignerAndContracts(
  web3Provider: ethers.providers.Web3Provider,
) {
  const signer = web3Provider.getSigner();
  const network = await web3Provider.getNetwork();
  const chainId = network.chainId;

  const contracts: PremiaContracts = {};

  ////////////////////////

  // WETH
  const wethAddress = getContractAddress(chainId, ContractType.Weth);
  contracts.weth = wethAddress
    ? Weth__factory.connect(wethAddress, signer.connectUnchecked())
    : undefined;

  // DAI
  const daiAddress = getContractAddress(chainId, ContractType.Dai);
  contracts.dai = daiAddress
    ? Erc20__factory.connect(daiAddress, signer.connectUnchecked())
    : undefined;

  // BUSD
  const busdAddress = getContractAddress(chainId, ContractType.Busd);
  contracts.busd = busdAddress
    ? Erc20__factory.connect(busdAddress, signer.connectUnchecked())
    : undefined;

  // WBNB
  const wbnbAddress = getContractAddress(chainId, ContractType.Wbnb);
  contracts.wbnb = wbnbAddress
    ? Weth__factory.connect(wbnbAddress, signer.connectUnchecked())
    : undefined;

  // PremiaOptionBusd
  const premiaOptionBusdAddress = getContractAddress(
    chainId,
    ContractType.PremiaOptionBusd,
  );
  contracts.premiaOptionBusd = premiaOptionBusdAddress
    ? PremiaOption__factory.connect(
        premiaOptionBusdAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // PremiaOptionDai
  const premiaOptionDaiAddress = getContractAddress(
    chainId,
    ContractType.PremiaOptionDai,
  );
  contracts.premiaOptionDai = premiaOptionDaiAddress
    ? PremiaOption__factory.connect(
        premiaOptionDaiAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // PremiaOptionBatch
  const premiaOptionBatchAddress = getContractAddress(
    chainId,
    ContractType.PremiaOptionBatch,
  );
  contracts.premiaOptionBatch = premiaOptionBatchAddress
    ? PremiaOptionBatch__factory.connect(
        premiaOptionBatchAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // PremiaMarket
  const premiaMarketAddress = getContractAddress(
    chainId,
    ContractType.PremiaMarket,
  );
  contracts.premiaMarket = premiaMarketAddress
    ? PremiaMarket__factory.connect(
        premiaMarketAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // Premia
  const premiaAddress = getContractAddress(chainId, ContractType.Premia);
  contracts.premia = premiaAddress
    ? PremiaErc20__factory.connect(premiaAddress, signer.connectUnchecked())
    : undefined;

  // PremiaPBC
  const premiaPBCAddress = getContractAddress(chainId, ContractType.PremiaPBC);
  contracts.premiaPBC = premiaPBCAddress
    ? PremiaPBC__factory.connect(premiaPBCAddress, signer.connectUnchecked())
    : undefined;

  // PremiaBondingCurve
  const premiaBondingCurveAddress = getContractAddress(
    chainId,
    ContractType.PremiaBondingCurve,
  );
  contracts.premiaBondingCurve = premiaBondingCurveAddress
    ? PremiaBondingCurve__factory.connect(
        premiaBondingCurveAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // FeeCalculator
  const feeCalculatorAddress = getContractAddress(
    chainId,
    ContractType.FeeCalculator,
  );
  contracts.feeCalculator = feeCalculatorAddress
    ? FeeCalculator__factory.connect(
        feeCalculatorAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // SushiswapRouter
  const sushiswapRouterAddress = getContractAddress(
    chainId,
    ContractType.SushiswapRouter,
  );
  contracts.sushiswapRouter = sushiswapRouterAddress
    ? UniswapV2Router02__factory.connect(
        sushiswapRouterAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // xPremio
  const xPremiaAddress = getContractAddress(chainId, ContractType.xPremia);
  contracts.xPremia = xPremiaAddress
    ? PremiaStaking__factory.connect(xPremiaAddress, signer.connectUnchecked())
    : undefined;

  // PremiaFeeDiscount
  const premiaFeeDiscountAddress = getContractAddress(
    chainId,
    ContractType.PremiaFeeDiscount,
  );
  contracts.premiaFeeDiscount = premiaFeeDiscountAddress
    ? PremiaFeeDiscount__factory.connect(
        premiaFeeDiscountAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // PremiaMining
  const premiaMiningAddress = getContractAddress(
    chainId,
    ContractType.PremiaMining,
  );
  contracts.premiaMining = premiaMiningAddress
    ? PremiaMining__factory.connect(
        premiaMiningAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  // PremiaMining
  const ropeMerkleClaimerAddress = getContractAddress(
    chainId,
    ContractType.RopeMerkleClaimer,
  );
  contracts.ropeMerkleClaimer = ropeMerkleClaimerAddress
    ? RopeMerkleClaimer__factory.connect(
        ropeMerkleClaimerAddress,
        signer.connectUnchecked(),
      )
    : undefined;

  ////////////////////////

  return { contracts, signer, chainId };
}
