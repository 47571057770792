import React, { useCallback, useState } from 'react';
import {
  Typography,
  Modal,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ethers, BigNumber } from 'ethers';
import { convertGraphMarketOrderToOrder } from 'web3/market';
import { Option } from 'web3/options';

import { useWeb3 } from 'state/application/hooks';
import { useTransact, useReferrer } from 'hooks';
import { useOptionSettings } from 'state/options/hooks';
import { ModalContainer, OptionTitle } from 'components';
import { OrderBookItem } from 'components/OptionOrderBook';
import { formatBigNumber } from 'utils/formatNumber';

export interface MintToSellModalProps {
  open: boolean;
  option: Option;
  orderBookItem: OrderBookItem;
  onClose: () => void;
}

const MintToSellModal: React.FC<MintToSellModalProps> = ({
  open,
  option,
  orderBookItem,
  onClose,
}) => {
  const { contracts } = useWeb3();
  const { register } = useForm();
  const [quantity, setQuantity] = useState(
    formatBigNumber(
      orderBookItem.quantity,
      false,
      {},
      orderBookItem.token.decimals,
    ),
  );
  const { denominator } = useOptionSettings();
  const transact = useTransact();
  const referrer = useReferrer();

  
  const onMintSellOrder = useCallback(async () => {
    if (!orderBookItem) return;
    
    const _order = convertGraphMarketOrderToOrder(orderBookItem.order);
    const newQuantity = BigNumber.from(quantity).div(formatBigNumber(
      orderBookItem.quantity,
      false,
      {},
      orderBookItem.token.decimals,
    )).mul(ethers.utils.parseUnits(orderBookItem.quantity.toString(), _order?.decimals));
    try {
      transact(
        contracts.premiaMarket?.fillOrder(
          _order,
          newQuantity,
          true,
          referrer,
        ),
        {
          option: orderBookItem.order.option,
          description: `Mint and sell ${quantity} option${
            quantity === '1' ? '' : 's'
          } for ${formatBigNumber(_order.pricePerUnit)} ${denominator} each`,
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, [contracts, denominator, transact, referrer, orderBookItem, quantity]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Mint To Fill</Typography>
        </Box>
        <OptionTitle hideSubtitle option={option} />

        <Box width={1 / 2} marginX='auto' marginTop={4}>
          <Grid container direction='column'>
            <InputLabel>Quantity</InputLabel>
            <Box width={1}>
              <FormControl style={{ width: '100%' }} variant='outlined'>
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity}
                  inputRef={register}
                  onChange={(event) => {
                    setQuantity(event.target.value);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Box>

        <Box width={1 / 2} marginX='auto' marginY={2}>
          <Button
            fullWidth
            disabled={!quantity}
            variant='contained'
            color='secondary'
            onClick={onMintSellOrder}
          >
            Mint To Fill Order
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default MintToSellModal;
