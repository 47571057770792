import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';

import { getOptionDepositsOfAccount } from 'graphql/queries';
import { OptionBalance, OptionType, Option } from 'web3/options';
import { useWithdrawSettings } from 'state/options/hooks';
import { WithdrawType } from 'state/options/reducer';
import { formatCompact } from 'utils/formatNumber';
import { useWeb3 } from 'state/application/hooks';

import { DataTable, TradeOptionModal, CurrencyLogo } from 'components';
import { HeadCell } from 'components/DataTable';
import TransferOptionModal from '../TransferOptionModal';
import ExerciseOptionModal from '../ExerciseOptionModal';
import WithdrawOptionModal from '../WithdrawOptionModal';
import WalletTableToolbar from './WalletTableToolbar';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../../../constants';

const headCells: HeadCell<OptionBalance>[] = [
  {
    id: 'expiration',
    numeric: false,
    label: 'Expiration',
    sortKey: (optionBalance: OptionBalance) =>
      optionBalance?.option.expiration.toString(),
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (optionBalance: OptionBalance) =>
      `${optionBalance?.option.token}/${optionBalance.option.denominator}`,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortKey: (optionBalance: OptionBalance) =>
      optionBalance?.option.type.toString(),
  },
  {
    id: 'strike',
    numeric: true,
    label: 'Strike',
    sortKey: (optionBalance: OptionBalance) =>
      optionBalance?.option.strikePrice.toString(),
  },
  {
    id: 'written',
    numeric: true,
    label: 'Written',
    sortKey: (optionBalance: OptionBalance) =>
      optionBalance?.nbWritten.toString(),
  },
  {
    id: 'balance',
    numeric: true,
    label: 'Balance',
    sortKey: (optionBalance: OptionBalance) =>
      optionBalance?.balance.toString(),
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    sortKey: (optionBalance: OptionBalance) => 1,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  thinFont: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
  },

  thickFont: {
    fontWeight: 700,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },
}));

export default function DepositsTable() {
  const classes = useStyles();

  const { account } = useWeb3();
  const { setWithdrawSettings } = useWithdrawSettings();
  const [anchorEl, setAnchorEl] = useState<null | {
    element: HTMLElement;
    optionBalanceId: string;
  }>(null);
  const [currentOption, setCurrentOption] = useState<null | OptionBalance>(
    null,
  );
  const [defaultTradePanel, setDefaultTradePanel] = useState<'trade' | 'mint'>(
    'trade',
  );
  const [optionToTrade, setOptionToTrade] = useState<null | Option>(null);
  const [
    optionToTransfer,
    setOptionToTransfer,
  ] = useState<null | OptionBalance>(null);
  const [
    optionToWithdraw,
    setOptionToWithdraw,
  ] = useState<null | OptionBalance>(null);
  const [
    optionToExercise,
    setOptionToExercise,
  ] = useState<null | OptionBalance>(null);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: optionsLoading, data, fetchMore }] = useLazyQuery(
    getOptionDepositsOfAccount,
    {
      variables: { account: account.toLowerCase(), first: 100, skip },
    },
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionBalances: OptionBalance[] = useMemo(() => {
    if (!!data?.accountHistoryItems?.length) {
      setResult(data?.accountHistoryItems || []);
    }

    return data ? data.optionBalances : [];
  }, [data]);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    option: OptionBalance,
  ) => {
    setCurrentOption(option);
    setAnchorEl({ element: event.currentTarget, optionBalanceId: option.id });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          optionBalances: isFirstPage
            ? currentResult?.fetchMoreResult?.optionBalances
            : [...result, ...currentResult?.fetchMoreResult?.optionBalances
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  return (
    <DataTable
      headCells={headCells}
      data={optionBalances}
      size={optionBalances.length}
      onChange={onChange}
      loading={optionsLoading}
      toolbar={
        <>
          {optionToTrade && (
            <TradeOptionModal
              open
              option={optionToTrade}
              defaultPanel={defaultTradePanel}
              onClose={() => setOptionToTrade(null)}
            />
          )}

          {optionToExercise && (
            <ExerciseOptionModal
              open
              optionBalance={optionToExercise}
              onClose={() => setOptionToExercise(null)}
            />
          )}

          {optionToWithdraw && (
            <WithdrawOptionModal
              open
              optionBalance={optionToWithdraw}
              onClose={() => setOptionToWithdraw(null)}
            />
          )}

          {optionToTransfer && (
            <TransferOptionModal
              open
              optionBalance={optionToTransfer}
              onClose={() => setOptionToTransfer(null)}
            />
          )}

          <WalletTableToolbar optionBalances={optionBalances} />
        </>
      }
      caption='* Highlighted options may be In the Money (ITM).'
      renderRow={(optionBalance: OptionBalance) => {
        const { id, balance, nbWritten, option } = optionBalance;
        const { token, denominator, expiration, strikePrice, type } = option;

        const isExpired = currentOption
          ? moment() > moment(Number(currentOption.option.expiration) * 1000)
          : false;

        return (
          <TableRow hover tabIndex={-1} key={id}>
            <TableCell
              component='th'
              scope='row'
              align='center'
              className={classes.thinFont}
            >
              {moment(Number(expiration.toString()) * 1000).format(
                'MMM. Do, YYYY',
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid container wrap='nowrap'>
                <span
                  className={cx(
                    type === OptionType.Call
                      ? classes.callGreen
                      : classes.putRed,
                  )}
                >
                  <b>{token.symbol}</b>
                </span>
                -<span>{denominator.symbol}</span>
              </Grid>
            </TableCell>

            <TableCell align='center'>
              {type === OptionType.Call ? (
                <span className={classes.callGreen}>CALL</span>
              ) : (
                <span className={classes.putRed}>PUT</span>
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(strikePrice.toString(), DEFAULT_DECIMALS),
                  )}
                </span>

                <CurrencyLogo currency={denominator} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(nbWritten.toString(), option.token.decimals),
                  )}
                </span>

                <CurrencyLogo currency={token} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(balance.toString(), token.decimals),
                  )}
                </span>

                <CurrencyLogo currency={token} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <IconButton
                onClick={(event) => handleOpenMenu(event, optionBalance)}
              >
                <Add />
              </IconButton>

              <Menu
                keepMounted
                anchorEl={anchorEl?.element}
                open={anchorEl?.optionBalanceId === optionBalance.id}
                onClose={handleCloseMenu}
              >
                {isExpired ? (
                  <MenuItem
                    key='expired'
                    onClick={() => {
                      handleCloseMenu();
                      setOptionToWithdraw(optionBalance);
                      setWithdrawSettings({
                        type: WithdrawType.WithdrawExpired,
                      });
                    }}
                  >
                    Withdraw Expired
                  </MenuItem>
                ) : (
                  [
                    <MenuItem
                      key='trade'
                      onClick={() => {
                        handleCloseMenu();
                        setDefaultTradePanel('trade');
                        setOptionToTrade(option);
                      }}
                    >
                      Trade on Marketplace
                    </MenuItem>,

                    <MenuItem
                      key='mint'
                      onClick={() => {
                        handleCloseMenu();
                        setDefaultTradePanel('mint');
                        setOptionToTrade(option);
                      }}
                    >
                      Mint Additional
                    </MenuItem>,

                    <MenuItem
                      key='earlyassignment'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToWithdraw(optionBalance);
                        setWithdrawSettings({
                          type: WithdrawType.TakeEarlyAssignment,
                        });
                      }}
                    >
                      Take Early Assignment
                    </MenuItem>,

                    <MenuItem
                      key='burn'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToWithdraw(optionBalance);
                        setWithdrawSettings({
                          type: WithdrawType.BurnEarly,
                        });
                      }}
                    >
                      Burn to Withdraw
                    </MenuItem>,

                    ...(Number(optionBalance.balance) > 0
                      ? [
                          <MenuItem
                            key='exercise'
                            onClick={() => {
                              handleCloseMenu();
                              setOptionToExercise(optionBalance);
                            }}
                          >
                            Exercise Option
                          </MenuItem>,
                          <MenuItem
                            key='transfer'
                            onClick={() => {
                              handleCloseMenu();
                              setOptionToTransfer(optionBalance);
                            }}
                          >
                            Transfer
                          </MenuItem>,
                        ]
                      : []),
                  ]
                )}
              </Menu>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
