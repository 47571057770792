import React from 'react';
import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ERC2612PermitMessage } from 'eth-permit/eth-permit';
import { formatEther } from 'ethers/lib/utils';
import { RSV } from 'eth-permit/rpc';

import { useStakingBalances } from 'state/staking/hooks';
import { formatCompact } from 'utils/formatNumber';

import PremiaLogo from 'assets/images/premia-logo.png';
import PremiaLogoGold from 'assets/images/premia-logo-gold.png';
import { SubTitle } from 'components/StyledPage';
import { StakeMode } from './types';

interface StakeRowProps {
  value: string;
  onValueChange: (e: any) => void;
  mode: StakeMode;
  action: 'stake' | 'unstake';
  lockupMonths?: number;
  hidden?: boolean;
  onActionChange: (action: 'stake' | 'unstake') => () => void;
  onModeChange: (mode: StakeMode) => () => void;
  onMaxClicked?: () => void;
  onLockupMonthsChange?: (months: number) => () => void;
  permit?: ERC2612PermitMessage & RSV;
  permitDeadline?: number;
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '20px 0'
  },
  
  imgContainer: {
    width: 120,
    height: 120,
    backgroundColor: '#1f2128',
    borderRadius: 12,
    margin: '8px 0',
    cursor: 'pointer',
  
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  
    '&:hover': {
      backgroundColor: '#333'
    }
  },

  amountLabel: {
    fontFamily: 'Roboto Mono',
    backgroundColor: '#66a6ff',
    borderRadius: 8
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  input: {
    backgroundColor: 'transparent',
    borderRadius: 8,
    outline: 'none',
    color: 'white',
    border: '2px solid #66a6ff',
    padding: 8,
  
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
})

const StakeRow: React.FC<StakeRowProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const stakingBalances = useStakingBalances();

  return (
    <div className={classes.row}>
      <div
        className={classes.imgContainer}
        onClick={props.onModeChange(props.mode)}
      >
        <img
          width={72}
          alt={`logo-${props.mode}`}
          src={props.mode === StakeMode.Premia ? PremiaLogo : PremiaLogoGold}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 10,
          justifyContent: 'center',
          textAlign: 'center',
          minWidth: 70,
        }}
      >
        <div>{props.mode}</div>
        <div className={classes.amountLabel}>
          {formatCompact(
            formatEther(
              props.mode === StakeMode.Premia
                ? stakingBalances.premiaBalance
                : stakingBalances.xPremiaBalance.add(
                    stakingBalances.xPremiaLocked,
                  ),
            ),
          )}
        </div>
      </div>

      {props.hidden ? undefined : (
        <div style={!mobile ? { marginLeft: 50 } : {}}>
          <Typography variant='body2' style={{ marginBottom: 8 }}>
            {props.mode === StakeMode.Premia &&
              'Stake Premia to earn platform fees.'}
            {props.mode === StakeMode.xPremia &&
              'Lock xPremia to reduce your protocol fees.'}
          </Typography>
          <div className={classes.inputGroup}>
            <SubTitle
              style={{
                fontFamily: 'Inter',
                fontSize: 12,
                marginRight: 10,
                minWidth: 110,
              }}
            >
              Quantity to Stake:{' '}
            </SubTitle>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <input className={classes.input} value={props.value} onChange={props.onValueChange} />
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  marginRight: 10,
                  fontSize: 12,
                  fontFamily: 'Inter',
                  cursor: 'pointer',
                }}
                onClick={props.onMaxClicked}
              >
                Max
              </div>
            </div>
          </div>

          {props.mode === StakeMode.xPremia ? (
            <div className={classes.inputGroup} style={{ marginTop: 6, marginBottom: 12 }}>
              <SubTitle
                style={{
                  fontFamily: 'Inter',
                  fontSize: 12,
                  marginRight: 10,
                  minWidth: 110,
                }}
              >
                Lockup Period:{' '}
              </SubTitle>
              <Select
                style={{ flexGrow: 1 }}
                value={props.lockupMonths}
                disabled={props.action === 'unstake'}
              >
                <MenuItem value={1} onClick={props.onLockupMonthsChange?.(1)}>
                  1 Month
                </MenuItem>
                <MenuItem value={3} onClick={props.onLockupMonthsChange?.(3)}>
                  3 Months
                </MenuItem>
                <MenuItem value={6} onClick={props.onLockupMonthsChange?.(6)}>
                  6 Months
                </MenuItem>
                <MenuItem value={12} onClick={props.onLockupMonthsChange?.(12)}>
                  12 Months
                </MenuItem>
              </Select>
            </div>
          ) : undefined}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 5,
            }}
          >
            <ButtonGroup>
              <Button
                variant='outlined'
                size='medium'
                color='primary'
                style={{
                  height: 20,
                  fontSize: 12,
                  fontFamily: 'Inter',
                  backgroundColor:
                    props.action === 'stake' ? '#66A6FF' : undefined,
                }}
                onClick={props.onActionChange('stake')}
              >
                Stake
              </Button>

              <Button
                variant='outlined'
                size='medium'
                style={{
                  height: 20,
                  fontSize: 12,
                  fontFamily: 'Inter',
                  backgroundColor:
                    props.action === 'unstake' ? '#66A6FF' : undefined,
                }}
                onClick={props.onActionChange('unstake')}
              >
                Unstake
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakeRow;
