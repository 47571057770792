import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  Box,
  Grid,
  Paper,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';

import { useStakingBalances } from 'state/staking/hooks';
import { formatEther } from 'ethers/lib/utils';
import { useWeb3, usePrices, useToggleWrapEthModal } from 'state/application/hooks';
import { formatCompact } from 'utils/formatNumber';
import { BigNumber } from 'bignumber.js';

import WrapEthModal from './WrapEthModal';
import TokenApprovalModal from './TokenApprovalModal';
import WalletDenominators from './WalletDenominators';
import TokenSearch from './TokenSearch';
import PremiaLogo from 'assets/images/premia-logo.png';
import PremiaLogoGold from 'assets/images/premia-logo-gold.png';
import { Loader } from 'components';
import { StakeMode } from 'pages/Stake/components/types';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0'
  },

  paper: {
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'relative',
  },

  title: {
    flex: '1 1 30%',
    marginBottom: 8,
  },

  premiaBalance: {
    width: '100%',
    borderTop: '1px solid rgba(228, 228, 228, 0.1)',
  },

  balanceText: {
    fontSize: '0.6rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '5rem',
    textOverflow: 'ellipsis',
    color: `${theme.palette.success.main}`,
  },

  priceText: {
    fontSize: '0.6rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '5rem',
    textOverflow: 'ellipsis',
    color: `${theme.palette.text.secondary}`,
    fontWeight: 300,
  },
}));

interface StakeRowProps {
  mode: StakeMode;
}

const StakeRow: React.FC<StakeRowProps> = (props) => {
  const { account } = useWeb3();
  const classes = useStyles();
  const stakingBalances = useStakingBalances();
  const prices = usePrices();

  const isPremiaMode = props.mode === StakeMode.Premia;
  const premiaPrice = prices['PREMIA'];

  return (
    <div className={classes.row}>
      <img
        width={24}
        alt={`logo-${props.mode}`}
        src={isPremiaMode ? PremiaLogo : PremiaLogoGold}
      />
      <span style={{ marginLeft: '1rem' }}>{props.mode}</span>
      <Grid container direction='column' alignItems='flex-end'>
        {stakingBalances.premiaBalance ? (
          <Typography variant='body2' className={classes.balanceText}>
            {formatCompact(
              formatEther(
                isPremiaMode
                  ? stakingBalances.premiaBalance
                  : stakingBalances.xPremiaBalance.add(
                      stakingBalances.xPremiaLocked,
                    ),
              ),
            )}
          </Typography>
        ) : account ? (
          <Loader />
        ) : null}{' '}
        {premiaPrice && stakingBalances.premiaBalance ? (
          <Typography variant='body2' className={classes.priceText}>
            ${' '}
            {formatCompact(
              Number(premiaPrice) *
                (isPremiaMode
                  ? Number(formatEther(stakingBalances.premiaBalance))
                  : Number(
                      new BigNumber(
                        stakingBalances.totalPremiaStaked.toString(),
                      )
                        .div(stakingBalances.xPremiaSupply.toString())
                        .toString(),
                    ) *
                    Number(
                      formatEther(
                        stakingBalances.xPremiaBalance.add(
                          stakingBalances.xPremiaLocked,
                        ),
                      ),
                    )),
            )}
          </Typography>
        ) : null}
      </Grid>
    </div>
  );
};

const TokenWallet: React.FC = () => {
  const { wallet } = useWeb3();
  const { wrapEthModalOpen, setWrapEthModalOpen } = useToggleWrapEthModal();
  const [tokenApprovalModalOpen, setTokenApprovalModal] = useState(false);
  const classes = useStyles();

  return wallet && wallet.provider ? (
    <Box ml='auto' mt={14} width='80%'>
      <WrapEthModal
        open={wrapEthModalOpen}
        onClose={() => setWrapEthModalOpen(false)}
      />

      <TokenApprovalModal
        open={tokenApprovalModalOpen}
        onClose={() => setTokenApprovalModal(false)}
      />
      
      <Paper>
        <Grid container direction='column'>
          <Box clone width={1} py={2} px={3}>
            <Grid item>
              <Toolbar disableGutters>
                <Typography
                  variant='h6'
                  component='h4'
                  className={classes.title}
                >
                  Wallet
                </Typography>

                <Button 
                  color='secondary'
                  variant='outlined'
                  onClick={() => setTokenApprovalModal(true)}
                >
                  <SettingsIcon />
                </Button>
              </Toolbar>

              <Box marginY={1}>
                <WalletDenominators />
              </Box>

              <TokenSearch />
            </Grid>
          </Box>

          <Grid item className={classes.premiaBalance}>
            <Box width={1} py={2} px={3}>
              <StakeRow mode={StakeMode.Premia} />
              <StakeRow mode={StakeMode.xPremia} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  ) : null;
};

export default TokenWallet;
