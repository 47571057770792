import React, { useCallback, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  Hidden,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BigNumber } from '@ethersproject/bignumber';
import { RSV } from 'eth-permit/rpc';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { ERC2612PermitMessage, signERC2612Permit } from 'eth-permit/eth-permit';
import moment from 'moment';

import { useWeb3 } from 'state/application/hooks';
import { useStakingBalances } from 'state/staking/hooks';
import { useTransact, useIsHardwareWallet, useApproval } from 'hooks';
import { formatBigNumber } from 'utils/formatNumber';

import { PageWithSidebar } from 'layouts';
import { Hero } from 'components';
import { HeroVariant } from 'components/Hero';
import { SubTitle, Title } from 'components/StyledPage';
import { StakeRow, StakeSelectBtn, StakeStats } from './components';
import { StakeMode } from './components/types';
import ProgressRing from 'components/ProgressRing';
import ChestClosed from 'assets/svg/ChestClosed.svg';
import ChestEmpty from 'assets/svg/ChestEmpty.svg';
import ChestFull from 'assets/svg/ChestFull.svg';
import ChestLocked from 'assets/svg/ChestLocked.svg';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  wrapper: {
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },

    [breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  pageTitle: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: 48,
    marginBottom: 32,
  },
  pageTitleMobile: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: '48px',
    margin: '82px 10px 16px',
  },
  container: {
    width: '100%',
    padding: '30px',
    marginTop: '50px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '20px',
  },
  containerMobile: {
    width: '100%',
    padding: '30px',
    marginTop: '10px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '20px',
  },
}));

interface StakeState {
  mode: StakeMode;
  action: 'stake' | 'unstake';
  lockupMonths: number;
  amount: string;
  permit?: ERC2612PermitMessage & RSV;
  permitDeadline?: number;
}

const Stake: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = useState<StakeState>({
    mode: StakeMode.Premia,
    action: 'stake',
    lockupMonths: 1,
    amount: '0',
  });

  const { web3, account, contracts } = useWeb3();
  const {
    unclaimedPremia,
    xPremiaLocked,
    xPremiaLockedUntil,
    premiaBalance,
    xPremiaBalance,
    premiaStaked,
  } = useStakingBalances();
  const isHardwareWallet = useIsHardwareWallet();
  const [shouldApprove, handleChangeShouldApprove] = useState(isHardwareWallet);
  const transact = useTransact();

  const {
    allowance: stakingAllowance,
    onApprove: onApproveStaking,
  } = useApproval(
    contracts.premia?.address as string,
    contracts.xPremia?.address as string,
  );
  const {
    allowance: lockingAllowance,
    onApprove: onApproveLocking,
  } = useApproval(
    contracts.xPremia?.address as string,
    contracts.premiaFeeDiscount?.address as string,
  );

  const isActionPermitted = shouldApprove
    ? getAllowance()?.gt(0)
    : state.permit;

  const lockedUntilDate = useMemo(
    () => moment(Number(xPremiaLockedUntil) * 1000),
    [xPremiaLockedUntil],
  );
  let actionTitle = 'Deposit';
  let chestImage = ChestEmpty;
  let progress = 0;

  switch (state.mode) {
    case StakeMode.Premia:
      if (state.action === 'unstake') {
        actionTitle = 'Withdraw';
        chestImage = ChestFull;
      }
      break;

    case StakeMode.xPremia:
      actionTitle = 'Deposit & Lock';
      chestImage = ChestClosed;
      if (state.action === 'unstake') {
        actionTitle = 'Withdraw';
        chestImage = ChestFull;
      } else if (Number(xPremiaLocked) > 0 && lockedUntilDate > moment()) {
        actionTitle = 'Locked';
        chestImage = ChestLocked;
      } else if (Number(xPremiaLocked) > 0 && moment() > lockedUntilDate) {
        actionTitle = 'Unlocked';
        chestImage = ChestFull;
        progress = 100;
      }
      break;
  }

  function handleActionChange(action: 'stake' | 'unstake') {
    return () => {
      setState((s) => ({
        ...s,
        action,
        amount: '0',
        permit: undefined,
        permitDeadline: undefined,
      }));
    };
  }

  function handleModeChange(mode: StakeMode) {
    return () => {
      setState((s) => ({
        ...s,
        mode,
        permit: undefined,
        permitDeadline: undefined,
        amount: '0',
      }));
    };
  }

  function handleLockupChange(lockupMonths: number) {
    return () => {
      setState((s) => ({ ...s, lockupMonths }));
    };
  }

  function handleAmountChange(e: any) {
    const value = e.target.value;
    setState((s) => ({
      ...s,
      amount: value,
      permit: undefined,
      permitDeadline: undefined,
    }));
  }

  function handleMaxClick() {
    if (state.action === 'stake') {
      switch (state.mode) {
        case StakeMode.Premia:
          setState((s) => ({
            ...s,
            amount: formatEther(premiaBalance),
          }));
          break;
        case StakeMode.xPremia:
          setState((s) => ({
            ...s,
            amount: formatEther(xPremiaBalance),
          }));
          break;
      }
    } else {
      switch (state.mode) {
        case StakeMode.Premia:
          setState((s) => ({
            ...s,
            amount: formatEther(premiaStaked),
          }));
          break;
        case StakeMode.xPremia:
          setState((s) => ({
            ...s,
            amount: formatEther(xPremiaLocked),
          }));
          break;
      }
    }
  }

  async function unstake(amount: BigNumber) {
    switch (state.mode) {
      case StakeMode.Premia:
        return transact(contracts.xPremia?.leave(amount), {
          description: `Unstake to withdraw ${formatBigNumber(
            amount,
          )} xPREMIA as PREMIA`,
        });
      case StakeMode.xPremia:
        return transact(contracts.premiaFeeDiscount?.unstake(amount), {
          description: `Unlock ${formatBigNumber(amount)} staked xPREMIA`,
        });
    }
  }

  async function stakeWithPermit(amount: BigNumber) {
    if (!state.permit || !state.permitDeadline) return;

    switch (state.mode) {
      case StakeMode.Premia:
        await transact(
          contracts.xPremia?.enterWithPermit(
            amount,
            state.permitDeadline,
            state.permit.v,
            state.permit.r,
            state.permit.s,
          ),
          {
            description: `Stake ${formatBigNumber(amount)} PREMIA for xPREMIA`,
          },
        );
        break;
      case StakeMode.xPremia:
        await transact(
          contracts.premiaFeeDiscount?.stakeWithPermit(
            amount,
            state.lockupMonths * 30 * 24 * 3600,
            state.permitDeadline,
            state.permit.v,
            state.permit.r,
            state.permit.s,
          ),
          {
            description: `Stake to lock ${formatBigNumber(
              amount,
            )} xPREMIA for fee discounts`,
          },
        );
        break;
    }

    return setState((s) => ({ ...s, permit: undefined }));
  }

  async function permitStake(amount: BigNumber) {
    switch (state.mode) {
      case StakeMode.Premia:
        return signPermit(
          contracts.premia?.address as string,
          contracts.xPremia?.address as string,
          amount,
        );
      case StakeMode.xPremia:
        return signPermit(
          contracts.xPremia?.address as string,
          contracts.premiaFeeDiscount?.address as string,
          amount,
        );
    }
  }

  function getAllowance() {
    switch (state.mode) {
      case StakeMode.Premia:
        return stakingAllowance;
      case StakeMode.xPremia:
        return lockingAllowance;
    }
  }

  async function approveStake() {
    switch (state.mode) {
      case StakeMode.Premia:
        return onApproveStaking();
      case StakeMode.xPremia:
        return onApproveLocking();
    }
  }

  async function stake(amount: BigNumber) {
    switch (state.mode) {
      case StakeMode.Premia:
        await transact(contracts.xPremia?.enter(amount), {
          description: `Stake ${formatBigNumber(amount)} PREMIA for xPREMIA`,
        });
        break;
      case StakeMode.xPremia:
        await transact(
          contracts.premiaFeeDiscount?.stake(
            amount,
            state.lockupMonths * 30 * 24 * 3600,
          ),
          {
            description: `Stake to lock ${formatBigNumber(
              amount,
            )} xPREMIA for fee discounts`,
          },
        );
        break;
    }
  }

  async function handleStakeUnstake() {
    const amount = parseEther(state.amount);

    if (state.action === 'unstake') {
      return unstake(amount);
    } else if (!shouldApprove) {
      if (state.permit && state.permitDeadline) {
        return stakeWithPermit(amount);
      } else {
        return permitStake(amount);
      }
    } else {
      const allowance = getAllowance();

      if (allowance?.gt(0)) {
        return stake(amount);
      } else {
        return approveStake();
      }
    }
  }

  function signPermit(token: string, spender: string, amount: BigNumber) {
    const deadline = Math.floor(new Date().getTime() / 1000 + 3600);

    signERC2612Permit(
      web3,
      token,
      account,
      spender,
      amount.toString(),
      deadline,
    ).then((r) =>
      setState((s) => ({ ...s, permit: r, permitDeadline: deadline })),
    );
  }

  const claimPremia = useCallback(() => {
    if (!contracts.premiaMining) return;
    return transact(contracts.premiaMining.withdraw(0, 0), {
      description: 'Claim PREMIA from interaction mining',
    });
  }, [contracts.premiaMining, transact]);

  return (
    <PageWithSidebar mobile={mobile}>
      <Grid container>
        <Hidden mdUp>
          <Box clone>
            <Typography
              component='h2'
              variant='h3'
              color='textPrimary'
              className={!mobile ? classes.pageTitle : classes.pageTitleMobile}
            >
              Premia Staking
            </Typography>
          </Box>
        </Hidden>

        <Hidden smDown>
          <Hero
            variant={HeroVariant.PBC}
            title={'Many Ways to Stake'}
            subTitle={'Refine, Stake, & Lock - Utilize your Ecosystem Tokens'}
            onLearnMore={() => {
              const url =
                'https://medium.com/premia/interaction-mining-more-d456b0b6f23f';

              window.open(url, '_blank') || window.location.replace(url);
            }}
          />
        </Hidden>

        <Box className={!mobile ? classes.container : classes.containerMobile}>
          <Title>Choose how to stake your Premia</Title>
          <SubTitle>
            Refine your interaction rewards, stake your Premia for fee sharing,
            or lock for reduced fees.
          </SubTitle>

          <Box clone marginTop={2}>
            <Grid container direction='row' className={classes.wrapper}>
              <Box clone marginLeft={2}>
                <Grid item sm={12} md={8}>
                  <Title>My Premia Tokens</Title>

                  {Number(unclaimedPremia) > 0 && (
                    <Box marginTop={3}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={claimPremia}
                      >
                        Claim Premia
                      </Button>
                    </Box>
                  )}

                  <StakeRow
                    value={state.amount}
                    onValueChange={handleAmountChange}
                    mode={StakeMode.Premia}
                    action={state.action}
                    hidden={state.mode !== StakeMode.Premia}
                    onActionChange={handleActionChange}
                    onModeChange={handleModeChange}
                    onMaxClicked={handleMaxClick}
                  />

                  <StakeRow
                    value={state.amount}
                    onValueChange={handleAmountChange}
                    mode={StakeMode.xPremia}
                    action={state.action}
                    hidden={state.mode !== StakeMode.xPremia}
                    lockupMonths={state.lockupMonths}
                    onActionChange={handleActionChange}
                    onModeChange={handleModeChange}
                    onLockupMonthsChange={handleLockupChange}
                    onMaxClicked={handleMaxClick}
                  />
                </Grid>
              </Box>

              <Hidden mdUp>
                <Box clone marginY={2}>
                  <Grid container direction='column' alignItems='center'>
                    <Button
                      size='large'
                      variant='contained'
                      color='primary'
                      onClick={handleStakeUnstake}
                    >
                      {state.action === 'stake'
                        ? isActionPermitted
                          ? 'Stake (2 / 2)'
                          : shouldApprove
                          ? 'Approve (1 / 2)'
                          : 'Sign Permit (1 / 2)'
                        : 'Unstake'}
                    </Button>

                    <FormControlLabel
                      label={
                        <span style={{ fontSize: '0.85rem' }}>
                          Use Approve (required by some hardware wallets)
                        </span>
                      }
                      control={
                        <Checkbox
                          name='shouldApprove'
                          size='small'
                          checked={shouldApprove}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => handleChangeShouldApprove(event.target.checked)}
                        />
                      }
                    />

                    {state.mode === StakeMode.xPremia && (
                      <Typography variant='body2' color='error'>
                        Locked xPremia cannot be unstaked until the lockup
                        period is complete.
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Hidden>

              <Grid
                item
                container
                direction='column'
                alignItems='center'
                sm={12}
                md={4}
              >
                <Typography>{actionTitle}</Typography>

                <Box clone height='160px' width='160px'>
                  <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                  >
                    <ProgressRing
                      style={{ position: 'absolute' }}
                      radius={80}
                      stroke={10}
                      progress={progress}
                    />

                    <img src={chestImage} alt='Premia Chest' />
                  </Grid>
                </Box>

                <StakeStats mode={state.mode} />
              </Grid>
            </Grid>
          </Box>

          <Hidden smDown>
            <Box clone marginY={2}>
              <Grid container direction='column' alignItems='center'>
                <Button
                  size='large'
                  variant='contained'
                  color='primary'
                  onClick={handleStakeUnstake}
                >
                  {state.action === 'stake'
                    ? isActionPermitted
                      ? state.mode === StakeMode.xPremia
                        ? 'Stake & Lock (2 / 2)'
                        : 'Stake (2 / 2)'
                      : shouldApprove
                      ? 'Approve (1 / 2)'
                      : 'Sign Permit (1 / 2)'
                    : 'Unstake'}
                </Button>

                <FormControlLabel
                  label={
                    <span style={{ fontSize: '0.85rem' }}>
                      Use Approve (required by some hardware wallets)
                    </span>
                  }
                  control={
                    <Checkbox
                      name='shouldApprove'
                      size='small'
                      checked={shouldApprove}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeShouldApprove(event.target.checked)
                      }
                    />
                  }
                />

                {state.mode === StakeMode.xPremia && (
                  <Box clone paddingTop={1}>
                    <Typography variant='body2' color='error'>
                      Locked xPremia cannot be unstaked until the lockup period
                      is complete.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Hidden>

          <Box
            clone
            marginTop={1}
            minHeight={50}
            borderRadius={15}
            style={{ border: '2px solid rgba(228,228,228,0.1)' }}
          >
            <Grid container direction='row'>
              <StakeSelectBtn
                mode={StakeMode.Premia}
                selected={state.mode === StakeMode.Premia}
                onClick={() =>
                  setState((s) => ({ ...s, mode: StakeMode.Premia }))
                }
              >
                Stake Premia
              </StakeSelectBtn>

              <StakeSelectBtn
                mode={StakeMode.xPremia}
                selected={state.mode === StakeMode.xPremia}
                onClick={() =>
                  setState((s) => ({ ...s, mode: StakeMode.xPremia }))
                }
              >
                Lock xPremia
              </StakeSelectBtn>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </PageWithSidebar>
  );
};

export default Stake;
