import React from 'react';
import { Grid, Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useWeb3 } from 'state/application/hooks';

import { PageWithSidebar } from 'layouts';
import { MintHero, CreateOption } from './components';
import { TokenWallet, AccountRequired } from 'components';

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: 48,
    marginBottom: 32,
  },
  pageTitleMobile: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: '48px',
    margin: '82px 10px 16px',
  },
}));

const Mint: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { wallet, account } = useWeb3();

  return (
    <PageWithSidebar
      hideAccountButtons={!wallet || !wallet.provider}
      mobile={mobile}
    >
      {wallet && wallet.provider && account ? (
        <Grid container>
          <Grid item xs={12} md={8}>
            <Hidden mdUp>
              <Typography
                component='h2'
                variant='h3'
                color='textPrimary'
                className={
                  !mobile ? classes.pageTitle : classes.pageTitleMobile
                }
              >
                Mint Options
              </Typography>
            </Hidden>

            <Hidden smDown>
              <MintHero />
            </Hidden>

            <CreateOption />
          </Grid>

          <Hidden smDown>
            <Grid item xs={4}>
              <Box position='fixed' width={1 / 4}>
                <TokenWallet />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      ) : (
        <AccountRequired />
      )}
    </PageWithSidebar>
  );
};

export default Mint;
