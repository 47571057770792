import React, { useCallback, useMemo } from 'react';
import {
  Paper,
  Grid,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Button,
  Fab
} from '@material-ui/core';
import { makeStyles, lighten, Theme } from '@material-ui/core/styles';
import { AttachMoney } from '@material-ui/icons';
import { useQuery } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { get } from 'lodash';
import cx from 'classnames';

import { getTokenPair } from 'graphql/queries';
import { getOptionContract } from 'web3/contracts';
import { OptionType } from 'web3/options';
import {
  useOptionSettings,
  useWriteOption,
  useWriteQuote,
  useMaxQuantity
} from 'state/options/hooks';
import { useWeb3 } from 'state/application/hooks';
import { useTokenBalance } from 'state/wallet/hooks';
import {
  useTradeSettings,
  useSell,
  useSellNewOption,
} from 'state/market/hooks';
import { useApproveMint, useApproveMarket, useDenominatorAddress } from 'hooks';
import { formatNumber, formatBigNumber } from 'utils/formatNumber';

import { Loader } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 300,
  },

  thick: {
    fontWeight: 500,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  rightAlign: {
    textAlign: 'right',
  },

  createAndSell: {
    borderColor: theme.palette.primary.main,
  },

  fieldHint: {
    position: 'absolute',
    bottom: -20,
    right: 0,
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '102%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  greenText: {
    color: theme.palette.success.main,
  },

  createOnly: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: lighten(theme.palette.common.black, 0.05),
    },
  },
}));

const MintAndSellOptionPanel: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const { contracts, account } = useWeb3();
  const denominatorAddress = useDenominatorAddress();
  const classes = useStyles();

  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
    selectedOption,
    setOptionSettings,
  } = useOptionSettings();
  const { price, quantity, setTradeSettings } = useTradeSettings();

  const { loading: loadingTokenPair, data } = useQuery(getTokenPair, {
    skip: !selectedToken,
    variables: { id: `${selectedToken?.symbol}/${denominator}` },
  });

  const activePair = useMemo(() => get(data, 'tokenPair'), [data]);

  const expectedFee = useWriteQuote();
  const tokenBalance = useTokenBalance(account, activePair?.token);
  const denominatorBalance = useTokenBalance(account, activePair?.denominator);
  const hasSufficientBalance =
    optionType === OptionType.Call
      ? Number(tokenBalance) >= Number(quantity) + Number(expectedFee)
      : Number(denominatorBalance) >=
        Number(strikePrice) * Number(quantity) + Number(expectedFee);

  const maxQuantity = useMaxQuantity(tokenBalance, denominatorBalance);

  const invalidPrice = useMemo(() => !price || price === '' || price === '0', [
    price,
  ]);
  const noStrikePrice = useMemo(() => !selectedOption?.id && !strikePrice, [
    selectedOption,
    strikePrice,
  ]);

  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const approvalToken =
    optionType === OptionType.Call
      ? activePair?.token
      : activePair?.denominator;

  const { allowance, onApprove } = useApproveMint(approvalToken, denominator);

  const handleApprove = useCallback(async () => {
    try {
      await onApprove();
    } catch (e) {
      console.error(e);
    }
  }, [onApprove]);

  const {
    loading: approvalLoading,
    approvedDenominator,
    tokenAllowance,
    onApproveToken,
    onApproveDenominator,
  } = useApproveMarket(denominatorAddress ?? '', denominator);

  const handleApproveMarket = useCallback(async () => {
    try {
      if (!approvedDenominator) {
        await onApproveDenominator();
      }

      if (!tokenAllowance.gt(0)) {
        await onApproveToken();
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    tokenAllowance,
    approvedDenominator,
    onApproveDenominator,
    onApproveToken,
  ]);

  const onWrite = useWriteOption();
  const onSellNewOption = useSellNewOption();
  const onSell = useSell();

  const handleWrite = useCallback(async () => {
    await (await onWrite())?.wait(1);
  }, [onWrite]);

  const handleWriteAndList = useCallback(async () => {
    if (!selectedToken || !selectedExpiration || !strikePrice) {
      return;
    }

    let optionId = await optionContract?.getOptionId(
      selectedToken.address,
      Math.floor(selectedExpiration.getTime() / 1000),
      ethers.utils.parseEther(strikePrice),
      optionType === OptionType.Call,
    );

    if (!optionId || optionId?._hex === '0x00') {
      await onSellNewOption();
    } else {
      await onSell(selectedOption, {
        isDelayedWriting: true,
        writeOnBuyFill: true,
      });
    }
  }, [
    onSell,
    onSellNewOption,
    optionContract,
    selectedToken,
    selectedExpiration,
    selectedOption,
    strikePrice,
    optionType,
  ]);

  return (
    <Grid container direction='row' wrap='nowrap'>
      <Box width={1} marginLeft={1} paddingRight={2}>
        <Grid
          item
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Box marginY={4}>
            <Typography variant='h4' component='h2' align='center'>
              Deposit{' '}
              {optionType === OptionType.Call
                ? formatNumber(Number(quantity) + Number(expectedFee))
                : formatNumber(
                    Number(quantity) * Number(strikePrice) +
                      Number(expectedFee),
                  )}{' '}
              <span
                className={cx(classes.thick, {
                  [classes.callGreen]: optionType === OptionType.Call,
                  [classes.putRed]: optionType === OptionType.Put,
                })}
              >
                {optionType === OptionType.Call
                  ? selectedToken?.symbol
                  : denominator}
              </span>
            </Typography>
          </Box>

          {!selectedOption?.id && (
            <Grid container justify='center'>
              <Box width={1} marginBottom={4}>
                <InputLabel>Strike Price</InputLabel>
                <FormControl style={{ width: '100%' }}>
                  <OutlinedInput
                    name='strikePrice'
                    type='number'
                    value={strikePrice ?? ''}
                    inputRef={register}
                    onChange={(event) => {
                      setOptionSettings({ strikePrice: event.target.value });
                    }}
                  />
                </FormControl>
              </Box>

              {loadingTokenPair ? (
                <Loader />
              ) : (
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={classes.fieldHint}
                >
                  {activePair?.strikePriceIncrement && (
                    <>
                      ({formatBigNumber(activePair.strikePriceIncrement)}{' '}
                      {denominator} increment)
                    </>
                  )}
                </Typography>
              )}
            </Grid>
          )}

          <Grid container justify='center'>
            <Box width={1} marginBottom={4} position='relative'>
              <InputLabel>Quantity</InputLabel>
              <FormControl style={{ width: '100%' }}>
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity}
                  inputRef={register}
                  inputProps={
                    selectedToken?.decimals
                      ? {
                          step: 1 * 10 ** -selectedToken.decimals,
                        }
                      : {}
                  }
                  onChange={(event) => {
                    setTradeSettings({ quantity: event.target.value });
                  }}
                />
                {
                  quantity &&
                    <Typography
                      className={cx(hasSufficientBalance ? classes.greenText : classes.putRed, classes.fieldStatus, classes.desktopFieldStatus)}
                      variant='body2'
                    >
                      { hasSufficientBalance ? 'Valid' : 'Invalid' }
                    </Typography>
                }
                {
                  selectedToken && selectedExpiration && strikePrice &&
                    <Fab
                      variant='extended'
                      color='primary'
                      size='small'
                      onClick={() => setTradeSettings({ quantity: maxQuantity.toString() }) }
                    >
                      MAX
                    </Fab>
                }
              </FormControl>

              {loadingTokenPair ? (
                <Loader />
              ) : (
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={classes.fieldHint}
                >
                  {strikePrice && quantity && (
                    <>
                      (Collateral:{' '}
                      {formatNumber(
                        optionType === OptionType.Call
                          ? Number(quantity)
                          : Number(strikePrice) * Number(quantity),
                      )}{' '}
                      {optionType === OptionType.Call
                        ? selectedToken?.symbol
                        : denominator}
                      )
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Grid>

          {allowance.eq(0) ? (
            <Box clone mt={6} width={3 / 4}>
              <Button
                disabled={!selectedToken}
                size='large'
                variant='contained'
                color='primary'
                onClick={handleApprove}
              >
                Approve
              </Button>
            </Box>
          ) : (
            <>
              <Box clone width={1} paddingX={2} paddingY={2}>
                <Paper className={classes.createAndSell}>
                  <Typography variant='h6' component='h2'>
                    Mint on Sale
                  </Typography>

                  <Box
                    width={1}
                    marginTop={1}
                    marginBottom={4}
                    position='relative'
                  >
                    <InputLabel>Price</InputLabel>
                    <FormControl style={{ width: '100%' }}>
                      <OutlinedInput
                        name='price'
                        type='number'
                        value={price === '0' ? '' : price}
                        inputRef={register}
                        onChange={(event) => {
                          setTradeSettings({ price: event.target.value })
                        }}
                      />
                    </FormControl>

                    <Typography
                      color='textSecondary'
                      variant='body2'
                      className={classes.fieldHint}
                    >
                      (Total: {formatNumber(Number(quantity) * Number(price))}{' '}
                      {denominator})
                    </Typography>
                  </Box>

                  {approvalLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {approvedDenominator && tokenAllowance.gt(0) ? (
                        <>
                          {hasSufficientBalance ? (
                            <Button
                              fullWidth
                              disabled={invalidPrice || noStrikePrice}
                              color='primary'
                              variant='contained'
                              onClick={handleSubmit(handleWriteAndList)}
                            >
                              <Box clone marginRight={2}>
                                <AttachMoney />
                              </Box>
                              Mint on Sale
                            </Button>
                          ) : (
                            <Button
                              fullWidth
                              disabled
                              color='primary'
                              variant='contained'
                            >
                              <Box clone marginRight={2}>
                                <AttachMoney />
                              </Box>
                              Insufficient Balance
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          onClick={handleApproveMarket}
                        >
                          Approve Market
                          <br />(
                          {approvedDenominator || tokenAllowance.gt(0)
                            ? '2 / 2'
                            : '1 / 2'}
                          )
                        </Button>
                      )}
                    </>
                  )}
                </Paper>
              </Box>

              <Box clone width={1} paddingX={4} paddingY={2}>
                {hasSufficientBalance ? (
                  <Box clone marginTop={1}>
                    <Paper
                      className={noStrikePrice ? '' : classes.createOnly}
                      onClick={noStrikePrice ? () => {} : handleWrite}
                    >
                      <Typography
                        variant='h6'
                        component='h2'
                        align='center'
                        color={noStrikePrice ? 'textSecondary' : 'primary'}
                      >
                        Mint to Wallet
                      </Typography>
                    </Paper>
                  </Box>
                ) : (
                  <Box clone marginTop={2}>
                    <Paper>
                      <Typography
                        variant='h6'
                        component='h2'
                        align='center'
                        color='primary'
                      >
                        Insufficient Balance
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default MintAndSellOptionPanel;
