import gql from 'graphql-tag';

import {
  OptionBalance,
  Option,
  OptionWithOrders,
  MarketOrder,
  TokenPair,
  Token,
  Account,
  AccountHistoryItem,
  TokenPairHistoryItem,
} from './fragments';

export const getOpenOrdersOfAccount = gql`
  ${MarketOrder}

  query OpenOrdersOfAccount(
    $first: Int = 100
    $skip: Int = 0
    $account: String!
  ) {
    marketOrders(
      first: $first
      skip: $skip
      where: { maker: $account, remainingUnfilled_gt: 0 }
    ) {
      ...MarketOrder
    }
  }
`;

export const getOptionDepositsOfAccount = gql`
  ${OptionBalance}

  query OptionDepositsOfAccount(
    $first: Int = 100
    $skip: Int = 0
    $account: String!
  ) {
    optionBalances(
      first: $first
      skip: $skip
      where: { owner: $account, nbWritten_gt: 0 }
    ) {
      ...OptionBalance
    }
  }
`;

export const getOptionBalancesOfAccount = gql`
  ${OptionBalance}

  query OptionBalancesOfAccount(
    $first: Int = 100
    $skip: Int = 0
    $account: String!
  ) {
    optionBalances(
      first: $first
      skip: $skip
      where: { owner: $account, balance_gt: 0 }
    ) {
      ...OptionBalance
    }
  }
`;

export const getOptionBalance = gql`
  ${OptionBalance}

  query OptionBalance($id: String!) {
    optionBalance(id: $id) {
      ...OptionBalance
    }
  }
`;

export const getFilteredOrders = gql`
  ${MarketOrder}

  query FilteredOrders(
    $first: Int = 100
    $skip: Int = 0
    $where: MarketOrder_filter
  ) {
    marketOrders(first: $first, skip: $skip, where: $where) {
      ...MarketOrder
    }
  }
`;

export const getFilteredOptions = gql`
  ${Option}

  query FilteredOptions(
    $first: Int = 100
    $skip: Int = 0
    $where: Option_filter
  ) {
    options(first: $first, skip: $skip, where: $where) {
      ...Option
    }
  }
`;

export const getFilteredOptionsWithOrders = gql`
  ${OptionWithOrders}

  query FilteredOptionsWithOrders(
    $first: Int = 100
    $skip: Int = 0
    $where: Option_filter
  ) {
    options(
      first: $first
      skip: $skip
      where: $where
      orderBy: expiration
      orderDirection: "asc"
    ) {
      ...OptionWithOrders
    }
  }
`;

export const getOptionWithOrders = gql`
  ${OptionWithOrders}

  query OptionWithOrders($optionId: String!) {
    option(id: $optionId) {
      ...OptionWithOrders
    }
  }
`;

export const getOrdersForOption = gql`
  ${MarketOrder}

  query OrdersForOption($first: Int = 100, $skip: Int = 0, $optionId: String!) {
    marketOrders(
      first: $first
      skip: $skip
      where: { optionId: $optionId, remainingUnfilled_gt: 0 }
    ) {
      ...MarketOrder
    }
  }
`;

export const getOrdersForAccount = gql`
  ${MarketOrder}

  query OrdersForAccount($first: Int = 100, $skip: Int = 0, $account: String!) {
    marketOrders(first: $first, skip: $skip, where: { maker: $account }) {
      ...MarketOrder
    }
  }
`;

export const getMatchingBuyOrders = gql`
  ${MarketOrder}

  query MatchingBuyOrders($optionId: String!, $pricePerUnit: String!) {
    marketOrders(
      where: {
        optionId: $optionId
        pricePerUnit_lte: $pricePerUnit
        remainingUnfilled_gt: 0
        side: "Sell"
      }
      orderBy: pricePerUnit
      orderDirection: asc
    ) {
      ...MarketOrder
    }
  }
`;

export const getMatchingSellOrders = gql`
  ${MarketOrder}

  query MatchingSellOrders($optionId: String!, $pricePerUnit: String!) {
    marketOrders(
      where: {
        optionId: $optionId
        pricePerUnit_gte: $pricePerUnit
        remainingUnfilled_gt: 0
        side: "Buy"
      }
      orderBy: pricePerUnit
      orderDirection: desc
    ) {
      ...MarketOrder
    }
  }
`;

export const getToken = gql`
  ${Token}

  query Token($tokenAddress: String!) {
    token(id: $tokenAddress) {
      ...Token
    }
  }
`;

export const getTokenPair = gql`
  ${TokenPair}

  query TokenPair($id: String!) {
    tokenPair(id: $id) {
      ...TokenPair
    }
  }
`;

export const getTokenPairs = gql`
  ${TokenPair}

  query TokenPairs(
    $denominatorAddress: String!
    $first: Int = 100
    $skip: Int = 0
  ) {
    tokenPairs(
      where: { denominator: $denominatorAddress }
      first: $first
      skip: $skip
    ) {
      ...TokenPair
    }
  }
`;

export const getAccountHistory = gql`
  ${AccountHistoryItem}

  query AccountHistory($account: String!, $first: Int = 100, $skip: Int = 0) {
    accountHistoryItems(
      first: $first
      skip: $skip
      where: { user: $account }
      orderBy: timestamp
      orderDirection: desc
    ) {
      ...AccountHistoryItem
    }
  }
`;

export const getAccount = gql`
  ${Account}

  query Account($account: String!) {
    account(id: $account) {
      ...Account
    }
  }
`;

export const getHistoryItemsForTokenPair = gql`
  ${TokenPairHistoryItem}

  query TokenPairHistoryItems($tokenPair: String!, $start: Int, $end: Int) {
    tokenPairHistoryItems(
      first: 1000
      where: {
        tokenPairId: $tokenPair
        timestamp_gt: $start
        timestamp_lt: $end
      }
      orderBy: timestamp
      orderDirection: desc
    ) {
      ...TokenPairHistoryItem
    }
  }
`;

export const getAllTokenPairHistoryItems = gql`
  ${TokenPairHistoryItem}

  query AllTokenPairHistoryItems($start: Int, $end: Int) {
    tokenPairHistoryItems(
      first: 1000
      where: { timestamp_gt: $start, timestamp_lt: $end }
      orderBy: timestamp
      orderDirection: desc
    ) {
      ...TokenPairHistoryItem
    }
  }
`;
