import React, { useCallback, useEffect, useState } from 'react';
import { Paper, Grid, Box, Button, Typography } from '@material-ui/core';

import { useWeb3 } from 'state/application/hooks';
import { useStakingBalances } from 'state/staking/hooks';
import { useTransact } from 'hooks';
import { formatNumber, formatBigNumber } from 'utils/formatNumber';
import { getRemainingPeriods } from 'utils';
import { StakeMode } from './types';

export interface StakeStatsProps {
  mode: StakeMode;
}

const StakeStats: React.FC<StakeStatsProps> = ({ mode }) => {
  const { contracts, account } = useWeb3();
  const {
    unclaimedPremia,
    xPremiaBalance,
    xPremiaLocked,
    xPremiaFeeDiscount,
    xPremiaStakeWithBonus,
    underlyingPremia,
  } = useStakingBalances();
  const transact = useTransact();

  const [untilUnlock, setUntilUnlock] = useState('0 minutes');

  useEffect(() => {
    const loadInfo = async () => {
      if (!contracts.premiaFeeDiscount) return;

      const { lockedUntil } = await contracts.premiaFeeDiscount.userInfo(
        account,
      );

      if (lockedUntil.toNumber())
        setUntilUnlock(getRemainingPeriods(lockedUntil.toNumber()));
    };
    loadInfo();
  }, [contracts, account]);

  const claimPremia = useCallback(() => {
    if (!contracts.premiaMining) return;
    return transact(contracts.premiaMining.withdraw(0, 0), {
      description: 'Claim PREMIA from interaction mining',
    });
  }, [contracts.premiaMining, transact]);

  return (
    <Box
      clone
      boxShadow={3}
      width={3 / 4}
      borderColor='primary'
      style={{ minWidth: '150px' }}
    >
      <Paper>
        <Grid container direction='column'>
          <Box clone padding={2}>
            <Grid item>
              <Typography variant='h5'>Stats</Typography>

              {mode === StakeMode.Premia && (
                <>
                  <Box marginY={4} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      xPremia Unlocked
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatBigNumber(xPremiaBalance)}
                    </Typography>
                  </Box>

                  <Box marginBottom={2} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      xPremia Locked
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatBigNumber(xPremiaLocked)}
                    </Typography>
                  </Box>

                  <Box marginBottom={2} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      Total xPremia
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatNumber(
                        Number(xPremiaLocked) / 1e18 +
                          Number(xPremiaBalance) / 1e18,
                      )}
                    </Typography>
                  </Box>

                  <Box marginY={4} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      Underlying Premia
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatBigNumber(underlyingPremia)}
                    </Typography>
                  </Box>
                </>
              )}

              {mode === StakeMode.xPremia && (
                <>
                  <Box marginY={4} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      xPremia Locked
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatBigNumber(xPremiaLocked)}
                    </Typography>
                  </Box>

                  <Box marginBottom={2} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      Lock Multiplier
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {isNaN(Number(formatBigNumber(xPremiaStakeWithBonus))) ||
                      Number(formatBigNumber(xPremiaLocked)) === 0
                        ? 0
                        : Number(formatBigNumber(xPremiaStakeWithBonus)) /
                          Number(formatBigNumber(xPremiaLocked))}
                      x
                    </Typography>
                  </Box>

                  <Box marginBottom={2} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      Fee Discount
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                      {formatNumber(Number(xPremiaFeeDiscount) / 1000)}%
                    </Typography>
                  </Box>

                  <Box marginY={4} textAlign='center'>
                    <Typography variant='body2' color='textSecondary'>
                      Until Unlock
                    </Typography>

                    <Typography variant='body1' style={{ fontSize: '1rem' }}>
                      {untilUnlock}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          </Box>

          <Grid item style={{ width: '100%' }}>
            <Button
              fullWidth
              variant='outlined'
              color='secondary'
              style={
                Number(unclaimedPremia) > 0
                  ? { borderRadius: 0 }
                  : { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
              }
              onClick={() =>
                window.open(
                  'https://app.sushi.com/swap?outputCurrency=0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
                  '_blank',
                )
              }
            >
              Buy Premia
            </Button>

            {Number(unclaimedPremia) > 0 && (
              <Button
                fullWidth
                variant='contained'
                color='primary'
                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                onClick={claimPremia}
              >
                Claim Premia
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default StakeStats;
