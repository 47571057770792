import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import { setBondingCurveSettings } from './actions';

export interface BondingCurveSettings {
  premiaAvailable: BigNumber;
  premiaSold: BigNumber;
}

export interface SetBondingCurveSettings {}

export interface BondingCurveState {
  settings: BondingCurveSettings;
}

export const initialState: BondingCurveState = {
  settings: {
    premiaAvailable: BigNumber.from(0),
    premiaSold: BigNumber.from(0),
  },
};

export default createReducer(initialState, (builder) =>
  builder.addCase(
    setBondingCurveSettings,
    (state, { payload }: { payload: SetBondingCurveSettings }) => {
      state.settings = { ...state.settings, ...payload };
    },
  ),
);
