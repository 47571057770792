import React, { useState, useMemo } from 'react';
import {
  makeStyles,
  withStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isEqual } from 'lodash';

import {
  Box,
  Paper,
  Toolbar,
  Typography,
  Grid,
  InputLabel,
  Slider,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { DateRangePicker } from 'react-dates';
import { ethers, BigNumber } from 'ethers';
import { get, uniq } from 'lodash';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { getFilteredOptionsWithOrders } from 'graphql/queries';
import { useFilterSettings } from 'state/market/hooks';
import { initialState } from 'state/market/reducer';
import { useWeb3 } from 'state/application/hooks';
import { OptionType, OptionWithOrders } from 'web3/options';
import { formatCompact } from 'utils/formatNumber';
import { useDebounce } from 'hooks';

import { WalletDenominators, TokenSelect, Loader } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'relative',
  },

  wrapper: {
    margin: '112px 0 0 auto',
    width: '85%',
  },

  wrapperMobile: {
    margin: '20px 0',
    width: '100%',
  },

  title: {
    flex: '1 1 30%',
    marginBottom: 8,
  },
  titleCenter: {
    textAlign: 'center',
    margin: '10px 0 14px',
  },

  negativeMargin: {
    marginBottom: '-16px',
  },

  dateRangePicker: {
    '& .DateRangePicker': {
      width: '100%',
    },

    '& .DateRangePickerInput': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'transparent !important',
    },

    '& .DateInput': {
      width: 'calc(50% - 20px)',
      backgroundColor: 'transparent !important',
    },

    '& .DateInput_input': {
      color: 'white',
      fontFamily: 'Roboto Mono',
      fontWeight: 400,
      fontSize: '0.85rem',
      letterSpacing: 'normal',
      backgroundColor: 'rgba(228, 228, 228, 0.1) !important',
      padding: '4px 8px',
      borderRadius: 8,

      '&::placeholder': {
        fontWeight: 400,
        letterSpacing: 'normal',
        color: theme.palette.text.secondary,
      },
    },

    '& .DateInput_input__focused': {
      borderColor: 'transparent',
    },
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
  },

  resetButton: {
    cursor: 'pointer',
    paddingTop: '16px',
    marginTop: '16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',

    '&:hover': {
      color: 'white',
    },
  },
}));

const ColoredSlider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },

  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,

    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },

  active: {},

  valueLabel: {
    left: 'calc(-50% + 4px)',
  },

  track: {
    height: 8,
    borderRadius: 4,
  },

  rail: {
    height: 8,
    borderRadius: 4,
  },

  markLabel: {
    marginTop: 4,
  },
}))(Slider);

export interface MarketOptionFilterProps {
  hidePair?: boolean;
  center?: boolean;
}

const MarketOptionFilter: React.FC<MarketOptionFilterProps> = ({
  hidePair = false,
  center,
}) => {
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null);
  const { wallet, account } = useWeb3();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    setFilterSettings,
    resetFilterSettings,
    ...activeFilter
  } = useFilterSettings();

  const {
    token,
    expirationRange,
    strikeRange,
    priceRange,
    optionType,
  } = activeFilter;

  const filter = useDebounce(
    useMemo(
      () => ({
        ...(token ? { token: token.address } : {}),
        ...(expirationRange
          ? {
              ...(expirationRange.start
                ? { expiration_gte: expirationRange.start?.unix() }
                : {}),
              ...(expirationRange.end
                ? { expiration_lte: expirationRange.end?.unix() }
                : {}),
            }
          : {}),
        ...(optionType ? { type: optionType } : {}),
      }),
      [token, expirationRange, optionType],
    ),
    250,
  );

  const { loading, data } = useQuery(getFilteredOptionsWithOrders, {
    variables: { first: 100, skip: 0, where: filter },
  });

  const options: OptionWithOrders[] = useMemo(() => get(data, 'options', []), [
    data,
  ]);

  const lowStrike = useMemo(
    () =>
      Number(
        ethers.utils.formatEther(
          options.reduce(
            (min, { strikePrice }) =>
              min.lte(strikePrice) ? min : BigNumber.from(strikePrice),
            BigNumber.from(100000000),
          ),
        ),
      ),
    [options],
  );

  const highStrike = useMemo(
    () =>
      Number(
        ethers.utils.formatEther(
          options.reduce(
            (max, { strikePrice }) =>
              max.gte(strikePrice) ? max : BigNumber.from(strikePrice),
            BigNumber.from(0),
          ),
        ),
      ),
    [options],
  );

  const strikeValues = useMemo(() => {
    const _strikeValues = uniq([
      strikeRange?.low ?? lowStrike,
      strikeRange?.high ?? highStrike,
    ]);

    if (_strikeValues.length < 2) {
      _strikeValues.unshift(0);
    }

    return _strikeValues;
  }, [strikeRange, lowStrike, highStrike]);

  const orders = useDebounce(
    useMemo(
      () =>
        options.reduce(
          (_orders: any, option: any) => [..._orders, ...option.orders],
          [],
        ),
      [options],
    ),
    250,
  );

  const lowPrice = useMemo(
    () =>
      Number(
        ethers.utils.formatEther(
          orders.reduce(
            (min, { pricePerUnit }) =>
              min.lte(pricePerUnit) ? min : BigNumber.from(pricePerUnit),
            BigNumber.from(100000000),
          ),
        ),
      ),
    [orders],
  );

  const highPrice = useMemo(
    () =>
      Number(
        ethers.utils.formatEther(
          orders.reduce(
            (max, { pricePerUnit }) =>
              max.gte(pricePerUnit) ? max : BigNumber.from(pricePerUnit),
            BigNumber.from(0),
          ),
        ),
      ),
    [orders],
  );

  const priceValues = useMemo(() => {
    const _priceValues = uniq([
      priceRange?.low ?? lowPrice,
      priceRange?.high ?? highPrice,
    ]);

    if (_priceValues.length < 2) {
      _priceValues.unshift(0);
    }

    return _priceValues;
  }, [priceRange, lowPrice, highPrice]);

  return (
    <Box className={!center ? classes.wrapper : classes.wrapperMobile}>
      <Box clone width={1} py={2} px={2} boxShadow={3}>
        <Paper className={classes.paper}>
          <Toolbar disableGutters>
            <Typography variant='h6' component='h4' className={classes.title}>
              Filter Available Options
            </Typography>
          </Toolbar>

          {/* top part */}
          <Box
            display='flex'
            flexDirection={!center || mobile ? 'column' : 'row'}
            justifyContent={!center ? 'flex-start' : 'space-between'}
          >
            <Box width={!center || mobile ? '100%' : '48%'}>
              {!hidePair && wallet && wallet.provider && account && (
                <Box marginBottom={2}>
                  <WalletDenominators />
                </Box>
              )}

              <Grid container>
                <InputLabel focused shrink>
                  Order Type
                </InputLabel>

                <ButtonGroup variant='contained' className={classes.orderTypes}>
                  <Box clone width={1 / 3}>
                    <Button
                      variant='contained'
                      color={
                        optionType === OptionType.Call ? 'primary' : 'secondary'
                      }
                      style={{ minWidth: 60 }}
                      onClick={() =>
                        setFilterSettings({ optionType: OptionType.Call })
                      }
                    >
                      Call
                    </Button>
                  </Box>

                  <Box clone width={1 / 3}>
                    <Button
                      variant='contained'
                      color={
                        optionType === OptionType.Put ? 'primary' : 'secondary'
                      }
                      style={{ minWidth: 60 }}
                      onClick={() =>
                        setFilterSettings({ optionType: OptionType.Put })
                      }
                    >
                      Put
                    </Button>
                  </Box>

                  <Box clone width={1 / 3}>
                    <Button
                      variant='contained'
                      color={
                        optionType === null || !optionType
                          ? 'primary'
                          : 'secondary'
                      }
                      style={{ minWidth: 60 }}
                      onClick={() => setFilterSettings({ optionType: null })}
                    >
                      Both
                    </Button>
                  </Box>
                </ButtonGroup>
              </Grid>

              {wallet && wallet.provider && account && (
                <>
                  {loading ? (
                    <Box clone marginTop={2}>
                      <Grid container justify='center'>
                        <Loader />
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      {!hidePair && (
                        <Box width={1} marginTop={2} marginBottom={2}>
                          <Grid container>
                            {!token && (
                              <InputLabel
                                focused
                                shrink
                              >
                                Select Token
                              </InputLabel>
                            )}

                            <TokenSelect
                              selectedToken={token}
                              onSelectToken={(selectedToken) =>
                                setFilterSettings({ token: selectedToken })
                              }
                            />
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}

              <Box width={1} marginTop={2} marginBottom={2}>
                <Grid container className={classes.dateRangePicker}>
                  <InputLabel focused shrink>
                    Expiration
                  </InputLabel>

                  <DateRangePicker
                    noBorder
                    hideKeyboardShortcutsPanel
                    customInputIcon={null}
                    startDate={expirationRange?.start ?? null}
                    startDateId='MarketOptionFilter__startDate'
                    endDate={expirationRange?.end ?? null}
                    endDateId='MarketOptionFilter__endDate'
                    onDatesChange={({ startDate, endDate }) =>
                      setFilterSettings({
                        expirationRange: { start: startDate, end: endDate },
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                  />
                </Grid>
              </Box>
            </Box>

            <Box width={!center || mobile ? '100%' : '48%'}>
              {highStrike !== 0 && (
                <Box width={1} marginTop={!center ? 2 : 0}>
                  <Grid container>
                    <InputLabel focused shrink>
                      Strike Price
                    </InputLabel>

                    <Box width={1} paddingX={2}>
                      <ColoredSlider
                        valueLabelDisplay='off'
                        min={lowStrike === highStrike ? 0 : lowStrike}
                        max={highStrike}
                        marks={strikeValues.map((value) => ({
                          label: formatCompact(value),
                          value,
                        }))}
                        value={strikeValues}
                        onChange={(event, value) => {
                          setFilterSettings({
                            strikeRange: {
                              low: (value as number[])[0],
                              high: (value as number[])[1],
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                </Box>
              )}

              {highPrice !== 0 && (
                <Box width={1} marginBottom={2}>
                  <Grid container>
                    <InputLabel focused shrink>
                      Price
                    </InputLabel>

                    <Box width={1} paddingX={2}>
                      <ColoredSlider
                        valueLabelDisplay='off'
                        min={lowPrice === highPrice ? 0 : lowPrice}
                        max={highPrice}
                        marks={priceValues.map((value) => ({
                          label: formatCompact(value),
                          value,
                        }))}
                        value={priceValues}
                        onChange={(event, value) => {
                          setFilterSettings({
                            priceRange: {
                              low: (value as number[])[0],
                              high: (value as number[])[1],
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                </Box>
              )}

              {!isEqual(initialState.filterSettings, activeFilter) && (
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={classes.resetButton}
                  onClick={resetFilterSettings}
                >
                  Reset All Filters
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default MarketOptionFilter;
