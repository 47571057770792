import React, { useMemo, useEffect } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import moment from 'moment';

import { getFilteredOptions, getTokenPairs } from 'graphql/queries';
import { useOptionSettings } from 'state/options/hooks';
import { Option, OptionType } from 'web3/options';
import { Token, TokenPair } from 'web3/tokens';
import { useDebounce, useExpirations, useDenominatorAddress } from 'hooks';
import { formatBigNumber } from 'utils/formatNumber';

import { WalletDenominators, Loader } from 'components';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../constants';

const HorizontalOptionFilter: React.FC = () => {
  const { register } = useForm();
  const expirations = useExpirations();
  const denominatorAddress = useDenominatorAddress();
  const {
    selectedOption,
    selectedToken,
    selectedExpiration,
    strikePrice,
    optionType,
    setOptionSettings,
  } = useOptionSettings();

  const optionsFilter = useDebounce(
    useMemo(
      () => ({
        denominator: denominatorAddress,
        ...(selectedToken ? { token: selectedToken.address } : {}),
        ...(selectedExpiration
          ? {
              expiration_gte: moment(selectedExpiration).unix(),
              expiration_lte: moment(selectedExpiration).unix(),
            }
          : {}),
        ...(optionType ? { type: optionType } : {}),
      }),
      [selectedToken, selectedExpiration, optionType, denominatorAddress],
    ),
    250,
  );

  const { loading: optionsLoading, data: optionsData } = useQuery(
    getFilteredOptions,
    {
      variables: { first: 100, skip: 0, where: optionsFilter },
    },
  );

  const options: Option[] = useMemo(() => get(optionsData, 'options', []), [
    optionsData,
  ]);

  const allStrikes = useMemo(
    () => options.map((option: Option) => option.strikePrice),
    [options],
  );

  const { loading: loadingTokenPairs, data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      skip: !denominatorAddress,
      variables: { denominatorAddress },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const allTokens: Token[] = useMemo(() => {
    return tokenPairs.map(({ token }) => token);
  }, [tokenPairs]);

  const filteredOption: Option | undefined = useMemo(
    () =>
      options.find((option: Option) => {
        const selectedStrike =
          selectedOption && !strikePrice
            ? Number(formatUnits(selectedOption?.strikePrice, DEFAULT_DECIMALS))
            : Number(strikePrice);
        return (
          Number(formatUnits(option.strikePrice, DEFAULT_DECIMALS)) ===
          selectedStrike
        );
      }),
    [options, strikePrice, selectedOption],
  );

  useEffect(() => {
    const strike = formatUnits(
      filteredOption?.strikePrice ?? '0',
      DEFAULT_DECIMALS,
    );

    if (filteredOption && selectedOption?.id !== filteredOption?.id) {
      setOptionSettings({ selectedOption: filteredOption });
    }

    if (filteredOption && (!strikePrice || strikePrice !== strike)) {
      setOptionSettings({ strikePrice: strike });
    }

    if (!filteredOption && strikePrice) {
      setOptionSettings({ strikePrice: null });
    }
  }, [
    optionsLoading,
    strikePrice,
    selectedOption,
    filteredOption,
    setOptionSettings,
  ]);

  return (
    <Box ml='auto' width='100%' marginBottom={3}>
      <Box width={1}>
        <Grid container alignItems='center'>
          <Grid item xs={3}>
            <WalletDenominators />
          </Grid>

          <Grid item xs={1} />

          <Grid item container xs={8} wrap='nowrap'>
            <Box
              clone
              width={allStrikes.length < 1 ? 1 / 3 : 1 / 4}
              style={{ marginRight: '0.5rem' }}
            >
              <FormControl>
                <InputLabel shrink>
                  {loadingTokenPairs ? <Loader /> : 'Select Token'}
                </InputLabel>
                <Select
                  disableUnderline
                  name='token'
                  ref={register}
                  value={selectedToken?.address ?? ''}
                  onChange={(event) =>
                    setOptionSettings({
                      selectedToken: allTokens.find(
                        (token: Token) => token.address === event.target.value,
                      ),
                      strikePrice: null,
                    })
                  }
                >
                  {allTokens.map((token) => (
                    <MenuItem value={token.address} key={token.address}>
                      {token.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {expirations.length > 0 && 
              <Box
                clone
                width={allStrikes.length < 1 ? 1 / 3 : 1 / 4}
                style={{ marginRight: '0.5rem' }}
              >
                <FormControl>
                  <InputLabel shrink>Expiration</InputLabel>

                  <Select
                  disableUnderline
                  name='expiration'
                  ref={register}
                  value={moment(selectedExpiration).format('YYYYMMMDD') ?? ''}
                >
                  {expirations.map((expiration) => (
                    <MenuItem
                      value={moment(expiration).format('YYYYMMMDD')}
                      key={expiration.toTimeString()}
                      onClick={() =>
                        setOptionSettings({
                          selectedExpiration: expiration,
                          strikePrice: null,
                        })
                      }
                    >
                      {moment(expiration).format('YYYYMMMDD').toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Box>
            }

            <Box
              clone
              width={allStrikes.length < 1 ? 1 / 3 : 1 / 4}
              style={{ marginRight: '0.5rem' }}
            >
              <FormControl>
                <InputLabel shrink>Call/Put</InputLabel>

                <Select
                  disableUnderline
                  name='optionType'
                  ref={register}
                  value={optionType ?? ''}
                  onChange={(event) =>
                    setOptionSettings({
                      optionType: event.target.value as OptionType,
                      strikePrice: null,
                    })
                  }
                >
                  <MenuItem value={OptionType.Call}>CALL</MenuItem>
                  <MenuItem value={OptionType.Put}>PUT</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {optionsLoading ? (
              <Loader />
            ) : allStrikes.length < 1 ? null : (
              <Box clone width={1 / 4}>
                <FormControl>
                  <InputLabel shrink>Strike Price</InputLabel>

                  <Select
                    disableUnderline
                    name='strikePrice'
                    ref={register}
                    value={strikePrice || ''}
                  >
                    {allStrikes.map((strike) => (
                      <MenuItem
                        value={formatUnits(strike, DEFAULT_DECIMALS)}
                        key={strike.toString()}
                        onClick={() =>
                          setOptionSettings({
                            strikePrice: formatUnits(strike, DEFAULT_DECIMALS),
                          })
                        }
                      >
                        {formatBigNumber(strike)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HorizontalOptionFilter;
