import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';

import { getOptionBalancesOfAccount } from 'graphql/queries';
import {
  OptionBalanceWithStatus,
  OptionType,
  OptionStatus,
  Option,
} from 'web3/options';
import { useWithdrawSettings } from 'state/options/hooks';
import { WithdrawType } from 'state/options/reducer';
import { formatCompact } from 'utils/formatNumber';
import { useWeb3, usePrices } from 'state/application/hooks';

import { DataTable, TradeOptionModal, CurrencyLogo } from 'components';
import { HeadCell } from 'components/DataTable';
import TransferOptionModal from '../TransferOptionModal';
import ExerciseOptionModal from '../ExerciseOptionModal';
import WithdrawOptionModal from '../WithdrawOptionModal';
import WalletTableToolbar from './WalletTableToolbar';
import { formatUnits } from 'ethers/lib/utils';
import { DEFAULT_DECIMALS } from '../../../../constants';
import { getOptionBalancesWithStatus } from 'utils';

const headCells: HeadCell<OptionBalanceWithStatus>[] = [
  {
    id: 'expiration',
    numeric: false,
    label: 'Expiration',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      optionBalance?.option.expiration.toString(),
  },
  {
    id: 'pair',
    numeric: false,
    label: 'Pair',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      `${optionBalance?.option.token}/${optionBalance.option.denominator}`,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      optionBalance?.option.type.toString(),
  },
  {
    id: 'strike',
    numeric: true,
    label: 'Strike',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      optionBalance?.option.strikePrice.toString(),
  },
  {
    id: 'balance',
    numeric: true,
    label: 'Balance',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      optionBalance?.balance.toString(),
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    sortKey: (optionBalance: OptionBalanceWithStatus) =>
      optionBalance?.optionStatus.toString(),
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    sortKey: (optionBalance: OptionBalanceWithStatus) => 1,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  thinFont: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
  },

  thickFont: {
    fontWeight: 700,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },
}));

export default function OpenPositionsTable() {
  const classes = useStyles();

  const { account } = useWeb3();
  const prices = usePrices();
  const { setWithdrawSettings } = useWithdrawSettings();
  const [anchorEl, setAnchorEl] = useState<null | {
    element: HTMLElement;
    optionBalanceId: string;
  }>(null);
  const [
    currentOption,
    setCurrentOption,
  ] = useState<null | OptionBalanceWithStatus>(null);
  const [defaultTradePanel, setDefaultTradePanel] = useState<'trade' | 'mint'>(
    'trade',
  );
  const [optionToTrade, setOptionToTrade] = useState<null | Option>(null);
  const [
    optionToTransfer,
    setOptionToTransfer,
  ] = useState<null | OptionBalanceWithStatus>(null);
  const [
    optionToWithdraw,
    setOptionToWithdraw,
  ] = useState<null | OptionBalanceWithStatus>(null);
  const [
    optionToExercise,
    setOptionToExercise,
  ] = useState<null | OptionBalanceWithStatus>(null);
  const [skip, setSkip] = useState(0);
  const [result, setResult] = useState<any[]>([]);

  const [loadData, { loading: optionsLoading, data, fetchMore }] = useLazyQuery(
    getOptionBalancesOfAccount,
    {
      variables: { first: 100, skip, account: account.toLowerCase() },
    },
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionBalances: OptionBalanceWithStatus[] = useMemo(
    () => {
      if (!!data?.optionBalances?.length) {
        setResult(data?.optionBalances || []);
      }
      return getOptionBalancesWithStatus(data ? data.optionBalances : [], prices); 
    },
    [data, prices],
  );

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    option: OptionBalanceWithStatus,
  ) => {
    setCurrentOption(option);
    setAnchorEl({ element: event.currentTarget, optionBalanceId: option.id });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (isFirstPage: boolean) => {
    fetchMore({
      variables: {
        skip: isFirstPage ? 0 : skip + 100,
      },
      updateQuery: (previousResult, currentResult) => {
        return {
          optionBalances: isFirstPage
            ? currentResult?.fetchMoreResult?.optionBalances
            : [...result, ...currentResult?.fetchMoreResult?.optionBalances
            ],
        };
      },
    });
    setSkip(isFirstPage ? 0: skip + 100);
  };

  return (
    <DataTable
      headCells={headCells}
      data={optionBalances}
      size={optionBalances.length}
      onChange={onChange}
      loading={optionsLoading}
      toolbar={
        <>
          {optionToTrade && (
            <TradeOptionModal
              open
              option={optionToTrade}
              defaultPanel={defaultTradePanel}
              onClose={() => setOptionToTrade(null)}
            />
          )}

          {optionToExercise && (
            <ExerciseOptionModal
              open
              optionBalance={optionToExercise}
              onClose={() => setOptionToExercise(null)}
            />
          )}

          {optionToWithdraw && (
            <WithdrawOptionModal
              open
              optionBalance={optionToWithdraw}
              onClose={() => setOptionToWithdraw(null)}
            />
          )}

          {optionToTransfer && (
            <TransferOptionModal
              open
              optionBalance={optionToTransfer}
              onClose={() => setOptionToTransfer(null)}
            />
          )}

          <WalletTableToolbar optionBalances={optionBalances} />
        </>
      }
      caption='* Highlighted options may be In the Money (ITM).'
      renderRow={(optionBalance: OptionBalanceWithStatus, index: number) => {
        const { balance, option, optionStatus } = optionBalance;
        const { token, denominator, expiration, strikePrice, type } = option;

        const isExpired = currentOption
          ? moment() > moment(Number(currentOption.option.expiration) * 1000)
          : false;

        return (
          <TableRow hover tabIndex={-1} key={index}>
            <TableCell
              component='th'
              scope='row'
              align='center'
              className={classes.thinFont}
            >
              {moment(Number(expiration.toString()) * 1000).format(
                'MMM. Do, YYYY',
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid container wrap='nowrap'>
                <span
                  className={cx(
                    type === OptionType.Call
                      ? classes.callGreen
                      : classes.putRed,
                  )}
                >
                  <b>{token.symbol}</b>
                </span>
                -<span>{denominator.symbol}</span>
              </Grid>
            </TableCell>

            <TableCell align='center'>
              {type === OptionType.Call ? (
                <span className={classes.callGreen}>CALL</span>
              ) : (
                <span className={classes.putRed}>PUT</span>
              )}
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(
                    formatUnits(strikePrice.toString(), DEFAULT_DECIMALS),
                  )}
                </span>

                <CurrencyLogo currency={denominator} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              <Grid
                container
                justify='center'
                alignItems='center'
                wrap='nowrap'
              >
                <span style={{ marginRight: '0.25rem' }}>
                  {formatCompact(formatUnits(balance, token.decimals))}
                </span>

                <CurrencyLogo currency={token} size='16px' />
              </Grid>
            </TableCell>

            <TableCell align='center'>
              {optionStatus === OptionStatus.POTENTIALLY_ITM ? (
                <span className={classes.callGreen}>{optionStatus}</span>
              ) : optionStatus === OptionStatus.EXPIRING_SOON ? (
                <span className={classes.putRed}>{optionStatus}</span>
              ) : (
                <span>{optionStatus}</span>
              )}
            </TableCell>

            <TableCell align='center'>
              <IconButton
                onClick={(event) => handleOpenMenu(event, optionBalance)}
              >
                <Add />
              </IconButton>

              <Menu
                keepMounted
                anchorEl={anchorEl?.element}
                open={anchorEl?.optionBalanceId === optionBalance.id}
                onClose={handleCloseMenu}
              >
                {isExpired ? (
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setOptionToWithdraw(optionBalance);
                      setWithdrawSettings({
                        type: WithdrawType.WithdrawExpired,
                      });
                    }}
                  >
                    Withdraw Expired
                  </MenuItem>
                ) : (
                  [
                    <MenuItem
                      key='trade'
                      onClick={() => {
                        handleCloseMenu();
                        setDefaultTradePanel('trade');
                        setOptionToTrade(option);
                      }}
                    >
                      Trade on Marketplace
                    </MenuItem>,

                    <MenuItem
                      key='exercise'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToExercise(optionBalance);
                      }}
                    >
                      Exercise Option
                    </MenuItem>,

                    <MenuItem
                      key='mint'
                      onClick={() => {
                        handleCloseMenu();
                        setDefaultTradePanel('mint');
                        setOptionToTrade(option);
                      }}
                    >
                      Mint Additional
                    </MenuItem>,

                    <MenuItem
                      key='earlyassignment'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToWithdraw(optionBalance);
                        setWithdrawSettings({
                          type: WithdrawType.TakeEarlyAssignment,
                        });
                      }}
                    >
                      Take Early Assignment
                    </MenuItem>,

                    <MenuItem
                      key='burn'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToWithdraw(optionBalance);
                        setWithdrawSettings({
                          type: WithdrawType.BurnEarly,
                        });
                      }}
                    >
                      Burn to Withdraw
                    </MenuItem>,

                    <MenuItem
                      key='transfer'
                      onClick={() => {
                        handleCloseMenu();
                        setOptionToTransfer(optionBalance);
                      }}
                    >
                      Transfer
                    </MenuItem>,
                  ]
                )}
              </Menu>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
