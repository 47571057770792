import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  Fab,
  OutlinedInput,
  InputLabel,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { ethers, BigNumber } from 'ethers';
import moment from 'moment';
import cx from 'classnames';

import { useWithdrawSettings } from 'state/options/hooks';
import { WithdrawType } from 'state/options/reducer';
import { useWeb3 } from 'state/application/hooks';
import { useTransact } from 'hooks';
import { getOptionContract } from 'web3/contracts';
import { OptionBalance, OptionType } from 'web3/options';
import { TokenDenominator } from 'web3/tokens';
import { formatBigNumber, formatNumber } from 'utils/formatNumber';

import { ModalContainer, OptionTitle } from 'components';
import { formatEther, formatUnits } from 'ethers/lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 300,
  },

  thick: {
    fontWeight: 500,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  fieldLabel: {
    width: '300px',
    textAlign: 'left',
    paddingBottom: '8px',
  },

  spacer: {
    width: '25%',
  },

  fieldRow: {
    width: '100%',
    marginTop: 24,
    marginBottom: 64,
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '103%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  greenText: {
    color: theme.palette.success.main,
  },
}));

export interface WithdrawOptionModalProps {
  open: boolean;
  optionBalance: OptionBalance;
  onClose: () => void;
}

const WithdrawOptionModal: React.FC<WithdrawOptionModalProps> = ({
  open,
  optionBalance,
  onClose,
}) => {
  const { contracts } = useWeb3();
  const { register } = useForm();
  const transact = useTransact();
  const classes = useStyles();

  const { type, quantity, setWithdrawSettings } = useWithdrawSettings();

  const decimals = optionBalance.option.token.decimals;

  const claimable =
    Number(formatUnits(optionBalance.option.supply, decimals)) +
    Number(formatUnits(optionBalance.option.exercised, decimals)) -
    Number(formatUnits(optionBalance.option.claimsPreExp, decimals));

  const ownershipPct =
    claimable > 0
      ? Number(formatUnits(optionBalance.nbWritten, decimals)) / claimable
      : 0;
  const tokenToExpect =
    ownershipPct *
    Number(formatUnits(optionBalance.option.pool.tokenAmount, decimals));
  const denominatorToExpect =
    ownershipPct *
    Number(formatEther(optionBalance.option.pool.denominatorAmount));

  const hasSufficientBalance = Number(quantity) <= Number(formatUnits(optionBalance.balance, optionBalance.option.token.decimals));

  const optionContract = useMemo(
    () =>
      getOptionContract(
        contracts,
        TokenDenominator[
          optionBalance.option.denominator
            .symbol as keyof typeof TokenDenominator
        ],
      ),
    [contracts, optionBalance],
  );

  const maxQuantity = Number(
    formatUnits(
      optionBalance.balance,
      optionBalance.option.token.decimals,
    ),
  );

  const handleWithdraw = useCallback(async () => {
    await transact(optionContract?.withdraw(optionBalance.option.id), {
      option: optionBalance.option,
      description: `Withdrawing all ${optionBalance.option.token.symbol} and ${
        optionBalance.option.denominator.symbol
      } collateral from option${
        Number(optionBalance.nbWritten) === 1 ? '' : 's'
      }`,
    });

    onClose();
  }, [optionBalance, optionContract, transact, onClose]);

  const handleTakeAssignment = useCallback(async () => {
    if (!quantity) return;

    const collateralSymbol =
      optionBalance.option.type === OptionType.Call
        ? optionBalance.option.denominator.symbol
        : optionBalance.option.token.symbol;

    await transact(
      optionContract?.withdrawPreExpiration(
        optionBalance.option.id,
        ethers.utils.parseUnits(quantity, optionBalance.option.token.decimals),
      ),
      {
        option: optionBalance.option,
        description: `Taking early assignment on ${quantity} options to withdraw ${collateralSymbol} collateral`,
      },
    );

    onClose();
  }, [optionBalance, optionContract, quantity, transact, onClose]);

  const handleBurn = useCallback(async () => {
    if (!quantity) return;

    const collateralSymbol =
      optionBalance.option.type === OptionType.Call
        ? optionBalance.option.token.symbol
        : optionBalance.option.denominator.symbol;

    await transact(
      optionContract?.cancelOption(
        optionBalance.option.id,
        ethers.utils.parseUnits(quantity, optionBalance.option.token.decimals),
      ),
      {
        option: optionBalance.option,
        description: `Burning ${quantity} options to withdraw ${collateralSymbol} collateral`,
      },
    );

    onClose();
  }, [optionBalance, optionContract, quantity, transact, onClose]);

  let depositAmount;
  if (type === WithdrawType.WithdrawExpired) {
    if (optionBalance.option.type === 'CALL') {
      depositAmount = Number(formatUnits(optionBalance.nbWritten, decimals));
    } else {
      depositAmount =
        Number(formatUnits(optionBalance.nbWritten, decimals)) *
        Number(formatEther(optionBalance.option.strikePrice));
    }
  } else {
    if (optionBalance.option.type === 'CALL') {
      depositAmount = Number(quantity);
    } else {
      depositAmount =
        Number(quantity) *
        Number(formatEther(optionBalance.option.strikePrice));
    }
  }

  let receiveAmount = 0;
  if (type !== WithdrawType.WithdrawExpired) {
    if (optionBalance.option.type === 'CALL') {
      receiveAmount =
        Number(quantity) *
        Number(formatEther(optionBalance.option.strikePrice));
    } else {
      receiveAmount = Number(quantity);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='lg'>
        <Box marginBottom={4}>
          <OptionTitle option={optionBalance.option} />
        </Box>

        <Grid container justify='center'>
          <Box clone width='60%'>
            <Paper>
              <Grid
                item
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Box marginTop={4} marginBottom={2}>
                  <Typography align='center'>
                    You have minted{' '}
                    {formatUnits(optionBalance.nbWritten.toString(), decimals)}{' '}
                    of these options
                  </Typography>
                </Box>

                <Box marginBottom={4}>
                  <Typography align='center'>
                    You hold{' '}
                    {formatUnits(optionBalance.balance.toString(), decimals)} of
                    these options
                  </Typography>
                </Box>

                {type === WithdrawType.TakeEarlyAssignment ? (
                  <Box marginBottom={4}>
                    <Typography align='center'>
                      {formatNumber(
                        Number(
                          formatUnits(optionBalance.option.exercised, decimals),
                        ) -
                          Number(
                            formatUnits(
                              optionBalance.option.claimsPreExp,
                              decimals,
                            ),
                          ),
                      )}
                      {' options available to take early assignment'}
                    </Typography>
                  </Box>
                ) : undefined}

                {type !== WithdrawType.WithdrawExpired && (
                  <Box clone width={1}>
                    <Grid container justify='center'>
                      <Grid item container justify='center' direction='row'>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.fieldLabel}
                        >
                          Withdraw Type
                        </Typography>{' '}
                        <div className={classes.spacer} />
                      </Grid>

                      <ButtonGroup
                        variant='contained'
                        className={classes.orderTypes}
                      >
                        <Box clone width={1 / 3}>
                          <Button
                            variant='contained'
                            color={
                              type === WithdrawType.TakeEarlyAssignment
                                ? 'primary'
                                : 'secondary'
                            }
                            style={{ minWidth: 100 }}
                            onClick={() =>
                              setWithdrawSettings({
                                type: WithdrawType.TakeEarlyAssignment,
                              })
                            }
                          >
                            Take Assignment
                          </Button>
                        </Box>
                        ,
                        <Box clone width={1 / 3}>
                          <Button
                            variant='contained'
                            color={
                              type === WithdrawType.BurnEarly
                                ? 'primary'
                                : 'secondary'
                            }
                            style={{ minWidth: 100 }}
                            onClick={() =>
                              setWithdrawSettings({
                                type: WithdrawType.BurnEarly,
                              })
                            }
                          >
                            Burn
                          </Button>
                        </Box>
                      </ButtonGroup>
                    </Grid>
                  </Box>
                )}

                <Grid container justify='center'>
                  {type !== WithdrawType.WithdrawExpired && (
                    <Box
                      width={3 / 4}
                      marginTop={2}
                      marginBottom={6}
                      position='relative'
                    >
                      <InputLabel>Quantity</InputLabel>
                      <FormControl style={{ width: '100%' }} variant='outlined'>
                        <OutlinedInput
                          name='quantity'
                          type='number'
                          value={quantity || ''}
                          inputRef={register}
                          inputProps={
                            optionBalance.option.token.decimals
                              ? {
                                  step:
                                    1 *
                                    10 ** -optionBalance.option.token.decimals,
                                }
                              : {}
                          }
                          onChange={(event) => {
                            setWithdrawSettings({
                              quantity: event.target.value,
                            });  
                          }}
                        />
                        {
                          quantity &&
                            <Typography
                              className={cx(Number(quantity) > 0 && hasSufficientBalance ? classes.greenText : classes.putRed, classes.fieldStatus, classes.desktopFieldStatus)}
                              variant='body2'
                            >
                              { Number(quantity) > 0 && hasSufficientBalance ? 'Valid' : 'Invalid' }
                            </Typography>
                        }
                        <Fab
                          variant='extended'
                          color='primary'
                          size='small'
                          onClick={() => setWithdrawSettings({ quantity: maxQuantity.toString() }) }
                        >
                          MAX
                        </Fab>
                      </FormControl>
                    </Box>
                  )}

                  {type === WithdrawType.WithdrawExpired && (
                    <Box width={1 / 2} marginBottom={6}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => handleWithdraw().then(onClose)}
                      >
                        Withdraw Expired
                      </Button>
                    </Box>
                  )}

                  {type === WithdrawType.TakeEarlyAssignment &&
                    (!hasSufficientBalance ? (
                      <Box width={1 / 2} marginBottom={6}>
                        <Button
                          fullWidth
                          disabled
                          variant='contained'
                          color='primary'
                        >
                          Insufficient Balance
                        </Button>
                      </Box>
                    ) : (
                      <Box width={1 / 2} marginBottom={6}>
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          onClick={() => handleTakeAssignment().then(onClose)}
                        >
                          Confirm
                        </Button>
                      </Box>
                    ))}

                  {type === WithdrawType.BurnEarly &&
                    (Number(quantity) >
                    Number(
                      formatUnits(
                        optionBalance.balance,
                        optionBalance.option.token.decimals,
                      ),
                    ) ? (
                      <Box width={1 / 2} marginBottom={6}>
                        <Button
                          fullWidth
                          disabled
                          variant='contained'
                          color='primary'
                        >
                          Insufficient Balance
                        </Button>
                      </Box>
                    ) : (
                      <Box width={1 / 2} marginBottom={6}>
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          onClick={() => handleBurn().then(onClose)}
                        >
                          Confirm
                        </Button>
                      </Box>
                    ))}
                </Grid>
              </Grid>

              <Box width={1} paddingBottom={2} textAlign='center'>
                <a
                  href='https://premia.medium.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Learn how withdrawals work
                </a>
              </Box>
            </Paper>
          </Box>

          {(quantity || type === WithdrawType.WithdrawExpired) && (
            <Box clone marginLeft={4} width='35%'>
              <Paper>
                <Box clone width={1} height='100%' padding={4}>
                  <Grid
                    container
                    direction='column'
                    justify='space-around'
                    alignItems='center'
                  >
                    <Box marginBottom={4}>
                      <Typography align='center'>You deposited:</Typography>

                      <Typography align='center'>
                        <b>{formatNumber(depositAmount)}</b>{' '}
                        <span className={classes.putRed}>
                          {optionBalance.option.type === 'CALL'
                            ? optionBalance.option.token.symbol
                            : optionBalance.option.denominator.symbol}
                        </span>
                      </Typography>
                    </Box>

                    <Box marginBottom={4}>
                      <Typography align='center'>You will receive:</Typography>

                      {type === WithdrawType.BurnEarly ? (
                        <Typography align='center'>
                          <b>{formatNumber(depositAmount)}</b>{' '}
                          <span className={classes.callGreen}>
                            {optionBalance.option.type === 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                        </Typography>
                      ) : undefined}

                      {type === WithdrawType.TakeEarlyAssignment ? (
                        <Typography align='center'>
                          <b>{formatNumber(receiveAmount)}</b>{' '}
                          <span className={classes.callGreen}>
                            {optionBalance.option.type !== 'CALL'
                              ? optionBalance.option.token.symbol
                              : optionBalance.option.denominator.symbol}
                          </span>
                        </Typography>
                      ) : undefined}

                      {type === WithdrawType.WithdrawExpired ? (
                        <>
                          <Typography align='center'>
                            <b>{formatNumber(tokenToExpect)}</b>{' '}
                            <span className={classes.callGreen}>
                              {optionBalance.option.token.symbol}
                            </span>
                          </Typography>

                          <Typography align='center'>
                            <b>{formatNumber(denominatorToExpect)}</b>{' '}
                            <span className={classes.callGreen}>
                              {optionBalance.option.denominator.symbol}
                            </span>
                          </Typography>
                        </>
                      ) : undefined}
                    </Box>

                    <Box clone width={1}>
                      <Grid
                        container
                        direction='row'
                        justify='space-between'
                        wrap='nowrap'
                      >
                        <Grid container item direction='column'>
                          <div className={classes.thin}>Total Minted</div>
                          <div className={classes.thin}>Exercised</div>
                          <div className={classes.thin}>Deposited DAI</div>
                          <div className={classes.thin}>Taken Assignment</div>
                          <div className={classes.thin}>Expired</div>
                        </Grid>

                        <Grid
                          container
                          item
                          direction='column'
                          alignItems='flex-end'
                        >
                          <div className={classes.thick}>
                            {formatNumber(
                              formatUnits(
                                BigNumber.from(optionBalance.option.supply).add(
                                  optionBalance.option.exercised,
                                ),
                                optionBalance.option.token.decimals,
                              ),
                            )}
                          </div>
                          <div className={classes.thick}>
                            {formatBigNumber(
                              optionBalance.option.exercised,
                              true,
                              {},
                              optionBalance.option.token.decimals,
                            )}
                          </div>
                          <div className={classes.thick}>
                            {formatBigNumber(
                              optionBalance.option.pool.denominatorAmount,
                            )}
                          </div>
                          <div className={classes.thick}>
                            {formatBigNumber(
                              optionBalance.option.claimsPreExp,
                              true,
                              {},
                              optionBalance.option.token.decimals,
                            )}
                          </div>
                          <div className={classes.thick}>
                            {moment() >
                            moment(
                              Number(optionBalance.option.expiration) * 1000,
                            )
                              ? 'Yes'
                              : 'No'}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}
        </Grid>
      </ModalContainer>
    </Modal>
  );
};

export default WithdrawOptionModal;
