import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  Hidden,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import { getTokenPairs } from 'graphql/queries';
import { TokenPair, Token } from 'web3/tokens';
import { OptionType } from 'web3/options';
import { useWeb3 } from 'state/application/hooks';
import { useFilterSettings } from 'state/market/hooks';
import { useDenominatorAddress, useExpirations } from 'hooks';

import { Loader } from 'components';
import SpaceBullBearHero from 'assets/svg/SpaceBullBearHero.svg';

const useStyles = makeStyles({

  heroBox: {
    padding: '42px',
  },

  heroImage: {
    borderRadius: 30,
    height: '100%',
    width: '100%',
  },

  title: {
    fontFamily: '"Poppins"',
    fontSize: '3vw',
    fontWeight: 600,
  },

  subtitle: {
    fontFamily: '"Inter"',
    fontSize: '1rem',
  },

  filterContainer: {
    position: 'absolute',
    bottom: 0,
    '& .MuiSelect-root': {
      borderRadius: 0
    },
    '& .MuiGrid-item:first-child .MuiSelect-root': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    }
  },
  
  fullWidth: {
    width: '100%',
    borderRadius: 0,
  },

  button: {
    height: 58,
    marginTop: -5,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  }
});

const ExploreHero: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const { wallet, account } = useWeb3();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  const expirations = useExpirations();
  const denominatorAddress = useDenominatorAddress();

  const {
    token,
    expirationRange,
    optionType,
    setFilterSettings,
  } = useFilterSettings();

  const { loading: loadingTokenPairs, data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      skip: !denominatorAddress,
      variables: { denominatorAddress },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const allTokens: Token[] = useMemo(() => {
    return tokenPairs.map(({ token }) => token);
  }, [tokenPairs]);

  const onSubmit = useCallback(() => history.push('/marketplace'), [history]);

  return (
    <Box width={1} position='relative' mb={4}>
      <img
        src={SpaceBullBearHero}
        alt='Hero'
        className={classes.heroImage}
        onLoad={() => setLoading(false)}
      />

      {loading ? null : (
        <>
          <Box
            position='absolute'
            p={{ sm: 4, md: 8 }}
            top={0}
            left={0}
            width={1}
            height='100%'
            className={classes.heroBox}
          >
            <Typography
              component='h1'
              variant='h2'
              color='textPrimary'
              className={classes.title}
            >
              Explore Premia
            </Typography>

            <Typography
              component='h3'
              color='textPrimary'
              className={classes.subtitle}
            >
              Find the right option for you
            </Typography>
          </Box>

          <Hidden smDown>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container className={classes.filterContainer}>
                {wallet && wallet.provider && account && (
                  <Grid item sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel variant='filled'>
                        {loadingTokenPairs ? <Loader /> : 'Select Token'}
                      </InputLabel>

                      <Select
                        name='token'
                        variant='filled'
                        ref={register}
                        value={token || 'All Tokens'}
                        onChange={(event) =>
                          setFilterSettings({
                            token:
                              event.target.value === 'All Tokens'
                                ? null
                                : (event.target.value as Token),
                          })
                        }
                      >
                        <MenuItem value={'All Tokens'}>All Tokens</MenuItem>

                        {allTokens.map((token) => (
                          <MenuItem value={token as any} key={token.address}>
                            {token.symbol}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid
                  item
                  sm={wallet && wallet.provider && account ? 3 : 4}
                >
                  <FormControl className={classes.fullWidth}>
                    <InputLabel variant='filled' shrink>Expiration</InputLabel>

                    <Select
                      name='expiration'
                      variant='filled'
                      ref={register}
                      value={
                        expirationRange?.end?.toISOString() ||
                        'All Expirations'
                      }
                      onChange={(event) =>
                        setFilterSettings({
                          expirationRange:
                            event.target.value !== 'All Expirations'
                              ? {
                                  start: moment(),
                                  end: moment(event.target.value as string),
                                }
                              : null,
                        })
                      }
                    >
                      <MenuItem value={'All Expirations'}>
                        {'All Expirations'}
                      </MenuItem>

                      {expirations.map((expiration) => (
                        <MenuItem
                          value={moment(expiration).toISOString()}
                          key={expiration.toTimeString()}
                        >
                          &#60; {moment(expiration).diff(moment(), 'days')}{' '}
                          days
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  sm={wallet && wallet.provider && account ? 3 : 4}
                >
                  <FormControl className={classes.fullWidth}>
                    <InputLabel variant='filled'>Call/Put</InputLabel>

                    <Select
                      name='optionType'
                      variant='filled'
                      ref={register}
                      value={optionType || 'Both'}
                      onChange={(event) =>
                        setFilterSettings({
                          optionType:
                            event.target.value === 'Both'
                              ? null
                              : (event.target.value as OptionType),
                        })
                      }
                    >
                      <MenuItem value={'Both'}>Both</MenuItem>
                      <MenuItem value={OptionType.Call}>Call</MenuItem>
                      <MenuItem value={OptionType.Put}>Put</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  sm={wallet && wallet.provider && account ? 3 : 4}
                >
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    className={cx(classes.button, classes.fullWidth)}
                  >
                    Find Options
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Hidden>
        </>
      )}
    </Box>
  );
};

export default ExploreHero;
