import { BigNumber, BigNumberish } from 'ethers';

import { TokenPair, Token } from './tokens';
import { MarketOrder } from './market';

export enum OptionType {
  Call = 'CALL',
  Put = 'PUT',
}

export enum OptionStatus {
  OPEN = 'Open',
  POTENTIALLY_ITM = 'Potentially ITM',
  EXPIRING_SOON = 'Expiring Soon',
}
export interface OptionPool {
  id: string;
  tokenAmount: BigNumber;
  denominatorAmount: BigNumber;
}

export interface OptionDetails {
  id?: BigNumber;
  token: Token | string;
  denominator: Token | string;
  expiration: BigNumberish;
  strikePrice: BigNumber;
  type: OptionType;
  pair?: TokenPair;
}

export interface OptionDetailsWithId extends OptionDetails {
  id: BigNumber;
}

export interface Option extends OptionDetailsWithId {
  id: BigNumber;
  contractAddress: string;
  decimals: number;
  token: Token;
  denominator: Token;
  strikePrice: BigNumber;
  expiration: BigNumber;
  type: OptionType;
  claimsPreExp: BigNumber;
  claimsPostExp: BigNumber;
  exercised: BigNumber;
  supply: BigNumber;
  openInterest: BigNumber;
  totalVolume: BigNumber;

  pool: OptionPool;
  pair: TokenPair;
}

export interface OptionWithOrders extends Option {
  orders: [MarketOrder];
}

export interface OptionBalance {
  id: string;
  option: Option;
  nbWritten: BigNumber;
  balance: BigNumber;
}

export interface OptionBalanceWithStatus {
  id: string;
  option: Option;
  nbWritten: BigNumber;
  balance: BigNumber;
  optionStatus: OptionStatus;
}

export interface OptionData {
  token: string;
  decimals: number;
  strikePrice: BigNumber;
  expiration: BigNumber;
  isCall: boolean;
  claimsPreExp: BigNumber;
  claimsPostExp: BigNumber;
  exercised: BigNumber;
  supply: BigNumber;
}

export function convertOptionToOptionData(option: Option): OptionData {
  return {
    token: option.token.address,
    decimals: option.decimals,
    strikePrice: option.strikePrice,
    expiration: option.expiration,
    isCall: option.type === OptionType.Call,
    claimsPreExp: option.claimsPreExp,
    claimsPostExp: option.claimsPostExp,
    exercised: option.exercised,
    supply: option.supply,
  };
}
