import { BigNumber, BigNumberish } from 'ethers';
import { BytesLike } from '@ethersproject/bytes';

import { Token, TokenPair } from './tokens';
import { Option } from './options';

export enum SaleSide {
  Buy = 'Buy',
  Sell = 'Sell',
}

export interface MarketOrder {
  id: string;
  hash: BytesLike;
  maker: string;
  side: SaleSide;
  pair: TokenPair;
  option: Option;
  optionContract: string;
  optionId: BigNumber;
  paymentToken: Token;
  pricePerUnit: BigNumber;
  expirationTime: BigNumber;
  isDelayedWriting: boolean;
  salt: BigNumber;
  decimals: number;
  amount: BigNumber;
  amountFilled: BigNumber;
  remainingUnfilled: BigNumber;
}

export interface MarketOrderWithValid {
  id: string;
  hash: BytesLike;
  maker: string;
  side: SaleSide;
  pair: TokenPair;
  option: Option;
  optionContract: string;
  optionId: BigNumber;
  paymentToken: Token;
  pricePerUnit: BigNumber;
  expirationTime: BigNumber;
  isDelayedWriting: boolean;
  salt: BigNumber;
  decimals: number;
  amount: BigNumber;
  amountFilled: BigNumber;
  remainingUnfilled: BigNumber;
  valid: boolean;
}

export interface IOrderCreateProps {
  maker: string;
  side: number;
  optionId: BigNumberish;
  optionContract: string;
  paymentToken: string;
  pricePerUnit: BigNumberish;
  isDelayedWriting: boolean;
}

export interface IOrder {
  maker: string;
  side: BigNumberish;
  optionId: BigNumberish;
  optionContract: string;
  paymentToken: string;
  pricePerUnit: BigNumberish;
  expirationTime: BigNumberish;
  isDelayedWriting: boolean;
  salt: BigNumberish;
  decimals: number;
}

export interface IOrderCreated {
  hash: BytesLike;
  maker: string;
  side: BigNumberish;
  optionId: BigNumberish;
  optionContract: string;
  paymentToken: string;
  pricePerUnit: BigNumberish;
  expirationTime: BigNumberish;
  isDelayedWriting: boolean;
  salt: BigNumberish;
  amount: BigNumberish;
  decimals: number;
}

export function convertGraphMarketOrderToOrder(
  graphOrder: MarketOrder,
): IOrder {
  return {
    maker: graphOrder.maker,
    side: graphOrder.side === SaleSide.Buy ? 0 : 1,
    optionId: graphOrder.optionId,
    optionContract: graphOrder.optionContract,
    paymentToken: graphOrder.paymentToken.address,
    pricePerUnit: graphOrder.pricePerUnit,
    expirationTime: graphOrder.expirationTime,
    isDelayedWriting: graphOrder.isDelayedWriting,
    salt: graphOrder.salt,
    decimals: graphOrder.decimals,
  };
}
