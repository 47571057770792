import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Toolbar, Tooltip, Typography, Button } from '@material-ui/core';
import { Loader } from 'components';

import { useWithdrawExpired } from 'state/options/hooks';
import { useApproveOptionBatch } from 'hooks';
import {
  OptionBalance,
  OptionType,
} from 'web3/options';
import moment from 'moment';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },

    title: {
      flex: '1 1 100%',
    },

    subtitle: {
      marginTop: '-8px',
      marginBottom: '8px',
      marginLeft: '1rem',
      fontSize: '0.85rem',
      color: theme.palette.text.secondary,
    },
  }),
);

interface WalletTableToolbarProps {
  optionBalances: OptionBalance[];
}

const WalletTableToolbar:React.FC<WalletTableToolbarProps> = ({ optionBalances }) => {
  const classes = useToolbarStyles();
  const [pairs, setPairs] = useState<any[][]>([]);

  const _optionBalances: OptionBalance[] = useMemo(
    () =>
      optionBalances.filter((optionBalance: OptionBalance) =>
        optionBalance
          ? moment() > moment(Number(optionBalance.option.expiration) * 1000)
          : false,
      ),
    [optionBalances],
  );

  useEffect(() => {
    const _pairs = [
      ...new Set(
        _optionBalances.reduce(
          (result: any[][], optionBalance: OptionBalance) => {
            return optionBalance.option.type === OptionType.Put
              ? [
                  ...result,
                  [
                    optionBalance.option.token.address,
                    optionBalance.option.denominator,
                  ],
                ]
              : [
                  ...result,
                  [
                    optionBalance.option.denominator.address,
                    optionBalance.option.denominator,
                  ],
                ];
          },
          [],
        ),
      ),
    ];

    setPairs(_pairs);
  }, [_optionBalances]);

  const {
    loading: approvalLoading,
    approvedAllDenominator,
    onApproveDenominator,
    approvedAllTokens,
    onApproveToken,
  } = useApproveOptionBatch(pairs);

  const handleApprove = useCallback(async () => {
    try {
      if (!approvedAllDenominator) await onApproveDenominator();

      if (!approvedAllTokens) await onApproveToken();
    } catch (e) {
      console.error(e);
    }
  }, [approvedAllDenominator, onApproveDenominator, approvedAllTokens, onApproveToken]);

  const handleWithdrawExpired = useWithdrawExpired(_optionBalances);

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant='h6' id='tableTitle'>
          Deposits
        </Typography>

        {approvalLoading ? (
          <Loader />
        ) : (
          <Tooltip title='Withdraw your collateral from all expired options'>
            <Box>
              {approvedAllDenominator && approvedAllTokens ? (
                <Button
                  disabled={_optionBalances.length < 1}
                  color='primary'
                  variant='outlined'
                  style={{ minWidth: 170, marginRight: 8 }}
                  onClick={() => handleWithdrawExpired()}
                >
                  Withdraw Expired
                </Button>
              ) : (
                <Button
                  disabled={_optionBalances.length < 1}
                  color='primary'
                  variant='outlined'
                  style={{ minWidth: 133, marginRight: 8 }}
                  onClick={() => handleApprove()}
                >
                  Approve All
                </Button>
              )}
            </Box>
          </Tooltip>
        )}
      </Toolbar>

      <Typography className={classes.subtitle} variant='body1'>
        View and interact with your deposits below
      </Typography>
    </>
  );
};

export default WalletTableToolbar;
