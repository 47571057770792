import { useSelector } from 'react-redux';
import { parseEther } from 'ethers/lib/utils';
import moment from 'moment';

import { AppState } from 'state';
import { useBlockNumber } from 'state/application/hooks';
import { AVG_BLOCK_TIME } from '../../constants';

export const usePBCSettings = () => {
  const lastBlockNumber = useBlockNumber();
  const settings = useSelector<AppState, AppState['pbc']['pbcSettings']>(
    (state) => state.pbc.pbcSettings,
  );

  const pbcLength = settings.endBlock - settings.startBlock;
  const blockNumber = lastBlockNumber ?? settings.startBlock;
  const elapsed = settings.startBlock ? blockNumber - settings.startBlock : 0;
  const progress =
    settings.endBlock > 1 && blockNumber > settings.endBlock
      ? 100
      : (elapsed / pbcLength) * 100;

  let blocksLeft = 0;

  if (progress < 100) {
    blocksLeft = settings.endBlock - blockNumber;
  }

  if (!lastBlockNumber || !settings.endBlock || blocksLeft < 3) {
    blocksLeft = Math.floor(
      moment('Feb 14 2021 17:47:38 GMT-0800').diff(moment(), 'seconds') /
        AVG_BLOCK_TIME,
    );
  }

  const secondsLeft = blocksLeft * AVG_BLOCK_TIME;
  const percentContrib =
    Number(parseEther(settings.userContribution.toString())) /
    Number(parseEther(settings.ethTotal.toString()));

  const premiaAllocation = 10000000 * percentContrib;

  return {
    ...settings,
    progress,
    blocksLeft,
    secondsLeft,
    percentContrib,
    premiaAllocation,
  };
};
