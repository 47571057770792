import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';

import { updateVersion } from './user/actions';
import application from './application/reducer';
import bondingCurve from './bondingCurve/reducer';
import pbc from './pbc/reducer';
import staking from './staking/reducer';
import user from './user/reducer';
import options from './options/reducer';
import market from './market/reducer';
import multicall from './multicall/reducer';
import transactions from './transactions/reducer';

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists'];

const store = configureStore({
  reducer: {
    application,
    bondingCurve,
    pbc,
    user,
    options,
    market,
    multicall,
    staking,
    transactions,
  },
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

store.dispatch(updateVersion());

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
