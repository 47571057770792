import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getDenominatorLogo } from 'utils/getDenominatorLogo';
import { TokenPair } from 'web3/tokens';

import { CurrencyLogo } from 'components';
import SpaceBackground_1 from 'assets/svg/SpaceBackground_1.svg';
import SpaceBackground_2 from 'assets/svg/SpaceBackground_2.svg';
import SpaceBackground_3 from 'assets/svg/SpaceBackground_3.svg';
import SpaceBackground_4 from 'assets/svg/SpaceBackground_4.svg';
import SpaceBackground_5 from 'assets/svg/SpaceBackground_5.svg';
import SpaceBackground_6 from 'assets/svg/SpaceBackground_6.svg';
import SpaceBackground_7 from 'assets/svg/SpaceBackground_7.svg';
import SpaceBackground_8 from 'assets/svg/SpaceBackground_8.svg';
import SpaceBackground_9 from 'assets/svg/SpaceBackground_9.svg';

const backgrounds = [
  SpaceBackground_1,
  SpaceBackground_2,
  SpaceBackground_3,
  SpaceBackground_4,
  SpaceBackground_5,
  SpaceBackground_6,
  SpaceBackground_7,
  SpaceBackground_8,
  SpaceBackground_9,
];

const useStyles = makeStyles(() => ({
  centerLogo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  mobile: {
    width: '70px',
    height: '70px',
  },

  notMobile: {
  },

  hangingDenominator: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    transform: 'translate(15%, 15%)',
  },

  hoverable: {
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },
  hoverableMobile: {
    cursor: 'pointer',
    width: '72px',
    height: '72px',

    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export interface TokenPairLogoProps {
  pair: TokenPair;
  backgroundIndex?: number;
  mobile?: boolean; 
}

export const TokenPairLogo: React.FC<TokenPairLogoProps> = ({
  pair,
  backgroundIndex = 0,
  mobile
}) => {
  const classes = useStyles();
  const denominatorLogo = useMemo(
    () => getDenominatorLogo(pair.denominator.symbol),
    [pair.denominator.symbol],
  );

  return (
    <Box position='relative' marginRight={!mobile ? 2 : 1} className={!mobile ? classes.hoverable : classes.hoverableMobile} id={`${backgroundIndex}${pair}`}>
      <img src={backgrounds[backgroundIndex % 9]} alt='space background' className={mobile ? classes.mobile : classes.notMobile} />

      <Box className={classes.centerLogo}>
        <CurrencyLogo currency={pair.token} size={!mobile ? '64px' : '48px'} />
      </Box>

      <Box className={classes.hangingDenominator}>
        <img
          src={denominatorLogo}
          alt={pair.denominator.symbol}
          height={!mobile ? 24 : 18}
          width={!mobile ? 24 : 18}
        />
      </Box>
    </Box>
  );
};

export default TokenPairLogo;
