import React from 'react';
import { Box, Typography, ButtonGroup, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export type AccountViewTabOptions =
  | 'positions'
  | 'orders'
  | 'deposits'
  | 'history';

export interface SelectAccountViewProps {
  activeTab: AccountViewTabOptions;
  setActiveTab: (activeTab: AccountViewTabOptions) => void;
}

const SelectAccountView: React.FC<SelectAccountViewProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      position='relative'
      mb={4}
      px={!mobile ? 4 : 1}
      py={2}
      width={1}
      boxShadow={3}
      borderRadius={16}
    >
      <Typography variant='h6'>Select a View</Typography>

      <Box clone width={1} my={2}>
        <ButtonGroup variant='contained'>
          <Box clone width={1 / 4}>
            <Button
              variant='contained'
              color={activeTab === 'positions' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('positions')}
            >
              Positions
            </Button>
          </Box>

          <Box clone width={1 / 4}>
            <Button
              variant='contained'
              color={activeTab === 'orders' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('orders')}
            >
              Orders
            </Button>
          </Box>

          <Box clone width={1 / 4}>
            <Button
              variant='contained'
              color={activeTab === 'deposits' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('deposits')}
            >
              Deposits
            </Button>
          </Box>

          <Box clone width={1 / 4}>
            <Button
              variant='contained'
              color={activeTab === 'history' ? 'primary' : 'secondary'}
              onClick={() => setActiveTab('history')}
            >
              History
            </Button>
          </Box>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default SelectAccountView;
