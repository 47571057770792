import React, { useCallback, useMemo } from 'react';
import { Box, Button, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { formatEther } from 'ethers/lib/utils';

import { usePrices, useWeb3 } from 'state/application/hooks';
import { usePBCSettings } from 'state/pbc/hooks';
import { useTransact } from 'hooks';
import formatNumber, {
  formatBigNumber,
  formatCompact,
} from 'utils/formatNumber';

import { PageWithSidebar } from 'layouts';
import { Hero, TokenWallet } from 'components';
import { HeroVariant } from 'components/Hero';
import PBCContribute from './components/PBCContribute';
import PBCTimer from './components/PBCTimer';
import { Container, SubTitle, Title } from '../../components/StyledPage';
import ProgressRing from '../../components/ProgressRing';

const useStyles = makeStyles({
  claimBtn: {
    marginTop: 16,
    border: '1px solid white',
    padding: '10px 0',
    borderRadius: 16,
    color: '#b151e8',
    backgroundColor: 'transparent',
    fontFamily: 'Inter',

    '&:hover': {
      backgroundColor: 'rgba(177, 81, 238, 0.2)'
    },

    '& .MuiButton-label': {
      color: '#808191'
    },

    '&.PBCEnded': {
      '& .MuiButton-label': {
        color: '#b151e8'
      }
    }
  },

  containerWithBorder: {
    borderRadius: 12,
    border: '1px solid #3edaff'
  },

  textVariantTwo: {
    fontSize: 11,
    color: '#3edaff',
    fontFamily: 'Roboto Mono'
  },

  textVariantOne: {
    fontSize: 11,
    color: '#b151e8',
    fontFamily: 'Roboto Mono'
  }
});

const PBC: React.FC = () => {
  const { contracts, wallet, account } = useWeb3();
  const classes = useStyles();
  const transact = useTransact();

  const pbcSettings = usePBCSettings();
  const prices = usePrices();

  const ethTotal = useMemo(() => Number(formatEther(pbcSettings.ethTotal)), [
    pbcSettings.ethTotal,
  ]);
  const premiaPrice = useMemo(
    () => (prices.WETH ? (ethTotal * prices.WETH) / 10000000 : 0),
    [prices.WETH, ethTotal],
  );
  const isPBCEnded = useMemo(() => pbcSettings.progress >= 100, [
    pbcSettings.progress,
  ]);


  const handleClaim = useCallback(async () => {
    return transact(contracts.premiaPBC?.collect());
  }, [contracts, transact]);

  return (
    <PageWithSidebar>
      <Grid container>
        <Grid item sm={12} md={8}>
          <Box clone paddingTop={{ xs: 8, md: 0 }}>
            <Typography
              component='h2'
              variant='h3'
              color='textPrimary'
              style={{
                fontFamily: '"Teko"',
                fontWeight: 600,
                fontSize: 48,
                marginBottom: 32,
              }}
            >
              Primary Bootstrap Contribution
            </Typography>
          </Box>

          <Hidden smDown>
            <Hero
              variant={HeroVariant.PBC}
              title={'The Premia PBC is Live'}
              subTitle={'Contribute ETH to receive Premia tokens'}
              onLearnMore={() => {
                const url =
                  'https://medium.com/premia/what-is-the-pbc-82d5ad16fdc7';

                window.open(url, '_blank') || window.location.replace(url);
              }}
            />
          </Hidden>

          <Container>
            <Title>Contribute ETH to receive Premia after the PBC ends</Title>
            <SubTitle>
              Participate in our Primary Bootstrap Contribution phase by
              depositing Ethereum
            </SubTitle>

            <Box clone marginTop={3}>
              <Grid container direction='row' justify='space-around'>
                <Button
                  className={cn(classes.claimBtn, isPBCEnded && !pbcSettings.hasCollected ? 'PBCEnded': '')}
                  disabled={
                    !isPBCEnded ||
                    pbcSettings.hasCollected ||
                    pbcSettings.userContribution.lte(0)
                  }
                  onClick={handleClaim}
                >
                  {!isPBCEnded || pbcSettings.userContribution.lte(0)
                    ? 'Claim Here After PBC'
                    : pbcSettings.hasCollected
                    ? 'Already Claimed'
                    : 'Claim'}
                </Button>

                <Grid
                  item
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Box
                    width={3 / 10}
                    marginRight={2}
                    marginTop={2}
                    style={{ minWidth: 320 }}
                  >
                    <div className={classes.containerWithBorder} style={{ padding: 12 }}>
                      <Title style={{ textAlign: 'center', marginBottom: 5 }}>
                        Current Price
                      </Title>

                      <Box width={1}>
                        <Grid container direction='row' justify='space-between'>
                          <Box marginRight={2}>
                            <div className={classes.textVariantOne}>Total Contributed:</div>
                            <div className={classes.textVariantOne}>
                              Total Premia in PBC:
                            </div>
                            <div className={classes.textVariantOne}>ETH Price:</div>
                            <div className={classes.textVariantOne}>
                              Derived Premia Price:
                            </div>
                          </Box>

                          <Box>
                            <div className={classes.textVariantTwo} style={{ textAlign: 'right' }}>
                              {formatNumber(ethTotal, true, {
                                maximumFractionDigits: 3,
                              })}{' '}
                              ETH
                            </div>

                            <div className={classes.textVariantTwo} style={{ textAlign: 'right' }}>
                              10M
                            </div>

                            <div className={classes.textVariantTwo} style={{ textAlign: 'right' }}>
                              {prices.WETH ? Math.floor(prices.WETH) : '-'} USD
                            </div>

                            <div className={classes.textVariantTwo} style={{ textAlign: 'right' }}>
                              {prices.WETH ? formatNumber(premiaPrice) : '-'}{' '}
                              USD
                            </div>
                          </Box>
                        </Grid>
                      </Box>
                    </div>
                  </Box>

                  {wallet && wallet.provider && account && (
                    <Box width={1 / 3} marginTop={2} style={{ minWidth: 180 }}>
                      <Grid container justify='center' alignItems='center'>
                        <ProgressRing radius={80} stroke={5} progress={100} />

                        <ProgressRing
                          style={{
                            position: 'absolute',
                            transform: 'rotate(-90deg)',
                          }}
                          radius={90}
                          stroke={10}
                          progress={pbcSettings.percentContrib * 100}
                          isOpaque={true}
                        />

                        <Box clone position='absolute'>
                          <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                          >
                            <div className={classes.textVariantOne}>Your share*</div>

                            <div className={classes.textVariantTwo} style={{ padding: '5px 0' }}>
                              {formatBigNumber(pbcSettings.userContribution)}{' '}
                              ETH (
                              {formatNumber(pbcSettings.percentContrib * 100)}%)
                            </div>

                            <div className={classes.textVariantOne}>
                              {formatCompact(pbcSettings.premiaAllocation)}{' '}
                              Premia
                            </div>
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </Grid>

                <Grid item container justify='center' alignItems='center'>
                  <Hidden xsDown>
                    <ProgressRing radius={170} stroke={10} progress={100} />

                    <ProgressRing
                      style={{
                        position: 'absolute',
                        transform: 'rotate(-90deg)',
                      }}
                      radius={190}
                      stroke={20}
                      progress={pbcSettings.progress}
                      isOpaque={true}
                    />
                  </Hidden>

                  <Hidden xsDown>
                    <Box width={1} position='absolute'>
                      <PBCTimer />
                    </Box>
                  </Hidden>

                  <Hidden smUp>
                    <Box width={1} paddingY={4}>
                      <PBCTimer />
                    </Box>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>

            {!isPBCEnded && <PBCContribute isPBCEnded={isPBCEnded} />}

            <SubTitle style={{ marginTop: 10 }}>
              * Your share will change as new contributions are made, as your
              share depends on the total ETH collected during the PBC.
            </SubTitle>

            <SubTitle style={{ marginTop: 10 }}>
              Premia Received = 10M * (Your ETH Contribution / Total ETH
              Contribution)
            </SubTitle>
          </Container>
        </Grid>

        <Hidden smDown>
          <Grid item xs={4}>
            <Box position='fixed' width={1 / 4}>
              <TokenWallet />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </PageWithSidebar>
  );
};

export default PBC;
