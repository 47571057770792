import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBlockNumber, useWeb3 } from 'state/application/hooks';
import { setBondingCurveSettings } from './actions';

export default function Updater(): null {
  const { contracts } = useWeb3();
  const dispatch = useDispatch();

  const latestBlockNumber = useBlockNumber();

  useEffect(() => {
    if (!contracts?.premiaBondingCurve?.address) return;

    contracts?.premia
      ?.balanceOf(contracts?.premiaBondingCurve?.address)
      .then((r) => dispatch(setBondingCurveSettings({ premiaAvailable: r })));

    contracts?.premiaBondingCurve
      .soldAmount()
      .then((r) => dispatch(setBondingCurveSettings({ premiaSold: r })));
  }, [contracts, dispatch, latestBlockNumber]);

  return null;
}
