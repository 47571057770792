import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  InputLabel,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { lighten, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AttachMoney } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { ethers, BigNumber } from 'ethers';
import moment from 'moment';
import cx from 'classnames';

import { getOptionContract } from 'web3/contracts';
import { OptionDetailsWithId, OptionType } from 'web3/options';
import { useWeb3 } from 'state/application/hooks';
import { useApproveMarket, useDenominatorAddress } from 'hooks';
import {
  useOptionSettings,
  useWriteOption,
  useWriteQuote,
} from 'state/options/hooks';
import {
  useSell,
  useSellNewOption,
  useTradeSettings,
} from 'state/market/hooks';
import { formatNumber } from 'utils/formatNumber';

import { Loader, CurrencyLogo, ModalContainer } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 300,
    whiteSpace: 'nowrap',
  },

  thick: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  rightAlign: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },

  subtitle: {
    marginTop: 8,
    marginBottom: 16,
    color: theme.palette.grey[400],
    fontSize: '0.8rem',
  },

  createAndSell: {
    borderColor: theme.palette.primary.main,
  },

  fieldHint: {
    position: 'absolute',
    bottom: -20,
    right: 0,
  },

  createOnly: {
    marginTop: 16,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: lighten(theme.palette.common.black, 0.05),
    },
  },
}));

export interface CreateOptionModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateOptionModal: React.FC<CreateOptionModalProps> = ({
  open,
  onClose,
}) => {
  const { register, handleSubmit } = useForm();
  const { contracts } = useWeb3();

  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();
  const { quantity, price, setTradeSettings } = useTradeSettings();
  const denominatorAddress = useDenominatorAddress();
  const classes = useStyles();

  const theme = useTheme();
  const thinDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const expectedFee = useWriteQuote();
  const onWrite = useWriteOption();
  const onSell = useSell();
  const onSellNewOption = useSellNewOption();

  const optionContract = useMemo(
    () => getOptionContract(contracts, denominator),
    [contracts, denominator],
  );

  const {
    loading: approvalLoading,
    approvedDenominator,
    tokenAllowance,
    onApproveToken,
    onApproveDenominator,
  } = useApproveMarket(denominatorAddress ?? '', denominator);

  const handleApprove = useCallback(async () => {
    try {
      if (!approvedDenominator) {
        await onApproveDenominator();
      }

      if (!tokenAllowance.gt(0)) {
        await onApproveToken();
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    tokenAllowance,
    approvedDenominator,
    onApproveDenominator,
    onApproveToken,
  ]);

  const handleWrite = useCallback(async () => {
    await (await onWrite())?.wait(1);
    onClose();
  }, [onWrite, onClose]);

  const handleWriteAndList = useCallback(async () => {
    if (!selectedExpiration || !selectedToken || !strikePrice) {
      return;
    }

    let optionId = await optionContract?.getOptionId(
      selectedToken.address,
      Math.floor(selectedExpiration.getTime() / 1000),
      ethers.utils.parseEther(strikePrice),
      optionType === OptionType.Call,
    );

    if (!optionId || optionId?._hex === '0x00') {
      await onSellNewOption();
    } else {
      const option: OptionDetailsWithId = {
        id: optionId as BigNumber,
        token: selectedToken.symbol,
        denominator,
        strikePrice: ethers.utils.parseEther(strikePrice),
        expiration: BigNumber.from(
          Math.floor(selectedExpiration.getTime() / 1000),
        ),
        type: optionType,
      };

      await onSell(option, { isDelayedWriting: true, writeOnBuyFill: true });
    }

    onClose();
  }, [
    onSell,
    onSellNewOption,
    optionContract,
    selectedToken,
    selectedExpiration,
    denominator,
    strikePrice,
    optionType,
    onClose,
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size={!thinDesktop ? 'md' : 'lg'}>
        <Box
          paddingBottom={3}
          display='flex'
          justifyContent={!mobile ? 'left' : 'center'}
        >
          <Typography>Confirm Details Below</Typography>
        </Box>

        <Box display='flex' flexDirection={!mobile ? 'row' : 'column'}>
          <Box
            width={!mobile ? 0.4 : 1}
            marginRight={!mobile ? 1 : 0}
            marginBottom={!mobile ? 0 : 3}
          >
            <Paper>
              <Box padding={2}>
                <Grid item container direction='column'>
                  <Grid
                    item
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                  >
                    <Box paddingY={!mobile ? 2 : 1}>
                      <Typography>I want to mint</Typography>
                    </Box>

                    <Box paddingBottom={4}>
                      <CurrencyLogo currency={selectedToken} size='64px' />
                    </Box>
                  </Grid>

                  <Box clone width={1}>
                    <Grid item container>
                      <Box width={1 / 2}>
                        <Grid item container direction='column'>
                          <div className={classes.thin}>Token</div>
                          <div className={classes.thin}>Denomination</div>
                          <div className={classes.thin}>Strike Price</div>
                          <div className={classes.thin}>Expiration</div>
                          <div className={classes.thin}>Call / Put</div>
                          <div className={classes.thin}>Contract Amount</div>
                          {Number(expectedFee) !== 0 && (
                            <div className={classes.thin}>Protocol Fee</div>
                          )}
                        </Grid>
                      </Box>

                      <Box width={1 / 2}>
                        <Grid
                          item
                          container
                          direction='column'
                          alignItems='flex-end'
                        >
                          <div
                            className={cx(classes.thick, classes.rightAlign, {
                              [classes.callGreen]:
                                optionType === OptionType.Call,
                              [classes.putRed]: optionType === OptionType.Put,
                            })}
                          >
                            {selectedToken?.symbol}
                          </div>

                          <div
                            className={cx(classes.thick, classes.rightAlign, {
                              [classes.putRed]: optionType === OptionType.Call,
                              [classes.callGreen]:
                                optionType === OptionType.Put,
                            })}
                          >
                            {denominator}
                          </div>

                          <div className={classes.rightAlign}>
                            {formatNumber(strikePrice)}{' '}
                            <span
                              className={cx(classes.thick, {
                                [classes.putRed]:
                                  optionType === OptionType.Call,
                                [classes.callGreen]:
                                  optionType === OptionType.Put,
                              })}
                            >
                              {denominator}
                            </span>
                          </div>

                          <div className={classes.rightAlign}>
                            {moment(selectedExpiration).format('MMM. Do, YYYY')}
                          </div>

                          <div
                            className={cx(classes.thick, classes.rightAlign, {
                              [classes.callGreen]:
                                optionType === OptionType.Call,
                              [classes.putRed]: optionType === OptionType.Put,
                            })}
                          >
                            {optionType.toString()}
                          </div>

                          <div className={classes.rightAlign}>
                            {formatNumber(quantity)}{' '}
                            <span
                              className={cx(classes.thick, {
                                [classes.callGreen]:
                                  optionType === OptionType.Call,
                                [classes.putRed]: optionType === OptionType.Put,
                              })}
                            >
                              {selectedToken?.symbol}
                            </span>
                          </div>

                          {Number(expectedFee) !== 0 && (
                            <div className={classes.rightAlign}>
                              {formatNumber(expectedFee)}{' '}
                              <span
                                className={cx(classes.thick, {
                                  [classes.callGreen]:
                                    optionType === OptionType.Call,
                                  [classes.putRed]:
                                    optionType === OptionType.Put,
                                })}
                              >
                                {optionType === OptionType.Call
                                  ? selectedToken?.symbol
                                  : denominator}
                              </span>
                            </div>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box
            width={!mobile ? 0.6 : 1}
            marginLeft={!mobile ? 1 : 0}
            paddingX={!mobile ? 5 : 2}
          >
            <Grid
              item
              container
              direction='column'
              justify='center'
              alignItems='center'
            >
              <Typography variant='h4' component='h2' align='center'>
                Deposit{' '}
                {optionType === OptionType.Call
                  ? formatNumber(Number(quantity) + Number(expectedFee))
                  : formatNumber(
                      Number(quantity) * Number(strikePrice) +
                        Number(expectedFee),
                    )}{' '}
                <span
                  className={cx(classes.thick, {
                    [classes.callGreen]: optionType === OptionType.Call,
                    [classes.putRed]: optionType === OptionType.Put,
                  })}
                >
                  {optionType === OptionType.Call
                    ? selectedToken?.symbol
                    : denominator}
                </span>
              </Typography>

              <Typography
                variant='h6'
                component='h4'
                align='center'
                className={classes.subtitle}
              >
                {selectedToken?.symbol}-
                <span className={classes.thin}>{denominator}</span> •{' '}
                <span className={classes.thin}>
                  {moment(selectedExpiration).format('YYYYMMMDD').toUpperCase()}
                </span>{' '}
                • {formatNumber(strikePrice)}{' '}
                <span className={classes.thin}>{denominator}</span> •{' '}
                <span
                  className={
                    optionType === 'CALL' ? classes.callGreen : classes.putRed
                  }
                >
                  {optionType}
                </span>
              </Typography>

              <Box clone width={1} paddingX={4} paddingY={2}>
                <Paper className={classes.createAndSell}>
                  <Typography variant='h6' component='h2'>
                    Mint on Sale
                  </Typography>

                  <Box
                    width={1}
                    marginTop={1}
                    marginBottom={4}
                    position='relative'
                  >
                    <InputLabel>Price</InputLabel>
                    <FormControl style={{ width: '100%' }}>
                      <OutlinedInput
                        name='price'
                        type='number'
                        value={price === '0' ? '' : price}
                        inputRef={register}
                        onChange={(event) =>
                          setTradeSettings({ price: event.target.value })
                        }
                      />
                    </FormControl>

                    <Typography
                      color='textSecondary'
                      variant='body2'
                      className={classes.fieldHint}
                    >
                      (Total: {formatNumber(Number(quantity) * Number(price))}{' '}
                      {denominator})
                    </Typography>
                  </Box>

                  {approvalLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {approvedDenominator && tokenAllowance.gt(0) ? (
                        <Button
                          fullWidth
                          disabled={!price || price === '' || price === '0'}
                          color='primary'
                          variant='contained'
                          onClick={handleSubmit(handleWriteAndList)}
                        >
                          <Box clone marginRight={2}>
                            <AttachMoney />
                          </Box>
                          Mint on Sale
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          onClick={handleApprove}
                        >
                          Approve Market
                          <br />(
                          {approvedDenominator || tokenAllowance.gt(0)
                            ? '2 / 2'
                            : '1 / 2'}
                          )
                        </Button>
                      )}
                    </>
                  )}
                </Paper>
              </Box>

              <Box clone width={1} paddingX={4} paddingY={2}>
                <Paper className={classes.createOnly} onClick={handleWrite}>
                  <Typography
                    variant='h6'
                    component='h2'
                    align='center'
                    color='primary'
                  >
                    Mint to Wallet
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default CreateOptionModal;
