import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBlockNumber, useWeb3 } from 'state/application/hooks';
import { setPBCSettings } from './actions';

export default function Updater(): null {
  const { account, contracts } = useWeb3();
  const dispatch = useDispatch();

  const latestBlockNumber = useBlockNumber();

  useEffect(() => {
    contracts?.premiaPBC
      ?.startBlock()
      .then((r) => dispatch(setPBCSettings({ startBlock: r.toNumber() })));

    contracts?.premiaPBC
      ?.endBlock()
      .then((r) => dispatch(setPBCSettings({ endBlock: r.toNumber() })));
  }, [contracts, dispatch]);

  useEffect(() => {
    contracts?.premiaPBC
      ?.ethTotal()
      .then((r) => dispatch(setPBCSettings({ ethTotal: r })));
  }, [contracts, dispatch, latestBlockNumber]);

  useEffect(() => {
    contracts?.premiaPBC
      ?.amountDeposited(account)
      .then((r) => dispatch(setPBCSettings({ userContribution: r })));

    contracts?.premiaPBC
      ?.hasCollected(account)
      .then((r) => dispatch(setPBCSettings({ hasCollected: r })));
  }, [contracts, account, dispatch, latestBlockNumber]);

  return null;
}
