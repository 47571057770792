import { BigNumber } from 'ethers';

import { Option } from './options';

export enum AccountHistoryAction {
  CancelSellOrder = 'Cancel Sell Order',
  CancelBuyOrder = 'Cancel Buy Order',
  OpenBuyOrder = 'Open Buy Order',
  OpenSellOrder = 'Open Sell Order',
  FillBuyOrder = 'Fill Buy Order',
  FillSellOrder = 'Fill Sell Order',
  FillMintOnDemandOrder = 'Fill Mint-on-Demand Order',
  ExerciseOption = 'Exercise Option',
  WithdrawOption = 'Withdraw Option',
  BurnOption = 'Burn Option',
  WriteOption = 'Write Option',
  SendOption = 'Send Option',
  ReceiveOption = 'Receive Option',
  ReceiveReferralFee = 'Receive Referral Fee',
}

export interface AccountHistoryItem {
  id: string;
  user: string;
  option: Option;
  amount: BigNumber;
  timestamp: BigNumber;
  action: AccountHistoryAction;
  txHash: string;
}

export interface Account {
  id: string;
  usersReferred: number;
  feesEarned: BigNumber;
}
