import React, { useCallback, useMemo } from 'react';
import {
  Typography,
  Modal,
  Grid,
  Button,
  ButtonGroup,
  Box,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import cx from 'classnames';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import moment from 'moment';

import { getTokenPairs } from 'graphql/queries';
import {
  useExpirations,
  useDenominatorAddress,
  useApproveMarket,
  useApproveMint,
} from 'hooks';
import { OptionType } from 'web3/options';
import { Token, TokenPair } from 'web3/tokens';
import { useWeb3 } from 'state/application/hooks';
import {
  useTradeSettings,
  useSellNewOption,
  useBuyNewOption,
} from 'state/market/hooks';
import { useOptionSettings } from 'state/options/hooks';
import { useTokenBalance } from 'state/wallet/hooks';
import { formatBigNumber, formatNumber } from 'utils/formatNumber';

import { Loader, ModalContainer, TokenWalletItem } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
  },

  skinnyRow: {
    width: '100%',
    marginBottom: 12,
  },

  firstRow: {
    marginTop: 32,
  },

  orderTypes: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },

  fieldHint: {
    position: 'absolute',
    top: -16,
    fontSize: 12,
    lineHeight: 1.3,
  },

  footerRow: {
    width: '100%',
    marginBottom: 48,
  },

  buyButton: {
    minWidth: 100,
    padding: '8px',
    height: '100%',
    backgroundColor: theme.palette.success.main,
  },

  sellButton: {
    minWidth: 100,
    backgroundColor: theme.palette.error.main,
  },

  fullwidth: {
    width: '100%',
  },

  inputWidth: {
    width: '70%',
  },

  itemContainer: {
    position: 'relative',
  },

  alignRight: {
    textAlign: 'right',
  },

  buttonWidth: {
    width: '30%',
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '72%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  mobileFieldStatus: {
    bottom: -20,
    left: 5,
  },

  greenText: {
    color: theme.palette.success.main,
  },
  redText: {
    color: theme.palette.error.main,
  },
}));

export interface TradeNewOptionModalProps {
  open: boolean;
  onClose: () => void;
}

const TradeNewOptionModal: React.FC<TradeNewOptionModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { account } = useWeb3();
  const { register, control } = useForm();

  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
    setOptionSettings,
  } = useOptionSettings();
  const { quantity, price, setTradeSettings } = useTradeSettings();
  const expirations = useExpirations();
  const denominatorAddress = useDenominatorAddress();

  const { loading: loadingTokenPairs, data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      skip: !denominatorAddress,
      variables: { denominatorAddress },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const allTokens: Token[] = useMemo(() => {
    return tokenPairs.map(({ token }) => token);
  }, [tokenPairs]);

  const activePair: TokenPair | undefined = useMemo(
    () =>
      tokenPairs.find(({ token }) => token.address === selectedToken?.address),
    [tokenPairs, selectedToken],
  );

  const denominatorBalance = useTokenBalance(
    account,
    activePair?.denominator ?? undefined,
  );
  const tokenBalance = useTokenBalance(account, activePair?.token ?? undefined);

  const hasSufficientBuyBalance =
    Number(denominatorBalance) >= Number(quantity) * Number(price);

  const hasSufficientSellBalance =
    optionType === OptionType.Call
      ? Number(tokenBalance) >= Number(quantity)
      : Number(denominatorBalance) >= Number(strikePrice) * Number(quantity);

  const isValidPrice = Number(strikePrice) > 0 && Number.isInteger(Number(strikePrice) / Number(formatBigNumber(activePair?.strikePriceIncrement)));

  const onBuyNew = useBuyNewOption();
  const onSellNew = useSellNewOption();

  const approvalToken =
    optionType === OptionType.Call
      ? activePair?.token
      : activePair?.denominator;

  const { allowance, onApprove } = useApproveMint(approvalToken, denominator);

  const handleApprove = useCallback(async () => {
    try {
      await onApprove();
    } catch (e) {
      console.error(e);
    }
  }, [onApprove]);

  const {
    loading: approvalLoading,
    approvedDenominator,
    tokenAllowance,
    onApproveToken,
    onApproveDenominator,
  } = useApproveMarket(denominatorAddress ?? '', denominator);

  const handleApproveMarket = useCallback(async () => {
    try {
      if (!approvedDenominator) {
        await onApproveDenominator();
      }

      if (!tokenAllowance.gt(0)) {
        await onApproveToken();
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    tokenAllowance,
    approvedDenominator,
    onApproveDenominator,
    onApproveToken,
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5' align={mobile ? 'center' : 'left'}>
            Trade New Option
          </Typography>
        </Box>

        <Box py={3}>
          <Typography variant='body2' color='textSecondary'>
            Order Type
          </Typography>

          <Grid container justify='center' className={classes.row}>
            <ButtonGroup variant='contained' className={classes.orderTypes}>
              <Box clone width={1 / 3}>
                <Button
                  variant='contained'
                  color={
                    optionType === OptionType.Call ? 'primary' : 'secondary'
                  }
                  style={{ minWidth: 100 }}
                  onClick={() =>
                    setOptionSettings({ optionType: OptionType.Call })
                  }
                >
                  Call
                </Button>
              </Box>

              <Box clone width={1 / 3}>
                <Button
                  variant='contained'
                  color={
                    optionType === OptionType.Put ? 'primary' : 'secondary'
                  }
                  style={{ minWidth: 100 }}
                  onClick={() =>
                    setOptionSettings({ optionType: OptionType.Put })
                  }
                >
                  Put
                </Button>
              </Box>
            </ButtonGroup>
          </Grid>

          <Grid container alignItems='center' className={classes.skinnyRow}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Select Token:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl
                className={cx(tablet ? classes.fullwidth : classes.inputWidth)}
              >
                <Autocomplete
                  autoHighlight
                  value={selectedToken}
                  options={allTokens}
                  getOptionSelected={(token) =>
                    token?.address === selectedToken?.address
                  }
                  getOptionLabel={(option: any) => option.symbol}
                  renderOption={(token: Token) => (
                    <TokenWalletItem
                      currency={token}
                      isSelected={selectedToken?.symbol === token.symbol}
                      onSelect={() =>
                        setOptionSettings({ selectedToken: token })
                      }
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant='filled'
                      {...params}
                      value={selectedToken?.symbol ?? ''}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Expiration:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl className={classes.fullwidth}>
                <Controller
                  control={control}
                  name='expiration'
                  defaultValue=''
                  value={selectedExpiration}
                  as={
                    <Select
                      disableUnderline
                      className={cx(
                        tablet ? classes.fullwidth : classes.inputWidth,
                      )}
                    >
                      {expirations.map((expiration) => (
                        <MenuItem
                          key={expiration.getTime()}
                          value={expiration.getTime()}
                          onClick={() =>
                            setOptionSettings({
                              selectedExpiration: expiration,
                            })
                          }
                        >
                          {moment(expiration).format('MMMM Do YYYY')}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Strike Price:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              {loadingTokenPairs ? (
                <Loader />
              ) : (
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={cx(
                    tablet ? classes.fullwidth : classes.inputWidth,
                    classes.alignRight,
                    classes.fieldHint,
                  )}
                >
                  {activePair?.strikePriceIncrement && (
                    <>
                      ({formatBigNumber(activePair.strikePriceIncrement)}{' '}
                      {denominator} increment)
                    </>
                  )}
                </Typography>
              )}
              <FormControl className={classes.fullwidth}>
                <OutlinedInput
                  name='strikePrice'
                  type='number'
                  value={strikePrice}
                  inputRef={register}
                  className={cx(
                    tablet ? classes.fullwidth : classes.inputWidth,
                  )}
                  inputProps={
                    activePair?.strikePriceIncrement
                      ? {
                          step: formatBigNumber(
                            activePair.strikePriceIncrement,
                          ),
                        }
                      : {}
                  }
                  onChange={(event) => {
                    setOptionSettings({ strikePrice: event.target.value })
                  }}
                />
                {
                  selectedToken && strikePrice &&
                    <Typography
                    className={cx(isValidPrice ? classes.greenText : classes.redText, classes.fieldStatus, tablet ? classes.mobileFieldStatus : classes.desktopFieldStatus)}
                    variant='body2'
                  >
                    { isValidPrice ? 'Valid' : 'Invalid' }
                    </Typography>
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.row}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Quantity:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              {loadingTokenPairs ? (
                <Loader />
              ) : (
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={cx(
                    tablet ? classes.fullwidth : classes.inputWidth,
                    classes.alignRight,
                    classes.fieldHint,
                  )}
                >
                  {strikePrice && quantity && (
                    <>
                      (Collateral:{' '}
                      {formatNumber(
                        optionType === OptionType.Call
                          ? Number(quantity)
                          : Number(strikePrice) * Number(quantity),
                      )}{' '}
                      {optionType === OptionType.Call
                        ? selectedToken?.symbol
                        : denominator}
                      )
                    </>
                  )}
                </Typography>
              )}
              <FormControl className={tablet ? classes.fullwidth : classes.inputWidth}>
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity}
                  inputRef={register}
                  className={classes.fullwidth}
                  inputProps={
                    selectedToken?.decimals
                      ? {
                          step: 1 * 10 ** -selectedToken.decimals,
                        }
                      : {}
                  }
                  onChange={(event) => {
                    setTradeSettings({ quantity: event.target.value })
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.footerRow}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2' color='textSecondary'>
                Price:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.itemContainer}>
              <FormControl className={classes.fullwidth}>
                <OutlinedInput
                  name='price'
                  type='number'
                  value={price}
                  inputRef={register}
                  className={cx(
                    tablet ? classes.fullwidth : classes.inputWidth,
                  )}
                  onChange={(event) => {
                    setTradeSettings({ price: event.target.value })
                  }}
                />
              </FormControl>

              <Typography
                color='textSecondary'
                variant='body2'
                className={cx(
                  tablet ? classes.fullwidth : classes.inputWidth,
                  classes.alignRight,
                  classes.fieldHint,
                )}
              >
                (Total: {formatNumber(Number(quantity) * Number(price))}{' '}
                {denominator})
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify='space-evenly' className={classes.skinnyRow}>
            {approvalLoading ? (
              <Loader />
            ) : (
              <>
                {approvedDenominator && tokenAllowance.gt(0) ? (
                  <>
                    {!hasSufficientBuyBalance ? (
                      <Tooltip title='Insufficient balance.'>
                        <Box
                          className={
                            mobile ? classes.fullwidth : classes.buttonWidth
                          }
                          marginRight={mobile ? 0 : 1}
                          marginBottom={mobile ? 1 : 0}
                        >
                          <Button
                            disabled
                            fullWidth
                            size='large'
                            variant='contained'
                            className={classes.buyButton}
                          >
                            Buy
                          </Button>
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box
                        className={
                          mobile ? classes.fullwidth : classes.buttonWidth
                        }
                        marginRight={mobile ? 0 : 1}
                        marginBottom={mobile ? 1 : 0}
                      >
                        <Button
                          disabled={
                            !selectedToken ||
                            !selectedExpiration ||
                            !strikePrice ||
                            !quantity ||
                            !price
                          }
                          size='large'
                          variant='contained'
                          className={classes.buyButton}
                          onClick={() => onBuyNew().then(onClose)}
                        >
                          Buy
                        </Button>
                      </Box>
                    )}

                    {allowance.eq(0) ? (
                      <Box
                        marginLeft={mobile ? 0 : 1}
                        marginTop={mobile ? 1 : 0}
                        className={
                          mobile ? classes.fullwidth : classes.buttonWidth
                        }
                      >
                        <Button
                          disabled={!selectedToken}
                          size='large'
                          variant='contained'
                          color='primary'
                          onClick={handleApprove}
                        >
                          Approve Mint
                        </Button>
                      </Box>
                    ) : (
                      <>
                        {!hasSufficientSellBalance ? (
                          <Tooltip title='Insufficient balance.'>
                            <Box
                              className={
                                mobile ? classes.fullwidth : classes.buttonWidth
                              }
                              marginLeft={mobile ? 0 : 1}
                              marginTop={mobile ? 1 : 0}
                            >
                              <Button
                                disabled
                                fullWidth
                                size='large'
                                variant='contained'
                                className={classes.sellButton}
                              >
                                Mint on Sale
                              </Button>
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            className={
                              mobile ? classes.fullwidth : classes.buttonWidth
                            }
                            marginLeft={mobile ? 0 : 1}
                            marginTop={mobile ? 1 : 0}
                          >
                            <Button
                              disabled={
                                !selectedToken ||
                                !selectedExpiration ||
                                !strikePrice ||
                                !quantity ||
                                !price
                              }
                              size='large'
                              variant='contained'
                              className={classes.sellButton}
                              onClick={() => onSellNew().then(onClose)}
                            >
                              Mint on Sale
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Box clone width={1 / 2}>
                    <Button
                      size='large'
                      variant='contained'
                      color='primary'
                      onClick={handleApproveMarket}
                    >
                      Approve Market
                      <br />(
                      {approvedDenominator || tokenAllowance.gt(0)
                        ? '2 / 2'
                        : '1 / 2'}
                      )
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default TradeNewOptionModal;
