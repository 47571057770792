import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useWeb3 } from 'state/application/hooks';

import { PageWithSidebar } from 'layouts';
import { TokenWallet, AccountRequired, ReferralModal } from 'components';
import { AccountViewTabOptions } from './components/SelectAccountView';
import {
  AccountHero,
  AccountHistoryTable,
  DepositsTable,
  OpenOrdersTable,
  OpenPositionsTable,
  SelectAccountView,
} from './components';

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: 48,
    marginBottom: 32,
  },
  pageTitleMobile: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: '48px',
    margin: '82px 10px 16px',
  },
}));

const Account: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<AccountViewTabOptions>(
    'positions',
  );
  const { wallet, account } = useWeb3();

  return (
    <PageWithSidebar
      hideAccountButtons={!wallet || !wallet.provider}
      mobile={mobile}
    >
      <ReferralModal
        open={referralModalOpen}
        onClose={() => setReferralModalOpen(false)}
      />

      {wallet && wallet.provider && account ? (
        <Grid container>
          <Grid item xs={12} md={8}>
            <Hidden mdUp>
              <Typography
                component='h2'
                variant='h3'
                color='textPrimary'
                className={
                  !mobile ? classes.pageTitle : classes.pageTitleMobile
                }
              >
                Your Account
              </Typography>
            </Hidden>

            <Hidden smDown>
              <AccountHero />
            </Hidden>

            <Box width={1} marginBottom={2} marginTop={-2} textAlign='right'>
              <Link to='/account' onClick={() => setReferralModalOpen(true)}>
                View Referrals
              </Link>
            </Box>

            <SelectAccountView
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {activeTab === 'positions' && <OpenPositionsTable />}
            {activeTab === 'orders' && <OpenOrdersTable />}
            {activeTab === 'deposits' && <DepositsTable />}
            {activeTab === 'history' && <AccountHistoryTable />}
          </Grid>

          <Hidden smDown>
            <Grid item xs={4}>
              <Box position='fixed' width={1 / 4}>
                <TokenWallet />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      ) : (
        <AccountRequired />
      )}
    </PageWithSidebar>
  );
};

export default Account;
