import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  Box,
  Typography,
  TableRow,
  TableCell,
  Hidden,
  Button,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from 'react-apollo';
import { Contract, ContractCall } from 'ethers-multicall';
import { BigNumber } from 'ethers';
import { get } from 'lodash';
import { useTradeSettings } from 'state/market/hooks';
import { useWeb3 } from 'state/application/hooks';
import { useOptionSettings } from 'state/options/hooks';
import { getOrdersForOption } from 'graphql/queries';
import {
  SaleSide,
  MarketOrder,
  IOrder,
  convertGraphMarketOrderToOrder,
} from 'web3/market';
import { Option } from 'web3/options';
import { Token } from 'web3/tokens';
import { formatBigNumber } from 'utils/formatNumber';
import PremiaMarketAbi from 'constants/abi/PremiaMarket.json';
import { SwapVert, FileCopy } from '@material-ui/icons';
import { DataTable, CurrencyLogo, MintToSellModal, CopySellOrderModal } from 'components';
import { HeadCell } from 'components/DataTable';

export interface OrderBookItem {
  price: BigNumber;
  order: MarketOrder;
  token: Token;
  paymentToken: Token;
  available: BigNumber;
  quantity: BigNumber;
  isBid: boolean;
  breakeven: BigNumber;
}

const bidHeadCells: HeadCell<OrderBookItem>[] = [
  {
    id: 'total_available',
    numeric: true,
    label: 'Total',
    sortKey: (item: OrderBookItem) => Number(item.available),
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
    sortKey: (item: OrderBookItem) => Number(item.quantity),
  },
  {
    id: 'break_even_price',
    numeric: true,
    label: 'Break-Even',
    sortKey: (item: OrderBookItem) => Number(item.breakeven),
  },
  {
    id: 'bid_price',
    numeric: true,
    label: 'Bid',
    sortKey: (item: OrderBookItem) => Number(item.price),
  },
  {
    id: 'mint_and_sell',
    numeric: false,
    label: '',
    sortKey: () => {
      return 1;
    },
  },
];

const askHeadCells: HeadCell<OrderBookItem>[] = [
  {
    id: 'ask_price',
    numeric: true,
    label: 'Ask',
    sortKey: (item: OrderBookItem) => Number(item.price),
  },
  {
    id: 'break_even_price',
    numeric: true,
    label: 'Break-Even',
    sortKey: (item: OrderBookItem) => Number(item.breakeven),
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
    sortKey: (item: OrderBookItem) => Number(item.quantity),
  },
  {
    id: 'total_available',
    numeric: true,
    label: 'Total',
    sortKey: (item: OrderBookItem) => Number(item.available),
  },
  {
    id: 'copy_sell_order',
    numeric: false,
    label: '',
    sortKey: () => {
      return 1;
    },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    overflowX: 'visible',
  },

  title: {
    paddingBottom: '8px',
    width: '100%',
    fontWeight: 700,
  },

  currencyLogo: {
    marginLeft: '0.25rem',
  },

  swapVertLogo: {
    marginLeft: '0.25rem',
  },

  fileCopyLogo: {
    marginLeft: '0.25rem',
  },

  tableCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bid: {
    color: theme.palette.success.main,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 700,
    },
  },

  ask: {
    color: theme.palette.error.main,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 700,
    },
  },

  thin: {
    fontWeight: 300,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 500,
    },
  },

  thick: {
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 700,
    },
  },
}));

export interface OptionOrderBookProps {
  activePanel: 'mint' | 'trade';
  option: Option;
  onGoToMint: () => void;
  onGoToTrade: () => void;
}

const OptionOrderBook: React.FC<OptionOrderBookProps> = ({
  activePanel,
  option,
  onGoToMint,
  onGoToTrade,
}) => {
  const classes = useStyles();
  const [validOrders, setValidOrders] = useState<MarketOrder[]>([]);
  const { contracts, multicallProvider } = useWeb3();
  const { setTradeSettings } = useTradeSettings();
  const { selectedOption, selectedToken } = useOptionSettings();
  const [mintToSellModalOpen, setMintToSellModalOpen] = useState(false);
  const [copySellOrderModalOpen, setCopySellOrderModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('xl'));
  const tablet = useMediaQuery(theme.breakpoints.up('lg'));

  const { loading, data } = useQuery(getOrdersForOption, {
    variables: { optionId: selectedOption?.id ?? '-1' },
    pollInterval: 2000,
  });

  const orders = useMemo(() => get(data, 'marketOrders', []), [data]);

  useEffect(() => {
    if (!contracts.premiaMarket || !multicallProvider || orders.length < 1)
      return;

    const marketContract = new Contract(
      contracts.premiaMarket.address,
      PremiaMarketAbi.abi,
    );

    (async () => {
      const convertedOrders = orders.map((order: MarketOrder) =>
        convertGraphMarketOrderToOrder(order),
      );

      const isValidCalls: ContractCall[] = convertedOrders.map(
        (order: IOrder) => marketContract.isOrderValid(order),
      );

      const isValidResults = await multicallProvider.all(isValidCalls);

      const _validOrders = orders.filter(
        (order: MarketOrder, i: number) => isValidResults[i],
      );

      setValidOrders(_validOrders);
    })();
  }, [orders, contracts.premiaMarket, multicallProvider, setValidOrders]);

  const ordersToBook = (orderBook: OrderBookItem[], order: MarketOrder) => {
    const price = order.pricePerUnit;
    let strikePrice: BigNumber = BigNumber.from(order.option.strikePrice);
    const bookItem = orderBook.find(
      (bid: OrderBookItem) => bid.price === price,
    );

    if (bookItem) {
      bookItem.available = BigNumber.from(bookItem.available).add(
        order.remainingUnfilled,
      );

      bookItem.quantity = BigNumber.from(bookItem.quantity).add(
        order.remainingUnfilled,
      );

      return orderBook;
    }

    const newBookItem = {
      price,
      order,
      token: order.option.token,
      paymentToken: order.paymentToken,
      available: order.remainingUnfilled,
      quantity: order.remainingUnfilled,
      isBid: order.side === SaleSide.Buy,
      breakeven:
        order.option.type === 'CALL'
          ? strikePrice.add(order.pricePerUnit)
          : strikePrice.sub(order.pricePerUnit),
    };

    return [...orderBook, newBookItem];
  };

  const sumAvailable = (
    orderBook: OrderBookItem[],
    currentItem: OrderBookItem,
  ) => {
    orderBook.forEach((item: OrderBookItem) => {
      if (item.isBid && item.price > currentItem.price) {
        currentItem.available = BigNumber.from(currentItem.available).add(
          item.quantity,
        );
      } else if (!item.isBid && item.price < currentItem.price) {
        currentItem.available = BigNumber.from(currentItem.available).add(
          item.quantity,
        );
      }
    });

    return orderBook;
  };

  const preOrders = validOrders.filter(
    (order: MarketOrder) => order.side === SaleSide.Buy,
  );

  const preBids = preOrders.reduce(ordersToBook, []);

  const bids = preBids.reduce(sumAvailable, preBids);

  const preAsks = validOrders
    .filter((order: MarketOrder) => order.side === SaleSide.Sell)
    .reduce(ordersToBook, []);

  const asks = preAsks.reduce(sumAvailable, preAsks);

  const renderOrderBookRow = (item: OrderBookItem, index: number) => (
    <TableRow hover tabIndex={-1} key={index} style={{ height: 53 }}>
      <TableCell
        align='center'
        className={item.isBid ? classes.thin : classes.ask}
        onClick={() =>
          item.isBid
            ? setTradeSettings({
                quantity: formatBigNumber(
                  item.available,
                  false,
                  {},
                  item.token.decimals,
                ),
              })
            : setTradeSettings({ price: formatBigNumber(item.price, false) })
        }
      >
        <div className={classes.tableCell}>
          {item.isBid
            ? formatBigNumber(item.available, true, {}, item.token.decimals)
            : formatBigNumber(item.price)}

          <CurrencyLogo
            className={classes.currencyLogo}
            currency={item.isBid ? item.token : item.paymentToken}
            size='16px'
          />
        </div>
      </TableCell>

      <TableCell
        align='center'
        className={classes.thick}
        onClick={() =>
          item.isBid
            ? setTradeSettings({
                quantity: formatBigNumber(
                  item.quantity,
                  false,
                  {},
                  item.token.decimals,
                ),
              })
            : setTradeSettings({
                price: formatBigNumber(item.breakeven, false),
              })
        }
      >
        <div className={classes.tableCell}>
          {item.isBid
            ? formatBigNumber(item.quantity, true, {}, item.token.decimals)
            : formatBigNumber(item.breakeven)}

          <CurrencyLogo
            className={classes.currencyLogo}
            currency={item.isBid ? item.token : item.paymentToken}
            size='16px'
          />
        </div>
      </TableCell>

      <TableCell
        align='center'
        className={item.isBid ? classes.bid : classes.thin}
        onClick={() =>
          item.isBid
            ? setTradeSettings({
                price: formatBigNumber(item.breakeven, false),
              })
            : setTradeSettings({
                quantity: formatBigNumber(
                  item.quantity,
                  false,
                  {},
                  item.token.decimals,
                ),
              })
        }
      >
        <div className={classes.tableCell}>
          {item.isBid
            ? formatBigNumber(item.breakeven)
            : formatBigNumber(item.quantity, true, {}, item.token.decimals)}

          <CurrencyLogo
            className={classes.currencyLogo}
            currency={item.isBid ? item.paymentToken : item.token}
            size='16px'
          />
        </div>
      </TableCell>

      <TableCell
        align='center'
        className={item.isBid ? classes.bid : classes.thin}
        onClick={() =>
          item.isBid
            ? setTradeSettings({ price: formatBigNumber(item.price, false) })
            : setTradeSettings({
                quantity: formatBigNumber(
                  item.available,
                  false,
                  {},
                  item.token.decimals,
                ),
              })
        }
      >
        <div className={classes.tableCell}>
          {item.isBid
            ? formatBigNumber(item.price)
            : formatBigNumber(item.available, true, {}, item.token.decimals)}

          <CurrencyLogo
            className={classes.currencyLogo}
            currency={item.isBid ? item.paymentToken : item.token}
            size='16px'
          />
        </div>
      </TableCell>

      <TableCell
        align='center'
        className={item.isBid ? classes.bid : classes.thin}
      >
        {item.isBid ? (
          <Tooltip title='Mint to Fill Order'>
            <SwapVert
              className={classes.swapVertLogo}
              onClick={() => {
                setSelectedItem(item);
                setMintToSellModalOpen(true);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={selectedToken ? 'Copy Sell Order': 'Insufficient Funds'}>
            <FileCopy
              className={classes.fileCopyLogo}
              onClick={() => {
                if (selectedToken) {
                  setSelectedItem(item);
                  setCopySellOrderModalOpen(true);
                }
              }}
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );

  return !selectedOption ? (
    <Box marginTop={4}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Box marginBottom={2}>
          <Typography variant='body1'>
            No options exist matching this filter.
          </Typography>
        </Box>

        <Button
          variant='outlined'
          onClick={activePanel === 'mint' ? onGoToTrade : onGoToMint}
        >
          {activePanel === 'trade' ? 'Mint Your Own' : 'Create Buy Order'}
        </Button>
      </Grid>
    </Box>
  ) : (
    <Grid container direction='column' className={classes.wrapper}>
      {mintToSellModalOpen && (
        <MintToSellModal
          open={mintToSellModalOpen}
          option={option}
          onClose={() => setMintToSellModalOpen(false)}
          orderBookItem={selectedItem}
        />
      )}  

      {copySellOrderModalOpen && (
        <CopySellOrderModal
          open={copySellOrderModalOpen}
          option={option}
          onClose={() => setCopySellOrderModalOpen(false)}
          orderBookItem={selectedItem}
        />
      )}
      <Hidden lgDown>
        <Typography variant='body1' className={classes.title}>
          Order Book
        </Typography>
      </Hidden>

      <Hidden xlUp>
        <Typography variant='body1' align='center' className={classes.title}>
          Order Book
        </Typography>
      </Hidden>

      <Box
        display='flex'
        style={desktop ? {} : { justifyContent: 'center', alignItems: 'center' }}
        flexDirection={!tablet ? 'column' : 'row'}
      >
        <Box marginRight={tablet ? 3 : 0}>
          <Grid container item>
            <DataTable
              headCells={bidHeadCells}
              data={bids}
              loading={loading}
              renderRow={renderOrderBookRow}
              isSinglelineHeader={true}
            />
          </Grid>
        </Box>

        <Box marginLeft={tablet ? 1 : 0}>
          <Grid container item>
            <DataTable
              headCells={askHeadCells}
              data={asks}
              loading={loading}
              renderRow={renderOrderBookRow}
              isSinglelineHeader={true}
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default OptionOrderBook;
