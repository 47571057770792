import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useFilterSettings } from 'state/market/hooks';

import { PageWithSidebar } from 'layouts';
import { MarketOptionFilter } from 'components';
import {
  ListedOptionsTable,
  StatisticsHeader,
  MarketplaceHero,
} from './components';

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: 48,
    paddingBottom: 32,
  },
  pageTitleMobile: {
    fontFamily: '"Teko"',
    fontWeight: 600,
    fontSize: '48px',
    margin: '82px 10px 26px',
  },
  marginForHero: {
    marginBottom: '14px',
  },
}));

const Marketplace: React.FC = () => {
  const [hasRendered, setHasRendered] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const thinDesktops = useMediaQuery(theme.breakpoints.down('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { setFilterSettings } = useFilterSettings();

  useEffect(() => {
    if (!hasRendered) {
      setFilterSettings({ token: null });
      setHasRendered(true);
    }
  }, [hasRendered, setFilterSettings]);

  return (
    <PageWithSidebar mobile={mobile}>
      <Grid container>
        <Grid item md={12} lg={8}>
          <Hidden mdUp>
            <Box clone className={thinDesktops ? classes.marginForHero : ''}>
              <Typography
                component='h2'
                variant='h3'
                color='textPrimary'
                className={
                  !mobile ? classes.pageTitle : classes.pageTitleMobile
                }
              >
                Marketplace
              </Typography>
            </Box>
          </Hidden>

          <Hidden smDown>
            <MarketplaceHero />
          </Hidden>

          <StatisticsHeader />

          <Hidden lgUp>
            <Box width='100%'>
              <MarketOptionFilter center />
            </Box>
          </Hidden>

          <ListedOptionsTable />
        </Grid>

        <Hidden mdDown>
          <Grid item xs={4}>
            <Box position='fixed' width={1 / 4}>
              <MarketOptionFilter />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </PageWithSidebar>
  );
};

export default Marketplace;
