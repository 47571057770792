import React, { useState, useRef, memo, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { Line, Bar } from 'react-chartjs-2';
import { debounce } from 'lodash';
import moment from 'moment';
import { formatCompact } from 'utils/formatNumber';
import { usePeriodFetchTokenPairs } from 'hooks';
import { TokenPairHistoryProps } from 'hooks/usePeriodFetchTokenPairs';
export interface ChartProps {
  id?: string;
  type?: string;
  label?: string;
  height: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chart: {
      borderRadius: 24,
      width: '100%',
      height: (props: ChartProps) => {
        return props.height + 30;
      },
      border: '1px solid #66A6FF',
      position: 'relative',
      overflow: 'hidden',
      background: 'black',

      '& canvas': {
        bottom: 0,
        position: 'absolute',
        left: 0,
      },
    },
    title: {
      color: 'rgba(255,255,255,0.9)',
      fontSize: 12,
      position: 'absolute',
      top: 20,
      left: 20,
      zIndex: 3,
    },
    value: {
      color: 'white',
      fontSize: 14,
      position: 'absolute',
      fontWeight: 700,
      top: 40,
      left: 20,
      zIndex: 3,
    },
    date: {
      color: 'rgba(255, 255, 255, 0.9)',
      fontSize: 12,
      position: 'absolute',
      top: 64,
      left: 20,
      zIndex: 3,
    },
    navItem: {
      padding: 0,
      minWidth: 'auto',
      marginLeft: '16px',
      fontSize: '12px',

      '& span': {
        color: '#B151E8',
      },
    },
    navContainer: {
      position: 'absolute',
      right: 20,
      top: 20,
      zIndex: 1000,
    },
  }),
);

const Chart: React.FC<ChartProps> = ({ id, type = 'line', label, height }) => {
  const classes = useStyles({ height });
  const chartRef = useRef<Line | Bar>(null);
  const [period, setPeriod] = useState<string>('ALL');
  const openInterestRef = useRef(null);
  const timestampRef = useRef(null);
  const tokenPairHistoryItems = usePeriodFetchTokenPairs(period, id);
  const [hoverIndex, setHoverIndex] = useState(0);

  useEffect(() => {
    if (tokenPairHistoryItems.length)
      setHoverIndex(tokenPairHistoryItems.length - 1);
  }, [tokenPairHistoryItems.length]);

  const data = tokenPairHistoryItems
    .filter((item: TokenPairHistoryProps) => {
      return !!Number(type === 'line' ? item.openInterest : item.totalVolume);
    })
    .map((item: TokenPairHistoryProps) => {
      return Number(type === 'line' ? item.openInterest : item.totalVolume);
    });

  const dataSet = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 120, 0, 240);
    gradient.addColorStop(0, '#3cead9');
    gradient.addColorStop(1, '#5b81e9');

    return {
      labels: Array(data.length).fill(''),
      datasets: [
        {
          fill: 'start',
          backgroundColor:
            type === 'line' ? gradient : Array(data.length).fill(gradient),
          maxBarThickness: 56,
          data,
        },
      ],
    };
  };

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 2,
      },
      line: {
        tension: 0.1,
      },
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    tooltips: { enabled: false },
    hover: { mode: null },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    onHover: debounce((e: any) => {
      const item = chartRef?.current?.chartInstance.getElementAtEvent(e);
      if (item?.length) {
        const selectedIndex = item[0]._index;
        setHoverIndex(selectedIndex);
      }
    }, 100),
  };

  return (
    <Paper className={classes.chart}>
      <Typography
        component='h1'
        variant='h3'
        color='textPrimary'
        className={classes.title}
      >
        {label}
      </Typography>
      {tokenPairHistoryItems[hoverIndex] && (
        <Typography
          component='h2'
          variant='h3'
          color='textPrimary'
          className={classes.value}
          ref={openInterestRef}
        >
          {formatCompact(
            type === 'line'
              ? tokenPairHistoryItems[hoverIndex].openInterest
              : tokenPairHistoryItems[hoverIndex].totalVolume,
          )}
        </Typography>
      )}
      {tokenPairHistoryItems[hoverIndex] && (
        <Typography
          component='h3'
          variant='h3'
          color='textPrimary'
          className={classes.date}
          ref={timestampRef}
        >
          {moment
            .unix(Number(tokenPairHistoryItems[hoverIndex].timestamp))
            .format('MMM DD YYYY')}
        </Typography>
      )}
      <Grid className={classes.navContainer}>
        <Button className={classes.navItem} onClick={() => setPeriod('1W')}>
          1W
        </Button>
        <Button className={classes.navItem} onClick={() => setPeriod('1M')}>
          1M
        </Button>
        <Button className={classes.navItem} onClick={() => setPeriod('ALL')}>
          ALL
        </Button>
      </Grid>

      {type === 'line' && (
        <Line data={dataSet} options={options} ref={chartRef} />
      )}
      {type === 'bar' && (
        <Bar data={dataSet} options={options} ref={chartRef} />
      )}
    </Paper>
  );
};

export default memo(Chart);
