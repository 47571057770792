import { useSelector } from 'react-redux';

import { AppState } from 'state';

export const useBondingCurve = () => {
  const settings = useSelector<AppState, AppState['bondingCurve']['settings']>(
    (state) => state.bondingCurve.settings,
  );

  return {
    ...settings,
  };
};
