import { createAction } from '@reduxjs/toolkit';

import { OptionType, Option } from 'web3/options';
import { TokenDenominator, Token } from 'web3/tokens';
import { ExerciseType, WithdrawType } from './reducer';

export interface SetOptionSettings {
  denominator?: TokenDenominator;
  selectedToken?: Token | null;
  selectedExpiration?: Date;
  optionType?: OptionType;
  strikePrice?: string | null;
  selectedOption?: Option | null;
}

export interface SetExerciseSettings {
  type?: ExerciseType;
  quantity?: string;
  maxSlippage?: string;
}

export interface SetWithdrawSettings {
  type?: WithdrawType;
  quantity?: string;
}

export interface SetTransferSettings {
  quantity?: string;
  to?: string;
}

export const setOptionSettings = createAction<SetOptionSettings>(
  'options/setOptionSettings',
);

export const setExerciseSettings = createAction<SetExerciseSettings>(
  'options/setExerciseSettings',
);

export const setWithdrawSettings = createAction<SetWithdrawSettings>(
  'options/setWithdrawSettings',
);

export const setTransferSettings = createAction<SetTransferSettings>(
  'options/setTransferSettings',
);
