import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Card, Typography, Chip, Box } from '@material-ui/core';
import { formatUnits } from 'ethers/lib/utils';
import { get } from 'lodash';
import cx from 'classnames';

import { getTokenPairs } from 'graphql/queries';
import { getAvailabilities, TokenPair, PairAvailability } from 'web3/tokens';
import { useDenominatorAddress } from 'hooks';
import { getDenominatorLogo } from 'utils/getDenominatorLogo';
import { formatCompact } from 'utils/formatNumber';
import { usePrices } from 'state/application/hooks';
import { contracts } from 'web3/contracts';

import { TokenPairLogo, Loader } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      flexDirection: 'column',
      height: 'calc(100vh - 140px)',
      width: '100%',
      marginTop: '72px',
    },
    pageTitleMobile: {
      fontFamily: '"Teko"',
      fontWeight: 600,
      fontSize: '48px',
      margin: '4px 0px 4px',
    },
    cardsWrapper: {
      flexDirection: 'column',
      overflowY: 'scroll',
      width: '100%',
      height: '100%',
    },
    cardRoot: {
      width: 'calc(100% - 4px)', 
      minHeight: '190px',
      height: 'auto', 
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '8px 0px 8px 4px',
      cursor: 'pointer',
    },
    col: {
      flexDirection: 'column', 
      justifyContent: 'space-evenly',
    },
    elementWrapper: {
      justifyContent: 'center',
      margin: '4px 6px',
    },
    header: {
      alignSelf: 'center',
      flexDirection: 'column',
      width: 'calc(100% - 28px)',
      margin: '16px 0px 16px' 
    },
    greenText: {
      color: theme.palette.success.main,
    },
    redText: {
      color: theme.palette.error.main,
    },
    thinText: {
      fontWeight: 300,
      marginRight: '16px',
      whiteSpace: 'nowrap',
    },
    loaderWithMessage: {
      position: 'absolute',
      top: '120px',
      flexDirection: 'column',
      alignItems: 'center', 
      margin: '0 auto',
    },
  }),
);



export default function PairSummariesMobileCards() {
  const classes = useStyles();
  const history = useHistory();
  const denominatorAddress = useDenominatorAddress();
  const prices = usePrices();
  const [high, setHigh] = React.useState(true);

  const { data: tokenPairsData } = useQuery(
    getTokenPairs,
    {
      variables: { denominatorAddress: denominatorAddress || contracts.DAI[1] },
    },
  );

  const tokenPairs: TokenPair[] = useMemo(
    () => get(tokenPairsData, 'tokenPairs') || [],
    [tokenPairsData],
  );

  const availabilities = useMemo(() => getAvailabilities(tokenPairs, prices), [
    tokenPairs,
    prices,
  ]);


  const getOpenInterest = (pair: TokenPair) => {
    const price = prices[pair.token.symbol];
    return formatCompact(
      price * Number(formatUnits(pair.openInterest, pair.token.decimals),
    ), 1, );
   };

   const getVolume = (pair: TokenPair) => {
    const price = prices[pair.token.symbol];
    return formatCompact(price * Number(
      formatUnits(pair.totalVolume, pair.token.decimals),
    ), 1, );
   };

   const getAvailabilityValue = (pair: TokenPair) => {
    const availability = availabilities[pair.name];
    return availability === 'Low' ? 0 : 1;
   };


  const sortByOpenInterest = () => {
    tokenPairs.sort((a, b) => parseFloat(getOpenInterest(high ? b : a)) - parseFloat(getOpenInterest(high ? a : b)));
    setHigh(!high);
  }

  const sortByVolme = () => {
    tokenPairs.sort((a, b) => parseFloat(getVolume(high ? b : a)) - parseFloat(getVolume(high ? a : b)));
    setHigh(!high);
  }

  const sortByAvilability = () => {
    tokenPairs.sort((a, b) => getAvailabilityValue(high ? b : a) - getAvailabilityValue(high ? a : b));
    setHigh(!high);  
  }


  const cards = tokenPairs && tokenPairs.length > 0 ? tokenPairs.map((pair, index) => {
    const availability = availabilities[pair.name];
    const denominatorLogo = getDenominatorLogo(pair.denominator.symbol);

    return (
      <Card className={classes.cardRoot} key={pair.name} onClick={() => history.push(`/pairs/${pair.name}`)}>
        <Box display="flex" className={classes.col}>
          <Box display="flex" className={classes.elementWrapper}>
            <TokenPairLogo pair={pair} backgroundIndex={index} />
          </Box>
          <Box display="flex" className={classes.elementWrapper}>
            <Typography className={classes.thinText}><b>{pair.token.symbol}</b>-{pair.denominator.symbol}</Typography>
          </Box>
        </Box>
        <Box display="flex" className={classes.col}>
          <Box display="flex" className={classes.col}>
            <Typography>Open Interest:</Typography>
            <Box display="flex">
              <b style={{ marginRight: '0.25rem' }}>
                {getOpenInterest(pair)}
              </b>
              <img
                src={denominatorLogo}
                height={16}
                width={16}
                alt={pair.denominator.symbol}
              />
            </Box>
          </Box>
          <Box display="flex" className={classes.col}>
            <Typography>Volume:</Typography>
            <Box display="flex">
            <b style={{ marginRight: '0.25rem' }}>
              {getVolume(pair)}
            </b>
            <img
              src={denominatorLogo}
              height={16}
              width={16}
              alt={pair.denominator.symbol}
            />
            </Box>
          </Box>
          <Box display="flex" className={classes.col}>
            <Typography>Availablility:</Typography>
            <Typography
              className={cx({
                [classes.greenText]: availability === PairAvailability.HIGH,
                [classes.redText]: availability === PairAvailability.LOW,
              })}
            >{availability}</Typography>
          </Box>
        </Box>
      </Card>
    )
  }) : false;

  return Object.values(prices).length > 0 ? (
    <Box display="flex" className={classes.body}>
      <Box display="flex" className={classes.header}>
        <Typography className={classes.pageTitleMobile}>Market Pairs</Typography>
        <Box display="flex" flexDirection="column" minHeight="80px" justifyContent="space-evenly" style={{ margin: '8px 0'}}>
          <Typography variant="h5">Sort by: </Typography>
          <Box display="flex" width="94%" justifyContent="space-between"  style={{ margin: '4px 0 0'}}>
            <Chip onClick={sortByOpenInterest} label="Open Interest" size="small" variant="outlined" style={{ margin: '0 4px 0 0'}}/>
            <Chip onClick={sortByVolme} label="Volume" size="small" variant="outlined" style={{ margin: '0 4px'}} />
            <Chip onClick={sortByAvilability} label="Availability" size="small" variant="outlined" style={{ margin: '0 0 0 4px'}}/>
          </Box>
        </Box>
      </Box>
      <Box display="flex" className={classes.cardsWrapper}>
        {cards}
      </Box>
    </Box>
  ) : (
    <Box display="flex" className={classes.loaderWithMessage}>
      <Loader />
    </Box>
  );
}
