import React, { useEffect } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';

import { getTokenPair } from 'graphql/queries';
import { useFilterSettings } from 'state/market/hooks';
import { PageWithSidebar } from 'layouts';
import {
  MarketOptionFilter,
  Loader,
  Breadcrumb,
  TokenPriceChip,
} from 'components';
import { PairOptionsTable, Chart } from './components';

export interface PairProps {
  tokenSymbol: string;
  denominatorSymbol: string;
}

const Pair: React.FC<PairProps> = ({ tokenSymbol, denominatorSymbol }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { token, setFilterSettings } = useFilterSettings();

  const { loading, data } = useQuery(getTokenPair, {
    variables: { id: `${tokenSymbol}/${denominatorSymbol}` },
  });

  const pair = get(data, 'tokenPair', null);

  useEffect(() => {
    if (pair && token !== pair.token) {
      setFilterSettings({ token: pair.token });
    }
  }, [token, pair, setFilterSettings]);

  return (
    <PageWithSidebar mobile={mobile}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Breadcrumb
            mobile={mobile}
            steps={[
              { title: 'Dashboard', link: '/' },
              { title: 'Pairs', link: '/pairs' },
              { title: `${tokenSymbol}-${denominatorSymbol}` },
            ]}
          />

          <Grid container spacing={4} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} md={6}>
              <Chart
                id={`${tokenSymbol}/${denominatorSymbol}`}
                type='line'
                label='Open Interest'
                height={240}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Chart
                id={`${tokenSymbol}/${denominatorSymbol}`}
                type='bar'
                label='Volume'
                height={240}
              />
            </Grid>
          </Grid>

          {loading && <Loader />}

          {pair && (
            <Grid container>
              <Box marginRight={1}>
                <TokenPriceChip token={pair.token} />
              </Box>

              <TokenPriceChip token={pair.denominator} />
            </Grid>
          )}

          {pair && (
            <Box style={!mobile ? {} : { marginTop: '24px' }}>
              <PairOptionsTable pair={pair} />
            </Box>
          )}
        </Grid>

        <Hidden smDown>
          <Grid item xs={4}>
            <Box position='fixed' width={1 / 4}>
              <MarketOptionFilter hidePair />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </PageWithSidebar>
  );
};

export default Pair;
