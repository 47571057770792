import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    padding: 30,
    marginTop: 50,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: 20
  },

  title: {
    fontSize: 18
  },

  subtitle: {
    fontSize: 12,
    color: '#808191',
    fontFamily: '"Roboto Mono"'
  }
})

export const Container = (props: any) => {
  const classes = useStyles();
  return <div className={classes.container}>{ props.children }</div>;
};

export const Title = (props: any) => {
  const classes = useStyles();
  return <div className={classes.title}>{ props.children }</div>;
};

export const SubTitle = (props: any) => {
  const classes = useStyles();
  return <div className={classes.subtitle}>{ props.children }</div>;
};
