import { get } from 'lodash';
import { utils } from 'ethers';

import DiamondHands from '../constants/ropeMerkleRoots/diamond_hands.json';
import { useWeb3 } from 'state/application/hooks';
import { useTransact } from 'hooks';

export function useClaimNft(amount: number = 1) {
  const { account, signer, contracts } = useWeb3();
  const transact = useTransact();
  const formattedAddr = account === '' ? account : utils.getAddress(account);

  const index = get(DiamondHands, `claims.${formattedAddr}.index`);
  const merkleProof = get(DiamondHands, `claims.${formattedAddr}.proof`);

  const useClaimNft = () =>
    transact(
      contracts.ropeMerkleClaimer
        ?.connect(signer!)
        .claim(89, index, formattedAddr, amount, merkleProof),
      {
        closeOnSuccess: true,
        option: null,
        description: `Claiming Diamond Hands NFT`,
      },
    );

  return useClaimNft;
}
