import React from 'react';
import { Paper, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, lighten, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import cx from 'classnames';

import { OptionType } from 'web3/options';
import { useOptionSettings } from 'state/options/hooks';
import { useTradeSettings } from 'state/market/hooks';

import { CurrencyLogo, Loader } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  thin: {
    fontWeight: 300,
  },

  thick: {
    fontWeight: 500,
  },

  callGreen: {
    color: theme.palette.success.main,
  },

  putRed: {
    color: theme.palette.error.main,
  },

  rightAlign: {
    textAlign: 'right',
  },

  subtitle: {
    marginTop: 8,
    marginBottom: 16,
    color: theme.palette.grey[400],
    fontSize: '0.8rem',
  },

  createAndSell: {
    borderColor: theme.palette.primary.main,
  },

  fieldHint: {
    position: 'absolute',
    bottom: -20,
    right: 0,
  },

  createOnly: {
    marginTop: 16,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: lighten(theme.palette.common.black, 0.05),
    },
  },
}));

const MintOptionDetailsPanel: React.FC = () => {
  const { quantity } = useTradeSettings();
  const classes = useStyles();

  const {
    denominator,
    selectedToken,
    selectedExpiration,
    optionType,
    strikePrice,
  } = useOptionSettings();

  return (
    <Grid item>
      <Box paddingBottom={3}>
        <Typography>Confirm Details Below</Typography>
      </Box>

      <Grid container direction='row' wrap='nowrap'>
        <Box width={1} marginRight={1}>
          <Paper>
            <Box padding={4}>
              <Grid item container direction='column'>
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                >
                  <Box paddingY={2}>
                    <Typography>I want to mint</Typography>
                  </Box>

                  <Box paddingBottom={4}>
                    {selectedToken ? (
                      <CurrencyLogo currency={selectedToken} size='64px' />
                    ) : (
                      <Loader />
                    )}
                  </Box>
                </Grid>

                <Box clone width={1}>
                  <Grid item container>
                    <Box width={1 / 2}>
                      <Grid item container direction='column'>
                        <div className={classes.thin}>Token</div>
                        <div className={classes.thin}>Denomination</div>
                        <div className={classes.thin}>Strike Price</div>
                        <div className={classes.thin}>Expiration</div>
                        <div className={classes.thin}>Call / Put</div>
                        <div className={classes.thin}>Contract Amount</div>
                      </Grid>
                    </Box>

                    <Box width={1 / 2}>
                      <Grid
                        item
                        container
                        direction='column'
                        alignItems='flex-end'
                      >
                        <div
                          className={cx(classes.thick, classes.rightAlign, {
                            [classes.callGreen]: optionType === OptionType.Call,
                            [classes.putRed]: optionType === OptionType.Put,
                          })}
                        >
                          {selectedToken?.symbol}
                        </div>
                        <div
                          className={cx(classes.thick, classes.rightAlign, {
                            [classes.putRed]: optionType === OptionType.Call,
                            [classes.callGreen]: optionType === OptionType.Put,
                          })}
                        >
                          {denominator}
                        </div>
                        <div className={classes.rightAlign}>
                          {strikePrice?.toString()}{' '}
                          <span
                            className={cx(classes.thick, {
                              [classes.putRed]: optionType === OptionType.Call,
                              [classes.callGreen]:
                                optionType === OptionType.Put,
                            })}
                          >
                            {denominator}
                          </span>
                        </div>
                        <div className={classes.rightAlign}>
                          {moment(selectedExpiration).format('MMM. Do, YYYY')}
                        </div>
                        <div
                          className={cx(classes.thick, classes.rightAlign, {
                            [classes.callGreen]: optionType === OptionType.Call,
                            [classes.putRed]: optionType === OptionType.Put,
                          })}
                        >
                          {optionType.toString()}
                        </div>
                        <div className={classes.rightAlign}>
                          {quantity.toString()}{' '}
                          <span
                            className={cx(classes.thick, {
                              [classes.callGreen]:
                                optionType === OptionType.Call,
                              [classes.putRed]: optionType === OptionType.Put,
                            })}
                          >
                            {selectedToken?.symbol}
                          </span>
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MintOptionDetailsPanel;
