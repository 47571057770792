import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },

    title: {
      flex: '1 1 100%',
    },

    subtitle: {
      marginTop: '-8px',
      marginBottom: '8px',
      marginLeft: '1rem',
      fontSize: '0.85rem',
      color: theme.palette.text.secondary,
    },
  }),
);

const OptionsTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant='h6' id='tableTitle'>
          Listed Options
        </Typography>
      </Toolbar>

      <Typography className={classes.subtitle} variant='body1'>
        Select a contract to trade below
      </Typography>
    </>
  );
};

export default OptionsTableToolbar;
