import React, { useCallback, useMemo } from 'react';
import {
  Typography,
  Modal,
  Grid,
  Button,
  Box,
  Fab,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { formatUnits } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import cx from 'classnames';

import { OptionBalance } from 'web3/options';
import { TokenDenominator } from 'web3/tokens';
import { getOptionContract } from 'web3/contracts';
import { useWeb3 } from 'state/application/hooks';
import { useTransferSettings } from 'state/options/hooks';
import { useTransact } from 'hooks';
import { shortenAddress } from 'utils';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ModalContainer, OptionTitle } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  putRed: {
    color: theme.palette.error.main,
  },

  fieldStatus: {
    position: 'absolute',
  },

  desktopFieldStatus: {
    left: '104%',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  greenText: {
    color: theme.palette.success.main,
  }
}));

export interface TransferOptionModalProps {
  open: boolean;
  optionBalance: OptionBalance;
  onClose: () => void;
}

const TransferOptionModal: React.FC<TransferOptionModalProps> = ({
  open,
  optionBalance,
  onClose,
}) => {
  const { account, contracts } = useWeb3();
  const { register } = useForm();
  const { quantity, to, setTransferSettings } = useTransferSettings();
  const transact = useTransact();
  const classes = useStyles();

  const hasSufficientBalance = Number(quantity) <= Number(formatUnits(optionBalance.balance, optionBalance.option.token.decimals));

  const optionContract = useMemo(
    () =>
      getOptionContract(
        contracts,
        TokenDenominator[
          optionBalance.option.denominator
            .symbol as keyof typeof TokenDenominator
        ],
      ),
    [contracts, optionBalance],
  );

  const maxQuantity = Number(formatUnits(optionBalance.balance, optionBalance.option.token.decimals));

  const onTransferOption = useCallback(async () => {
    if (!optionContract || !to || !quantity) return;

    await transact(
      optionContract.safeTransferFrom(
        account,
        to,
        optionBalance.option.id,
        ethers.utils.parseUnits(quantity, optionBalance.option.token.decimals),
        [],
      ),
      {
        option: optionBalance.option,
        description: `Transfer ${quantity} options to ${shortenAddress(to)}`,
      },
    );

    onClose();
  }, [account, optionContract, optionBalance, to, quantity, transact, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Transfer Option</Typography>
        </Box>

        <OptionTitle hideSubtitle option={optionBalance.option} />

        <Box marginY={4}>
          <Typography align='center'>
            You hold{' '}
            {ethers.utils.formatUnits(
              optionBalance.balance.toString(),
              optionBalance.option.token.decimals,
            )}{' '}
            of these options
          </Typography>
        </Box>

        <Box width={1 / 2} marginX='auto' marginTop={4}>
          <Grid container direction='column'>
            <InputLabel>Quantity</InputLabel>
            <Box width={1}>
              <FormControl style={{ width: '100%' }} variant='outlined'>
                <OutlinedInput
                  name='quantity'
                  type='number'
                  value={quantity || ''}
                  inputRef={register}
                  inputProps={
                    optionBalance.option.token.decimals
                      ? {
                          step: 1 * 10 ** -optionBalance.option.token.decimals,
                        }
                      : {}
                  }
                  onChange={(event) => {
                    setTransferSettings({ quantity: event.target.value })
                  }}
                />
                {
                  quantity &&
                    <Typography
                      className={cx(Number(quantity) > 0 && hasSufficientBalance ? classes.greenText : classes.putRed, classes.fieldStatus, classes.desktopFieldStatus)}
                      variant='body2'
                    >
                      { Number(quantity) > 0 && hasSufficientBalance ? 'Valid' : 'Invalid' }
                    </Typography>
                }
                <Fab
                  variant='extended'
                  color='primary'
                  size='small'
                  onClick={() => setTransferSettings({ quantity: maxQuantity.toString() }) }
                >
                  MAX
                </Fab>
              </FormControl>
            </Box>

            <Box width={1} marginTop={2} marginBottom={2}>
              <InputLabel>To Address</InputLabel>
              <FormControl style={{ width: '100%' }}>
                <OutlinedInput
                  name='to'
                  value={to || ''}
                  inputRef={register}
                  onChange={(event) =>
                    setTransferSettings({ to: event.target.value })
                  }
                />
              </FormControl>
            </Box>
          </Grid>
        </Box>

        <Box width={1 / 2} marginX='auto' marginY={2}>
          <Button
            fullWidth
            disabled={
              !to ||
              !quantity ||
              !hasSufficientBalance
            }
            variant='contained'
            color='secondary'
            onClick={onTransferOption}
          >
            Transfer Option
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default TransferOptionModal;
