import React, { PropsWithChildren } from 'react';

interface ProgressRingProps {
  // width: number;
  radius: number;
  stroke: number;
  progress: number;
  isOpaque?: boolean;
  style?: React.CSSProperties;
}

const ProgressRing: React.FC<PropsWithChildren<ProgressRingProps>> = (
  props: PropsWithChildren<ProgressRingProps>,
) => {
  const normalizedRadius = props.radius - props.stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - (props.progress / 100) * circumference;

  // linear-gradient(180deg, #3edaff80 12.5%, #5f75ee80 100%)
  return (
    <svg
      style={{ ...props.style }}
      height={props.radius * 2}
      width={props.radius * 2}
    >
      <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' stopColor='#3edaff' />
        <stop offset='100%' stopColor='#5f75ee' />
      </linearGradient>
      <linearGradient id='gradient2' x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' stopColor='#3edaff' />
        <stop offset='100%' stopColor='#5f75ee' />
      </linearGradient>
      <circle
        stroke={'#333'}
        fill='transparent'
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: circumference - circumference }}
        strokeWidth={props.stroke}
        strokeLinecap={'round'}
        r={normalizedRadius}
        cx={props.radius}
        cy={props.radius}
        z={1}
      />
      <circle
        stroke={`url(#gradient${props.isOpaque ? '2' : ''})`}
        fill='transparent'
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        strokeWidth={props.stroke}
        strokeLinecap={'round'}
        r={normalizedRadius}
        cx={props.radius}
        cy={props.radius}
        z={2}
      />
    </svg>
  );
};

export default ProgressRing;
