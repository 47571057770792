import { createAction } from '@reduxjs/toolkit';

import { OrderType } from './reducer';
import { OptionType } from 'web3/options';
import { Token, TokenDenominator } from 'web3/tokens';
import { PriceRange, DateRange } from './reducer';

export interface SetTradeSettings {
  orderType?: OrderType;
  quantity?: string;
  price?: string;
}

export const setTradeSettings = createAction<SetTradeSettings>(
  'market/setTradeSettings',
);

export interface SetFilterSettings {
  denominator?: TokenDenominator;
  token?: Token | null;
  expirationRange?: DateRange | null;
  strikeRange?: PriceRange | null;
  priceRange?: PriceRange | null;
  optionType?: OptionType | null;
  createdByUserOnly?: boolean;
}

export const setFilterSettings = createAction<SetFilterSettings>(
  'market/setFilterSettings',
);
