import React from 'react';
import { Typography, Modal, Button, Box } from '@material-ui/core';
import { get } from 'lodash';
import { utils } from 'ethers';

import DiamondHands from '../../constants/ropeMerkleRoots/diamond_hands.json';
import { useWeb3 } from 'state/application/hooks';
import { useClaimNft } from 'hooks';

import { ModalContainer } from 'components';
import DiamondHandsUncommon from 'assets/images/diamond-hands-uncommon.jpg';

export interface ClaimDiamondHandsModalProps {
  open: boolean;
  onClose: () => void;
}

const ClaimDiamondHandsModal: React.FC<ClaimDiamondHandsModalProps> = ({
  open,
  onClose,
}) => {
  const { account } = useWeb3();
  const claimNft = useClaimNft(1);
  const hasClaim =
    account === ''
      ? false
      : get(DiamondHands, `claims.${utils.getAddress(account)}.index`, null) !==
        null;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer size='md'>
        <Box width={1} marginBottom={2}>
          <Typography variant='h5'>Claim Diamond Hands NFT</Typography>
        </Box>

        <Box width={1 / 2} marginX='auto' marginTop={4}>
          <img
            src={DiamondHandsUncommon}
            width={460}
            height='auto'
            alt='Diamond Hands Ape'
          />
        </Box>

        <Box width={1 / 2} marginX='auto' marginY={2}>
          <Button
            fullWidth
            disabled={!hasClaim}
            variant='contained'
            color='secondary'
            onClick={claimNft}
          >
            Claim NFT
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default ClaimDiamondHandsModal;
